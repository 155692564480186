import React, { Component } from 'react';
import { Card, Header, Segment, Button, Label } from 'semantic-ui-react';
import  HeaderGeneric from "../menus/HeaderGeneric";
import { connect } from 'react-redux';
import { firebaseAuth } from '../../firebase';
import { clearSelectedProgram,  retrieveProgramLayout, retrieveProgramHistory } from '../../actions';
import ProgramBuilder from './ProgramBuilder';
import { HorizontalDiv, StyledContainer, SizingContainer } from '../StyledComponents';
import { CheckoutModal } from '../common/CheckoutModal';
import { priceToString } from '../../commonMethods';

class Program extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  renderProgramDetails = () => {
    const { config, program, retrieveProgramLayout, retrieveProgramHistory } = this.props;
    const { programBuilder } = program;
    const { id, productId, name, description, price, frequency, tags, teamName, coachName } = programBuilder
    const owned = this.isOwned(id, program.userPrograms);
    return (
      <SizingContainer>
        <Card.Content style={{ marginBottom: '5px', display: 'flex', justifyContent: 'center', paddingTop: '50px', height: '250px', backgroundImage : "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/assets/pexels-tima-miroshnichenko-6389074.jpg')", backgroundSize : 'cover'}}>
          <Header as='h2' style={{ color: 'ghostwhite'}}>{name}
          <Header.Subheader style={{ marginTop: '10px', color: 'lightgrey'}}> {coachName && `Created by ${coachName}`} {teamName && `of ${teamName}`}</Header.Subheader>
          </Header>
        </Card.Content>
            <HorizontalDiv>
              <span></span>
              <div>{tags.map((tag,index) => <Label key={'tag-' + index} tag size='small'>{tag}</Label>)}</div>
            </HorizontalDiv>
            <Header style={{marginTop : '8px'}} as='h4'>{frequency} days/week</Header>
          {owned ?
            <Button primary onClick={() => {
              retrieveProgramLayout(id, programBuilder);
              retrieveProgramHistory(id);
            }}> View Program </Button>
            :
            <>
              <Header style={{marginTop : 0}} as='h2'> {priceToString(price)} </Header>
              <CheckoutModal trigger={<Button primary>Get It Now!</Button>} id={id} name={name} price={priceToString(price)} stripeId={config.user.stripeId} productId={productId}/>
            </>
          }
          <Segment>
              {description}
          </Segment>
      </SizingContainer>
    )
  }

  isOwned = (programId, programs) => {
    let isOwned = false;
     programs.forEach(program => {
      if (program.id === programId)
        isOwned = true;
    });
    return isOwned;
  }

  render() {
    const { program, clearSelectedProgram } = this.props;
    const { programBuilder } = program;
    const { id, creator, layout } = programBuilder;
    let editable = false;
    if (creator === firebaseAuth.currentUser.uid)
      editable = true;
    return (
      <StyledContainer>
        <HeaderGeneric
            itemLeft={ <Button size='huge' style={{background: 'transparent', padding: '10px'}} icon="arrow left"
                            onClick={(e) => clearSelectedProgram() }/>
              }
          />
        {!id || layout ?
            <ProgramBuilder
              editable={editable}
            />
            :
            this.renderProgramDetails()
        }
      </StyledContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    config : state.config,
    program : state.program
  }
}

export default connect(mapStateToProps, { clearSelectedProgram, retrieveProgramLayout, retrieveProgramHistory })(Program);
