import { LOGIN_USER, LOGIN_USER_SUCCESS, LOGIN_USER_FAIL} from '../actions/types';
import Debug from '../Debug';
const INITIAL_STATE =  {
  user: null,
  loading: false,
  error: ''
}

const AuthReducer = (state = INITIAL_STATE, action) => {
    Debug(action);
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: '' };
    case LOGIN_USER_FAIL:
      return { ...state, loading: false, error: 'Authentication Failed. ' + action.payload };
    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    default:
      return state;
  }
}

export default AuthReducer;
