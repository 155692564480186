import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import Debug from '../../Debug';
import { AuthContext } from "./Auth";

const LoggedOutRoute = ({ component: RouteComponent, ...rest }) => {
  const {currentUser} = useContext(AuthContext);
  Debug("currentUser", currentUser);
  return (
    <Route
      {...rest}
      render={routeProps =>
        !currentUser ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={"/"} />
        )
      }
    />
  );
};

//TODO FIX LEAK ON SIGN IN

export default LoggedOutRoute;
