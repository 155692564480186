import React, { Component } from 'react';
import { Header, Card } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Debug from '../../Debug';
import { retrieveExerciseHistory } from '../../actions';
import {  dateToMonthString } from '../../commonMethods';
import ExerciseSummary from './ExerciseSummary';
import { Loading } from '../common/Loading';

class ExerciseHistory extends Component {

  componentDidMount() {
    this.props.retrieveExerciseHistory(this.props.athlete, this.props.exercise.key);
  }

  componentDidUpdate(prevProps) {
    if (this.props.athlete !== prevProps.athlete)
      this.props.retrieveExerciseHistory(this.props.athlete, this.props.exercise.key);
  }

  isLoaded = (start) => {
    return this.props.historyLog.loadedMonths.includes(dateToMonthString(start));
  }

  getWorkoutsWithExercise = (exercise, workouts) => {
    let filteredWorkouts = []
    for (var i=0; i<workouts.length; i++) {
      let exerciseWorkout = this.isExerciseInWorkout(exercise, workouts[i])
      if (exerciseWorkout !== false)
        filteredWorkouts.push({...exerciseWorkout, workoutId : workouts[i].id, date: workouts[i].date});
    }
    Debug(filteredWorkouts);
    return filteredWorkouts;
  }

  isExerciseInWorkout = (exercise, workout) => {
    for (var i=0; i<workout.exerciseArr.length; i++){
        if (workout.exerciseArr[i].exercise === exercise)
            return workout.exerciseArr[i];
    }
    return false;
  }

  getExerciseSummaries = (exercise, exerciseWorkouts) => {
    let exerciseSum = [];
    exerciseWorkouts.forEach((workout, i) => {
      let exerciseObj = this.isExerciseInWorkout(exercise, workout)
      if (exerciseObj !== false) {
        exerciseObj = {...exerciseObj, workoutId : exerciseWorkouts[i].id, date: exerciseWorkouts[i].date};
        exerciseSum.push(exerciseObj);
      }
    });

    return exerciseSum;
  }

  render() {
    const { exercise, historyLog } = this.props;
    const {loading, exerciseWorkouts} = historyLog;

    const exerciseSum = this.getExerciseSummaries(exercise.key, exerciseWorkouts);

    /*
    let exerciseWorkouts = this.getWorkoutsWithExercise(exercise.key, workouts);
    exerciseWorkouts = orderByDateDesc(exerciseWorkouts, 'date');
    */
    /*
    if (workouts.length < 100 && exerciseWorkouts.length < 10) {
      if (loadedMonths.length > 0) {
        var month = loadedMonths[loadedMonths.length-1];
        Debug(month);
        let last = monthToDateString(month);
        let start = getPreviousMonthStart(new Date(last));
        let end = getPreviousMonthEnd(new Date(last));
        Debug(start, end);

      }
    }
    */
    if (loading)
     return <Loading active={loading} />
    else if (exerciseSum.length === 0)
      return <Header as='h4' >No workouts logged containing this exercise. </Header>

//Card on Click - open track modal with full workout summary
    return (
        <div style={{width: '100%'}}>
            {exerciseSum.map((exerciseWorkout, i) => (
              <Card style={{width: '100%', padding:'3%', paddingBottom: '0%'}} key={'exercise-workout-' + i}>
              <Header as='h4' dividing>{exerciseWorkout.date.toDate().toLocaleDateString()}</Header>
                <ExerciseSummary
                  key={'exercise-sum-' + i}
                  exerciseIndex={exerciseWorkout.exerciseIndex}
                  exercise={exerciseWorkout.exercise}
                  exerciseNotes={exerciseWorkout.exerciseNotes}
                  weightArr={exerciseWorkout.weightArr}
                />
              </Card>
            ))}
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
           historyLog: state.historyLog
         }
};

export default connect(mapStateToProps, {retrieveExerciseHistory})(ExerciseHistory);
