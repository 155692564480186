import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Transition, Header, Button, Radio, Segment, List, Modal, Icon, Input } from 'semantic-ui-react';
import HeaderMain from './HeaderMain';
import HeaderGeneric from '../menus/HeaderGeneric';
import { SizingContainer, StyledContainer, HorizontalDiv } from '../StyledComponents';
import { ListItem } from '../common/ListItem';
import { InfoTooltip } from '../common/InfoTooltip';
import { getAthleteName, getTeamRole, getRoleMembers, hasCoachPro  } from '../../commonMethods';
import { MemberSelectModal } from '../team/MemberSelectModal';
import { updateUserConfig, updateTeamConfig } from '../../actions';
import { firebaseAuth, firebaseDb, teamsRef } from '../../firebase';
import { SuccessPortal } from '../common/SuccessPortal';
import { UpgradeModal } from '../common/UpgradeModal';
import { CreateTeamModal } from '../team/CreateTeamModal';
import { COACH, ASSISTANT_COACH } from '../../assets/literals';

const AddTrackModal = ({ trigger, header, teamId, tracks, updateTeamConfig }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  return <Modal
    onClose={() => setOpen(false)}
    onOpen={() => setOpen(true)}
    open={open}
    size='tiny'
    trigger={trigger}
  >
    <Header icon>
      {header}
    </Header>
    <Modal.Content>
      <StyledContainer>
        <Input placeholder='Track Name...' onChange={(e, data) => setName(data.value)} value={name} />
      </StyledContainer>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={() => setOpen(false)}>
        <Icon name='remove' /> Cancel
      </Button>
      <Button primary onClick={() => {
          const id = Math.random().toString(36).substring(2, 11);
          let newTrackArr = _.cloneDeep(tracks);
          newTrackArr.push({id, name, athletes: []});
          updateTeamConfig(teamId, 'tracks', newTrackArr);
          setName("");
          setOpen(false);
        }}>
        <Icon name='checkmark' /> Create
      </Button>
    </Modal.Actions>
  </Modal>

}

const ConfirmModal = ({header, text, onConfirm, trigger}) => {
  const [open, setOpen] = useState(false)

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size='small'
      trigger={trigger}
    >
      <Header icon>
        {header}
      </Header>
      <Modal.Content>
        <p>{text}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='red' inverted onClick={() => setOpen(false)}>
          <Icon name='remove' /> No
        </Button>
        <Button color='green' inverted onClick={() => {
            onConfirm();
            setOpen(false)
          }}>
          <Icon name='checkmark' /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const getNewTrackArr = (newAthleteArr, tracks, trackIndex) => {
  let newTrackArr = _.cloneDeep(tracks);
  newTrackArr[trackIndex] = {...newTrackArr[trackIndex], athletes : newAthleteArr}
  return newTrackArr;
}

const TrackListItem = ({tracks, index, members, teamId, updateTeamConfig, readOnly}) => {
  const { name, athletes } = tracks[index];
  const [expanded, setExpanded] = useState(false);

  return <List.Item
    key={'track-' + index}
  >
    <HorizontalDiv>
      <Header as='h3'> {name} </Header>
      <Button size="huge" style={{backgroundColor: 'transparent'}} icon onClick={() => setExpanded(!expanded)}>
        <Icon name={expanded ? 'caret down' : 'caret left'}/>
      </Button>
    </HorizontalDiv>
    {expanded &&
      <>
        {athletes && athletes.map((athlete, j) => {
          const athleteName = getAthleteName(members,athlete);
          return <ListItem
                    key={athlete + index}
                    leftHandHeader={athleteName}
                    rightHand={!readOnly && <ConfirmModal
                                    trigger={<Button style={{background : 'transparent'}} icon="x" />}
                                    header="Remove Athlete"
                                    text={`Are you sure you want to remove ${athleteName} from the ${name} track?`}
                                    onConfirm={() => {
                                      let newAthleteArr = athletes.filter(item => item !== athlete);
                                      updateTeamConfig(teamId, 'tracks', getNewTrackArr(newAthleteArr, tracks, index));
                                    }}
                              />}
                  />
        }
        )}
        <br/>
        {!readOnly && <><MemberSelectModal
         trigger={<Button size='tiny' fluid> Add Athlete </Button>}
         header='Add Athletes'
         confirmText="Select the athletes you'd like to add to the track."
         members={members}
         onConfirm={(newAthletes) => {
             let newAthleteArr = [...new Set([...athletes, ...newAthletes])];
             updateTeamConfig(teamId, 'tracks', getNewTrackArr(newAthleteArr, tracks, index));
           }
         }
        />
        <br/>
        </>}
      </>
    }
    <br/>
  </List.Item>
}




class Team extends Component {
  constructor() {
    super();
    this.state = {
      inputCode: '',
      error: '',
      loading :false,
      expandCoaches : false,
      expandAthletes : false,
      email: ''
    }
  }


  generateTeamCode =  () => this.props.updateTeamConfig(this.props.config.team.id, 'teamCode', Math.random().toString(36).substring(5));

  generateEmailBody = (teamName, teamCode) => {
    return "Hello, %0D%0A%0D%0A" +
                    "Please follow the steps below to join team " + teamName + "!%0D%0A%0D%0A" +
                    "Go to https://app.traintensity.com and create an account.%0D%0A" +
                    "Head over to %22Profile>Join A Team%22 and enter the following code:%0D%0A" +
                    teamCode + "%0D%0A%0D%0A" +
                    "Looking forward to having you on board!%0D%0A%0D%0A"
  }

  checkInputCode = () => {
    const { inputCode } = this.state;
    this.setState({loading: true})
    firebaseDb.collection(teamsRef).where("teamCode", "==", inputCode).get().then(querySnapshot => {
      if (querySnapshot.size === 0)
        this.setState({ error: 'Invalid code entered.', loading: false, inputCode: ''})
      else {
        querySnapshot.forEach((doc) => {
             console.log(doc.id, " => ", doc.data());
             this.props.updateTeamConfig(doc.id, "members", [...doc.data().members, {uid: firebaseAuth.currentUser.uid, role : 'athlete', name : firebaseAuth.currentUser.displayName }]);
             this.props.updateUserConfig('team', doc.id);
             this.setState({loading: false, inputCode: '', error: '', success: 'You have joined team ' + doc.data().name});
         });
      }
    })
    .catch(error => {
      this.setState({ error: 'An unexpected error has occured.', loading: false, inputCode: ''})
    });
  }

  removeAthlete = (athleteId, team) => {
    const { updateTeamConfig, updateUserConfig } = this.props;
    const { id, members, tracks } = team;

    let newMemberArr = _.cloneDeep(members);
    for (var i=0; i<newMemberArr.length; i++) {
      if (newMemberArr[i].uid === athleteId) {
        newMemberArr.splice(i,1);
        break;
      }
    }

    let newTrackArr = _.cloneDeep(tracks);
    let updateTrack = false;
    for (var i=0; i<newTrackArr.length; i++) {
      let athletes = newTrackArr[i].athletes;
      for (var j=0; j<athletes.length; j++) {
        if (athletes[i] === athleteId) {
          updateTrack=true;
          athletes.splice(j,1);
          break;
        }
      }
    }

  updateUserConfig('team', "", athleteId);
  updateTeamConfig(id, 'members', newMemberArr);
  if (updateTrack)
     updateTeamConfig(id, 'tracks', newTrackArr);
  }

  updateAthlete = (athleteId, team, key, value) => {
    const { id, members } = team;
    let newMemberArr = _.cloneDeep(members);
    for (var i=0; i<newMemberArr.length; i++) {
      if (newMemberArr[i].uid === athleteId) {
        newMemberArr[i][key] = value;
        break;
      }
    }
    this.props.updateTeamConfig(id, 'members', newMemberArr);
  }

  render() {
    const { error, inputCode, loading, expandCoaches, expandAthletes, email } = this.state;
    const {config, updateTeamConfig, updateUserConfig} = this.props;
    const { team, subscriptions,success } = config;
    const { id, admin, name, members, tracks, teamCode } = team;
    const coaches = getRoleMembers(members, 'coach');
    const athletes = getRoleMembers(members, 'athlete');
    const teamRole = getTeamRole(firebaseAuth.currentUser.uid, members);
    const readOnly = teamRole !== COACH;
    const isAdmin = admin === firebaseAuth.currentUser.uid;

    return (
      <>
      <HeaderMain activeItem='Profile'/>
      <StyledContainer>
      <HeaderGeneric
        itemLeft={ <Button style={{fontSize: '20px', background: 'transparent'}} icon="arrow left" onClick={() => this.props.history.goBack()} />}
      />
        {success && <SuccessPortal text={success}></SuccessPortal>}
        <Transition
          animation='pulse'
          duration={500}
          visible={true}
          transitionOnMount
        >
        <SizingContainer>
          {(teamRole === COACH || teamRole === ASSISTANT_COACH) ?
            <><Segment>
            <List divided>
                <ListItem
                  leftHandHeader="Team Name"
                  rightHand={name}
                />
                <ListItem
                  leftHandHeader="Admin"
                  rightHand={<p>{getAthleteName(members, admin)}</p>}
                />
                <ListItem
                  leftHandHeader="Team Code"
                  subHeader="Users can join the team by entering this code."
                  rightHand={<HorizontalDiv>
                                {isAdmin &&
                                <ConfirmModal
                                  trigger={<Button style={{background : 'transparent'}} icon="refresh" />}
                                  header="Generate Team Code"
                                  text="Are you sure you want to generate a new team code? The current code will become invalid."
                                  onConfirm={() => this.generateTeamCode()}
                                />}
                                {teamCode} &nbsp;
                             </HorizontalDiv>}
                />
              </List>
              </Segment>
              <Header> Tracks </Header>
              <Segment>
              <InfoTooltip
                content='Tracks are groups of athletes that follow the same programming. If you program for a team, create track(s) and add the athletes here.'
              />
                <List divided>
                 {tracks && tracks.map((track,index) =>
                   <TrackListItem
                        key={'track-' + index}
                        tracks={tracks}
                        index={index}
                        members={members}
                        teamId={id}
                        updateTeamConfig={updateTeamConfig}
                        readOnly={readOnly}
                       />
                 )}
                 </List>
                 <AddTrackModal
                    header="Create New Track"
                    trigger={<Button fluid size='tiny'> Create New Track </Button>}
                    teamId={id}
                    tracks={tracks}
                    updateTeamConfig={updateTeamConfig}
                 />
              </Segment>
              <Header> Coaches </Header>
              <Segment>
              <HorizontalDiv>
              <p/>
              <Button fluid size="huge" style={{ backgroundColor: 'transparent'}} icon onClick={() => this.setState({expandCoaches : !expandCoaches})}>
                <Icon name={expandCoaches ? 'caret down' : 'caret left'}/>
              </Button>
              {isAdmin && <InfoTooltip
                content='Toggle the "Read Only" switch for coaches who should not have access to programming or editing athlete workouts.'
              />}
              </HorizontalDiv>
              {expandCoaches &&
              <><List divided>
               {coaches.map(member =>
                  <ListItem
                    key={member.uid}
                    leftHandHeader={member.name}
                    subHeader={isAdmin && member.uid !== admin &&
                      <Radio toggle label='Read Only'
                        checked={member.readOnly || false}
                        disabled={loading}
                        onChange={(e, data) => {
                          this.updateAthlete(member.uid, team, 'readOnly', data.checked);
                        }}
                      />
                    }
                    rightHand={!readOnly && <ConfirmModal
                                    trigger={<Button style={{background : 'transparent'}} icon="x" />}
                                    header="Remove Coach"
                                    text={`Are you sure you want to remove coaching access from ${member.name}? They will be downgraded to athlete.`}
                                    onConfirm={() => {
                                      let newMemberArr = _.cloneDeep(members);
                                      for (var i=0; i<newMemberArr.length; i++) {
                                        if (newMemberArr[i].uid === member.uid) {
                                          newMemberArr[i].role = 'athlete';
                                          break;
                                        }
                                      }
                                      updateTeamConfig(id, 'coaches', team.coaches.filter(coach => coach !== member.uid));
                                      updateTeamConfig(id, 'members', newMemberArr);
                                    }}
                              />}
                  />
                 )}
                 </List>
                 {!readOnly && <MemberSelectModal
                  trigger={<Button size='tiny' fluid> Add Coach </Button>}
                  header='Add Coach'
                  confirmText='Select your new coach. If the coach is not on the team they should join first!'
                  members={members}
                  onConfirm={(member) => {
                      let newMemberArr = _.cloneDeep(members);
                      for (var i=0; i<newMemberArr.length; i++) {
                        if (newMemberArr[i].uid === member) {
                          newMemberArr[i].role = 'coach'
                          break
                        }
                      }
                      updateTeamConfig(id, 'members', newMemberArr);
                      updateTeamConfig(id, 'coaches', [...team.coaches, member]);
                    }
                  }
                 />}
                 </>}
              </Segment>
              <Header> Athletes </Header>
              <Segment>
              <Button fluid size="huge" style={{ backgroundColor: 'transparent'}} icon onClick={() => this.setState({expandAthletes : !expandAthletes})}>
                <Icon name={expandAthletes ? 'caret down' : 'caret left'}/>
              </Button>
              {expandAthletes &&
                athletes.map(member =>
                  <ListItem
                    key={member.uid}
                    leftHandHeader={member.name}
                    rightHand={!readOnly && <ConfirmModal
                                    trigger={<Button style={{background : 'transparent'}} icon="x" />}
                                    header="Remove Athlete From Team"
                                    text={`Are you sure you want to remove ${member.name} from the team?`}
                                    onConfirm={() => {
                                        this.removeAthlete(member.uid, team);
                                    }}
                              />}
                  />
                )}
                 <br/>
                 <p style={{color: 'grey'}}> Want to invite a new athlete? Send them an email: </p>
                 <Input placeholder='Enter email' value={email} onChange={(e,data) => this.setState({email: data.value })}/>
                 <Button icon onClick={()=>{
                     let emailSubject = "Join team " + name + " on the Traintensity app!";
                     window.location.href = "mailto:" + email + "?subject=" + emailSubject + "&body=" + this.generateEmailBody(name, teamCode)
                     return
                 }}> <Icon name='send'/>  </Button>
              </Segment>
              <br/>
              <ConfirmModal
                  trigger={<Button style={{background: 'transparent'}} compact> Leave Team </Button>}
                  header="Leave team"
                  text={`Are you sure you want to leave team ${name}?`}
                  onConfirm={() => {
                    this.removeAthlete(firebaseAuth.currentUser.uid, team);
                  }}
              />
              </>
              :
              <>{name ?
                <Segment>
                <List divided>
                    <ListItem
                      leftHandHeader="Team Name"
                      rightHand={name}
                    />
                    <ListItem
                      leftHandHeader="Admin"
                      rightHand={<p>{getAthleteName(members, admin)}</p>}
                    />
                    <ConfirmModal
                        trigger={<Button style={{background: 'transparent'}} compact> Leave Team </Button>}
                        header="Leave team"
                        text={`Are you sure you want to leave team ${name}?`}
                        onConfirm={() => {
                          this.removeAthlete(firebaseAuth.currentUser.uid, team);
                        }}
                    />
                  </List>
                  </Segment>
                 :
                <>
                  <br/>
                  <Header as='h3'>You are currently not on a team.</Header>
                  {hasCoachPro(subscriptions) ?
                    <CreateTeamModal
                      updateUserConfig={updateUserConfig}
                    />
                    :
                    <><Header as='h5'> Looking to build a team? Upgrade to Coach Pro to get started!</Header>
                      <UpgradeModal/>
                    </>
                  }
                  <br/><br/>
                  <Header as='h5'>Trying to join a team? Enter the team code below:</Header>
                  <Input value={inputCode} onChange={(e, data) => this.setState({ inputCode: data.value.trim(), error: ''})} placeholder="Team Code"></Input>
                  <Button color='black' loading={loading} onClick={() => this.checkInputCode()}> Join <Icon name='caret right'></Icon> </Button>
                  {error && <p className='ui error message'>{error}</p>}
                  {success && <p className='ui success message'>{success}</p>}
                  <br/><br/>
                </>
              }
              </>
          }
        </SizingContainer>
        </Transition>
      </StyledContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config
  }
}
export default withRouter(connect(mapStateToProps, { updateUserConfig, updateTeamConfig })(Team));
