import React, {useState} from 'react';
import moment from 'moment';
import { firebaseAuth } from '../../firebase';
import { Transition, Statistic, TransitionablePortal, Button, Modal, Menu, Icon, Header } from 'semantic-ui-react';
import { Loading } from '../common/Loading';
import { SNATCH, CLEAN, JERK, SQUAT, PRESS_VERTICAL, PRESS, PULL, COLOR_SECONDARY, COLOR_LIGHT, COLOR_PRIMARY } from '../../assets/literals';
import { HorizontalDiv } from '../StyledComponents';
import {IntensityZoneBarChart} from '../charts/IntensityZoneBarChart';
import logo from '../../assets/logo_color_transparent.png';
import { retrievePRs, retrieveWorkouts, getChartData, zoneDataToChartFormat, getCategoryL2Name, getExerciseOption, numberWithCommas } from '../../commonMethods';
import snatch_image from '../../assets/exerciseImages/snatch_g.jpg';
import clean_image from '../../assets/exerciseImages/clean_g.jpg';
import jerk_image from '../../assets/exerciseImages/jerk_g.jpg';
import squat_image from '../../assets/exerciseImages/squat_g.jpg';
import pull_image from '../../assets/exerciseImages/pull_g.jpg';
import press_image from '../../assets/exerciseImages/press_g.jpg';
import generic_image from '../../assets/exerciseImages/generic.jpg';


export const YearInReview = ({ athlete, config }) => {
        const [open, setOpen] = useState(false);
        const [loading, setLoading] = useState(false);
        const [personalRecords, setPersonalRecords] = useState([]);
        const [chartData, setChartData] = useState({});
        const [activeItem, setActiveItem] = useState(0);
        const [touchPosition, setTouchPosition] = useState(null)
        const [transitioning, setTransitioning] = useState(false)

        const handleTouchStart = (e) => {
            const touchDown = e.touches[0].clientX
            setTouchPosition(touchDown)
        }

        const handleTouchMove = (e) => {
            const touchDown = touchPosition

            if(touchDown === null) {
                return
            }

            const currentTouch = e.touches[0].clientX
            const diff = touchDown - currentTouch

            if (diff > 5 && activeItem < tabs.length-1) {
                setTransitioning(true);
                setActiveItem(activeItem+1)
                setTimeout(function(){
                  setTransitioning(false)
                }, 10);
            }

            if (diff < -5 && activeItem > 0) {
                setTransitioning(true);
                setActiveItem(activeItem-1);
                setTimeout(function(){
                  setTransitioning(false)
                }, 10);
            }

            setTouchPosition(null)
        }

        let tabs = ['total'];
        tabs = tabs.concat(Object.keys(chartData?.totalTypeData || []));

        const loadWorkouts = () => {
        setLoading(true);
        let yearWorkouts = [];
        //const start = moment(new Date()).subtract(1, "month").toDate();
        //const end = moment(new Date()).endOf('isoweek').toDate();
        const start = moment([2023]).startOf('year').toDate();
        const end = moment([2023]).endOf('year').toDate();
        retrieveWorkouts(start, end, athlete)
         .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
             yearWorkouts.push(doc.data());
            });
            }).catch((error) => {
            console.log("Error getting document:", error);
            alert("Error retrieving workouts");
            setLoading(false)
            })
            .finally(() => {
              setChartData(getChartData(yearWorkouts, athlete, config));
              setLoading(false);
          });
        }

        const loadPRs = (athlete,fromDate, oneRM) => {
          let records = [];
          retrievePRs(athlete, fromDate, oneRM).then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                  const { date, repMax } = doc.data();
                  if (date?.toDate().getFullYear() === 2023 && repMax===1) //have to pull all PRS and filter after, or create a collection
                    records.push(doc.data());
              });
            }).catch((error) => {
                console.log("PRs: Error getting document:", error);
              })
              .finally(() => setPersonalRecords(records));
        }


        const handleItemClick = (i) => {
          setActiveItem(i);
        }

        const getTopVariations = (sortedLiftData, lift) => {
            let topVariations = [];
            for (var i=0; i<sortedLiftData.length; i++){
              if (topVariations.length === 3)
               return topVariations;
              else {
                if (getExerciseOption(sortedLiftData[i][0], config.user.customExercises).category_level_2 === lift)
                  topVariations.push(sortedLiftData[i])
              }
            }
            return topVariations
        }

        const getImageUrl = (lift) => {
          let imageUrl=null;
          switch(lift) {
            case SNATCH:
              imageUrl=snatch_image;
              break;
            case CLEAN:
                imageUrl=clean_image;
                break;
            case JERK:
                imageUrl=jerk_image;
                break;
            case SQUAT:
              imageUrl=squat_image;
              break;
            case PRESS_VERTICAL:
              imageUrl=press_image;
              break;
            case PRESS:
              imageUrl=press_image;
              break;
            case PULL:
              imageUrl=pull_image;
              break;
            default:
              imageUrl=generic_image;
              break;
          }
          return imageUrl;
        }

        const renderVariations = (variations, headerText, center) => {
          return <div>
                  <div style={{color: 'white', padding: '5px', width: '100%', fontWeight: 'bold', fontSize: '17px', backgroundColor: COLOR_SECONDARY}}>{headerText || 'Top Exercise Variations'} </div>
                  <div style={{ textAlign: center ? 'center' : 'left', border: '2px solid', borderTop: 'none', borderColor: 'lightgrey', padding: '5px'}}>
                    <div style={{textAlign: 'left'}}>{variations.map((lift, index) => center ?
                        <HorizontalDiv key={'variation-' + index} style={{ fontWeight: 'bold', fontSize: '14px', borderBottom: '1px solid', borderColor: 'lightgrey'}}>
                            <span>{index+1}.</span> <span>{getExerciseOption(lift[0]).text}</span> <span></span>
                        </HorizontalDiv>
                       :
                       <p key={'variation-' + index} style={{ fontWeight: 'bold', fontSize: '14px'}}>
                           {index+1}. {getExerciseOption(lift[0]).text}
                       </p>
                    )}

                        </div>
                  </div>
                </div>
        }

        const renderCategorySummary = (sortedLiftData, lift) => {
          const { reps, volume, missedReps, intensity } = chartData.totalTypeData[lift];
          const { intensityZoneTypeData } = chartData;
          let topVariations = getTopVariations(sortedLiftData, lift);
          const liftName = getCategoryL2Name(lift)
          return <>
              <div style={{ textAlign: 'center', paddingTop: '60px', width: '100%', height: '250px', backgroundImage : `linear-gradient(rgba(8, 8, 37, 0.85), rgb(88 88 88 / 68%)), url(${getImageUrl(lift)})`, backgroundSize: 'cover' }}>
                <Header style={{textAlign: 'center', color: 'white', margin: 0, fontSize: '36px'}}> {liftName} </Header>
                <Statistic.Group style={{justifyContent: 'space-evenly'}} size='small'>
                  <div style={{width:'50%', textAlign: 'center'}}>
                    <Statistic>
                      <Statistic.Value style={{color: 'white'}}>{reps}</Statistic.Value>
                      <Statistic.Label style={{color:'lightgrey'}}>Reps lifted <br/> (above {config.user.intensityThreshold}%)</Statistic.Label>
                    </Statistic>
                  </div>
                  <div style={{width:'50%', textAlign: 'center'}}>
                  <Statistic>
                    <Statistic.Value style={{color: 'white'}}>
                      {intensity*100}%
                    </Statistic.Value>
                    <Statistic.Label style={{color:'lightgrey'}}>Avg Session<br/> Intensity</Statistic.Label>
                  </Statistic>
                  </div>
                </Statistic.Group>
                <br/><br/>
              </div>
              <Transition transitionOnMount visible={!transitioning} animation='slide right' duration={200}>
              <Modal.Content style={{padding: '3%'}}>
                    <HorizontalDiv>
                    <div style={{ height: '250px', width: '100%', textAlign: 'center'}}>
                      <Statistic size='tiny' color='red' style={{width:'100px'}}>
                        <Statistic.Value style={{fontWeight:'bold'}}>
                          {Math.round(((reps - missedReps) / reps)*100)}%
                        </Statistic.Value>
                        <Statistic.Label style={{color:'slategrey'}}>Success Rate</Statistic.Label>
                      </Statistic>
                      <IntensityZoneBarChart
                        data={zoneDataToChartFormat(intensityZoneTypeData[lift])}
                        isProgramming={false}
                      />
                    </div>
                    <div style= {{ height: '250px', textAlign: 'center'}}>
                      <Statistic size='tiny' style={{width:'100%', textAlign :'center'}}>
                        <Statistic.Value style={{ textTransform: 'lowercase', fontWeight: 'bold', color: '#000000d9'}}>
                          {Math.round(volume/reps)}{config?.user?.defaultUnit}
                        </Statistic.Value>
                        <Statistic.Label style={{color:'slategrey'}}>Avg Weight</Statistic.Label>
                      </Statistic>
                      <br/><br/>
                      {renderVariations(topVariations)}
                    </div>
                    </HorizontalDiv>
                    <br/><br/>
                </Modal.Content>
                </Transition>
              </>
        }

        const displayData = (i) => {
          const { totalData, dailyTotalData, volumeTypeData, totalTypeData, totalLiftData} = chartData;
          let sortedLiftData = [];
          for (var lift in totalLiftData) {
              sortedLiftData.push([lift, totalLiftData[lift].reps]);
          }
          sortedLiftData.sort(function(a, b) {
              return b[1] - a[1];
          });
          let topLifts = sortedLiftData.slice(0, 5);
          if (tabs[i] === 'total') {
            const {displayName} = firebaseAuth.currentUser;
            return <><div style={{backgroundColor: '#000000e6', width: '100%', height: '250px'}}>
                      <Header style={{color: COLOR_LIGHT, textAlign: 'center', paddingTop: '100px'}}> Congrats {displayName.substr(0, displayName.indexOf(' '))}. </Header>
                      <p style={{color: COLOR_LIGHT, fontSize: '18px', textAlign: 'center'}}> You trained <span style={{fontWeight : 'bold', fontSize : '18px', color: COLOR_SECONDARY}}>{dailyTotalData.length} days </span> this year! </p>
                   </div>
                  <Transition transitionOnMount mountOnShow visible={!transitioning} animation='slide right' duration={200}>
                   <Modal.Content style={{textAlign: 'center', padding: '3%', marginTop: '33px'}}>
                    <Statistic.Group style={{display: 'flex', justifyContent: 'center'}} size='tiny'>
                      <Statistic>
                        <Statistic.Value style={{color: COLOR_SECONDARY}}>
                          {numberWithCommas(totalData.volume)}
                        </Statistic.Value>
                        <Statistic.Label>{config.user.defaultUnit} lifted</Statistic.Label>
                      </Statistic>
                      <Statistic>
                        <Statistic.Value style={{color: COLOR_SECONDARY}}>{totalData.intensity*100}%</Statistic.Value>
                        <Statistic.Label>Average Intensity</Statistic.Label>
                      </Statistic>
                    </Statistic.Group>
                    <br/>
                    <Statistic size='mini'>
                      <Statistic.Value>
                        {personalRecords.length}
                      </Statistic.Value>
                      <Statistic.Label>Personal Records</Statistic.Label>
                    </Statistic>
                    <br/><br/>
                    <div style={{ paddingLeft: '10%', paddingRight: '10%', textAlign: 'center'}}>
                      <div>{renderVariations(topLifts, 'Top 5 Exercises', true)}</div>
                    </div>
                </Modal.Content>
              </Transition>
              </>
          }
          else
            return renderCategorySummary(sortedLiftData, tabs[i])
        }

        return (
          <TransitionablePortal
            open={open}
            trigger={<Button circular compact style={{background: 'black', color: COLOR_LIGHT}} onClick={() => setOpen(true)}>2023 <br/> Year In Review!</Button>}
            transition = {{
                animation: 'scale',
                duration: 400,
              }}
              closeOnDocumentClick={false}
          >
            {open ? <Modal
              size='tiny'
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              open={true}
              closeIcon
            >
                <div
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                >
                  <Loading active={loading} />
                  <div style={{padding: '0px !important', margin: '0px !important', }}>
                    <div style={{textAlign : 'left', width:'100%',backgroundColor: 'transparent', position : 'absolute'}}><img style={{width: '99%', maxWidth: '100px', padding:'2%', paddingBottom: '0'}} src={logo} alt='||--||' />
                        <p style={{color: COLOR_LIGHT, textAlign: 'left', paddingLeft: '5px'}}>  Year In Review </p>
                    </div>
                  </div>
                    <div style={{textAlign : 'right', width:'100%',backgroundColor: 'transparent', position : 'absolute'}}>
                        <br/><span style={{color: COLOR_LIGHT, marginRight: '50px', fontSize: '16px'}}>  2023 </span>
                    </div>
                    {Object.keys(chartData).length > 0 ?
                      <>
                        {displayData(activeItem)}
                      </>
                       :
                    <div style={{backgroundColor: '#000000e6', textAlign: 'center' }}>
                      <div style={{ paddingTop: '200px', paddingBottom: '150px'}}>
                        <Header style={{color: COLOR_LIGHT}} as='h3'>Your 2023 Year In Review is ready.</Header>
                        <Button size='large' style={{backgroundColor: COLOR_SECONDARY, color: COLOR_LIGHT}} onClick={() => { loadWorkouts(); loadPRs(athlete, new Date(new Date().getFullYear(), 0, 1), true);}}>
                            Load Your Review!
                        </Button>
                      </div>
                      <br/><br/>
                    </div>}
                    <br/>
                    <br/>
                    <Menu compact fluid style={{justifyContent: 'center'}} secondary borderless>
                     {tabs.map( (obj, i) =>
                       <Menu.Item
                         key={'item-' + i}
                         name={'item-' + i}
                         onClick={() => handleItemClick(i)}
                        >
                        <Icon compact size={tabs?.length > 5 ? 'tiny' : 'small'} name='circle' color={activeItem === i ? 'black' : 'grey'} />
                       </Menu.Item>
                     )}
                   </Menu>
                </div>
            </Modal> : <></>}
          </TransitionablePortal>
        )
  }
