import React, { Component } from 'react';
import { Transition, Button, Icon, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Debug from '../../Debug';
import SetContainer from './SetContainer';
import { deleteBuilderWeight, updateBuilderWeight } from '../../actions';
import { getExerciseOption } from '../../commonMethods';
import { getIntensity } from './shared_weight_functions';
import { HorizontalDiv, ButtonDiv, WeightContainer, StyledInput, StyledButton } from '../StyledComponents';


export const SetRepInput = React.forwardRef(({style, placeholder, id, value, min, max, onChange, onKeyUp}, ref) => {
  return <StyledInput
     fluid required
     type='tel'
     inputMode="decimal"
     step="any"
     style={style}
     placeholder={placeholder}
     value={value}
     min={min}
     max={max}
     onChange={onChange}
     onKeyUp={onKeyUp}
  >
   <input style={{paddingLeft: 0, paddingRight: 0, border: '0', borderBottom: '1px solid lightgrey', textAlign: 'center', fontSize: '18px', fontWeight: 'bold'}} />
  </StyledInput>
});

class Weight extends Component {

    constructor(props) {
      super(props);
      this.state = {
        active: false,
        expanded: false
      }

      this.repsInput = React.createRef();
      this.weightInput = React.createRef();
    }

    componentDidMount(){
      Debug(this.props);
    }

    getDefaultSetArr = (sets, reps) => {
        let defaultSetArr = [];
        Debug(sets, reps);
        for (var i =0; i< sets; i++) {
          defaultSetArr.push({"pattern" : ""});
          for (var j=0; j<reps; j++){
            defaultSetArr[i].pattern = defaultSetArr[i].pattern + '1';
          }
        }
        return defaultSetArr;
    }

    handleChange = (id, value) => {
      const { exerciseIndex, weightIndex, sets, reps} = this.props;

      if (value.length>0) {
        if (id === 'sets' || id === 'reps')
          value = isNaN(value) ? 0 : parseInt(value);
        else if (id === 'weight') {
          let lastChar = value.slice(-1);
          if (lastChar === '.' || lastChar === '*' || lastChar ===',' || lastChar ==='#' || lastChar==='+')
            value= value.substring(0, value.length - 1) + '.';
          else
            value = isNaN(value) ? 0 : parseFloat(value);
        }
      }

      this.props.updateBuilderWeight(exerciseIndex, weightIndex, id, value);
      //update setArr if sets or reps changes is being updated
      let defaultSetArr = [];
      if (id === 'sets') {
          defaultSetArr = this.getDefaultSetArr(value, reps);
          this.props.updateBuilderWeight(exerciseIndex, weightIndex, 'setArr', defaultSetArr);
      }
      else if (id === 'reps') {
          defaultSetArr = this.getDefaultSetArr(sets, value);
          this.props.updateBuilderWeight(exerciseIndex, weightIndex, 'setArr', defaultSetArr);
      }
    }

    expand = () => {
      const {expanded} = this.state;
      let iconStr = 'caret left'
      if (expanded)
        iconStr='caret down';

        return (
         <Button
                  icon
                  value={expanded}
                  type='button'
                  size='massive'
                  style={{background: 'transparent',padding: 0, paddingLeft: '30px'}}
                  onClick={() => this.setState({expanded: !this.state.expanded})}
              >
                <Icon name={iconStr}
                  onClick={() => this.setState({expanded: !this.state.expanded})}
                  />
              </Button>
      )
    }

    renderSetContainer = () => {
        const {exerciseIndex, weightIndex, sets, reps, setArr} = this.props;
        if (this.state.expanded)
          return <Transition><SetContainer
                      exerciseIndex= {exerciseIndex}
                      weightIndex={weightIndex}
                      sets={sets}
                      reps={reps}
                      setArr={setArr}/>
                  </Transition>
    }
/*
    <WeightInput
      weight={weight}
      unit={unit}
      exerciseIndex={exerciseIndex}
      weightIndex={weightIndex}
      handleChange={this.props.updateBuilderWeight}
    />
    */

    render() {
      const {exercise, weight,unit,sets,reps,exerciseIndex,weightIndex, workingMax, defaultUnit, athleteConfig} = this.props;
      const exerciseOption = getExerciseOption(exercise);
      const { implement, category } = exerciseOption;

      let xButton = <Icon/>;
      if (weightIndex !== 0) {
        xButton = <ButtonDiv><StyledButton icon
                    style={{background:'transparent'}}
                    className='ui small button'
                    type='button'
                    variant='primary'
                    onClick={(e) => {this.props.deleteBuilderWeight(exerciseIndex, weightIndex)}}
                  >
                  <Icon name='trash'/>
                  </StyledButton></ButtonDiv>
      }

      return(
        <WeightContainer>
          <HorizontalDiv style={{justifyContent: 'flex-end'}}>
                  {getIntensity(category, weight, unit, workingMax, athleteConfig.intensityThreshold, defaultUnit)}
                  {xButton}
              </HorizontalDiv>
           <HorizontalDiv style={{fontWeight: 'bold', color: 'grey'}}>
            <HorizontalDiv style={{width:'100%'}}>
               <SetRepInput
                   placeholder='Sets'
                   value={sets}
                   min={0}
                   max={999}
                   onChange={(e, data) => this.handleChange('sets', data.value)}
                /*   onKeyUp={() => {
                     if (!reps) {
                       if (sets.toString().length === 2)
                         this.repsInput.current.focus();
                       else if (sets.toString().length === 1)
                       setTimeout(() => {this.repsInput.current.focus()}, 500)
                     }
                   }}
                  */
               />
               &nbsp;X&nbsp;
                <SetRepInput
                  placeholder='Reps'
                  value={reps}
                  min={0}
                  max={9999}
                  onChange={(e, data) => this.handleChange('reps', data.value)}
                  ref={this.repsInput}
                  /*onKeyUp={() => {
                    if (!weight) {
                      if (reps.toString().length === 2)
                        this.weightInput.current.focus();
                      else if (reps.toString().length === 1)
                      setTimeout(() => {this.weightInput.current.focus()}, 500)
                    }
                  }}*/
                />
                {implement !== 'none' && <>&nbsp;@&nbsp;<SetRepInput
                  placeholder="Weight"
                  value={weight}
                  min={0}
                  max={999}
                  ref={this.weightInput}
                  onChange={(e, data) => this.handleChange('weight', data.value)}
                />
                <Dropdown
                      style={{marginLeft:'10px', marginRight:'10px', fontWeight: 'bold', fontSize : '18px'}}
                      options={[
                        { key: 'lbs', text: 'lbs', value: 'lbs' },
                        { key: 'kg', text: 'kg', value: 'kg' },
                        { key: '%', text: '%', value: '%' },
                      ]}
                      onChange={(e, data) => {this.handleChange('unit', data.value)}}
                      value={unit}
                  /></>}
                  </HorizontalDiv>
                  {this.expand()}
           </HorizontalDiv>
          {this.renderSetContainer()}
        </WeightContainer>
    )}
 }

export default connect(null, { deleteBuilderWeight, updateBuilderWeight })(Weight);
