import _ from 'lodash';
import exerciseOptions from './assets/exercise-classification';
import exerciseCategories from './assets/exercise_category';
import exerciseCategoriesL2 from './assets/exercise_category_level_2';
import exerciseImplement from './assets/exercise_implement';
import benchmarkTypes from './assets/benchmark-types';
import {SNATCH, CLEAN, JERK, CLEANJERK, SQUAT, PULL, ROW, PRESS, PRESS_VERTICAL, CORE, OTHER,
        STRENGTH, BARBELL, NONE, TIME, AMRAP, MAXREPS, EMOM, DISTANCE, CUSTOM, CONDITIONING, KG, LBS,
        COMPLETE, INPROGRESS, MONTHLY, WEEKLY, DAILY, REPS, SETS, VOLUME,
        LESSFIFTY, FIFTY, SIXTY, SEVENTY, EIGHTY, NINETY,HUNDREDPLUS, ATHLETE, COACH, ASSISTANT_COACH
        } from './assets/literals';
import Debug from './Debug';
import { firebaseAuth, firebaseDb } from './firebase';

export const getCategoryName = (key) => {
    for (var i=0;i<exerciseCategories.length;i++){
      if (exerciseCategories[i].key === key)
        return exerciseCategories[i].text;
    }
    return "Category";
}

export const getCategoryL2Name = (key) => {
    for (var i=0;i<exerciseCategoriesL2.length;i++){
      if (exerciseCategoriesL2[i].key === key)
        return exerciseCategoriesL2[i].text;
    }
    return "Subcategory";
}

export const getCategoryL2Obj = (key) => {
    for (var i=0;i<exerciseCategoriesL2.length;i++){
      if (exerciseCategoriesL2[i].key === key)
        return exerciseCategoriesL2[i];
    }
    return {};
}

export const getImplementName = (key) => {
    for (var i=0;i<exerciseImplement.length;i++){
      if (exerciseImplement[i].key === key)
        return exerciseImplement[i].text;
    }
    return "Implement";
}

export const getBenchmarkType = (key) => {
  for (var i=0;i<benchmarkTypes.length;i++){
    if (benchmarkTypes[i].key === key)
      return benchmarkTypes[i].text ? benchmarkTypes[i].text + ":" : "";
  }
  return "";
}

export const getExerciseOptions = (customExercises) => {
  let allExerciseOptions = exerciseOptions;
  if (customExercises)
    allExerciseOptions = mergeCustomExercises(exerciseOptions, customExercises);

  return allExerciseOptions;
}

export const getExerciseOption = (key, customExercises) => {
  let allExerciseOptions = exerciseOptions;
  if (customExercises)
    allExerciseOptions = mergeCustomExercises(exerciseOptions, customExercises);
  for (var i=0;i<allExerciseOptions.length;i++){
    if (allExerciseOptions[i].key === key)
      return allExerciseOptions[i];
  }
  return {};
}

export const getWorkingExerciseIndex = (exercise, workingMaxes) => {
  for (var i=0; i<workingMaxes.length; i++) {
    if (workingMaxes[i].lift === exercise)
      return i
  }
  return null;
}

export const mergeCustomExercises = (exerciseOptions, customExercises) => {
  let customExerciseArr = []
  for (const [key, value] of Object.entries(customExercises)) {
      customExerciseArr.push({...value, key, value: key});
    }
    let initialExerciseOptions = exerciseOptions.concat(customExerciseArr);
    return initialExerciseOptions;
}

export const getTracks = (athlete, tracks) => {
  let athleteTracks = [];
  if (tracks) {
    for (var i=0; i<tracks.length; i++) {
      const { athletes, id } = tracks[i];
      if (athletes.includes(athlete))
          athleteTracks.push(id)
    }
  }
  return athleteTracks;
}

export const getTeamRole = (athlete, members) => {
  if (members) {
    for (var i=0; i<members.length; i++) {
      const { role, readOnly } = members[i];
      if (members[i].uid === athlete) {
        if (role === 'athlete')
          return ATHLETE;
        else if (role === 'coach') {
          if (!readOnly)
            return COACH;
          else
            return ASSISTANT_COACH;
        }
        return ATHLETE;
      }
    }
  }
  return null;
}

export const hasPro = (subscriptions) => {
  console.log('has pro...', subscriptions);
  if (subscriptions) {
    for (var i=0; i<subscriptions.length; i++){
      if (subscriptions[i] === 'coach_pro' || subscriptions[i] === 'athlete_pro')
        return true;
    }
  }
  return false;
}

export const hasCoachPro = (subscriptions) => {
  if (subscriptions) {
    for (var i=0; i<subscriptions.length; i++){
      if (subscriptions[i] === 'coach_pro')
        return true;
    }
  }
  return false;
}

export const getRoleMembers = (members, role) => {
  let roleMembers = [];
  if (members) {
    for (var i=0; i<members.length; i++) {
      if (members[i].role === role)
        roleMembers.push(members[i]);
    }
  }
  return roleMembers;
}

export const getAthleteName = (members, athlete) => {
    if (members) {
      for (var i=0; i<members.length; i++) {
        if (members[i].uid === athlete)
          return members[i].name
      }
      return null;
    }
  }

export const dateToMonthString = (date) => {
  return date.getMonth()+1 + "-" + date.getFullYear()
}

export const monthToDateString = (dateString) => {
    let arr = dateString.split('-');
    if (arr.length === 2) {
      let date = new Date();
      date.setMonth(arr[0]-1)
      date.setYear(arr[1]);
      date.setDate(1);
      date.setHours(0,0,0,0);
      return date;
    }
    return null;
  }

  export const orderByDateDesc = (arr, date) => {
      return arr.slice().sort(function (a, b) {
      return a[date] > b[date] ? -1 : 1;
    });
  }

export const getPreviousMonthStart = (prevStart) => {
  prevStart.setDate(0);
  prevStart.setDate(1);
  prevStart.setHours(0,0,0,0);
  return prevStart;
}

export const getPreviousMonthEnd = (prevEnd) => {
    prevEnd.setDate(0)
    prevEnd.setHours(23,59,59,999)
    return prevEnd;
}

export const kgToLbs = kg => {
  return Math.round(10* kg * 2.2046226218)/10;
}

export const lbsToKg = lbs => {
  return Math.round(10 * lbs/2.2046226218)/10;
}

export const epleyFormula = (weight, reps) => {
  return Math.round(weight * (36 / (37-reps)));
}

export const brzyckiFormula = (weight, reps) => {
  return Math.round(weight * (36 / (37-reps)));
}

export const intensityResolution = (exercise, workingMaxes, customExercises) => {
  const { implement, category_level_2 } = getExerciseOption(exercise, customExercises);

  if (implement !== BARBELL)
    return null;
/*
  switch(category) {
    case 'olympic':
      return workingMaxes[0].weight;
    case 'strength-upper':
      return workingMaxes[0].weight;
    case 'strength-lower':
      return workingMaxes[0].weight;
    case 'olympic':
      return workingMaxes[0].weight;
  }
  */
if (workingMaxes) {
  switch(category_level_2) {
      case SNATCH:
        return workingMaxes[0].weight;
      case CLEAN:
        return workingMaxes[1].weight;
      case JERK:
        return workingMaxes[2].weight;
      case CLEANJERK:
        return  workingMaxes[3].weight;
      case SQUAT:
        if (exercise.toLowerCase().includes("front"))
          return workingMaxes[6].weight;
        else if (exercise.includes("Ovhd") || exercise.toLowerCase().includes("overhead")) //toLower is to handle custom exercises
          return workingMaxes[0].weight;
        else
          return workingMaxes[7].weight;
      case PULL:
        if (exercise.includes("Sn") || exercise.toLowerCase().includes('snatch')) //toLower is to handle custom exercises
          return workingMaxes[0].weight;
        else if (exercise.includes("Cl") || exercise.toLowerCase().includes("clean"))
          return workingMaxes[1].weight;
        else return workingMaxes[9].weight;
      case PRESS:
        return workingMaxes[8].weight;
      case PRESS_VERTICAL:
        return workingMaxes[10].weight;
      case  ROW:
        return workingMaxes[11].weight;
      default:
        return 0;
    }
  }
  else
    return null;
}

export const getIntensityColor = intensity => {
  if (intensity <= 50) {
    return '#e10000'
  }
  if (intensity > 50 && intensity<70) {
    return "#ec8c06";
  }
  if (intensity >= 70 && intensity <= 85) {
    return "#00b52b";
  }
  if (intensity >= 85) {
    return '#e10000';
  }
  else
    return '';
}

export const getStartDate = (end, timeframe) => {
  const start = new Date(new Date().setDate(end.getDate() - parseInt(timeframe)));
  start.setHours(0,0,0,0);
  return start;
}

export const retrieveWorkouts = (start, end, athlete) => {
  return firebaseDb.collection('workouts').where('userId', '==', athlete)
             .where('date', '>=', start)
             .where('date', '<', end)
             .get();
}

export const retrievePRs = (athlete, fromDate, oneRM) => {
//  if (!firebase.auth.currentUser)
//    return 1;
console.log('loading PRs for ', athlete);
  var prRef = firebaseDb.collection('personalRecords')
    .where('userId', '==', athlete || firebaseAuth.currentUser.uid)
//    .where('date', '>=', fromDate);
//  if (oneRM)
  //  prRef = prRef.where('repMax', '==', 1)
  return prRef.get();
}

export const buildChartData = (data, athleteConfig, customExercises) => {
  Debug(data);
  const { date, exerciseArr } = data;
  const { defaultUnit, intensityThreshold, workingMaxes } = athleteConfig;
  let workingMaxUnit = data.defaultUnit || athleteConfig.defaultUnit || 'kg';

  var totalVolume = 0, totalIntensity=0, totalReps = 0, totalMissedReps=0, totalSets = 0;
  var liftData = {},
      typeData = {},
      totalZoneData = {};

  //split clean&jerk into separate movements
  let exerciseArrMod = [...exerciseArr];
  for (var k=0; k<exerciseArrMod.length; k++ ) {
    if (exerciseArrMod[k].exercise === 'CleanAndJerk') {
      exerciseArrMod.push({...exerciseArr[k], exercise : 'Clean'});
      exerciseArrMod.push({...exerciseArr[k], exercise : 'SplitJk'});
      exerciseArrMod.splice(k, 1);
      k--;
    }
  }

  //Traverse through Exercises
  for (var i=0; i<exerciseArrMod.length; i++) {
    const  { type, exercise} = exerciseArrMod[i];
    const exerciseOption = getExerciseOption(exercise, customExercises);
    if ((type === STRENGTH || !type) && exerciseOption) {
    const exerciseType = exerciseOption.category_level_2;

    let workingMax = exerciseArrMod[i].workingMax;
    //use current working max if none was saved during time of workout
    if (workingMax === 0 || !workingMax) {
      workingMax = intensityResolution(exercise, workingMaxes, customExercises);
    }

    //Convert working max to current default unit
    if (workingMaxUnit !== defaultUnit) {
      if (defaultUnit === KG)
        workingMax = lbsToKg(workingMax);
      else if (defaultUnit === LBS)
        workingMax = kgToLbs(workingMax);
    }
    Debug(exercise, workingMax, exerciseType)
    //initialize variables to current data or 0 if first
    var itemVolume=0, itemSets=0, itemReps=0, itemMissedReps=0, itemTopIntensity=0, itemZoneData={};
    var liftVolume = (liftData[exercise] === undefined) ? 0 : liftData[exercise].volume;
    var intensity = (liftData[exercise] === undefined) ? 0 : liftData[exercise].intensity;
    var topIntensity = (liftData[exercise] === undefined) ? 0 : liftData[exercise].topIntensity;
    var liftSets = (liftData[exercise] === undefined) ? 0 : liftData[exercise].sets;
    var liftReps = (liftData[exercise] === undefined) ? 0 : liftData[exercise].reps;
    var liftMissedReps = (liftData[exercise] === undefined) ? 0 : liftData[exercise].missedReps;
    var tSets = (typeData[exerciseType] === undefined) ? 0 : typeData[exerciseType].sets;
    var tReps = (typeData[exerciseType] === undefined) ? 0 : typeData[exerciseType].reps;
    var tMissedReps = (typeData[exerciseType] === undefined) ? 0 : typeData[exerciseType].missedReps;
    var tVolume = (typeData[exerciseType] === undefined) ? 0 : typeData[exerciseType].volume;
    var tIntensity = (typeData[exerciseType] === undefined) ? 0 : typeData[exerciseType].intensity;
    var tTopIntensity = (typeData[exerciseType] === undefined) ? 0 : typeData[exerciseType].topIntensity;
    liftData = {...liftData, [exercise] : {date: date.seconds, volume : liftVolume, intensity, topIntensity, sets: liftSets, reps: liftReps, missedReps: liftMissedReps} };
    typeData = {...typeData, [exerciseType] : {date: date.seconds, volume: tVolume, intensity: tIntensity, topIntensity: tTopIntensity, sets: tSets, reps: tReps, missedReps: tMissedReps } };

    //traverse through each weight, check if it is above threshold, if yes, add
    for (var j=0; j<exerciseArrMod[i].weightArr.length;j++) {
      let { weight, sets, setArr, unit } = exerciseArrMod[i].weightArr[j];
      if (unit === '%' && workingMax>0) {
        weight = ((weight/100)* workingMax); //convert percentages to weights
        unit = defaultUnit;
      }
      let reps=0, missedReps=0;
      //loop through setArr to get rep breakout
      if (setArr.length > 0) {
        for (var l=0; l<setArr.length; l++) {
          const { pattern } = setArr[l];
          let repBreakout = getRepBreakout(pattern);
          reps+=repBreakout.reps-repBreakout.didNotAttempt; //remove DNT from total rep count
          missedReps+=repBreakout.miss+ repBreakout.closeMiss;
        }
      }

      //convert if units don't match
      if (defaultUnit !== unit) {
        if (defaultUnit === KG && unit === LBS)
          weight = Math.round(lbsToKg(weight));
        else if (defaultUnit === LBS && unit === KG)
          weight = Math.round(kgToLbs(weight));
      }
      Debug(weight)
      const intensity = (weight/workingMax + Number.EPSILON)*100;
      if (intensityThreshold <= intensity) {
        totalVolume += (reps*weight);
        totalReps += parseInt(reps);
        totalMissedReps += parseInt(missedReps);
        totalSets += parseInt(sets);
        itemReps += parseInt(reps);
        itemMissedReps += parseInt(missedReps);
        itemSets += parseInt(sets);
        itemVolume += parseFloat(reps*weight);
        itemTopIntensity = intensity/100 > itemTopIntensity ? Math.round(intensity)/100 : itemTopIntensity;
        //add intensity zone data
        let intensityZone = getIntensityZone(intensity);
        if (!itemZoneData[intensityZone])
          itemZoneData[intensityZone] = getEmptyZoneData();

        itemZoneData[intensityZone].reps += reps;
        itemZoneData[intensityZone].missedReps += missedReps;
        itemZoneData[intensityZone].sets += sets;
        itemZoneData[intensityZone].volume += (reps*weight);

      }
      Debug( totalVolume, totalReps, totalMissedReps, totalSets, itemReps, itemMissedReps, itemSets, itemVolume, itemZoneData);
    }

    totalZoneData = addZoneData(totalZoneData, itemZoneData);

    let averageIntensity = Math.round((((itemVolume/itemReps)/workingMax) * 100)) / 100;
    if (isNaN(averageIntensity) || !isFinite(averageIntensity))
    	averageIntensity = 0
    if (averageIntensity)
      totalIntensity = totalIntensity === 0 ? averageIntensity : Math.round(((totalIntensity + averageIntensity)/2)*100)/100;
    //Add data to liftData
    liftData[exercise].volume += itemVolume;
    liftData[exercise].sets += itemSets;
    liftData[exercise].reps += itemReps;
    liftData[exercise].missedReps += itemMissedReps;
    liftData[exercise].topIntensity = itemTopIntensity > liftData[exercise].topIntensity ? itemTopIntensity : liftData[exercise].topIntensity;
    liftData[exercise].intensityZoneData = addZoneData(liftData[exercise].intensityZoneData || {}, itemZoneData);

    if (liftData[exercise] && liftData[exercise].intensity > 0 && averageIntensity > 0)
      liftData[exercise].intensity = Math.round(((liftData[exercise].intensity + averageIntensity)/2)*100)/100 ;
    else if (averageIntensity > 0)
      liftData[exercise].intensity = averageIntensity;
    //Add data to typeData
    typeData[exerciseType].volume += itemVolume;
    typeData[exerciseType].sets += itemSets;
    typeData[exerciseType].reps += itemReps;
    typeData[exerciseType].missedReps += itemMissedReps;
    typeData[exerciseType].topIntensity = itemTopIntensity > typeData[exerciseType].topIntensity ? itemTopIntensity : typeData[exerciseType].topIntensity;
    typeData[exerciseType].intensityZoneData = addZoneData(typeData[exerciseType].intensityZoneData || {}, itemZoneData);
    Debug('typeIntensity: ' + typeData[exerciseType].intensity);

    if (typeData[exerciseType] && typeData[exerciseType].intensity > 0 && averageIntensity > 0)
      typeData[exerciseType].intensity = Math.round(((typeData[exerciseType].intensity + averageIntensity)/2)*100)/100 ;
    else if (averageIntensity > 0)
      typeData[exerciseType].intensity = averageIntensity;
  }
  Debug(liftData);
  Debug(typeData);
  }
  return {
    date: date.seconds,
    volume: totalVolume,
    intensity: totalIntensity,
    sets: totalSets,
    reps: totalReps,
    missedReps: totalMissedReps,
    intensityZoneData: totalZoneData,
    unit: defaultUnit,
    liftData,
    typeData,
  }
}

export const addZoneData = (data, newData) => {
  for (var key in newData) {
      if (newData.hasOwnProperty(key)) {
        if (!data[key])
            data[key] = getEmptyZoneData();

          data[key].volume += newData[key].volume;
          data[key].sets += newData[key].sets;
          data[key].reps += newData[key].reps;
          data[key].missedReps += newData[key].missedReps;
      }
  }
  return data;
}

export const getChartData = (workouts, athlete, config) => {
  console.log("CD:", config)
  let chartData = {
    totalData : {},
    totalLiftData: {},
    totalTypeData: {},
    dailyTotalData : [],
    dailyLiftData : {},
    dailyTypeData : {},
    weeklyTotalData: [],
    monthlyTotalData: [],
    weeklyLiftData : {},
    monthlyLiftData : {},
    weeklyTypeData : {},
    monthlyTypeData : {},
//    volumeLiftData : [],
    volumeTypeData : [],
    intensityZoneTypeData : {},
    intensityZoneLiftData : {}
  }

  for (var i=0; i<workouts.length; i++) {
    const { status } = workouts[i];
    if (status === COMPLETE || status === INPROGRESS || status === undefined )
      chartData = buildFullData(workouts[i], chartData, athlete, config);
  }

  //console.log("chartData:", chartData);
  return chartData;
}

export const buildFullData = (data, currentChartData, athlete, config) => {
      const { athleteConfigs, customExercises } = config;
      let {totalData, dailyLiftData, dailyTypeData, dailyTotalData, volumeTypeData, intensityZoneTypeData, intensityZoneLiftData} = currentChartData; //removed volumeLiftData
      let athleteConfig = config.user;
      if (athlete && athleteConfigs && athleteConfigs[athlete]) {
        athleteConfig = athleteConfigs[athlete];
      }

      const result = buildChartData(data, athleteConfig, customExercises);
      //console.log('chartdataresult:', result);
      const {
          date,
          volume,
          intensity,
          sets,
          reps,
          missedReps,
          intensityZoneData,
          liftData,
          typeData
        } = result;

      let workoutVolumeTypeData = { date };
      //let workoutVolumeLiftData = { date };

      for (var key in liftData) {
          if (liftData.hasOwnProperty(key)) {
              data = liftData[key];
              if (dailyLiftData[key] === undefined)
                dailyLiftData[key] = [data];
              else
                dailyLiftData[key].push(data);

            //  workoutVolumeLiftData[key + '-reps'] = data.reps;
            //  workoutVolumeLiftData[key + '-sets'] = data.sets;
            //  workoutVolumeLiftData[key + '-volume'] = data.volume;
            intensityZoneLiftData[key] = addZoneData(intensityZoneLiftData[key] || {}, data.intensityZoneData);
          }
      }

      let ts={}, ws = {}, ms = {};
      if (dailyLiftData) {
        for (var k in dailyLiftData) {
          ws[k] = reduceByWeek(dailyLiftData[k]);
          ms[k] = reduceByMonth(dailyLiftData[k]);
          ts[k] = reduceToSum(dailyLiftData[k]);
        }
      }

      for (var key2 in typeData) {
          if (typeData.hasOwnProperty(key2)) {
              data = typeData[key2];
              if (dailyTypeData[key2] === undefined)
                dailyTypeData[key2] = [data];
              else
                dailyTypeData[key2].push(data);

              workoutVolumeTypeData[key2 + '-reps'] = data.reps;
              workoutVolumeTypeData[key2 + '-sets'] = data.sets;
              workoutVolumeTypeData[key2 + '-volume'] = data.volume;
              workoutVolumeTypeData[key2 + '-averageWeight'] = reps>0 ? (Math.round((data.volume/data.reps)*10)/10) : 0;
              intensityZoneTypeData[key2] = addZoneData(intensityZoneTypeData[key2] || getEmptyIntensityZoneData(), data.intensityZoneData);
          }
      }

      for (var i=0; i<exerciseCategoriesL2.length; i++) {
        const key = exerciseCategoriesL2[i].key
        if (key !== CORE && key !== OTHER) {
          workoutVolumeTypeData[key + '-reps'] = workoutVolumeTypeData[key + '-reps'] ? workoutVolumeTypeData[key + '-reps'] : 0;
          workoutVolumeTypeData[key + '-sets'] = workoutVolumeTypeData[key + '-sets'] ? workoutVolumeTypeData[key + '-sets'] : 0;
          workoutVolumeTypeData[key + '-volume'] = workoutVolumeTypeData[key + '-volume'] ? workoutVolumeTypeData[key + '-volume'] : 0;
          workoutVolumeTypeData[key + '-averageWeight'] = workoutVolumeTypeData[key + '-averageWeight'] ? workoutVolumeTypeData[key + '-averageWeight'] : 0;
        }
      }

      //volumeLiftData.push(workoutVolumeLiftData);
      volumeTypeData.push(workoutVolumeTypeData);
      //console.log('volliftdata: ', volumeLiftData);
      //console.log('voltypedata: ', volumeTypeData);

      let tt ={}, wt = {}, mt = {};
      if (dailyTypeData) {
        for (var j in dailyTypeData) {
          wt[j] = reduceByWeek(dailyTypeData[j]);
          mt[j] = reduceByMonth(dailyTypeData[j]);
          tt[j] = reduceToSum(dailyTypeData[j]);
        }
      }

    totalData = {
        volume : totalData.volume ? totalData.volume + volume : volume,
        intensity: totalData.intensity ? ( intensity > 0 ? Math.round(((totalData.intensity + intensity) / 2 + Number.EPSILON) * 100) : totalData.intensity*100)/ 100 : intensity,
        sets: totalData.sets ? totalData.sets + sets : sets,
        reps: totalData.reps ? totalData.reps + reps : reps,
        missedReps: totalData.missedReps ? totalData.missedReps + missedReps : missedReps
    }

    dailyTotalData = [...dailyTotalData, {
        date,
        volume,
        sets,
        reps,
        missedReps,
        intensity,
        intensityZoneData
      }];

      let weeklyTotalData = [], monthlyTotalData = [];
      if (dailyTotalData) {
        weeklyTotalData = reduceByWeek(dailyTotalData);
        monthlyTotalData = reduceByMonth(dailyTotalData);
      }

      return {
          totalData,
          totalLiftData : ts,
          totalTypeData: tt,
          dailyTotalData,
          dailyLiftData,
          dailyTypeData,
          weeklyTotalData,
          monthlyTotalData,
          weeklyLiftData : ws,
          monthlyLiftData : ms,
          weeklyTypeData : wt,
          monthlyTypeData : mt,
      //    volumeLiftData,
          volumeTypeData,
          intensityZoneTypeData,
          intensityZoneLiftData
        }
    }

export const getEmptyZoneData = () => {
  return { missedReps:0, reps:0, sets:0, volume:0};
}

export const getEmptyIntensityZoneData = () => {
  return {
    [LESSFIFTY]: getEmptyZoneData(),
    [FIFTY]: getEmptyZoneData(),
    [SIXTY]: getEmptyZoneData(),
    [SEVENTY]: getEmptyZoneData(),
    [EIGHTY]: getEmptyZoneData(),
    [NINETY]: getEmptyZoneData(),
    [HUNDREDPLUS]: getEmptyZoneData()
  }
}

export const getIntensityZone = (intensity) => {
  if (!isFinite(intensity))
    return null;
  else if (intensity < 50 )
    return LESSFIFTY;
  else if (intensity>=50 && intensity<60)
    return FIFTY;
    else if (intensity>=60 && intensity<70)
      return SIXTY;
    else if (intensity>=70 && intensity<80)
      return SEVENTY;
    else if (intensity>=80 && intensity<90)
      return EIGHTY;
    else if (intensity>=90 && intensity<100)
      return NINETY;
    else if (intensity>=100)
      return HUNDREDPLUS;
    else
      return null;
}

export const getIntensityZoneLabel = (zone) => {
  switch (zone) {
    case LESSFIFTY:
      return '0-50%';
    case FIFTY:
      return '50-59%';
    case SIXTY:
      return '60-69%';
    case SEVENTY:
      return '70-79%';
    case EIGHTY:
      return '80-89%';
    case NINETY:
      return '90-99%';
    case HUNDREDPLUS:
      return '100%+';
    default:
      return "";
  }
}

export const getTypeLabel = (type, sport) => {
  switch (type) {
    case SNATCH:
      return 'Snatch';
    case CLEAN:
      return 'Clean';
    case JERK:
      return 'Jerk';
    case SQUAT:
      return 'Squat';
    case PULL:
      return (sport && sport === 'powerlifting') ? 'Deadlift' : 'Pull';
    case PRESS:
      return 'Bench';
    case PRESS_VERTICAL:
      return 'Press (Vertical)';
    case ROW:
      return 'Row';
    default:
      return type;
  }
}

export const numberWithCommas = (x) => {
    if (x)
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return "";
}

export const decimalToPercent = (x) => {
   return Math.round((x*100 + Number.EPSILON) * 10) / 10;
}

export const getCategoryColor = (category) => {
  switch (category) {
      case SNATCH:
        return '#9cbc55';
      case CLEAN:
        return '#5588bb';
      case JERK:
        return '#69bdbd';
      case SQUAT:
        return '#444464';
      case PULL:
        return '#eb9c45';
      case PRESS:
        return '#bb5555';
      case PRESS_VERTICAL:
        return '#ac6444';
      case ROW:
        return '#848494';
      case CLEANJERK:
        return '#bd8c79';
    default:
      return '#bd8c79';
  }
}

export const hasPercentWork = (exerciseArr) => {
  for (var i=0; i<exerciseArr.length; i++) {
    const { weightArr } = exerciseArr[i];
    if (weightArr) {
      for (var j=0; j<weightArr.length; j++) {
        if (weightArr[j].unit === '%') {
          return true;
        }
      }
    }
  }
  return false;
}

export const generatePR = (date, exercise, repMax, unit, weight) => {
  const userId = firebaseAuth.currentUser.uid;
  return {
    userId,
    date,
    exercise,
    repMax,
    unit,
    weight
  }
}


export const getRecord = (currentRecord) => {
  const {oneRM, twoRM, threeRM, fiveRM, tenRM } = currentRecord;
  if (oneRM && oneRM.weight > 0)
    return oneRM;
  if (twoRM && twoRM.weight > 0)
    return twoRM;
  if (threeRM && threeRM.weight > 0)
    return threeRM
  if (fiveRM && fiveRM.weight > 0)
    return fiveRM
  if (tenRM && tenRM.weight > 0)
    return tenRM

  return null;
}

export const scoreToString = (score, wodType) => {
  let scoreString = '';
  switch (wodType) {
    case NONE:
      scoreString = 'NONE';
      break;
    case TIME:
      scoreString = score ? score.minutes + ':' + score.seconds : '';
      break;
    case AMRAP:
      scoreString = score ? score.rounds + '+' + score.reps : '';
      break;
    case MAXREPS:
        scoreString = score ? score.reps : '';
        break;
    case CUSTOM:
      scoreString = score ? score.reps : '';
      break;
    default:
      scoreString = 'NONE';
  }
  return scoreString
}

export const replaceNewLines = (str, char ) => {
  if (str) {
    str = str.replace(/(?:\r\n|\r|\n)/g, char);
    return str;
  }
  else
    return '';
}

export const reduceToSum = (data) => {
  let total = { volume: 0, sets: 0, reps: 0, missedReps: 0, topIntensity: 0};
  if (data) {
    for (var i=0; i<data.length; i++) {
      total.volume = total.volume + data[i].volume;
      total.sets = total.sets + data[i].sets;
      total.reps = total.reps + data[i].reps;
      total.missedReps = total.missedReps + data[i].missedReps;
      total.topIntensity = total.topIntensity > data[i].topIntensity ? data[i].topIntensity : data[i].topIntensity;
      total.intensity = total.intensity > 0 ? ( data[i].intensity > 0 ? Math.round(((total.intensity + data[i].intensity) / 2 + Number.EPSILON) * 100) : total.intensity*100) / 100 : data[i].intensity;
    }
  }
      return total;
}

export const  reduceByMonth = (data) => {
    if (data) {
      return data.reduce((total, current, index) => {
        const month = { date: null, volume: 0, intensity : 0 };
        if (index !== 0 && (total && total[total.length - 1] && total[total.length - 1].date && monthsMatch(new Date(total[total.length - 1].date * 1000), new Date(current.date * 1000)))) {
         if (total[total.length - 1].intensity > 0 && current.intensity > 0) {
          total[total.length - 1].intensity =
            Math.round(((total[total.length - 1].intensity * (total[total.length - 1].volume/(total[total.length - 1].volume + current.volume))) +
            (current.volume/(total[total.length - 1].volume + current.volume) * current.intensity)) * 100 + Number.EPSILON) / 100;
        }
          else if (current.intensity > 0)
          	total[total.length - 1].intensity = current.intensity;

          total[total.length - 1].volume = total[total.length - 1].volume + current.volume;
          total[total.length - 1].sets = total[total.length - 1].sets + current.sets;
          total[total.length - 1].reps = total[total.length - 1].reps + current.reps;
          total[total.length - 1].missedReps = total[total.length - 1].missedReps + current.missedReps;
          return total;
        } else {
          month.date = new Date(new Date(current.date * 1000).getFullYear(), new Date(current.date * 1000).getMonth());
          month.date = month.date.getTime() / 1000
          month.volume = current.volume;
          month.sets = current.sets;
          month.reps = current.reps;
          month.missedReps = current.missedReps;
          month.intensity = current.intensity  //TODO: do i need to add and divide here?
          total.push(month);
          return total;
        }
      }, []);
    } else {
      console.error('Cannot reduce undefined data');
      return []
    }
  }

const monthsMatch = (dateA, dateB) => {
    if (dateA.getFullYear() === dateB.getFullYear()) {
      return dateA.getMonth() === dateB.getMonth();
    } else {
      return false;
    }
}

const weeksMatch = (dateA, dateB) => {
    if (dateA.getFullYear() === dateB.getFullYear()) {
      return weekOfYear(dateA) === weekOfYear(dateB);
    } else {
      return false;
    }
}

export const reduceByWeek = (data) => {
    if (data) {
      return data.reduce((total, current, index) => {
        const week = { date: null, volume: 0, intensity : 0 };
        if (index !== 0 && (total && total[total.length - 1] && total[total.length - 1].date && weeksMatch(new Date(total[total.length - 1].date * 1000), new Date(current.date * 1000)))) {
         if (total[total.length - 1].intensity > 0  && current.intensity > 0) {
          total[total.length - 1].intensity =
          	Math.round(((total[total.length - 1].intensity * (total[total.length - 1].volume/(total[total.length - 1].volume + current.volume))) +
            (current.volume/(total[total.length - 1].volume + current.volume) * current.intensity)) * 100 + Number.EPSILON) / 100;
        }
        else if (current.intensity > 0)
          total[total.length - 1].intensity = current.intensity;

          total[total.length - 1].volume = total[total.length - 1].volume + current.volume;
          total[total.length - 1].sets = total[total.length - 1].sets + current.sets;
          total[total.length - 1].reps = total[total.length - 1].reps + current.reps;
          total[total.length - 1].missedReps = total[total.length - 1].missedReps + current.missedReps;
          return total;
        } else {
          week.date = getMonday(new Date(current.date * 1000));
          week.date = week.date.getTime() / 1000;
          week.volume = week.volume + current.volume;
          week.sets = current.sets;
          week.reps = current.reps;
          week.missedReps = current.missedReps;
          week.intensity = current.intensity;
          total.push(week);
          return total;
        }
      }, []);
    } else {
      console.error('Cannot reduce undefined data');
      return []
    }
  }

const weekOfYear = (date) => {
    var d = new Date(+date);
    d.setHours(0,0,0);
    d.setDate(d.getDate()+4-(d.getDay()||7));
    return Math.ceil((((d-new Date(d.getFullYear(),0,1))/8.64e7)+1)/7);
};

const getMonday = (d) => {
  d = new Date(d);
  var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export const workoutToSummary = (workout, customExercises) => {
const { name, exerciseArr, date, notes } = workout;
let summary = date.toDate().toLocaleDateString() + '\n'
  + name + '\n'
  + convertExercisesToSummary(exerciseArr, customExercises)
  + notes + '\n';

  return summary;
}

export const convertExercisesToSummary = (exerciseArr, customExercises) => {
  let summary = '';
  var renderedComplexes = [];

  for (var i=0; i<exerciseArr.length; i++) {
    const { type, complexId } = exerciseArr[i];

    if (type === CONDITIONING) {
      summary += conditioningToSummary(exerciseArr[i]);
    }
    //if the exercise was part of a complex and already rendered, do not render
    else if (renderedComplexes.includes(complexId)) {
        continue;
    }
    //if the element is the first of a complex, find all exercise in the complex and pass to ExerciseComplex Component
    else if (complexId) {
      const complexArr = exerciseArr.reduce((acc, obj, i) => {
          if (obj.complexId === complexId) {
            obj.exerciseIndex = i;
            acc.push(obj);
            return acc;
          }
          return acc;
      }, []);
      renderedComplexes.push(complexId);
      summary += complexToSummary(complexArr, customExercises);
    }
    else
      summary += exerciseToSummary(exerciseArr[i], customExercises);
  }
  return summary;
}

export const conditioningToSummary = (exerciseObj) => {
  let summary = '';
  const { wodType, title, description, score, notes } = exerciseObj;

  if (title)
    summary += title + '\n';

  summary += getBenchmarkType(wodType) + '\n'
  + description + '\n'

  if (score)
    summary += getScore(wodType, score) + '\n';
  if (notes)
    summary += notes + '\n';

  return summary;
}

export const getScore = (wodType, score) => {
  let scoreStr = '';
  if (score) {
    switch (wodType) {
      case TIME:
        scoreStr = score.minutes + ' : ' + score.seconds;
        break;
      case AMRAP:
        scoreStr = score.rounds + ' rounds + ' + score.reps + ' reps';
        break;
      case EMOM:
        scoreStr = score.rounds + ' rounds';
        break;
      case MAXREPS:
        scoreStr = score.reps;
        break;
      case DISTANCE:
       scoreStr = score.distance + ' ' + score.unit;
       break;
      case CUSTOM:
        scoreStr = score.score;
        break;
      default:
        scoreStr = '';
    }
    return scoreStr;
 }
}

export const complexToSummary = (complexArr, customExercises) => {
  let summary = ''
  for (var i=0; i< complexArr.length; i++) {
      const { exercise, weightArr } = complexArr[i];
      let str = (i === 0) ? '' : '+ ';
      str += weightArr[0].reps + " " + getExerciseOption(exercise, customExercises).text + '\n';
      summary += str;
   }

  summary += weightComplexToSummary(complexArr[0].weightArr);

  if (complexArr[0].coachNotes)
    summary += complexArr[0].coachNotes + '\n';
  if (complexArr[0].exerciseNotes)
    summary += complexArr[0].exerciseNotes + '\n'

  return summary
}

export const weightComplexToSummary = weightArr => {
  let summary = '';
  for (var i=0; i< weightArr.length; i++) {
     const { weight, sets, unit } = weightArr[i];
      summary += weight>0 ? (sets + (sets>1 ? " sets " : " set ") +  ' @ ' + weight + unit)
              :  sets + (sets>1 ? "sets" : "set")
      summary += '\n'
  }
  return summary;
}

export const exerciseToSummary = (exerciseObj, customExercises) => {
  const {exercise, weightArr, exerciseNotes, coachNotes } = exerciseObj;
  let summary = getExerciseOption(exercise, customExercises).text + '\n';
  summary += weightToSummary(weightArr);
  if (coachNotes)
    summary += coachNotes + '\n';
  if (exerciseNotes)
    summary += exerciseNotes + '\n'

  return summary;
}

export const weightToSummary = (weightArr) => {
  let summary = '';
  for (var i=0; i<weightArr.length; i++) {
    const { weight, sets, reps, unit, setArr } = weightArr[i];
    summary += (weight > 0 ? (sets + ' x ' + reps + ' @ ' + weight + unit) : sets + ' x ' + reps) + '\n';
    if (setArr) {
      summary += setToSummary(setArr, reps);
    }
  }
  return summary;
}

export const setToSummary = (setArr, reps) => {
  for(var i=0; i<setArr.length; i++) {
    const { pattern } = setArr[i];
    let madeReps = pattern.match(/1/g);
    madeReps = madeReps ? madeReps = madeReps.length : 0;
    if (reps > madeReps)
       return'Set' + (i + 1) + ': ' + madeReps + '/' + reps + '\n'
  }
  return '';
}

export const stripeCheckout = async (stripe, priceId) => {
  const docRef = await firebaseDb
  .collection('users')
  .doc(firebaseAuth.currentUser.uid)
  .collection('checkout_sessions')
  .add({
    price: priceId,
    success_url: window.location.origin,
    cancel_url: window.location.origin,
  });
// Wait for the CheckoutSession to get attached by the extension
docRef.onSnapshot((snap) => {
  const { error, sessionId } = snap.data();
  if (error) {
    // Show an error to your customer and
    // inspect your Cloud Function logs in the Firebase console.
    alert(`An error occured: ${error.message}`);
  }
  if (sessionId) {
    stripe.redirectToCheckout({ sessionId });
  }
});
}

export const priceToString = (price) => {
  let dollars = price/100;
  return dollars.toLocaleString("en-US", {style:"currency", currency:"USD"});
}

export const getDateTickFormat = (unixTime, aggregate) => {
  var dateFormatted = new Date(0);
  dateFormatted.setUTCSeconds(unixTime);
  switch (aggregate) {
    case MONTHLY:
      dateFormatted = dateFormatted.toLocaleDateString('en', {  year: 'numeric', month: 'short' })
      break;
    case WEEKLY:
      dateFormatted = 'Wk ' + (dateFormatted.getMonth() + 1) + '/' + dateFormatted.getDate();
      break;
    case DAILY:
      dateFormatted = (dateFormatted.getMonth() + 1) + '/' + dateFormatted.getDate();
      break;
    default:
     dateFormatted = (dateFormatted.getMonth() + 1) + '/' + dateFormatted.getDate() + '/' + dateFormatted.getUTCFullYear();

  }
  return dateFormatted;
}

export const getLabel = (metric, defaultUnit) => {
  switch(metric) {
      case REPS:
        return 'Reps'
      case SETS:
        return 'Sets'
      case VOLUME:
        return 'Volume (' + defaultUnit + ')';
      default:
        return '';
    }
}

export const getRepBreakout = (pattern) => {
  let breakout = {
    reps:0,
    made: 0,
    miss: 0,
    closeMiss:0,
    didNotAttempt: 0
  }

  for (var i=0; i<pattern.length; i++){
    var rep = pattern.charAt(i);
    switch (rep) {
      case '1': // MAKE
        breakout.made++;
        break;
      case '0': //BAD MISS
        breakout.miss++;
        break;
      case '9': //CLOSE MISS
        breakout.closeMiss++;
        break;
      case '5': //N/A
        breakout.didNotAttempt++;;
        break;
      default:
        break;
    }
    breakout.reps++;
  }
  return breakout;
}

export const zoneDataToChartFormat = (zoneData) => {
  if (!zoneData || Object.keys(zoneData).length === 0)
    return null;

  let output = [];
  for (var key in zoneData) {
    if (zoneData.hasOwnProperty(key)) {
        const {missedReps, reps, sets, volume} = zoneData[key];
        output.push({
            intensityZone: key,
            madeReps: reps-missedReps,
            missedReps: missedReps,
            sets: sets,
            volume: volume
          });
    }
  }
    return output;
}

export const removeEmptyFields = (data) => {
  if (!data)
    return null;

  let output = _.cloneDeep(data);
  for (var i=0; i<output.length; i++) {
    for (var prop in output[i]) {
      if (Object.prototype.hasOwnProperty.call(output[i], prop)) {
          if (output[i][prop] === 0)
            delete output[i][prop]
      }
    }
  }
  return output;
}
