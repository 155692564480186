import React from 'react'
import { Icon, Button, Modal } from 'semantic-ui-react';
import Fuse from 'fuse.js';
import { WodList } from './WodList';
import { StyledContainer } from '../StyledComponents';
import { ExerciseSearch } from '../exercise/ExerciseSearch';
import benchmarks from '../../assets/benchmarks';


export const WodModal = ({onWodSelect}) => {

  const [open, setOpen] = React.useState(false)
  const [wods, setWods] = React.useState(benchmarks);

  const fuseSearch = (data, searchQuery) => {
    const options = {
        includeScore: false,
        keys: ['type', 'title', 'description']
    }
    const fuse = new Fuse(data, options);
    let result = [];
    fuse.search(searchQuery).forEach(element => result.push(element.item));

    return result;
  }

  const onSearchChange = (key, value, benchmarks, searchQuery) => {
    if (!searchQuery)
      setWods(benchmarks)
    else
    setWods(fuseSearch(benchmarks, searchQuery))
  }

  return (
    <Modal
      closeIcon
      onClose={() => {setOpen(false); setWods(benchmarks)}}
      onOpen={() => setOpen(true)}

      open={open}
      trigger={<Button style={{background:'transparent'}} type='button'> <Icon style={{padding: 0 }} size='large' name='list' /> </Button>}
    >
      <Modal.Header>Benchmark Workouts</Modal.Header>
      <Modal.Content style={{minHeight: window.innerHeight}}>
        <Modal.Description>
          <StyledContainer>
              <ExerciseSearch
                exerciseOptions={benchmarks}
                onSearchChange={onSearchChange}
              />
              <WodList
                wods={wods}
                onWodSelect={onWodSelect}
                toggleModal={() => setOpen(!open)}
              />
            </StyledContainer>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}
