import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, Header } from 'semantic-ui-react';
import { scoreToString } from '../../commonMethods';
import {  HorizontalDiv } from '../StyledComponents';
import  { retrieveExerciseHistory } from '../../actions';
import { Loading } from '../common/Loading';

class WodHistoryScores extends Component {
  //TODO: Check if this is working
  componentDidMount() {
    this.props.retrieveExerciseHistory(this.props.athlete, this.props.title);
  }

  componentDidUpdate(prevProps) {
    if (this.props.athlete !== prevProps.athlete)
      this.props.retrieveExerciseHistory(this.props.athlete, this.props.title);
  }

  render() {
    const { title, historyLog } = this.props;
    const { loading, exerciseWorkouts } = historyLog;
    let scores = [];
    exerciseWorkouts.forEach( workout => {
      const { date, exercises, exerciseArr } = workout;
      for (var i=0; i < exercises.length; i++) {
          if (exercises[i] === title)
            scores.push({...exerciseArr[i], date });
      }
    });

    if (loading)
      return <Loading />
    else if (scores.length > 0) {
        return (
           scores.map((wodScore, i) => {
             const { date, score, wodType} = wodScore;
            return (
                <div style={{width: '100%', padding:'3%', paddingBottom: '0%'}} key={'wod-history-' + i}>
                  <HorizontalDiv>
                    <Header as='h4'>{scoreToString(score, wodType)}</Header>
                    <p> {date.toDate().toLocaleDateString()} </p>
                  </HorizontalDiv>
                  <Divider/>
               </div>
            )
          }
        ))
    }
    else {
      return <Header as='h4'> No history recorded. </Header>
    }
  }
}

const mapStateToProps = state => {
  return {
           historyLog: state.historyLog,
           config : state.config
         }
};

export default connect(mapStateToProps, {retrieveExerciseHistory})(WodHistoryScores);
