import React, { Component } from 'react';
import Fuse from 'fuse.js';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import Debug from '../../Debug';
import CreateExerciseModal from './CreateExerciseModal';
import { CategoryDropdown } from './CategoryDropdown';
import { ExerciseList } from './ExerciseList';
import { ExerciseSearch } from './ExerciseSearch';
import { HorizontalDiv } from '../StyledComponents';
import exerciseOptions from '../../assets/exercise-classification';
import { retrieveCustomExercises } from '../../actions';

const INITIAL_STATE = {
  touched : false,
  initialExerciseOptions : exerciseOptions,
  exerciseOptions,
  category: '',
  category_level_2: '',
  searchQuery: ''
}


class ExerciseListView extends Component {

  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    if (this.props.config && !this.props.config.customExercises)
      this.props.retrieveCustomExercises();
  }

  componentDidUpdate() {
        if (this.props.config && this.props.config.didInvalidate) {
                this.props.retrieveCustomExercises();
                this.setState({...INITIAL_STATE, exerciseOptions: this.customExercisesToArr(this.props.config.customExercises)}); //set state to all custom exercises when a new one is created
        }
  }

  customExercisesToArr = (customExercises) => {
    let customExerciseArr = []
    for (const [key, value] of Object.entries(customExercises)) {
        customExerciseArr.push({...value, key, value: key});
      }
      return customExerciseArr;
  }

  mergeCustomExercises = (exerciseOptions, customExercises) => {
  //  Debug(customExercises)
      let customExerciseArr = this.customExercisesToArr(customExercises);
      let initialExerciseOptions = exerciseOptions.concat(customExerciseArr);
      return initialExerciseOptions;
  }

  fuseSearch = (exerciseOptions, searchQuery) => {
    const options = {
        includeScore: false,
        keys: ['text', 'value', 'category', 'category_level_2']
    }
    const fuse = new Fuse(exerciseOptions, options);
    let result = [];
    fuse.search(searchQuery).forEach(element => result.push(element.item));

    return result;
  }

  onSearchChange = (searchQuery) => {
    this.setState({searchQuery});
  }

  filterExerciseCategories = (key, value, exerciseOptions, searchQuery = null) => {

    let newExerciseOptions = exerciseOptions;
    const {category, category_level_2} = this.state;

    if (key === 'category' && value) {
      newExerciseOptions = newExerciseOptions.filter((element)=> { return element[key] === value})
      if (category_level_2)
        newExerciseOptions = newExerciseOptions.filter((element)=> { return element.category_level_2 === category_level_2});
    }
    else if (key === 'category_level_2' && value) {
      if (category)
        newExerciseOptions = newExerciseOptions.filter((element)=> { return element.category === category});
      newExerciseOptions = newExerciseOptions.filter((element)=> { return element[key] === value})
    }
    else {
      if (category && category !== "")
        newExerciseOptions = newExerciseOptions.filter((element)=> { return element.category === category});
      Debug(newExerciseOptions);
      if (category_level_2 && category_level_2 !== "")
        newExerciseOptions = newExerciseOptions.filter((element)=> { return element.category_level_2 === category_level_2})
    }
    if (searchQuery) {
      this.setState({searchQuery});
      newExerciseOptions = this.fuseSearch(newExerciseOptions, searchQuery);
    }
    else if (this.state.searchQuery)
      newExerciseOptions = this.fuseSearch(newExerciseOptions, this.state.searchQuery);

//    Debug(newExerciseOptions);

    this.setState({
      [key]: value,
      exerciseOptions: newExerciseOptions
    })
  }

  updateExerciseOptions = (exerciseOptions) => {
    this.setState({exerciseOptions});
  }

  onCreateNewExercise = (exercise) => {
    //this.props.toggleModal();
  //  this.filterExerciseCategories(null, null, exerciseOptions, exercise);
  this.setState({searchQuery: exercise});
//  this.filterExerciseCategories(null, null, null, exercise);
  }

  render() {
    const {exerciseIndex, onExerciseSelect, config} = this.props;
    let initialExerciseOptions = exerciseOptions;
    if (config.customExercises)
      initialExerciseOptions = this.mergeCustomExercises(initialExerciseOptions, config.customExercises);
     Debug(initialExerciseOptions)
    return (
      <><div>
          <HorizontalDiv>
            <ExerciseSearch
                onSearchChange = {this.filterExerciseCategories}
                exerciseOptions = {initialExerciseOptions}
            />
            <CreateExerciseModal
                trigger={ <Button
                              icon='add'
                           />}
                exerciseIndex={exerciseIndex}
                onSave={this.onCreateNewExercise}
            />
          </HorizontalDiv>
          <CategoryDropdown
              onChange = {this.filterExerciseCategories}
              exerciseOptions={initialExerciseOptions}
              icon='filter'
              category={this.state.category}
              category_level_2={this.state.category_level_2}
          />
        </div>
      <br/>
      <ExerciseList
        onExerciseSelect={onExerciseSelect}
        exerciseIndex={exerciseIndex}
        exerciseOptions={this.state.exerciseOptions}
        toggleModal={this.props.toggleModal}
        userConfig={this.props.userConfig}
      /></>
    );
  }
}

const mapStateToProps = state => {
  return {
           config: state.config
         }
}

export default connect(mapStateToProps, { retrieveCustomExercises })(ExerciseListView);
