import React, { Component } from 'react';
import Debug from '../../Debug';
import { Input, Header, Table } from 'semantic-ui-react';
import { StyledContainer, HorizontalDiv } from '../StyledComponents';
import { brzyckiFormula } from '../../commonMethods';

class RepMaxCalculator extends Component {

  constructor() {
      super();
      this.state = {
        weight: '',
        reps: 1
      }
    }

    renderRow = (percent, weight) => {
      return <Table.Row>
              <Table.Cell>{percent}</Table.Cell>
              <Table.Cell>{weight}</Table.Cell>
            </Table.Row>
    }


  render () {
      const { weight, reps } = this.state;
      Debug( weight, reps)
      let estRM = null;
      if (weight>0 && reps>0)
            estRM = brzyckiFormula(weight, reps)
      return (
        <StyledContainer>
          <HorizontalDiv>
              <Input
                style={{width:'130px'}}
                size='small'
                label='Weight'
                value={weight}
                type='number'
                onChange={(e, data) => this.setState({weight : data.value})}
              />
              <Input
                style={{width:'70px'}}
                size='small'
                label='Reps'
                value={reps}
                type='number'
                onChange={(e, data) => this.setState({reps : data.value})}
              />
          </HorizontalDiv>
          <br/>
          <br/>
          <StyledContainer style={{width:'100%'}}>
          <Header> {reps>1 && 'Projected'} 1RM:</Header>
          <Header style={{margin: 0}} as ='h1'>{
              estRM
          }
          </Header>
          <Table inverted celled unstackable
                textAlign='center'
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Percentage</Table.HeaderCell>
                <Table.HeaderCell>Weight</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
                {this.renderRow('95%', Math.round(estRM*0.95))}
                {this.renderRow('90%', Math.round(estRM*0.90))}
                {this.renderRow('85%', Math.round(estRM*0.85))}
                {this.renderRow('80%', Math.round(estRM*0.80))}
                {this.renderRow('75%', Math.round(estRM*0.75))}
                {this.renderRow('70%', Math.round(estRM*0.70))}
                {this.renderRow('65%', Math.round(estRM*0.65))}
                {this.renderRow('60%', Math.round(estRM*0.60))}
                {this.renderRow('55%', Math.round(estRM*0.55))}
                {this.renderRow('50%', Math.round(estRM*0.50))}
                {this.renderRow('40%', Math.round(estRM*0.40))}
                {this.renderRow('30%', Math.round(estRM*0.30))}
                {this.renderRow('20%', Math.round(estRM*0.20))}
                {this.renderRow('10%', Math.round(estRM*0.10))}
            </Table.Body>
          </Table>
          </StyledContainer>
        </StyledContainer>
      )
  }
}


export default RepMaxCalculator;
