import React from 'react';
import { Table } from 'semantic-ui-react';

export const ProgramHistory = ({ data }) => {
  return <Table celled striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Schedule History</Table.HeaderCell>
          <Table.HeaderCell>Scheduled By</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Athletes</Table.HeaderCell>
          <Table.HeaderCell>Start Date</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map(history => {
          const { timestamp, name, creatorName, athletes, startDate } = history;
          let athleteString = '';
          athletes.forEach(athlete => athleteString += athlete.name + ', ');
          athleteString = athleteString.slice(0, -2);
          return <Table.Row key={timestamp}>
                  <Table.Cell collapsing> {timestamp.toDate().toLocaleDateString()} </Table.Cell>
                  <Table.Cell collapsing textAlign='right'>{creatorName} </Table.Cell>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{athleteString}</Table.Cell>
                  <Table.Cell>{startDate.toDate().toLocaleDateString()}</Table.Cell>
                </Table.Row>
        }
        )}
      </Table.Body>
    </Table>
}
