import React, { Component } from 'react';
import { Button, Header } from 'semantic-ui-react';
import Fuse from 'fuse.js';
import { StyledContainer } from '../StyledComponents';
import { WodList } from './WodList';
import { ExerciseSearch } from '../exercise/ExerciseSearch';
import HeaderGeneric from '../menus/HeaderGeneric';
import benchmarks from '../../assets/benchmarks';
import WodHistoryScores from './WodHistoryScores';

class WodHistory extends Component {

  constructor(props) {
    super(props);
    this.state = {
        wods : benchmarks,
        selectedWod : null
    }
  }

  setWods = (wods) => {
    this.setState({wods});
  }

  fuseSearch = (data, searchQuery) => {
    const options = {
        includeScore: false,
        keys: ['type', 'title', 'description']
    }
    const fuse = new Fuse(data, options);
    let result = [];
    fuse.search(searchQuery).forEach(element => result.push(element.item));

    return result;
  }

  onSearchChange = (key, value, benchmarks, searchQuery) => {
    if (!searchQuery)
      this.setWods(benchmarks)
    else
    this.setWods(this.fuseSearch(benchmarks, searchQuery))
  }

  renderHelper = () => {
    const { wods, selectedWod } = this.state;
    if (selectedWod) {
      const { title } = selectedWod;
      return <>
        <HeaderGeneric
            itemLeft={ <Button style={{fontSize: '20px', background: 'transparent'}} icon="arrow left" onClick={() => this.setState({selectedWod: null})} />}
        />
          <Header>{title}</Header>
          <WodHistoryScores
            title={title}
            athlete={this.props.athlete}
          />
        </>
    }
    else {
      return <><ExerciseSearch
        exerciseOptions={benchmarks}
        onSearchChange={this.onSearchChange}
      />
      <WodList
        wods={wods}
        onWodSelect={(selectedWod) => this.setState({selectedWod})}
      /></>
    }
  }

  render() {
    return <StyledContainer>
        {this.renderHelper()}
    </StyledContainer>
  }
}

export default WodHistory;
