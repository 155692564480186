import React, { PureComponent } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList } from 'recharts';

export default class VerticalBarChart extends PureComponent {

  render() {
    const { data, metric } = this.props;
    console.log('ddata:', data)
    return (
      <ResponsiveContainer width='100%' height={120}>
        <BarChart
          layout="vertical"
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis type="number"/>
          <YAxis tick={false} type="category" dataKey="name" />
          <Legend />
          <Bar name={"Current weeks " + metric} dataKey={"current" + metric} fill="#2d7a9c">
             <LabelList fill='white' dataKey={"current" + metric} position="inside" color="white"  />
          </Bar>
          <Bar name={"Previous weeks " + metric}dataKey={"previous" + metric} fill="#b6b6b6">
            <LabelList fill='white' dataKey={"previous" + metric} position="inside" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
