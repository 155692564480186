import React, { PureComponent } from 'react';
import Debug from '../../Debug';
import {
  LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { StyledContainer, HorizontalDiv } from '../StyledComponents';
import { Radio } from 'semantic-ui-react';
import { brzyckiFormula } from '../../commonMethods';
/*
let  data = [
  {
    date: 'Page A', uv: 4000, pv: 2400, amt: 2400,
  },
  {
    name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
  },
  {
    name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
  },
  {
    name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
  },
  {
    name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
  },
  {
    name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
  },
  {
    name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
  },
];

data = [{"date":{"seconds":1579502167,"nanoseconds":536000000},"exercise":"BackSquat","repMax":1,"unit":"kg","userId":"UWl7sQdZ37W4og314PdcZfefeh22","weight":160,"id":"PWc2uFO9heBMKsIZJCtt"},{"date":{"seconds":1585360967,"nanoseconds":536000000},"exercise":"BackSquat","repMax":8,"unit":"kg","userId":"UWl7sQdZ37W4og314PdcZfefeh22","weight":120,"id":"PWc2uFO9heBMKsIZJCtt"},{"date":{"seconds":1593301767,"nanoseconds":0},"exercise":"BackSquat","repMax":8,"unit":"kg","userId":"UWl7sQdZ37W4og314PdcZfefeh22","weight":130,"id":"CDVapjag4tuklpCWMb7s"},{"date":{"seconds":1601958567,"nanoseconds":536000000},"exercise":"BackSquat","repMax":5,"unit":"kg","userId":"UWl7sQdZ37W4og314PdcZfefeh22","weight":140,"id":"PWc2uFO9heBMKsIZJCtt"},{"date":{"seconds":1603823567,"nanoseconds":0},"exercise":"BackSquat","repMax":1,"unit":"kg","userId":"UWl7sQdZ37W4og314PdcZfefeh22","weight":165,"id":"CDVapjag4tuklpCWMb7s"},{"date":{"seconds":1606705367,"nanoseconds":536000000},"exercise":"BackSquat","repMax":3,"unit":"kg","userId":"UWl7sQdZ37W4og314PdcZfefeh22","weight":150,"id":"PWc2uFO9heBMKsIZJCtt"},{"date":{"seconds":1608951767,"nanoseconds":0},"exercise":"BackSquat","repMax":1,"unit":"kg","userId":"UWl7sQdZ37W4og314PdcZfefeh22","weight":175,"id":"CDVapjag4tuklpCWMb7s"}]
data.forEach(obj => {
  obj.date = obj.date.seconds
  obj.estRM = brzyckiFormula(obj.weight, obj.repMax)
});
Debug(data)
*/

const RadioSlider = ({onChange}) => <Radio slider
    onChange={onChange}
 />

class PRChart extends PureComponent {

  constructor(props) {
    super(props);
    Debug("Props:", props);
    this.state = {
      projected : false
    }
  }

  dateToString = (unixTime) => {
    var dateFormatted = new Date(0);
    dateFormatted.setUTCSeconds(unixTime);
    dateFormatted = (dateFormatted.getMonth() + 1) + '/' + dateFormatted.getDate(); // + '/' + dateFormatted.getFullYear()
    return dateFormatted;
  }

  convertData = (data) => {
    data.forEach(obj => {
      if (obj.date)
        obj.date = obj.date.seconds
      else
        obj.date = 0

      obj.estRM = brzyckiFormula(obj.weight, obj.repMax)
    });
    return data;
  }

  removeSameDayRecords = (data) => {
    //find all the dates
    let dates = [], newData = [];
    data.forEach(obj => {
      if (!dates.includes(obj.date))
        dates.push(obj.date);
    });

    //for each date find the top record of the date (biggest estRM)
    for (var i=0; i<dates.length; i++) {
      let date = dates[i];
      let dateData = data.filter(obj => obj.date === date);
      let topRecord = dateData[0];
      dateData.forEach(obj => {
        if (obj.estRM > topRecord.estRM)
          topRecord = obj;
      });
      newData.push(topRecord);
    }
    return newData;
  }

  render() {
      Debug(this.props.data)
      let data = [...this.props.data].map(i => ({ ...i}));;
      data = this.convertData([...data]);
      const actualData = data.filter(obj => obj.repMax===1);
      Debug(this.props.data)
      Debug(data);
      data = this.removeSameDayRecords(data);
      Debug(actualData)
    return (
      <StyledContainer style={{width:'100%'}}>
          <HorizontalDiv>
            1RM&nbsp;
            <RadioSlider
                  onChange={() => this.setState({projected: !this.state.projected})}
            />
            &nbsp;Projected
          </HorizontalDiv>
          <div style={{width:'100%', height: '100%'}}>
            <ResponsiveContainer  width="99%" aspect={2}>
              <LineChart
                data={this.state.projected ? data : actualData}
                margin={{
                  top: 5, right: 30, left: 20, bottom: 5,
                }}
              >
                <XAxis
                  type="number"
                  domain={["dataMin", "dataMax"]}
                  tickFormatter = {(unixTime) => this.dateToString(unixTime)}
                  dataKey="date" />
                <YAxis
                  type="number"
                  domain={["dataMin", "dataMax"]}
                  yAxisId="left"
                  label={{ value: 'Weight', orientation: "left", angle: -90, position: 'insideLeft', textAnchor: 'middle' }}
                />
                <Tooltip
                  labelFormatter = {label => { return this.dateToString(label)}}
                />
                <Legend />
                <Line yAxisId="left" dataKey={this.state.projected ? "estRM" : "weight"} stroke="#316B83" dot={false} strokeWidth={4}  />
              </LineChart>
            </ResponsiveContainer>
          </div>
      </StyledContainer>
    );
  }
}

export default PRChart;
