import React from 'react';
import { Header, Button, Modal, Icon, Select} from 'semantic-ui-react';

const membersToOptions = (members) => {
  let options = [];
  if (members) {
    for (var i=0; i<members.length; i++) {
      options.push({
        key: members[i].uid,
        value: members[i].uid,
        text: members[i].name
      })
    }
  }
  return options;
}

export const MemberSelectModal = ({trigger, header, members, onConfirm, confirmText}) => {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const selectOptions = membersToOptions(members);

  const onClose = () => {
    setOpen(false);
    setSelected(null);
  }

  return (
    <Modal
      onClose={() => onClose()}
      onOpen={() => setOpen(true)}
      open={open}
      size='tiny'
      trigger={trigger}
    >
      <Header icon>
        {header}
      </Header>
      <Modal.Content>
        <Header as='h4'>
          {confirmText}
        </Header>
        <Select
            fluid multiple
            placeholder='Members'
            options={selectOptions}
            onChange={(e, data) => setSelected(data.value)}
            value={selected}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onClose()}>
          <Icon name='remove' /> Cancel
        </Button>
        <Button color='blue' disabled={selected === null} onClick={() => {
            onConfirm(selected);
            setOpen(false)
          }}>
          <Icon name='checkmark' /> Add
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
