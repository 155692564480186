import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firebaseAuth, firebaseDb, workoutsRef } from "../../firebase";
import { Header, Button, Divider, Container, Dropdown, Popup, Icon } from 'semantic-ui-react';
import Debug from '../../Debug';
import { Loading } from '../common/Loading';
import { UpgradeModal } from '../common/UpgradeModal';
import { DateRangePicker } from '../common/DateRangePicker';
import VolumeLineChart from '../charts/VolumeLineChart';
import StackedVolumeChart from '../charts/StackedVolumeChart';
import { StyledContainer, ChartContainer, HorizontalDiv } from '../StyledComponents';
import AthleteSelect from '../menus/AthleteSelect';
import HeaderMain from './HeaderMain';
import HeaderGeneric from '../menus/HeaderGeneric';
import PieSummary from '../charts/PieSummary';
import { StatTable } from '../charts/StatTable';
import {ZoneChartGroup} from '../charts/ZoneChartGroup';
import {AverageWeightChartGroup} from '../charts/AverageWeightChartGroup';
import { getExerciseOptions, getChartData, getExerciseOption, numberWithCommas, zoneDataToChartFormat, removeEmptyFields, hasPro, getStartDate, getIntensityColor } from '../../commonMethods';
import category_level_2_options from '../../assets/exercise_category_level_2';
import { COLOR_PRIMARY, DAILY, WEEKLY, MONTHLY, VOLUME, SETS, REPS, SNATCH, CLEAN, JERK, SQUAT, PULL, PRESS, ROW, PRESS_VERTICAL } from '../../assets/literals';

const emptyChartData = {
  dailyTotalData: [],
  dailyTypeData: {},
  dailyLiftData: {},
  weeklyTotalData: [],
  weeklyTypeData: {
      typeName : []
  },
  weeklyLiftData: {
    liftName : []
  },
  monthlyTotalData: [],
  monthlyTypeData: {
      typeName : []
  },
  monthlyLiftData: {
    liftName : []
  },
  volumeLiftData: [],
  volumeTypeData: [],
  intensityZoneTypeData: {},
  intensityZoneLiftData: {},
}
const INITIAL_STATE = {
    athlete : null,
    loading: false,
    timeframe : '7',
    category: '',
    lift: '',
    exerciseOptions : getExerciseOptions(),
    liftDropdown: [],
    showWeightliftingZones: false,
    showPowerliftingZones: false,
    aggregate : DAILY,
    metric : REPS,
    currentData: [],
    currentVolume: 0,
    currentIntensity: 0,
    chartData: emptyChartData,
    workouts: []
};

//TODO: LIFT PR

class Progress extends Component {

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE, athlete : props.historyLog.athlete || firebaseAuth.currentUser.uid};

//    this.state.currentData = [{"date":1584495523,"volume":3600,"intensity":600},{"date":1584667849,"volume":2755,"intensity":344.375},{"date":1584839851,"volume":1685,"intensity":210.625},{"date":1585362334,"volume":1980,"intensity":247.5},
//      {"date":1584495523,"volume":3600,"intensity":600},{"date":1584667849,"volume":2755,"intensity":344.375},{"date":1584839851,"volume":1685,"intensity":210.625},{"date":1585362334,"volume":1980,"intensity":247.5},
//      {"date":1584495523,"volume":3600,"intensity":600},{"date":1584667849,"volume":2755,"intensity":344.375},{"date":1584839851,"volume":1685,"intensity":210.625},{"date":1585362334,"volume":1980,"intensity":247.5},
  //  ];

  }

  componentDidMount() {

    if (this.props.config && this.props.config.customExercises) {
      const exerciseOptions = getExerciseOptions(this.props.config.customExercises);
      this.setState({exerciseOptions, liftDropdown: exerciseOptions })
    }
    this.loadInitialWorkouts(this.state.athlete);
  }

  loadInitialWorkouts(athlete) {
    const { timeframe } = this.state;
    if (timeframe === 'custom')
      this.setState({timeframe: 7, renderCustomPicker: false });
    const end = new Date();
    const start = getStartDate(end, timeframe === 'custom' ? 7 : timeframe );
    Debug(start, end);
    this.retrieveWorkouts(start,end, athlete);
  }

  clearSelection = () => {
    this.setState({currentData: this.state.chartData.dailyTotalData, category: '', lift: ''})
    this.setCurrentStats(this.state.chartData.dailyTotalData);
  }

    async retrievePRs(fromDate) {
    //  if (!firebase.auth.currentUser)
    //    return 1;
      const { athlete } = this.state;
      var prRef = firebaseDb.collection("users").doc(athlete).collection("personalrecords");
      if (fromDate)
        prRef.where('date', '>=', fromDate);
      await prRef.get().then((querySnapshot) => {
          Debug(querySnapshot);
          querySnapshot.forEach((doc) => {
              this.setState({
                  liftPRs : doc.data()
              });
          });
        }).catch((error) => {
            console.log("Error getting document:", error);
          });
    }

    retrieveWorkouts(start, end, athlete) {
      this.setState({loading: true});
      let workouts = [];
      firebaseDb.collection(workoutsRef).where('userId', '==', athlete)
                 .where('date', '>=', start)
                 .where('date', '<', end)
                 .get().then((querySnapshot) => {
          Debug(querySnapshot);
          querySnapshot.forEach((doc) => {
              Debug(doc.id, ' => ', doc.data());
              workouts.push(doc.data());
          });
        }).catch((error) => {
            console.log("Error getting document:", error);
            alert("Error retrieving workouts");
            this.setState({loading: true});
          })
          .finally(() => {
            let numDays = (end.getTime() - start.getTime()) / (1000*3600*24);
            let aggregate = DAILY;
            if (numDays > 120)
              aggregate = MONTHLY;
            else if (numDays > 40)
              aggregate = WEEKLY;

            this.setChartData(workouts, aggregate);
          });
    }

setChartData = (workouts, aggregate) => {
  const chartData = getChartData(workouts, this.state.athlete, this.props.config);
  this.setCurrentStats(chartData.dailyTotalData);
  this.setState({
    loading: false,
    chartData: chartData,
    currentData: chartData.dailyTotalData,
  });
  this.updateCurrentData(aggregate, this.state.category, this.state.lift );
}

 setCurrentStats = (data, m) => {
   const metric = m || this.state.metric;
   Debug(data);
    let currentVolume = 0, currentSets = 0, currentReps = 0, currentIntensity = 0, vol=0;
    if (data) {
        data.forEach((ele) => {
          currentVolume += ele.volume;
          currentSets += ele.sets;
          currentReps += ele.reps;
        });
        data.forEach(ele => {
          currentIntensity += ele.intensity * (ele.volume/currentVolume)
        });

        if (isNaN(currentIntensity))
          currentIntensity = 0;

  currentIntensity = currentIntensity === 0 ? 0 : (Math.round((currentIntensity*100 + Number.EPSILON) * 10) / 10);

  switch (metric) {
    case VOLUME:
      vol = currentVolume;
      break;
    case SETS:
      vol = currentSets;
      break;
    case REPS:
      vol = currentReps;
      break;
    default:
      vol = currentVolume;
  }
  Debug(currentVolume, currentIntensity);

  }
  this.setState({
      currentVolume: numberWithCommas(vol),
      currentIntensity
  });
  }

  updateFilter = (key, value) => {
    const { aggregate, category, chartData } = this.state;
    const { dailyLiftData, dailyTypeData } = chartData
    this.setState(
             {[key]:value }
      );
      Debug("LOG", dailyLiftData[value]);
      if (key === 'lift'){
        this.setCurrentStats(dailyLiftData[value]);
        this.updateCurrentData(aggregate, category, value);
      }
      else if (key === 'category') {
        this.setCurrentStats(dailyTypeData[value]);
        this.setState({
            lift : '',
            liftDropdown : this.state.exerciseOptions.filter((el) => {
                  return el.category_level_2 === value
                })
        });
        this.updateCurrentData(aggregate, value, null);
      }
      else if (key === 'timeframe') {
        if (this.state.timeframe !== value) {
          this.setState({...INITIAL_STATE, timeframe : value, athlete : this.state.athlete });
          const end = new Date();
          const start = getStartDate(end, value);
          this.retrieveWorkouts(start, end, this.state.athlete);
        }
     }
    }

    updateCurrentData = (aggregate, category, lift) => {
      let currentData = [];
      const { dailyTotalData, weeklyTotalData, monthlyTotalData, dailyLiftData, weeklyLiftData, monthlyLiftData, dailyTypeData, weeklyTypeData, monthlyTypeData } = this.state.chartData;
      switch(aggregate) {
        case DAILY:
          if (lift)
            currentData = dailyLiftData[lift];
          else if (category)
            currentData = dailyTypeData[category];
          else
            currentData = dailyTotalData;
          this.setState({aggregate : DAILY, currentData})
          break;
        case WEEKLY:
          if (lift)
            currentData = weeklyLiftData[lift];
          else if (category)
            currentData = weeklyTypeData[category];
          else
            currentData = weeklyTotalData;
          this.setState({aggregate : WEEKLY, currentData});
          break;
        case MONTHLY:
          if (lift)
            currentData = monthlyLiftData[lift];
          else if (category)
            currentData = monthlyTypeData[category];
          else
            currentData = monthlyTotalData;
          this.setState({aggregate : MONTHLY, currentData});
          break;
        default:
          this.setState({aggregate : DAILY, currentData : dailyTotalData})
      }
    }

    onAthleteSelect = athlete => {
      this.setState({...INITIAL_STATE, athlete});
      this.loadInitialWorkouts(athlete);
    }

    getVolumeLabel = (defaultUnit) => {
      switch (this.state.metric) {
        case VOLUME:
          return 'Volume (' + (defaultUnit || 'kg') + ')'
        case SETS:
          return 'Sets';
        case REPS:
          return 'Reps';
        default:
          return 'Volume (' + (defaultUnit || 'kg') + ')'
      }
    }

   getSummaryTip = (intensity, category, lift) => {
     if (intensity === 0)
      return '';
      if (intensity <= 50) {
        return "Your average intensity" + ((category || lift) ? (lift ? " in the " + lift : " in the " + category) : "" ) + " is well under the optimal range for strength gains. Consider increasing intensity.";
      }
      if (intensity > 50 && intensity<70) {
        return "Your average intensity" + ((category || lift) ? (lift ? " in the " + lift : " in the " + category) : "" ) + " is below the optimal range for strength gains but sufficient for maintenance and technique improvements."
      }
      if (intensity >= 70 && intensity <= 85) {
        return "You are in the optimal intensity range to maximize strength gains" + ((category || lift) ? (lift ? " in the " + lift : " in the " + category) : "" ) + ".";
      }
      if (intensity >= 85) {
        return "Your average intensity" + ((category || lift) ? (lift ? " in the " + lift : " in the " + category) : "" ) + " is above the optimal range. It is recommended not to spend more than a week at this intensity to avoid overtraining.";
      }
      else
        return '';
    };


  render() {
    const { config } = this.props;
    const { athleteConfigs, user, subscriptions } = config;
    console.log('has pro...', config)
    const {athlete, chartData, showWeightliftingZones, showWeightliftingAverages, showPowerliftingZones, showPowerliftingAverages, lift, category, currentIntensity } = this.state;
    const { intensityZoneTypeData, volumeTypeData} = chartData;
    let defaultUnit = 'kg';
    if (athlete && athleteConfigs && athleteConfigs[athlete]) {
      defaultUnit = athleteConfigs[athlete].defaultUnit;
    }
    else if (config && config.user && config.user.defaultUnit)
      defaultUnit = config.user.defaultUnit;

    const intensityColor = getIntensityColor(this.state.currentIntensity);
    return(
      <>
      <HeaderMain activeItem='Progress'/>
        <StyledContainer>
        <HeaderGeneric
          text="Performance" />
        <br/>
        <Loading active={this.state.loading} />
        <div style={{width : '99%', maxWidth:'1250px'}}>
        {config.team && config.team.members &&
          <AthleteSelect
            onAthleteSelect={this.onAthleteSelect}
          />}
          <div style={{width : '99%', maxWidth:'500px', margin:'auto'}}>
              <Dropdown button
                style={{marginBottom: '5px', width:'100%', maxWidth: '360px', textAlign: 'center'}}
                className='circular'
                placeholder='Timeframe'
                options={[
                           { key: '7', value: '7', text: 'Last 7 days' },
                           { key: '14', value: '14', text: 'Last 14 days' },
                           { key: '30', value: '30', text: 'Last 30 days' },
                           { key: 'custom', value: 'custom', text: 'Custom' },
                           ]
                          }
                  onChange={(e,data) => {
                    if (data.value === 'custom')
                      this.setState({renderCustomPicker: true, timeframe: 'custom'});
                    else {
                      this.setState({renderCustomPicker: false });
                      this.updateFilter('timeframe', data.value);
                    }
                  }}
                  value={this.state.timeframe}
              /><br/>
          {this.state.renderCustomPicker &&
            <>
              {hasPro(subscriptions) ?
              <DateRangePicker
                onSelect={(start, end) => {
                    this.retrieveWorkouts(start, end, this.state.athlete);
                }}
                selectsRange
                inline
              />
              :
              <UpgradeModal
                defaultOpen
              />
            }
            </>}
          </div>
          <br/>
          <div style={{width: '100%', display:'flex', justifyContent: 'center', marginBottom: '15px'}}>
            <HorizontalDiv style={{width: 'fit-content'}}>
              <div style={{width: '120px'}}>
                <span style={{fontSize: 'x-large', fontWeight: 'bold', color: intensityColor}}>{this.state.currentIntensity}&nbsp;%</span>
                <br/><span style={{fontSize: 'medium', fontWeight: 'bold', color: intensityColor}}>Intensity</span>
              </div>
              <PieSummary
                key={this.state.metric}
                intensity={this.state.currentIntensity}
              />
              <div style={{width:'120px'}}>
              <span style={{fontSize: 'x-large', fontWeight: 'bold', color: COLOR_PRIMARY}}>{this.state.currentVolume}</span><br/>
              <Dropdown style={{fontSize: 'medium', fontWeight: 'bold', color: COLOR_PRIMARY}} upward floating
                  options={[
                        {
                          key: REPS,
                          text: 'Reps',
                          value: REPS
                        },
                        {
                          key: SETS,
                          text: 'Sets',
                          value: SETS
                        },
                        {
                          key: VOLUME,
                          text: 'Volume (' + defaultUnit + ')',
                          value: VOLUME
                        }
                      ]}
                    value={this.state.metric}
                    onChange={(e, data) => {
                      this.setState({ metric : data.value });
                      this.setCurrentStats(this.state.currentData, data.value)
                    }
                  }
                />
               </div>
            </HorizontalDiv>
          </div>
          <Container style={{color: 'lightslategrey'}}>{this.getSummaryTip(currentIntensity, category, getExerciseOption(lift).text)}</Container>
          <br/>
          <div style={{width:'99%', height: this.props.config.isMobile ? '250px' : '600px', userSelect: 'none', WebkitUserSelect: 'none'}}>
            <ChartContainer>
              <StackedVolumeChart
                data={volumeTypeData}
                metric={this.state.metric}
                unit={defaultUnit}
                isMobile={this.props.config.isMobile}
              />
            </ChartContainer>
          </div>
          <br/><br/>
          <HorizontalDiv>
            <Dropdown
              placeholder='Category'
              options={category_level_2_options}
              onChange={(e,data) => { this.updateFilter('category', data.value); }}
              value={this.state.category}
              icon='filter'
              labeled
              button
              fluid
              scrolling
              className='icon'
            />
            <Dropdown
              style={{marginLeft:'.5em'}}
              placeholder='Lift'
              icon='filter'
              labeled
              button
              fluid
              scrolling
              search
              className='icon'
              options={this.state.liftDropdown
                      }
              onChange={(e,data) => { this.updateFilter('lift', data.value); }}
              value={this.state.lift}
            />
            <Button compact style={{background: 'transparent'}} size='large' icon='undo' onClick={() => this.clearSelection()} />
          </HorizontalDiv>
          <br/>
          <div style={{width:'99%', height:'100%', userSelect: 'none', WebkitUserSelect: 'none'}}>
            <ChartContainer>
            <Dropdown
              style={{float: 'right', marginLeft: '5%', fontSize: '16px', fontFamily : 'Lato'}}
              options={[
                    {
                      key: DAILY,
                      text: 'Daily',
                      value: DAILY
                    },
                    {
                      key: WEEKLY,
                      text: 'Weekly',
                      value: WEEKLY,
                    },
                    {
                      key: MONTHLY,
                      text: 'Monthly',
                      value: MONTHLY,
                    }
                  ]}
                value={this.state.aggregate}
                onChange={(e, data) => {
                  this.updateCurrentData(data.value, this.state.category, this.state.lift );
                }
              }
            />
            <VolumeLineChart
                data={this.state.currentData}
                metric={this.state.metric}
                unit={defaultUnit}
                aggregate={this.state.aggregate}
                isMobile={this.props.config.isMobile} />
            </ChartContainer>
          </div>
          <Header as='h3'> Weightlifting </Header>
          <ChartContainer style={{padding: '0', borderRadius: '8px', boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)'}}>
                <HorizontalDiv
                  onClick={()=>this.setState({ showWeightliftingZones: !showWeightliftingZones})}
                >
                  <span></span>
                  <Header style= {{marginLeft: '55px'}} as='h4'>
                    Intensity Zones
                  </Header>
                  <Button icon style={{background: 'transparent'}}>
                    <Icon
                      size='big'
                      name={showWeightliftingZones ? 'caret down' : 'caret left'}
                      onClick={()=>this.setState({ showWeightliftingZones: !showWeightliftingZones})}/>
                  </Button>
                </HorizontalDiv>
                {showWeightliftingZones &&
                  <>
                  <Divider/>
                  <ZoneChartGroup
                    sport='weightlifting'
                    zoneData={{[SNATCH]: zoneDataToChartFormat(intensityZoneTypeData[SNATCH]),
                            [CLEAN]: zoneDataToChartFormat(intensityZoneTypeData[CLEAN]),
                            [JERK]: zoneDataToChartFormat(intensityZoneTypeData[JERK]),
                            [SQUAT]: zoneDataToChartFormat(intensityZoneTypeData[SQUAT]),
                            [PULL]: zoneDataToChartFormat(intensityZoneTypeData[PULL]),
                            [PRESS_VERTICAL]: zoneDataToChartFormat(intensityZoneTypeData[PRESS_VERTICAL])}}
                  >
                  </ZoneChartGroup></>
                }
                <Divider/>
                <HorizontalDiv
                  onClick={()=>this.setState({ showWeightliftingAverages: !showWeightliftingAverages})}
                >
                  <span></span>
                  <Header style= {{marginLeft: '55px'}} as='h4'>
                    Average Weight
                  </Header>
                  <Button icon style={{background: 'transparent'}}>
                    <Icon
                      size='big'
                      name={showWeightliftingAverages ? 'caret down' : 'caret left'}
                      onClick={()=>this.setState({ showWeightliftingAverages: !showWeightliftingAverages})}/>
                  </Button>
                </HorizontalDiv>
                {showWeightliftingAverages &&
                  <AverageWeightChartGroup
                    data={removeEmptyFields(volumeTypeData)}
                    categories={[SNATCH, CLEAN, JERK, SQUAT, PULL, PRESS_VERTICAL]}
                    sport='weightlifting'
                    />}
              </ChartContainer>
              <Header as='h3'> Powerlifting </Header>
              <ChartContainer style={{padding: '0', borderRadius: '8px', boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)'}}>
                <HorizontalDiv
                  onClick={()=>this.setState({ showPowerliftingZones: !showPowerliftingZones})}>
                  <span></span>
                  <Header style= {{marginLeft: '55px'}} as='h4'>
                    Intensity Zones
                  </Header>
                  <Button style={{background: 'transparent'}} icon>
                    <Icon
                      size='big'
                      name={showPowerliftingZones ? 'caret down' : 'caret left'}
                      onClick={()=>this.setState({ showPowerliftingZones: !showPowerliftingZones})}/>
                  </Button>
                </HorizontalDiv>
                {showPowerliftingZones &&
                <><Divider/>
                <ZoneChartGroup
                  sport='powerlifting'
                  zoneData={{[SQUAT]: zoneDataToChartFormat(intensityZoneTypeData[SQUAT]),
                          [PRESS]: zoneDataToChartFormat(intensityZoneTypeData[PRESS]),
                          [PULL]: zoneDataToChartFormat(intensityZoneTypeData[PULL]),
                          [ROW]: zoneDataToChartFormat(intensityZoneTypeData[ROW]),
                          [PRESS_VERTICAL]: zoneDataToChartFormat(intensityZoneTypeData[PRESS_VERTICAL])}}
                >
                </ZoneChartGroup>
                </>}
                <Divider/>
                <HorizontalDiv
                  onClick={()=>this.setState({ showPowerliftingAverages: !showPowerliftingAverages})}
                >
                  <span></span>
                  <Header style= {{marginLeft: '55px'}} as='h4'>
                    Average Weight
                  </Header>
                  <Button icon style={{background: 'transparent'}}>
                    <Icon
                      size='big'
                      name={showPowerliftingAverages ? 'caret down' : 'caret left'}
                      onClick={()=>this.setState({ showPowerliftingAverages: !showPowerliftingAverages})}/>
                  </Button>
                </HorizontalDiv>
                {showPowerliftingAverages &&
                  <AverageWeightChartGroup
                    data={removeEmptyFields(volumeTypeData)}
                    categories={[SQUAT, PRESS, PULL, ROW, PRESS_VERTICAL]}
                    sport='weightlifting'
                    />}
              </ChartContainer>
          <br/><br/>
          <Popup content='Intensity is only calculated for barbell movements.'
              trigger={<Button style={{float: 'right', background : 'transparent' }} icon='info' />}
          />
          <ChartContainer>
            <StatTable
              data={this.state.currentData}
              unit={defaultUnit || 'kg'}
            />
          </ChartContainer>
          </div>
        <br/><br/><br/><br/><br/><br/>
      </StyledContainer>
      </>
    );
  }
}


/* MAYBE ADD IN FUTURE FOR
<Dropdown
  style={{marginRight:'.5em'}}
  placeholder='Focus'
  fluid
  selection
  options={[{ key: 'strength', value: 'strength', text: 'Overall Strength' },
             { key: 'weightlifting', value: 'weightlifting', text: 'Olympic Weightlifting' },
             { key: 'powerlifting', value: 'powerlifting',text: 'Powerlifting' }]
            }
    onChange={(e,data) => { this.updateFilter('focus', data.value); }}
    value={this.state.focus}
/>
*/

const mapStateToProps = state => {
  return {
    config : state.config,
    historyLog: state.historyLog
  }
}

export default connect(mapStateToProps)(Progress);
