export const LOGIN_USER = 'login_user'
export const LOGIN_USER_SUCCESS = 'login_user_success';
export const LOGIN_USER_FAIL = 'login_user_fail';
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNUP_USER_FAIL = 'signup_user_fail';
export const SIGNUP_USER_PASSWORD_NOT_MATCHED = 'signup_user_password_not_matched';
export const RETRIEVE_WORKOUTS = 'retrieve_workouts';
export const RETRIEVE_WORKOUTS_SUCCESS = 'retrieve_workouts_success';
export const RETRIEVE_WORKOUTS_FAIL = 'retrieve_workouts_fail';
export const RETRIEVE_TRACK_WORKOUTS = 'retrieve_track_workouts';
export const RETRIEVE_TRACK_WORKOUTS_SUCCESS = 'retrieve_track_workouts_success';
export const RETRIEVE_TRACK_WORKOUTS_FAIL = 'retrieve_track_workouts_fail';
export const SELECT_WORKOUT = "select_workout";
export const CLEAR_SELECTED_WORKOUT = "clear_selected_workout";

export const CREATE_USER_CONFIG = 'create_user_config';
export const UPDATE_CONFIG = 'update_config';
export const RETRIEVE_USER_CONFIG = 'retrieve_user_config';
export const RETRIEVE_USER_CONFIG_SUCCESS = 'retrieve_user_config_success';
export const RETRIEVE_USER_CONFIG_FAIL = 'retrieve_user_config_fail';
export const UPDATE_USER_CONFIG = 'update_user_config';
export const UPDATE_USER_CONFIG_FAIL = 'update_user_config_fail';
export const UPDATE_USER_CONFIG_SUCCESS = 'update_user_config_success';
export const UPDATE_TEAM_CONFIG = 'update_team_config';
export const UPDATE_TEAM_CONFIG_FAIL = 'update_team_config_fail';
export const UPDATE_TEAM_CONFIG_SUCCESS = 'update_team_config_success';
export const UPDATE_CURRENT_RECORDS = 'update_current_records';
export const UPDATE_CURRENT_RECORDS_SUCCESS = 'update_current_records_success';
export const UPDATE_CURRENT_RECORDS_FAIL = 'update_current_records_fail';
export const RETRIEVE_ATHLETE_CONFIG = 'retrieve_athlete_config';
export const RETRIEVE_ATHLETE_CONFIG_SUCCESS = 'retrieve_athlete_config_success';
export const RETRIEVE_ATHLETE_CONFIG_FAIL = 'retrieve_athlete_config_fail';
export const RETRIEVE_ATHLETE_CUSTOM_EXERCISES = 'retrieve_athlete_custom_exercies';
export const RETRIEVE_ATHLETE_CUSTOM_EXERCISES_SUCCESS = 'retrieve_athlete_custom_exercies_success';
export const RETRIEVE_ATHLETE_CUSTOM_EXERCISES_FAIL = 'retrieve_athlete_custom_exercies_fail';

export const RETRIEVE_TEAM = 'retrieve_team';
export const RETRIEVE_TEAM_SUCCESS = 'retrieve_team_success';
export const RETRIEVE_TEAM_FAIL = 'retrieve_team_fail';

export const UPDATE_BUILDER_FIELD = "update_builder_field";
export const UPDATE_BUILDER_EXERCISE = "update_builder_exercise";
export const ADD_BUILDER_EXERCISE = 'add_builder_exercise';
export const DELETE_BUILDER_EXERCISE = 'delete_builder_exercise';
export const ADD_BUILDER_WEIGHT = 'add_builder_weight';
export const DELETE_BUILDER_WEIGHT = 'delete_builder_weight';
export const UPDATE_BUILDER_WEIGHT = 'update_builder_weight';
export const ADD_BUILDER_SET = 'add_builder_set';
export const DELETE_BUILDER_SET = 'delete_builder_set';
export const UPDATE_BUILDER_SET = 'update_builder_set';
export const SAVE_WORKOUT = 'save_workout';
export const SAVE_WORKOUT_SUCCESS = 'save_workout_success';
export const SAVE_WORKOUT_FAIL = 'save_workout_fail';
export const DELETE_WORKOUT = 'delete_workout';
export const DELETE_WORKOUT_SUCCESS = 'delete_workout_success';
export const DELETE_WORKOUT_FAIL = 'delete_workout_fail';
export const START_WORKOUT_BUILDER = 'start_workout_builder';
export const COPY_TO_WORKOUT_BUILDER = 'copy_to_workout_builder';
export const DELETE_BUILDER_COMPLEX = 'delete_builder_complex';
export const UPDATE_COMPLEX_REPS = 'update_complex_reps';
export const ADD_BUILDER_WOD = 'add_builder_wod';
export const SWAP_EXERCISES = 'swap_exercises';

export const RETRIEVE_WORKOUT_HISTORY = 'retrieve_workouts_history';
export const RETRIEVE_WORKOUT_HISTORY_SUCCESS = 'retrieve_workouts_history_success';
export const RETRIEVE_WORKOUT_HISTORY_FAIL = 'retrieve_workouts_history_fail';
export const RETRIEVE_EXERCISE_HISTORY = 'retrieve_exercise_history';
export const RETRIEVE_EXERCISE_HISTORY_SUCCESS = 'retrieve_exercise_history_success';
export const RETRIEVE_EXERCISE_HISTORY_FAIL = 'retrieve_exercise_history_fail';
export const RETRIEVE_RECORDS_HISTORY = 'retrieve_records_history';
export const RETRIEVE_RECORDS_HISTORY_SUCCESS = 'retrieve_records_history_success';
export const RETRIEVE_RECORDS_HISTORY_FAIL = 'retrieve_records_history_fail';
export const RECORD_PR = 'record_pr';
export const RECORD_PR_SUCCESS = 'record_pr_success';
export const RECORD_PR_FAIL = 'record_pr_fail';
export const DELETE_PR= 'delete_pr';
export const DELETE_PR_SUCCESS = 'delete_pr_success';
export const DELETE_PR_FAIL = 'delete_pr_fail';
export const UPDATE_PR_SUCCESS = 'update_pr_success';
export const UPDATE_PR_FAIL = 'update_pr_fail';
export const RETRIEVE_CUSTOM_EXERCISES = 'retrieve_custom_exercises';
export const RETRIEVE_CUSTOM_EXERCISES_SUCCESS = 'retrieve_custom_exercises_success';
export const RETRIEVE_CUSTOM_EXERCISES_FAIL = 'retrieve_custom_exercises_fail';
export const CREATE_CUSTOM_EXERCISE = 'create_custom_exercise';
export const CREATE_CUSTOM_EXERCISE_SUCCESS = 'create_custom_exercise_success';
export const CREATE_CUSTOM_EXERCISE_FAIL = 'create_custom_exercise_fail';
export const RESET_CALENDAR = 'reset_calendar';
export const SET_ATHLETE_OR_TRACK = 'set_athlete_or_track';
export const SET_HISTORY = 'set_history';

export const START_PROGRAM_BUILDER = 'start_program_builder';
export const CLEAR_SELECTED_PROGRAM = 'clear_selected_program';
export const SELECT_PROGRAM = 'select_program';
export const UPDATE_PROGRAM_BUILDER_FIELD = 'update_program_builder_field';
export const UPDATE_PROGRAM_BUILDER_LAYOUT = 'update_program_builder_layout';
export const ADD_PROGRAM_BUILDER_LAYOUT = 'add_program_builder_layout';
export const DELETE_PROGRAM_BUILDER_LAYOUT = 'delete_program_builder_layout';
export const ADD_PROGRAM_BUILDER_WEEK = 'add_program_builder_week';
export const ADD_PROGRAM_BUILDER_DAY = 'add_program_builder_day';
export const ADD_PROGRAM_BUILDER_WORKOUT = 'add_program_builder_workout';
export const SAVE_PROGRAM = 'save_program';
export const SAVE_PROGRAM_SUCCESS = 'save_program_success';
export const SAVE_PROGRAM_FAIL = 'save_program_fail';
export const DELETE_PROGRAM = 'delete_program';
export const DELETE_PROGRAM_SUCCESS = 'delete_program_success';
export const DELETE_PROGRAM_FAIL = 'delete_program_fail';
export const CLEAR_PROGRAMS = 'clear_programs';
export const RETRIEVE_PROGRAMS = 'retrieve_programs';
export const RETRIEVE_PROGRAMS_SUCCESS = 'retrieve_programs_success';
export const RETRIEVE_PROGRAMS_FAIL = 'retrieve_programs_fail';
export const RETRIEVE_PROGRAM_LAYOUT = 'retrieve_program_layout';
export const RETRIEVE_PROGRAM_LAYOUT_SUCCESS = 'retrieve_program_layout_success';
export const RETRIEVE_PROGRAM_LAYOUT_FAIL = 'retrieve_program_layout_fail';
export const RETRIEVE_PROGRAM_HISTORY = 'retrieve_program_history';
export const RETRIEVE_PROGRAM_HISTORY_SUCCESS = 'retrieve_program_history_success';
export const RETRIEVE_PROGRAM_HISTORY_FAIL = 'retrieve_program_history_fail';

export const RETRIEVE_SUBSCRIPTIONS = 'retrieve_subscriptions';
export const RETRIEVE_SUBSCRIPTIONS_SUCCESS = 'retrieve_subscriptions_success';
export const RETRIEVE_SUBSCRIPTIONS_FAIL = 'retrieve_subscriptions_fail';
