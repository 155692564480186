import React, { Component } from 'react';
import { Icon, Header, Button, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import Debug from '../../Debug';
import { selectWorkout, copyToWorkoutBuilder, deleteWorkout } from '../../actions';
import { ExerciseContainer, HorizontalDiv, Paragraph } from '../StyledComponents';
import ExerciseSummary from '../exercise/ExerciseSummary';
import ExerciseComplexSummary from '../exercise/ExerciseComplexSummary';
import EditCopyDeleteMenu from '../menus/EditCopyDeleteMenu';
import WodSummary from '../conditioning/WodSummary';
import SessionSummary from './SessionSummary';
import { buildChartData } from '../../commonMethods';
import { CONDITIONING, INPROGRESS, PROGRAMMED, COMPLETE, ASSISTANT_COACH } from '../../assets/literals';

class LogView extends Component {

  renderExerciseSummaries = () => {
    const { workout } = this.props;
    var renderedComplexes = [];
    return workout.exerciseArr.map((exerciseObj, i) => {
      const { type, exercise, exerciseNotes, coachNotes, weightArr, complexId } = exerciseObj;

      if (type === CONDITIONING) {
        const { wodType, title, description, score, notes } = exerciseObj;
        return <WodSummary
                  key={'conditioning-' + i}
                  headerSize={this.props.headerSize}
                  header
                  wodType={wodType}
                  title={title}
                  description={description}
                  notes={notes}
                  score={score}
        />
      }
      //if the exercise was part of a complex and already rendered, do not render
      else if (renderedComplexes.includes(complexId)) {
          return null;
      }
      //if the element is the first of a complex, find all exercise in the complex and pass to ExerciseComplex Component
      else if (complexId) {
        const complexArr = workout.exerciseArr.reduce((acc, obj, i) => {
            if (obj.complexId === complexId) {
              obj.exerciseIndex = i;
              acc.push(obj);
              return acc;
            }
            return acc;
        }, []);
        Debug(complexArr);
        renderedComplexes.push(complexId);
        return <ExerciseComplexSummary
              key={'complex-sum-' + complexId}
              complexArr={complexArr}
              headerSize={this.props.headerSize}
              config={this.props.config}
          />
      }
      else
      return <ExerciseSummary
          key={'exercise-sum-' + i}
          exerciseIndex={i}
          exercise={exercise}
          exerciseNotes={exerciseNotes}
          coachNotes={coachNotes}
          weightArr={weightArr}
          header={true}
          headerSize={this.props.headerSize}
          config={this.props.config}
      />
    }
    )
  }


  renderConditioning_LEGACY = (conditioning) => {
    if (conditioning)
      return <><Header as={this.props.headerSize} dividing>Conditioning/Accessory: </Header><Paragraph>{conditioning}</Paragraph></>
    else
      return null;
  }

  navigateToLog = (programming) => {
    const {history, athlete, trackMode } = this.props;
    history.push({pathname :'/', state : { fromCalendar : true, programming, athlete : trackMode ? null : athlete, trackMode }})
  }

  getStatus = (workout, athleteConfig, customExercises) => {
    switch(workout.status) {
        case INPROGRESS:
          return <Icon style={{padding: '1%'}} size='large' color='grey' name='ellipsis horizontal' />
        case PROGRAMMED:
          return <Icon style={{padding: '1%'}} size='large' color='grey' name='calendar times' />
        default:
          const sessionSummary = buildChartData(workout, athleteConfig, customExercises);
          return    <SessionSummary
                      data={sessionSummary}
                      duration={Math.round(Math.abs(workout.date-workout.end)/60)}
                      header="Session Summary"
                    />

      }
  }

  render() {
    Debug(this.props);
    const { title, fromCalendar, trackMode, teamRole, showStartButton, athlete, showMenu, scheduleOnly, showName, workout, templateId, config, deleteWorkout, selectWorkout, copyToWorkoutBuilder, onEdit, onCopy, onDelete, onSchedule} = this.props;
    const { athleteConfigs, customExercises } = config;
    const { highlight, status } = workout;
    let athleteConfig = config.user;
    if (athlete && athleteConfigs && athleteConfigs[athlete])
      athleteConfig = athleteConfigs[athlete];
    return (
        <div>
        <ExerciseContainer style={{textAlign: 'left', padding:'8%', backgroundColor: (fromCalendar && highlight) ? '#fff6b9' : ''}}>
          {title && <Message style={{textAlign: 'center'}}>
            <Message.Header style={{ color: 'slategrey'}}>{title}</Message.Header>
            </Message>}
          {showMenu && <HorizontalDiv style={{justifyContent: 'space-between', marginLeft: '-8%', marginRight: '-8%', marginTop: '-8%', marginBottom: '2%'}}>
          {this.getStatus(workout, athleteConfig, customExercises)}
          {teamRole !== ASSISTANT_COACH &&
            <EditCopyDeleteMenu
              showEdit={!scheduleOnly}
              onEdit={() => {
                  if (onEdit)
                    onEdit();
                  else { //default edit
                    selectWorkout(workout)
                    if (fromCalendar)
                      this.navigateToLog(true);
                    }}
              }
              showCopy={!scheduleOnly}
              onCopy={() => {
                    if (onCopy)
                      onCopy();
                    else { //default copy
                      copyToWorkoutBuilder({...workout, status: PROGRAMMED, id: templateId || null})
                      if (fromCalendar)
                        this.navigateToLog(true);
                    }
              }}
              showDelete={!scheduleOnly}
              onDelete={() => {
                    if (onDelete)
                      onDelete()
                    else //default delete
                    deleteWorkout(workout.id, trackMode);
                  }}
              showSchedule={onSchedule !== undefined}
              onSchedule={onSchedule}
          />}
          </HorizontalDiv>
          }
          <div>
            {showName && <Header style={{fontWeight: 'normal', marginBottom : '8px'}}> {workout.name} </Header>}
            {this.renderExerciseSummaries()}
            {this.renderConditioning_LEGACY(workout.conditioning)}
            <Paragraph>{workout.notes}</Paragraph>
          </div>
          {showStartButton && status !== COMPLETE &&
            <Button
              fluid
              primary
              size='large'
              onClick={(e) => {
                this.props.selectWorkout({...workout, status : INPROGRESS});
                if (fromCalendar)
                  this.navigateToLog(false);
              }}
            >
              {status === PROGRAMMED ? 'Start Session' : 'Continue Session'}
            </Button>
          }
        </ExerciseContainer>
        </div>
    );
  }
}


const mapStateToProps = state => {
  return {
           config : state.config
         }
};

export default withRouter(connect(mapStateToProps, {selectWorkout, copyToWorkoutBuilder, deleteWorkout})(LogView));
