import React, { useState } from 'react';
import { Button,  Form, Modal } from 'semantic-ui-react';
import { firebaseDb, firebaseAuth } from '../../firebase';

export const FeedbackModal = ({ trigger }) => {
        const [open, setOpen] = useState(false);
        const [loading, setLoading] = useState(false);
        const [message, setMessage] = useState();
        const [feedbackText, setFeedbackText] = useState('');

        const submitFeedback = () => {
          if (firebaseAuth.currentUser) {
            const feedback = {
              date : new Date(),
              userId : firebaseAuth.currentUser.uid,
              email : firebaseAuth.currentUser.email,
              displayName : firebaseAuth.currentUser.displayName,
              text : feedbackText
            }
            setLoading(true);
            firebaseDb.collection("feedback").add(feedback)
            .then((doc) => {
                setLoading(false);
                setFeedbackText('');
                setMessage({positive: true, text: "Feedback submitted. Thank you for being an active supporter of traintensity!"});
             }).catch((error) => {
               setLoading(false);
               setMessage({positive: false, text: "An error has occured. " + error.message});
            });
         }
        }

        return (
          <Modal
            size='tiny'
            onClose={() => {
              setOpen(false);
              setLoading(false);
              setMessage();
              setFeedbackText();
            }}
            onOpen={() => setOpen(true)}
            open={open}
            closeIcon
            trigger={trigger}
          >
            <Modal.Header>Feedback</Modal.Header>
            <Modal.Content>
                Have an idea for a feature? Noticed a bug? Want to tell us what you like/dislike?
                Submit below!
                <br/><br/>
                <Form>
                  <Form.TextArea style={{width: '100%'}}
                      rows={10}
                      placeholder='Enter feedback...'
                      value={feedbackText}
                      onChange={(e, data) => setFeedbackText(data.value)}
                  />
                </Form>
                {message && <div className={message.positive ? 'ui positive message' : 'ui negative message'}> {message.text}</div>}
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button
                content="Submit"
                loading={loading}
                onClick={() => submitFeedback()}
                primary
              />
            </Modal.Actions>
          </Modal>
        )
  }
