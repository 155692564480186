import styled from 'styled-components';
import { Input, Button } from 'semantic-ui-react';
import { COLOR_PRIMARY } from '../assets/literals';

export const StyledInput = styled(Input)`
  max-width: 10em;
  width:100%;
  padding-bottom:1em;
`;

export const StyledInputSmall = styled(Input)`
  max-width: 10em;
  width:100%;
  padding-bottom:1em;
`;

export const StyledButton = styled(Button)`
  width: 30px;
`;

export const HeaderTextButton = styled(Button)`
    background: transparent;
`;

export const Paragraph = styled.p`
    white-space: pre-line
  `;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
`;

export const  StyledContainerPadded = styled.div`
  padding: 10em;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
`;

export const SizingContainer = styled.div`
  max-width: 728px;
  width: 100vw;
  margin-bottom: 53px;
`;

export const FlexEndDiv = styled.div`
  display: flex;
  flex-direction:column;
  align-items: flex-end;
`;

export const HorizontalDiv = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonDiv = styled.div`
  display:flex;
  justify-content:flex-end
`;

export const ExerciseContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 4px solid #e8e8e8;
  border-radius: 10px;
  padding: 3%;
`;

export const WeightContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 0.2em solid #e8e8e8;
  padding: 2%;
`;

export const ChartContainer = styled.div`
  width:100%;
  height: 100%;
  overflow: hidden;
  @media only screen
  and (min-device-width: 600px) {
    padding: 2%;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
`;

export const TouchableButton = styled.button`
    background: transparent;
    padding: 10px 15px;
    border: none;
    border-bottom: 1px solid rgba(34,36,38,.15);
    outline: none;
    cursor: pointer;
    fontSize: 15px;
    opacity: 1;
    transition: opacity 300ms ease;
    width: 100%;
    height: 100%;
    display: flex
    `;

export const NumberedInput = styled.input`
    width: 4em;
    height: 1em;
    ::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const BlockContainer = styled.div`
  margin: 8px;
  width: 100%;
  background-color: ${props => (props.isDraggingOver ? 'yellow' : '')};
  display : flex;
  flex-direction : column;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
`;

export const BlockTitle = styled.h3`
  padding: 8px;
`;

export const WeekContainer = styled.div`
  padding: 8px;
  width: 100%;
  background-color : white;
  min-height: 100px;
  display : flex;
  @media only screen and (min-device-width: 600px) {
    flex-direction : row;
  }
  @media only screen and (max-device-width: 600px) {
    flex-direction : column;
  }
`;

export const DayContainer = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 100%;
  background-color : #b0c4de78
`;

export const WorkoutContainer = styled.div`
  padding: 8px;
  width: 100%;
  background-color : ${props => (props.isDragging ? COLOR_PRIMARY : 'white')}
`;
