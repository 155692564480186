import React from 'react'
import { Modal, Button, TransitionablePortal } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { firebaseAuth } from '../../firebase';
import Debug from '../../Debug';
import { INPROGRESS, PROGRAMMED, ASSISTANT_COACH } from '../../assets/literals';

export const LogModal = ({history, open, onClose, date, trackMode, renderSummary, daysWorkouts, startWorkoutBuilder, athlete, coach, teamRole }) => {
  console.log('athlete::', athlete, coach)
    Debug(date);

  return (
    <TransitionablePortal
      open={open}
      transition = {{
          animation: 'scale',
          duration: 400,
        }}
        closeOnDocumentClick={false}
    >
        {open ? <Modal
          closeIcon
          onClose={onClose}
          open={true}
          size="small"
        >
          <Modal.Header>{date.toLocaleDateString(undefined, {  year: 'numeric', month: 'long', day: 'numeric' })}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              {daysWorkouts.length > 0 ? renderSummary(daysWorkouts, false, true, false, true) : <div style={{textAlign: 'center'}}><p> {trackMode ? 'No workout templates programmed for the day.' : 'No workouts logged for the day.'} </p><br/></div>}
              {teamRole !== ASSISTANT_COACH && <>
                  <Link to={{pathname :'/', state : { fromCalendar : true, programming : true, athlete, coach, trackMode }}} className="nav_link">
                  <Button
                    fluid secondary
                    onClick={(e) => {
                      startWorkoutBuilder(date, date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' }) + ' Workout', athlete || firebaseAuth.currentUser.uid, athlete ? coach : "", PROGRAMMED, trackMode);
                    }}
                  >
                    Program Session
                  </Button>
                  </Link>
                  {!trackMode &&
                    <><br/><Link to={{pathname :'/', state : { fromCalendar : true, programming : false, athlete, coach  }}}>
                      <Button
                        fluid secondary
                        onClick={(e) => {startWorkoutBuilder(date, date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' }) + ' Workout', athlete || firebaseAuth.currentUser.uid, athlete ? coach : "", INPROGRESS, trackMode)}}
                      >
                        Log New Session
                      </Button>
                      </Link></>}
                </>}
            </Modal.Description>
          </Modal.Content>
        </Modal> : <></>}
      </TransitionablePortal>
  )
}
