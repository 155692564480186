import React from 'react';
import { Input, Label, Dropdown } from 'semantic-ui-react';
import { HorizontalDiv } from '../StyledComponents';

export const WeightAndUnitInput = ({weight, unit, handleChange, size, label}) => {
  return  <HorizontalDiv style={{width:"16em", alignSelf:'auto'}}>
                  <Input
                              required
                              className='ui labeled input'
                              labelPosition='right'
                              size={size || 'mini'}
                              type='number'
                              value={weight}
                              min={0}
                              max={999}
                              onChange={(e, data) => handleChange(null, 'weight', data.value)}
                            >
                            <Label>{label || 'Weight'}</Label>
                                <input />
                              <Label>
                                <Dropdown
                                      options={[
                                        { key: 'lbs', text: 'lbs', value: 'lbs' },
                                        { key: 'kg', text: 'kg', value: 'kg' },
                                        { key: '%', text: '%', value: '%' }
                                      ]}
                                      id='unit'
                                      onChange={(e, data) => handleChange(null,'unit', data.value)}
                                      value={unit}
                                  />
                              </Label>
                    </Input>
                  </HorizontalDiv>
}
