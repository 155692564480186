import React, { useState } from 'react';
import { Dropdown, Label, Rating, Button, Icon } from 'semantic-ui-react';
import { StyledContainer, HorizontalDiv, StyledInput} from '../StyledComponents';


const RatingContainer = ({rating, field, label, updateBuilderField}) => {

  const handleRate = (e, { rating, maxRating }) =>
   updateBuilderField(field, rating)

  return <HorizontalDiv
        style={{display:'flex', alignItems:'flex-start'}}
    >
      <p style={{width: '150px', fontWeight: 'bold'}}>{label}&nbsp;&nbsp; </p>
      <Rating icon='star' maxRating={5} size='huge' clearable
          onRate={handleRate}
          rating={rating}
      />
  </HorizontalDiv>
}
export const OptionalLog = ({workoutBuilder, updateBuilderField}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <><Button icon
      type='button'
        style={{
            background: 'transparent',
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%'
        }}
        onClick={() => setExpanded(!expanded)}
      >
      More Options<Icon name={'caret down'} />
     </Button>
    <StyledContainer>
        {expanded &&
          <><RatingContainer rating={workoutBuilder.difficulty} field='difficulty' label="Workout Difficulty" updateBuilderField={updateBuilderField}/>
          <RatingContainer rating={workoutBuilder.sleep} field='sleep' label="Last Night's Sleep" updateBuilderField={updateBuilderField}/>
          <RatingContainer rating={workoutBuilder.fatigue} field='fatigue' label="Stress/Fatigue Level" updateBuilderField={updateBuilderField}/>
          <HorizontalDiv style={{alignSelf:'auto'}}>
              <p style={{width: '150px', fontWeight: 'bold'}}>Bodyweight&nbsp;&nbsp;</p>
              <StyledInput fluid
                          className='ui labeled input'
                          labelPosition='right'
                          size='mini'
                          type='tel'
                          id='bodyweight'
                          value={workoutBuilder.bodyweight}
                          placeholder='Weight...'
                          min={0}
                          max={999}
                          onChange={(e, data) => updateBuilderField('bodyweight', data.value)}
                        >
                            <input />
                          <Label>
                            <Dropdown
                                  options={[
                                    { key: 'lbs', text: 'lbs', value: 'lbs' },
                                    { key: 'kg', text: 'kg', value: 'kg' }
                                  ]}
                                  id='bw-unit'
                                  onChange={(e, data) => updateBuilderField('bodyweightUnit', data.value)}
                                  value={workoutBuilder.bodyweightUnit || workoutBuilder.defaultUnit}
                              />
                          </Label>
                </StyledInput>
              </HorizontalDiv></>
            }
    </StyledContainer></>
  )
}
