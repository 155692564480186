import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Dropdown, Divider } from 'semantic-ui-react';
import { HorizontalDiv } from '../StyledComponents';
import { setAthleteOrTrack } from '../../actions';
import { getTeamRole } from '../../commonMethods';
import { firebaseAuth } from '../../firebase';
import { COACH, ASSISTANT_COACH } from '../../assets/literals';

class AthleteSelect extends Component {
  render() {
    const { config, historyLog, setAthleteOrTrack, onAthleteSelect, showTracks } = this.props;
    const { team, isMobile } = config;
    const { members, tracks  } = team;
    const teamRole = getTeamRole(firebaseAuth.currentUser.uid, members);

    console.log('teamrole:', teamRole);
    if (!teamRole === COACH && !teamRole === ASSISTANT_COACH)
      return null;

    let athletes = [];
    if (members) {
      members.forEach(member => {
        const {name, uid } = member;
        athletes.push({key: uid, value: uid, text: name})
      });
    }

    let trackOptions = []
    if (tracks) {
      tracks.forEach(track => {
        const {id, name } = track;
        trackOptions.push({key: id, value: id, text: name});
      })
    }

      return    (
        <div style={{width:'100%'}}>
          <HorizontalDiv style={{width: '100%', paddingLeft: '5px', paddingRight: '5px', alignItems : 'baseline'}}>
          <Header> {team.name} </Header>
            <Dropdown
                placeholder={showTracks ? 'Select Tracks' : 'Select Athlete'}
                search
                selection
                value={historyLog.athlete}
                options={showTracks ? trackOptions : athletes}
                onChange={(e, data) => {
                  const id = data.value
                  setAthleteOrTrack(id, showTracks);
                  if (onAthleteSelect)
                    onAthleteSelect(id);
                }}
              />
      </HorizontalDiv>
      {!isMobile && <Divider style={{marginTop: 0}}/>}
      </div>
    )}
}

const mapStateToProps = state => {
  const { config, historyLog } = state
  return {
           config,
           historyLog
         }
};

export default connect(mapStateToProps, { setAthleteOrTrack })(AthleteSelect);
