import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ExerciseListView from '../exercise/ExerciseListView';
import ExerciseReview from '../exercise/ExerciseReview';
import AthleteSelect from '../menus/AthleteSelect';
import HeaderMain from './HeaderMain';
import HeaderGeneric from '../menus/HeaderGeneric';
import WodHistory from '../conditioning/WodHistory';
import { HorizontalDiv, StyledContainer, SizingContainer } from '../StyledComponents';
import { getExerciseOption } from '../../commonMethods';
import { STRENGTH, CONDITIONING } from '../../assets/literals';

class ExercisesView extends Component {

  constructor() {
    super();
    this.state = {
      selectedExercise : null,
      tab : STRENGTH
    }
  }

  onTabSelect = (tab) => {
    this.setState({tab})
  }

  onAthleteSelect = (athlete) => {

  }

  onExerciseSelect = (value) => {
    const { historyLog, config } = this.props;
    const custExercises = (config.athleteCustomExercises && historyLog.athlete) ? config.athleteCustomExercises[historyLog.athlete] : config.customExercises;
    this.setState({selectedExercise: getExerciseOption(value, custExercises)});
  }

  renderStrengthConditioningTab = () =>
        <HorizontalDiv style={{textAlign : 'center'}}>
              <Button style={{textAlign:'center'}} size='large' basic={this.state.tab === STRENGTH ? false : true} primary fluid attached='left' onClick={() => this.onTabSelect(STRENGTH)}>Strength</Button>
              <Button style={{textAlign:'center'}} size='large' basic={this.state.tab === CONDITIONING ? false : true} primary fluid attached='right' onClick={() => this.onTabSelect(CONDITIONING)}>Conditioning</Button>
        </HorizontalDiv>

  renderExerciseOrList = () => {
    const { config, historyLog } = this.props;
    if (this.state.selectedExercise)
      return <ExerciseReview
                athlete={historyLog.athlete}
                userConfig={historyLog.athlete ? config.athleteConfigs[historyLog.athlete] : config.user}
                customExercises={historyLog.athlete ? config.athleteCustomExercises[historyLog.athlete] : config.customExercises}
                exercise={this.state.selectedExercise}
                clearExercise={() => this.setState({selectedExercise: null})}
              />
    else if (this.state.tab === CONDITIONING)
      return <>
          <HeaderGeneric
            text="Benchmarks" />
          {config.team && config.team.members && <AthleteSelect
            onAthleteSelect={this.onAthleteSelect}
          />}
          {this.renderStrengthConditioningTab()}
          <WodHistory
            athlete={historyLog.athlete}
          />
          </>
    else
      return   <>
                <HeaderGeneric
                  text="Exercises" />
                  {config.team && config.team.members && <AthleteSelect
                    onAthleteSelect={this.onAthleteSelect}
                  />}
                  {this.renderStrengthConditioningTab()}
                <ExerciseListView
                  onExerciseSelect={this.onExerciseSelect}
                  userConfig={historyLog.athlete ? config.athleteConfigs[historyLog.athlete] : config.user}
                  customExercises={historyLog.athlete ? config.athleteCustomExercises[historyLog.athlete] : config.customExercises}
                />
              </>
  }

  render() {
    return (
      <>
        <HeaderMain
          activeItem='Exercises'
          showHeader={false}
        />
        <StyledContainer style={{ textAlign: 'start'}}>
          <SizingContainer style={{paddingTop: '0', marginTop: '10px'}}>
            {this.renderExerciseOrList()}
          </SizingContainer>
        </StyledContainer>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { config, historyLog } = state;
  return {
    config,
    historyLog
  }
}

export default connect(mapStateToProps)(ExercisesView);
