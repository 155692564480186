import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import firebase from 'firebase/app';
import _ from 'lodash';
import { Header, Message, Icon, Select, Input, Button, Form, Dropdown, Divider, Transition, List, Segment, Label } from 'semantic-ui-react';
import { firebaseAuth } from '../../firebase';
import { DailyLogModal } from '../log/DailyLogModal';
import {SchedulerModal} from './SchedulerModal';
import StackedVolumeChart from '../charts/StackedVolumeChart';
import DummyConfig from './DummyConfig';
import AthleteSelect from '../menus/AthleteSelect';
import { ZoneChartGroup } from '../charts/ZoneChartGroup';
import { DragDropContext } from "react-beautiful-dnd";
import  HeaderGeneric from "../menus/HeaderGeneric";
import { InfoTooltip } from "../common/InfoTooltip";
import { UpgradeModal } from '../common/UpgradeModal';
import Block from './Block';
import { ProgramHistory } from "./ProgramHistory";
import { StyledContainer, ChartContainer, HorizontalDiv } from '../StyledComponents';
import { clearSelectedProgram, updateProgramBuilderField, updateProgramBuilderLayout, addProgramBuilderLayout, addProgramBuilderWeek,
          addProgramBuilderDay, selectWorkout, clearSelectedWorkout, saveProgram, retrieveProgramLayout
        } from '../../actions';
import { PROGRAM, BLOCKS, WORKOUTS, INPROGRESS, SNATCH, CLEAN, JERK, SQUAT, PULL, PRESS,
         PRESS_VERTICAL, ROW, SETS, REPS, VOLUME, COACH, ASSISTANT_COACH } from '../../assets/literals';
import { hasPro, getTeamRole, getChartData, zoneDataToChartFormat, kgToLbs, lbsToKg } from '../../commonMethods';
import programTags from '../../assets/program_tags';
import { generateLayoutId } from './shared';

const INITIAL_STATE = {
  workoutArr: [],
  error: false,
  expandDescription : false,
  openModal : false,
  type: PROGRAM,
  schedulingComponentId : null,
  metric: REPS,
  intensityThreshold: '60',
  workingMaxes : [{lift: "Snatch", weight: 100},
                    {lift: "Clean", weight: 100},
                    {lift: "SplitJk", weight: 100},
                    {lift: "CleanAndJerk", weight: 100},
                    {lift: "CleanPull", weight: 100},
                    {lift: "SnatchPull", weight: 100},
                    {lift: "FrontSquat", weight: 100},
                    {lift: "BackSquat", weight: 100},
                    {lift: "BenchPress", weight: 100},
                    {lift: "Deadlift", weight: 100},
                    {lift: "MilitaryPress", weight: 100},
                    {lift: "BentOverRow", weight: 100}
                  ]
}
class ProgramBuilder extends Component {

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE, defaultUnit: props.config.user.defaultUnit, frequency : props.program.programBuilder.frequency || 0,  };
  }

  componentDidMount() {
    window.scrollTo(0,0);
  }


  workoutsToArr = (layout) => {
    const { blockOrder, blocks, weeks, days, workouts } = layout;
    let workoutArr = [];
    const d = new Date();
    let start = moment(d.setDate(d.getDate() + ((7 - d.getDay()) % 7 + 1) % 7)); //set start to next monday
    if (blockOrder) {
    for (var i=0; i<blockOrder.length;i++) {
          const blockId = blockOrder[i];
          const weekIds = blocks[blockId].weeks;
          for (var j=0; j<weekIds.length; j++) {
            let weekStart = start.clone();
            weekStart.add(j, 'weeks');
            const week = weeks[weekIds[j]];
            const dayIds = week.days;
            for (var k=0; k<dayIds.length; k++) {
              const day = days[dayIds[k]];
              const workoutIds = day.workouts;
              for (var m=0; m<workoutIds.length; m++) {
                let date = weekStart.clone();
                date = date.add(k, 'days').toDate();
                date = firebase.firestore.Timestamp.fromDate(date);
                const workout = workouts[workoutIds[m]].workout;
                workoutArr.push({...workout, date, status : INPROGRESS });
              }
            }
          }
        }
      }
    return workoutArr;
  }

  openScheduler = (type, schedulingComponentId, schedulingIndex) => {
    this.setState({ openModal : true, type, schedulingComponentId, schedulingIndex})
  }

  closeScheduler = () => {
    this.setState({ openModal : false, type: null, schedulingComponentId : null})
  }

  handleChange = (e, { value }) => {
    const { tags } = this.props.program.programBuilder;
    if (tags.length > value.length) { // an item has been removed
        const difference = tags.filter(
            x => !value.includes(x),
        );
        //console.log(difference); // this is the item
        let updatedTags = [...tags]
        const index = updatedTags.indexOf(difference[0]);
        if (index > -1) {
          updatedTags.splice(index, 1);
        }
        return this.props.updateProgramBuilderField('tags', updatedTags);
    }
    return this.props.updateProgramBuilderField( 'tags', value );
};

onDragEnd = (result) => {
    const { program, updateProgramBuilderField} = this.props;
    const { programBuilder } = program;
    const { layout } = programBuilder;
    const { source, destination, draggableId } = result;

    // dropped outside the list
    if (!destination)
      return;
    if (destination.droppableId === source.dropppableId && destination.index === source.index)
      return;

    const start = layout.days[source.droppableId];
    const finish = layout.days[destination.droppableId]

    if (start === finish) {
      const day = layout.days[source.droppableId];
      const newWorkouts = Array.from(day.workouts);
      newWorkouts.splice(source.index, 1);
      newWorkouts.splice(destination.index, 0, draggableId);

      const newDay = {
        ...day,
        workouts : newWorkouts
      }

      const newLayout = {
        ...layout,
        days : {
          ...layout.days,
          [newDay.id] : newDay
        }
      }

      updateProgramBuilderField('layout', newLayout);
    }
    else {
        //Moving from one day to another
        const startWorkouts = Array.from(start.workouts);
        startWorkouts.splice(source.index, 1);
        const newStart = {
          ...start,
          workouts: startWorkouts
        };

        const finishWorkouts = Array.from(finish.workouts);
        finishWorkouts.splice(destination.index, 0, draggableId);
        const newFinish = {
          ...finish,
          workouts: finishWorkouts
        }

        const newLayout = {
          ...layout,
          days: {
            ...layout.days,
            [newStart.id] : newStart,
            [newFinish.id]: newFinish
          }
        }

        updateProgramBuilderField('layout', newLayout)
    }
  }

  onWorkoutSelected = selectedWorkout => {
    const workouts = this.props.program.programBuilder.layout.workouts;
    if (workouts[selectedWorkout])
    this.props.selectWorkout(workouts[selectedWorkout].workout);
  }

  onWorkoutSubmit = workout => {
    this.props.updateProgramBuilderLayout(WORKOUTS, workout.id, 'workout', workout);
    this.props.clearSelectedWorkout();
  }

  generateInitialWeekDayIds = frequency => {
    let ids = []
    for (var i=0; i< frequency; i++) {
      ids.push(generateLayoutId('day'));
    }
    return ids;
  }

  generateInitialDays = allDays => {
    for (var i=0; i<allDays.length; i++) {
      const id = allDays[i];
      allDays[i] = {
        id,
        title: 'Day ' + (i+1),
        workouts: []
      }
    }

    allDays = allDays.reduce((result, item) => ({
      ...result, [item.id] : item
      }), {}
    );

    return allDays;
  }

  createInitialLayout = (frequency) => {
    const week1Id = generateLayoutId('week');
    let week1dayIds = this.generateInitialWeekDayIds(frequency);
    let allDays = [...week1dayIds];
    allDays = this.generateInitialDays(allDays);

    return {
      creator : firebaseAuth.currentUser.uid,
      workouts : {},
      blocks: {
        'block-1': {
          id: 'block-1',
          title: 'Block 1',
          weeks: [week1Id]
        }
      },
      blockOrder : ['block-1'],
      weeks: {
        [week1Id]: {
          id: week1Id,
          title: 'Week 1',
          days: week1dayIds
        }
      },
      days: allDays
    }

  }

  handleSubmit = () => {
    const { program, saveProgram } = this.props;
    saveProgram(program.programBuilder);
  }

  teamToOptions = (team) => {
    const { members } = team;
    let options = []
    if (members) {
      for (var i=0; i< members.length; i++ ) {
        const { name, uid } = members[i];
        options.push({ key : uid, text : name, value : uid });
      }
    }
    else {
      const { uid, displayName, email } = firebaseAuth.currentUser;
      options.push({ key : uid, text : displayName || email, value : uid });
    }
    return options;
  }

  tracksToOptions = (team) => {
    let options = [];
    if (team) {
      const { tracks } = team;
      if (tracks) {
        for (var i=0; i< tracks.length; i++ ) {
          const { id, name } = tracks[i];
          options.push({ key : id, text : name, value : id });
        }
      }
    }
    return options;
  }

  descriptionExpandButton = () => {
    const { expandDescription } = this.state
    return <Button fluid style={{background: 'transparent'}} onClick={() =>  this.setState({expandDescription : !expandDescription})}>
              <Icon name={expandDescription ? 'caret down' : 'caret left'} />
            </Button>
  }

  convertWorkingMaxes = (workingMaxes, unit) => {
    let newWorkingMaxes = [...workingMaxes];
    if (unit === 'kg') {
      newWorkingMaxes.forEach((obj, i) => {
            obj.weight = lbsToKg(obj.weight)
      })
    }
    else if (unit === 'lbs') {
      newWorkingMaxes.forEach((obj, i) => {
            obj.weight = kgToLbs(obj.weight);
      })
    }
    return newWorkingMaxes;
  }

  onAthleteSelect = (athlete) => {
    const {defaultUnit, intensityThreshold, workingMaxes} = this.props.config.athleteConfigs[athlete];
    this.setState({
      workingMaxes : this.state.defaultUnit !== defaultUnit ? this.convertWorkingMaxes(_.cloneDeep(workingMaxes), this.state.defaultUnit) : workingMaxes,
      intensityThreshold
    })
  }

  render() {
    const { defaultUnit, intensityThreshold, workingMaxes, metric } = this.state;
    const { editable, config, program, log, clearSelectedProgram, retrieveProgramLayout, updateProgramBuilderField, addProgramBuilderLayout, addProgramBuilderWeek, addProgramBuilderDay, clearSelectedWorkout } = this.props;
    const { team, subscriptions, isMobile } = config;
    let dummyConfig={...config, athleteConfigs: {}, user: {...config.user, intensityThreshold, workingMaxes}} //empty athlete config so buildchartdata doesnt read it
    const{ selectedWorkout } = log;
    const { programBuilder, programHistory } = program;
    const { id, frequency, layout, tags, description } = programBuilder;
    const workoutArr = this.workoutsToArr(layout);
    const chartData = getChartData(workoutArr, firebaseAuth.currentUser.uid, dummyConfig);
    const { volumeTypeData,intensityZoneTypeData } = chartData;

    const { expandDescription } = this.state;
    const teamRole = getTeamRole(firebaseAuth.currentUser.uid, team.members);
    const isCoachBool = teamRole === COACH || teamRole === ASSISTANT_COACH;
    if (id && !layout)
      retrieveProgramLayout(id, programBuilder)

    return(
      <StyledContainer style={{width: '90%'}}>
      <HeaderGeneric
        itemRight={
            editable && hasPro(subscriptions) ?
            <Button
                style={{background: 'transparent', color: '#0E7AFE', fontSize:'18px'}}
                type='button'
                onClick={() => this.handleSubmit()}
            >
              Save
            </Button>
          : <></>
        }
        itemLeft={ <Button size='huge' style={{background: 'transparent', padding: '10px'}} icon="arrow left"
                        onClick={(e) => {
                                          clearSelectedProgram();
                                        }}/>}
      />
      <Form style={{ width: '100%'}}>
      <div style={{width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
      {!hasPro(subscriptions) &&
        <><Message color='yellow' style={{width: '100%'}}>
         <Icon name='warning' />
           You are on the free tier so you cannot save. Upgrade to pro before building your program!&nbsp;&nbsp;
           <UpgradeModal/>
       </Message></>}
       {isMobile && <Message info style={{width: '100%'}}>
          We recommend programming on a desktop/laptop for a full-screen experience.
      </Message>}
        {id && <Button primary size='big' onClick={() => this.openScheduler(PROGRAM, null, null)}>Schedule Program</Button>}
        <br/>
        <div style={{width: '100%', maxWidth : '728px' }}>
          <Input fluid disabled={!editable} style={{width: '100%'}} placeholder='Program Name' value={programBuilder.name} onChange={(e) => {updateProgramBuilderField('name', e.target.value)}}>
            <input style={{fontSize:'18px',fontWeight: 'bold', background: '#eeeeee', textAlign:'center' }} />
          </Input>
          {editable ?
            <Form.TextArea
              maxLength='50000'
              placeholder='Program description...'
              id='programDescription'
              value={description}
              onChange={(e) => {updateProgramBuilderField('description', e.target.value)}}
             /> :
             (expandDescription || description.length < 200) ?
              <Segment>{description}<br/>{this.descriptionExpandButton()}</Segment> :
              <Segment>{description.substring(0,200) + '...'}<br/>{this.descriptionExpandButton()}</Segment>
            }
            {editable ?
               <Dropdown
                  placeholder='Program tags'
                  fluid
                  multiple
                  search
                  selection
                  options={programTags}
                  value={tags}
                  onChange={this.handleChange}
               /> :
               <div>{tags.map((tag,index) => <Label key={'tag-' + index} tag size='small'>{tag}</Label>)}</div>}
         </div>
       </div>
       <br/>
       {frequency === 0 ?
       <div style={{width: '100%', display: 'flex', justifyContent : 'center'}}>
        <br/>
        <div style={{width: '728px'}}>
        <Header as='h4'>How many training days per week? </Header>
        <p style={{ color : 'grey'}}>Note: Once selected you cannot change. If training days change week by week, select 7.</p>
        <Select
          options={[
            { key: 1, value: 1, text: 1 },
            { key: 2, value: 2, text: 2 },
            { key: 3, value: 3, text: 3 },
            { key: 4, value: 4, text: 4 },
            { key: 5, value: 5, text: 5 },
            { key: 6, value: 6, text: 6 },
            { key: 7, value: 7, text: 7 }
          ]}
          error={this.state.error}
          placeholder='Days' type='number' value={this.state.frequency} onChange={(e, data) => {this.setState({frequency : data.value, error : false })}}>
        </Select>
        <br/><br/>
        <Button
          color='black'
          onClick={(e) => {
              const { frequency } = this.state;
              if (!frequency)
                this.setState({error : true });
              else {
                updateProgramBuilderField('frequency', this.state.frequency);
                updateProgramBuilderField('layout', this.createInitialLayout(this.state.frequency));
              }
          }}
        >
          Next <Icon name='chevron right' />
        </Button>
        </div>
       </div>
       :
       <Transition
         animation='pulse'
         duration={500}
         visible={true}
         transitionOnMount
       >
       <div style={{ display: "flex", flexDirection : 'column', width : '100%'}}>
       <DragDropContext
          onDragEnd={this.onDragEnd}
        >
          <Transition.Group
            as={List}
            duration={200}
            divided
            animation="slide down"
          >
            {layout && layout.blockOrder.map((blockId, index) => {
              const block = layout.blocks[blockId];
              return <List.Item key={block.id}><Block editable={editable} index={index} block={block} layout={layout} frequency={programBuilder.frequency} onWorkoutSelected={this.onWorkoutSelected} openScheduler={this.openScheduler}/><br/></List.Item>
            })}
          </Transition.Group>
          {editable && <Button
              onClick={() => {
                const i = layout.blockOrder.length;
                const blockId = 'block-' + (i+1);
                const currentNumWeeks = _.size(layout.weeks);
                const currentNumDays = _.size(layout.days);
                const weekId = generateLayoutId('week');
                addProgramBuilderLayout(BLOCKS, blockId, i, null);
                addProgramBuilderWeek(weekId, currentNumWeeks, blockId);
                for (var j=0; j< frequency; j++) {
                  addProgramBuilderDay(generateLayoutId('day'), currentNumDays+j, weekId)
                }
              }}
          >
            Add Block
          </Button>}
        </DragDropContext>
        <br/><br/>
        <Header as='h2'> Data Simulator </Header>
        <Divider/>
        <HorizontalDiv>
        {!isMobile && <div style={{width: '33%', marginRight: '5%'}}>
          {team?.length>0 &&
            <AthleteSelect
              onAthleteSelect={this.onAthleteSelect}
            />}
           <DummyConfig
            defaultUnit={defaultUnit}
            intensityThreshold={intensityThreshold}
            workingMaxes={workingMaxes}
            onChange={(key, value) => {
                this.setState({[key]: value})
            }}
          />
        </div>}
        <div style={{width:'99%', height: isMobile ? '250px' : '600px', userSelect: 'none', WebkitUserSelect: 'none'}}>
        <Dropdown
            style={{width: '200px', float: 'right', marginBottom: '5px'}}
            selection
            fluid
            options={[
              {
                key: REPS,
                text: 'Reps',
                value: REPS
              },
              {
                key: SETS,
                text: 'Sets',
                value: SETS
              },
              {
                key: VOLUME,
                text: 'Volume (tonnage)',
                value: VOLUME
              }
              ]}
              value={metric}
              onChange={(e, data) => {
                this.setState({ metric : data.value });
              }
            }
          />
          <ChartContainer>
            <StackedVolumeChart
              data={volumeTypeData}
              metric={metric}
              unit={defaultUnit}
              isMobile={config.isMobile}
            />
          </ChartContainer>
        </div>
        </HorizontalDiv>
        <br/><br/>
        <InfoTooltip content={<p>Note: Volume below the intensity threshold will not show here.</p>} />
        <ZoneChartGroup
          isProgramming
          zoneData={{[SNATCH]: zoneDataToChartFormat(intensityZoneTypeData[SNATCH]),
                  [CLEAN]: zoneDataToChartFormat(intensityZoneTypeData[CLEAN]),
                  [JERK]: zoneDataToChartFormat(intensityZoneTypeData[JERK]),
                  [SQUAT]: zoneDataToChartFormat(intensityZoneTypeData[SQUAT]),
                  [PRESS]: zoneDataToChartFormat(intensityZoneTypeData[PRESS]),
                  [PULL]: zoneDataToChartFormat(intensityZoneTypeData[PULL]),
                  [PRESS_VERTICAL]: zoneDataToChartFormat(intensityZoneTypeData[PRESS_VERTICAL]),
                  [ROW]: zoneDataToChartFormat(intensityZoneTypeData[ROW])
                }}
        >
        </ZoneChartGroup>
        <br/><br/>
        {programHistory && programHistory.length>0 && <ProgramHistory
            data={programHistory}
        />}
        <SchedulerModal
            frequency={frequency}
            programId={id}
            isCoach={isCoachBool}
            layout={programBuilder.layout}
            athleteOptions={this.teamToOptions(config.team)}
            trackOptions={this.tracksToOptions(config.team)}
            openModal={this.state.openModal}
            onClose={this.closeScheduler}
            type={this.state.type}
            schedulingIndex={this.state.schedulingIndex}
            schedulingComponentId={this.state.schedulingComponentId}
        />
       </div>
       </Transition>
      }
      <DailyLogModal
        history
        open={selectedWorkout && selectedWorkout !== ''}
        onClose={clearSelectedWorkout}
        selectedWorkout={selectedWorkout}
        header='testing'
        handleSubmit={this.onWorkoutSubmit}
      />
      </Form>
      </StyledContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    config : state.config,
    log : state.log,
    program : state.program
  }
}

export default connect(mapStateToProps, { retrieveProgramLayout, saveProgram, clearSelectedProgram, updateProgramBuilderField, updateProgramBuilderLayout, addProgramBuilderLayout, addProgramBuilderWeek, addProgramBuilderDay, selectWorkout, clearSelectedWorkout } )(ProgramBuilder);
