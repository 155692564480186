import React, { useState } from 'react';
import { Header, Modal, Card, Button, List, TransitionablePortal, Icon } from 'semantic-ui-react';
import { stripeCheckout } from '../../commonMethods';
import { useStripe} from '@stripe/react-stripe-js';
import firebase from 'firebase';

//const test_coach_pro_key= 'price_1JLfGfKbBtisReTOJVqdAGJQ';
//const test_athlete_pro_key='price_1Kpe9UKbBtisReTOUzHmcRrA';
const coach_pro_key = 'price_1L27kGKbBtisReTOdexBFBHZ';
const athlete_pro_key = 'price_1L27j3KbBtisReTOole5dqn4';

const CheckoutButton = ({priceKey}) => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  return  <Button fluid color='violet' loading={loading}
            onClick={() => {
                stripeCheckout(stripe, priceKey, 'payment');
                setLoading(true);
                }
            }>
            Upgrade
          </Button>
}

const ManageSubscriptionButton = () => {
  const [loading, setLoading] = useState(false);
  return <Button fluid primary
    loading={loading}
    onClick={async () => {
    setLoading(true);
    const functionRef = firebase
      .app()
      .functions('us-east4')
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl: window.location.origin });
    window.location.assign(data.url);
  }}
  >
    Manage Subscription
  </Button>
}

export const UpgradeModal = ({ subscribed, defaultOpen, onClose }) => {
  const [open, setOpen] = React.useState(defaultOpen || false)
  const close = () => {
    if (onClose)
      onClose();
    setOpen(false);
  }

  return (
    <TransitionablePortal
      defaultOpen={defaultOpen}
      trigger={
        <Button style={{ backgroundColor : subscribed ? 'transparent' : ''}} color={subscribed ? null : "violet"} onClick={() => setOpen(true)}>
        {subscribed ? 'Manage Subscription' : 'Upgrade to Pro'}
          <Icon name='caret right'></Icon>
        </Button>
      }
      onMount={() => setOpen(true)}
      onClose={() => close()}
      onOpen={() => setOpen(true)}
      transition = {{
          animation: 'scale',
          duration: 800,
        }}
    >
    <Modal
      size='tiny'
      onClose={() => close()}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
    >
      <Modal.Header>Upgrade to unlock more features!</Modal.Header>
      <Modal.Content>
      <Card fluid>
          <Card.Content style={{background: '#3a3a3a', paddingBottom: '0px'}} header={<div>
             <Header style={{color: 'white'}} as='h3' floated='left'>
               Coach Pro
             </Header>
             <Header style={{color: 'white'}} as='h3' floated='right'>
               $29.99/month
             </Header>
             </div>}
          />
          <Card.Content>
            <span style={{fontSize: '16px' }}>Build your team to manage your team's programming and performance!</span>
            <Header as='h4'>Get access to:</Header>
            <List>
              <List.Item><List.Icon color='green' name='checkmark'/>&nbsp;&nbsp; Program Design Tool</List.Item>
              <List.Item><List.Icon color='green' name='checkmark'/>&nbsp;&nbsp; Personal Programming</List.Item>
              <List.Item><List.Icon color='green' name='checkmark'/>&nbsp;&nbsp; Team Builder</List.Item>
              <List.Item><List.Icon color='green' name='checkmark'/>&nbsp;&nbsp; Track Programming</List.Item>
              <List.Item><List.Icon color='green' name='checkmark'/>&nbsp;&nbsp; Program Data Simulator</List.Item>
              <List.Item><List.Icon color='green' name='checkmark'/>&nbsp;&nbsp; Complete Performance Dashboard</List.Item>
              <List.Item><List.Icon color='green' name='checkmark'/>&nbsp;&nbsp; Custom Data Export</List.Item>
            </List>
          </Card.Content>
          <br/>
          {subscribed ?
            <ManageSubscriptionButton/>
            :
            <CheckoutButton priceKey={coach_pro_key}/>
          }
         </Card>
         <Card fluid>
           <Card.Content style={{background: '#3a3a3a', paddingBottom: '0px'}} header={<div>
              <Header style={{color: 'white'}} as='h3' floated='left'>
                Athlete Pro
              </Header>
              <Header style={{color: 'white'}} as='h3' floated='right'>
                $4.99/month
              </Header>
              </div>}
           />
           <Card.Content>
             <span style={{fontSize: '16px' }}>Unlock our full suite of tools to optimize your own training!</span>
             <Header as='h4'>Get access to:</Header>
             <List>
               <List.Item><List.Icon color='green' name='checkmark'/>&nbsp;&nbsp; Program Design Tool</List.Item>
               <List.Item><List.Icon color='green' name='checkmark'/>&nbsp;&nbsp; Personal Programming</List.Item>
               <List.Item><List.Icon color='red' name='x'/>&nbsp;&nbsp; Team Builder</List.Item>
               <List.Item><List.Icon color='red' name='x'/>&nbsp;&nbsp; Track Programming</List.Item>
               <List.Item><List.Icon color='green' name='checkmark'/>&nbsp;&nbsp; Program Data Simulator</List.Item>
               <List.Item><List.Icon color='green' name='checkmark'/>&nbsp;&nbsp; Complete Performance Dashboard</List.Item>
               <List.Item><List.Icon color='green' name='checkmark'/>&nbsp;&nbsp; Custom Data Export</List.Item>
             </List>
           </Card.Content>
           <br/>
           {subscribed ?
             <ManageSubscriptionButton/>
             :
             <CheckoutButton priceKey={athlete_pro_key}/>
           }
          </Card>
        </Modal.Content>
    </Modal>
    </TransitionablePortal>
  )
}
