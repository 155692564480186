import { RETRIEVE_WORKOUT_HISTORY, RETRIEVE_WORKOUT_HISTORY_SUCCESS, RETRIEVE_WORKOUT_HISTORY_FAIL,
          RETRIEVE_RECORDS_HISTORY, RETRIEVE_RECORDS_HISTORY_SUCCESS,
          RETRIEVE_EXERCISE_HISTORY, RETRIEVE_EXERCISE_HISTORY_FAIL, RETRIEVE_EXERCISE_HISTORY_SUCCESS,
          RECORD_PR, RECORD_PR_SUCCESS, RECORD_PR_FAIL, DELETE_PR, DELETE_PR_SUCCESS, DELETE_PR_FAIL,
          UPDATE_PR_SUCCESS, UPDATE_PR_FAIL, RESET_CALENDAR, SET_ATHLETE_OR_TRACK, SET_HISTORY
      } from './types';
import Debug from '../Debug';
import { firebaseAuth, firebaseDb, templatesRef, workoutsRef, personalRecordsRef } from '../firebase';
import { dateToMonthString } from '../commonMethods';

export const resetHistory = () => {
  return {
    type: RESET_CALENDAR
  }
}

export const setHistory = (key, value) => {
  return {
    type: SET_HISTORY,
    payload: {
      key,
      value
    }
  }
}

export const setAthleteOrTrack = (id, isTrack) => {
  return {
    type: SET_ATHLETE_OR_TRACK,
    payload: {
        id,
        isTrack
    }
  }
}

export const retrieveWorkoutHistory = (start, end, uid, isTrack) => {
  console.log('retrieving workout history', uid, isTrack)
  return (dispatch) => {
    dispatch({type: RETRIEVE_WORKOUT_HISTORY});
    let ref = firebaseDb.collection(isTrack ? templatesRef : workoutsRef);
      if (isTrack)
        ref = ref.where('track', '==', uid)
      else
        ref = ref.where('userId', '==', uid || firebaseAuth.currentUser.uid);

      ref.where('date', '>=', start)
        .where('date', '<=', end)
        .get().then((querySnapshot) => {
          //  const workouts = querySnapshot.docs.reduce((res, item) => ({...res, [item.id]: item.data()}), {});
          const workouts = querySnapshot.docs.map(workout => {
            const data = workout.data();
            data.id = workout.id
            return data;
          });
          const month = dateToMonthString(start)
          Debug(start, month)
          dispatch({ type: RETRIEVE_WORKOUT_HISTORY_SUCCESS, payload: { workouts, month } });
        }).catch((error) => {
            console.log("Error getting document:", error);
            alert('An error has occured: ' + error.message);
            dispatch({ type: RETRIEVE_WORKOUT_HISTORY_FAIL, payload: error.message });
          });
  }
}

export const retrieveExerciseHistory = (athlete, exercise, limit, offset) => {
  console.log('exercisehistory...', exercise)
  return (dispatch) => {
    dispatch({type: RETRIEVE_EXERCISE_HISTORY});
    firebaseDb.collection(workoutsRef)
        .where('userId', '==', athlete || firebaseAuth.currentUser.uid)
        .where('exercises', 'array-contains', exercise || null)
        .orderBy('date', 'desc')
      //  .startAt(offset)
      //  .limit(limit)
        .get().then((querySnapshot) => {
          const workouts = querySnapshot.docs.map(workout => {
            const data = workout.data();
            data.id = workout.id
            return data;
          });
          Debug(workouts)
          dispatch({ type: RETRIEVE_EXERCISE_HISTORY_SUCCESS, payload: workouts });
        }).catch((error) => {
            Debug("Error getting document:", error);
            alert('An error has occured: ' + error.message)
            dispatch({ type: RETRIEVE_EXERCISE_HISTORY_FAIL, payload: error.message });
          });
  }
}

export const retrieveRecordsHistory = (athlete, limit, exercise) => {
  return (dispatch) => {
    dispatch({type: RETRIEVE_RECORDS_HISTORY});
        let query = firebaseDb.collection(personalRecordsRef)
        .where('userId', '==', athlete || firebaseAuth.currentUser.uid)

        exercise ? query = query.where('exercise', '==', exercise) : query = query.where('exercise', '==', null)

        query.orderBy('date', 'desc')
        .limit(limit)
        .onSnapshot((querySnapshot) => {
          //  const workouts = querySnapshot.docs.reduce((res, item) => ({...res, [item.id]: item.data()}), {});
          const records = querySnapshot.docs.map(record => {
            const data = record.data();
            data.id = record.id
            return data;
          });
          dispatch({ type: RETRIEVE_RECORDS_HISTORY_SUCCESS, payload: records });
        });
  }
}

export const recordPR = (exercise, repMax, date, weight, unit, id, workoutId) => {

  const record = {
    userId: firebaseAuth.currentUser.uid,
    exercise,
    repMax : parseInt(repMax),
    date,
    weight: parseFloat(weight),
    unit,
    workoutId : workoutId || ''
  }
  return (dispatch) => {
    dispatch({type: RECORD_PR});
    if (id) {
      firebaseDb.collection("personalRecords").doc(id).update(record)
      .then((doc) => {
          dispatch({ type: UPDATE_PR_SUCCESS });
          Debug("Document successfully written!");
       }).catch((error) => {
         Debug("Error writing document: ", error);
         alert('An error has occured: ' + error.message)
         dispatch({ type: UPDATE_PR_FAIL, payload: error.message });
       })
    }
    else {
        firebaseDb.collection("personalRecords").add(record)
        .then((doc) => {
            dispatch({ type: RECORD_PR_SUCCESS });
            Debug("Document successfully written!");
         }).catch((error) => {
           alert('An error has occured: ' + error.message)
           Debug("Error writing document: ", error);
           dispatch({ type: RECORD_PR_FAIL, payload: error.message });
         })
    }
  }
}

export const deletePR = (id) => {
  return (dispatch) => {
    dispatch({type: DELETE_PR});
    firebaseDb.collection("personalRecords").doc(id).delete()
    .then((doc) => {
        dispatch({ type: DELETE_PR_SUCCESS });
       Debug("Document successfully deleted!");
     }).catch((error) => {
       alert('An error has occured: ' + error.message)
       Debug("Error writing document: ", error);
       dispatch({ type: DELETE_PR_FAIL, payload: error.message });
     })
 }
}
