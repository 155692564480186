import React, {Component} from 'react';
import { Transition, List, Card, Icon, Form } from 'semantic-ui-react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import _ from 'lodash';
import Debug from '../../Debug';
import { connect } from 'react-redux';
import { updateBuilderExercise, deleteBuilderExercise, deleteBuilderComplex, addBuilderWeight,
          updateBuilderWeight, addBuilderExercise, addCopiedExercise } from '../../actions';
import WeightComplex from '../weights/WeightComplex';
import {SetRepInput} from '../weights/Weight';
import { StyledButton, HorizontalDiv, ButtonDiv } from '../StyledComponents';
import ExerciseModal from './ExerciseModal';
import { EmbedModal } from '../common/EmbedModal';
import { intensityResolution, getExerciseOption } from '../../commonMethods';

class ExerciseComplex extends Component {

  constructor(props) {
     super(props)
     this.complexRef = React.createRef();
   }

   componentDidMount() {
     if (this.props.scrollExerciseIntoView) {
       this.complexRef.current.scrollIntoView({
          behavior: "smooth",
          block: 'center',
          inline: 'center'
        });
      }
   }

  // Update all reps element for an exercise complex
  handleRepsChange = (exerciseObj, id, value) => {
    if (value.length>0) {
      if (id === 'sets' || id === 'reps')
        value = isNaN(value) ? 0 : parseInt(value);
      else if (id === 'weight')
        value = isNaN(value) ? 0 : parseFloat(value);
    }

    const { exerciseIndex, weightArr } = exerciseObj;
    for (var i=0; i<weightArr.length; i++) {
        this.props.updateBuilderWeight(exerciseIndex, i, id, value)
        let defaultSetArr = [];
        if (id === 'sets') {
            defaultSetArr = this.getDefaultSetArr(value, weightArr[i].reps);
            this.props.updateBuilderWeight(exerciseIndex, i, 'setArr', defaultSetArr);
        }
        else if (id === 'reps') {
            defaultSetArr = this.getDefaultSetArr(weightArr[i].sets, value);
            this.props.updateBuilderWeight(exerciseIndex, i, 'setArr', defaultSetArr);
        }
    }
  }

  getDefaultSetArr = (sets, reps) => {
      let defaultSetArr = [];
      Debug(sets, reps);
      for (var i =0; i< sets; i++) {
        defaultSetArr.push({"pattern" : ""});
        for (var j=0; j<reps; j++){
          defaultSetArr[i].pattern = defaultSetArr[i].pattern + '1';
        }
      }
      Debug(defaultSetArr);
      return defaultSetArr;
  }

  updateBuilderComplex = (key, value) => {
    const { complexArr, updateBuilderExercise } = this.props;
    complexArr.forEach(obj => updateBuilderExercise(obj.exerciseIndex, key, value))
  }

  addBuilderComplexWeight = () => {
    const { complexArr, addBuilderWeight, athleteConfig } = this.props;
    for (var i=0 ; i<complexArr.length; i++){
      addBuilderWeight(complexArr[i].exerciseIndex, complexArr[i].weightArr[0].reps, athleteConfig.defaultUnit);
    }
  }
  renderComplexCloseButton = () => {
    const { complexArr } = this.props;
    const { complexId } = complexArr[0];
    return <ButtonDiv><StyledButton icon
                  style={{background:'transparent'}}
                  type='button'
                  variant='primary'
                  onClick={(e) => {this.props.deleteBuilderComplex(complexId)}}
                >
                <Icon name='x'/>
                </StyledButton></ButtonDiv>
  }

  copyComplex = (complexArr) => {
    const complexId = Math.random().toString(36).substring(5);
    const newComplex = _.cloneDeep(complexArr);
    newComplex.forEach(exercise => {
      exercise.weightArr = [{...exercise.weightArr[0], weight : "", sets: ""}];
      exercise.complexId = complexId;
      delete exercise.exerciseIndex;
      this.props.addCopiedExercise(exercise);
    });
    console.log('NEW COMPLEX: ' , newComplex);
  }

  renderComplexDuplicateButton = () => {
    const { complexArr } = this.props;
      return <ButtonDiv style={{marginRight: '10px'}}>
              <StyledButton icon
                  style={{background:'transparent'}}
                  type='button'
                  variant='primary'
                  onClick={(e) => {
                    this.copyComplex(complexArr)}}
                >
                  <Icon name='copy'/>
              </StyledButton>
            </ButtonDiv>
  }

  renderExerciseCloseButton = (exerciseIndex, i) => {
      return <ButtonDiv><StyledButton icon
                  style={{background:'transparent'}}
                  className='ui small button'
                  type='button'
                  variant='primary'
                  onClick={(e) => {this.props.deleteBuilderExercise(exerciseIndex)}}
                >
                <Icon name='trash'/>
                </StyledButton></ButtonDiv>
  }

  addExerciseToComplex = (complexId, complexArr) => {
    this.props.addBuilderExercise(complexId, complexArr[0].weightArr.map(element => ({...element, reps : ''})), this.props.athleteConfig.defaultUnit)
  }

  onExerciseSelect = (exercise, exerciseIndex) => {
    const {updateBuilderExercise, athleteConfig, ignoreIntensity } = this.props;
    updateBuilderExercise(exerciseIndex, 'exercise', exercise);
    if (!ignoreIntensity)
      updateBuilderExercise(exerciseIndex, 'workingMax', intensityResolution(exercise, athleteConfig.workingMaxes, this.props.customExercises) );
  }

  onDragEndWeightComplex = result => {
    const { complexArr, updateBuilderExercise } = this.props;
    const {source, destination} = result;
    if (destination && source.index !== destination.index) {
      const from = source.index, to = destination.index;

      complexArr.forEach((complex) => {
      const { weightArr, exerciseIndex } = complex;
      let newWeightArr = weightArr.map(a => ({...a}));
      if (to > from) {
        for (var i=from; i<to; i++) {
          [newWeightArr[i], newWeightArr[i+1]] = [newWeightArr[i+1], newWeightArr[i]];
        }
      }
      else if (from > to) {
        for (var j=from; j>to; j--) {
          [newWeightArr[j], newWeightArr[j-1]] = [newWeightArr[j-1], newWeightArr[j]];
        }
      }
      updateBuilderExercise(exerciseIndex, 'weightArr', newWeightArr);
    })
    }
  }

  render() {

    const { athleteConfig, complexArr, programming, deleteBuilderExercise } = this.props;
    const { complexId } = complexArr[0];

    return (
      <div ref={this.complexRef}>
        <Card style={{background: '#f6f6f6', fontSize: '16px', width:'99%'}}>
          <HorizontalDiv style={{justifyContent : 'flex-end'}}>
            {this.renderComplexDuplicateButton()}
            {this.renderComplexCloseButton()}
          </HorizontalDiv>
          <div style={{paddingLeft:'2%'}}>
          {complexArr.map((exerciseObj, i) => {
            const { videoId } = getExerciseOption(exerciseObj.exercise);

            return <div key={'complex-exercise-' + i}>
            <HorizontalDiv style={{justifyContent: 'flex-start'}}>
            {i===0 ?  <Icon /> : <Icon name='add' color='grey'/>}
            <SetRepInput
                style={{width:'5em'}}
                fluid required
                placeholder='Reps'
                value={exerciseObj.weightArr[0].reps}
                onChange={(e, data) => this.handleRepsChange(exerciseObj, 'reps', data.value)}
              /><br/>
              <HorizontalDiv style={{justifyContent: 'flex-start', width:'100%'}}>
                  <HorizontalDiv>
                    <ExerciseModal
                      exercise={exerciseObj.exercise}
                      onExerciseSelect = {this.onExerciseSelect}
                      exerciseIndex={exerciseObj.exerciseIndex}
                      onExit={() => { if (!exerciseObj.exercise)
                                        deleteBuilderExercise(exerciseObj.exerciseIndex)}
                                    }
                      open={exerciseObj.exercise ? false : true}
                    />
                    {videoId &&
                      <EmbedModal
                        videoId={videoId}
                      />
                    }
                    </HorizontalDiv>
              </HorizontalDiv>
              {this.renderExerciseCloseButton(exerciseObj.exerciseIndex, i)}
              </HorizontalDiv>
            </div>}
          )}
          <ButtonDiv style={{display: 'flex', justifyContent: 'flex-start'}}> <StyledButton icon
              className='secondary ui mini button'
              type='button'
              onClick={(e) => {this.addExerciseToComplex(complexId, complexArr)}}
            >
            <Icon name='add' />
            </StyledButton>
           </ButtonDiv>
           <br/>
          </div>
          {complexArr[0].weightArr &&
          <DragDropContext onDragEnd={this.onDragEndWeightComplex}>
            <Droppable droppableId={'c' + complexId}>
              {(provided, snapshot) => (
              <div
               {...provided.droppableProps}
               ref={provided.innerRef}
              >
              <Transition.Group
                as={List}
                duration={200}
                divided
                animation="slide down"
              >
              {complexArr[0].weightArr.map((weightObj, index) =>
                <List.Item key={'weight-' + index}>
                 <Draggable draggableId={'w' + index} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                    <WeightComplex key={'weight-complex-' + complexId + '-' + index}
                            athleteConfig={athleteConfig}
                            complexArr={complexArr}
                            weightIndex={index}
                            weight={weightObj.weight}
                            unit={weightObj.unit}
                            sets={weightObj.sets}
                            setArr={weightObj.setArr}
                            getDefaultSetArr={this.getDefaultSetArr}
                      />
                    </div>
                    )}
                </Draggable>
                </List.Item>
                )}
                </Transition.Group>
                {provided.placeholder}
                </div>
              )}
              </Droppable>
             </DragDropContext>}
              <br/>
              <StyledButton icon
                  className='ui mini button'
                  type='button'
                  variant='primary'
                  onClick={(e) => {this.addBuilderComplexWeight()}}
                >
                <Icon name='add' />&nbsp;Add
                </StyledButton>
            <br/>
            {(programming || complexArr[0].coachNotes) && <Form.TextArea
              maxLength='500'
              placeholder="Coach's Notes..."
              value={complexArr[0].coachNotes}
              readOnly={programming ? false : true}
              onChange={(e) => {this.updateBuilderComplex('coachNotes', e.target.value)}}
             />}
            <Form.TextArea
              maxLength='500'
              rows='2'
              placeholder='Notes...'
              value={complexArr[0].exerciseNotes}
              onChange={(e) => {this.updateBuilderComplex('exerciseNotes', e.target.value)}}
             />
        </Card>
       </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    config : state.config
  }
}

export default connect(mapStateToProps, { updateBuilderExercise, deleteBuilderExercise, deleteBuilderComplex, addBuilderWeight, updateBuilderWeight, addBuilderExercise, addCopiedExercise })(ExerciseComplex);
