import React, { Component } from 'react';
import Debug from '../../Debug';
import { FixedSizeList } from 'react-window';
import { Header, Image } from 'semantic-ui-react';
import { TouchableButton, HorizontalDiv } from '../StyledComponents';
import { getRecord } from '../../commonMethods';

const touch = {
  opacity: 0.5
}

class ExerciseListItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      touched : false
    }
  }

  toggleTouched = () => {
    Debug('touched')
      this.setState( prevState => ({
        touched: !prevState.touched
      }));
  }

handleMouseUp = () => {
  // Handle smooth animation when clicking without holding
  setTimeout( () => {
    this.setState({ touched: false });
  }, 150);
}

  render() {
      const { index, style, data } = this.props;
      const { userConfig } = data;
      const { touched } = this.state;
      const { value, text, category, category_level_2 } = data.exerciseOptions[index];
      const touchableStyle = touched ? touch : null;
      let record = null;
      if (userConfig && userConfig.currentRecords && userConfig.currentRecords[value]) {
        record = getRecord(userConfig.currentRecords[value]);
      }

    return (
    <div style={style}>
      <TouchableButton
        style={touchableStyle}
        onMouseDown={this.toggleTouched}
        onClick={() => {
            data.onExerciseSelect(value, data.exerciseIndex);
            if (data.toggleModal)
              data.toggleModal();
          }
        }
      >
      <HorizontalDiv style={{width : '100%', height:'100%'}}>
        <HorizontalDiv>
        <Image  style={{paddingRight: '10px'}} size="mini"  src={`/exerciseImages/snatch.png`} />
        <Header as='h5' style={{marginTop:'0'}} textAlign="left">{text}
          <Header.Subheader style={{marginTop:'10px'}}>{category.charAt(0).toUpperCase() + category.slice(1) + ' - ' + category_level_2.charAt(0).toUpperCase() + category_level_2.slice(1)}</Header.Subheader>
        </Header>
        </HorizontalDiv>
        {record &&
          <div><Header>{record.weight} {userConfig.currentRecords[value].unit}
          <Header.Subheader>{record.date ? record.date.toDate().toLocaleDateString() : ""}</Header.Subheader>
          </Header>
          </div>
        }
      </HorizontalDiv>
      </TouchableButton>
      </div>
    )
  }
}

export const ExerciseList = ({toggleModal, onExerciseSelect, exerciseIndex, exerciseOptions, userConfig}) => {
//  Debug(currentRecords)
  return (
    <FixedSizeList
      height={600}
      itemCount={exerciseOptions.length}
      itemSize={60}
      itemData={{toggleModal, exerciseOptions, exerciseIndex, onExerciseSelect, userConfig}}
    >
      {ExerciseListItem}
    </FixedSizeList>
  );
}

export default ExerciseList;
