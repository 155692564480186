import React, { useState} from 'react';
import { firebaseAuth, firebaseDb, teamsRef } from '../../firebase';
import { Input, Button, Modal, TransitionablePortal } from 'semantic-ui-react';

export const CreateTeamModal = ({ updateUserConfig }) => {

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [teamName, setTeamName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const setInitialState = () => {
    setOpen(false);
    setLoading(false);
    setTeamName('');
    setError('');
    setSuccess('');
  }

  const createTeam = (name) => {
    setLoading(true);
    const {uid, displayName } = firebaseAuth.currentUser;
    const teamCode = Math.random().toString(36).substring(5);
    const emptyTeam = {
      admin: uid,
      coaches: [uid],
      members: [{name: displayName , role: 'coach', uid}],
      name : teamName,
      teamCode,
      tracks: []
    }

    firebaseDb.collection(teamsRef).add(emptyTeam)
    .then((docRef) => {
        console.log(docRef);
        updateUserConfig('team', docRef.id, uid);
        setLoading(false);
      })
      .catch((error) => {
        alert(error.message);
        setLoading(false);
        setSuccess("Your team has been created!");
      });

  }

  return (
    <TransitionablePortal
      trigger={<Button size='big' primary onClick={() => setOpen(true)}>Create your team!</Button>}
      onMount={() => setOpen(true)}
      onClose={() => setInitialState()}
      onOpen={() => setOpen(true)}
      transition = {{
          animation: 'scale',
          duration: 800,
        }}
    >
    <Modal
      size='tiny'
      onClose={() => setInitialState()}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
    >
      <Modal.Header>Create your team!</Modal.Header>
      <Modal.Content style={{textAlign: 'center'}}>
            <Input placeholder='Team Name...' value={teamName} onChange={(e,data) => {
              setError('');
              setTeamName(data.value)}}
              error={error.length>0}
              disabled={success.length>0}
            />
            <br/><br/>
            <Button fluid primary onClick={() => {
                if (teamName.length >0) {
                  createTeam()
                }
                else {
                  setError('Please enter a team name.')
                }
              }}
              loading={loading}> Create </Button>
            {error && <p className='ui error message'>{error}</p>}
            {success && <p className='ui success message'>{success}</p>}
      </Modal.Content>
    </Modal>
    </TransitionablePortal>
  )
}
