export const COLOR_PRIMARY = '#005792';
export const COLOR_SECONDARY = '#04a1d9'
export const COLOR_LIGHT = '#dcfcfc';

//Categories
export const OLYMPIC = 'olympic';
export const STRENGTH_UPPER = 'strength-upper';
export const STRENGTH_LOWER = 'strength-lower';
export const BODYBUILDING = 'bodybuilding';
export const OTHER = 'other';

//Subcategories
export const SNATCH = 'snatch';
export const CLEAN = 'clean';
export const JERK = 'jerk';
export const SQUAT = 'squat';
export const PULL = 'pull';
export const ROW = 'row';
export const PRESS = 'press';
export const PRESS_VERTICAL = 'press_vertical';
export const CLEANJERK = 'cleanjerk';
export const CORE = 'core';

export const STRENGTH = 'strength';
export const CONDITIONING = 'conditioning';

export const  INPROGRESS = 'in_progress';
export const PROGRAMMED = 'programmed';
export const COMPLETE = 'complete';

export const KG = 'kg';
export const LBS = 'lbs';
export const UNIT = 'unit';
export const WEIGHT = 'weight';
export const SETS = 'sets';
export const VOLUME = 'volume';
export const WORKINGMAX = 'workingMax';

export const NONE = 'none';
export const TIME = 'time';
export const AMRAP = 'amrap';
export const MAXREPS = 'maxreps';
export const EMOM = 'emom';
export const DISTANCE = 'distance';
export const CUSTOM ='custom';

export const TYPE = 'type';
export const WODTYPE ='wodType'
export const NOTES = 'notes';
export const COACHNOTES = 'coachNotes';
export const TITLE = 'title';
export const DESCRIPTION = 'description';
export const FAVORITED = 'favorited';
export const BENCHMARK = 'benchmark';
export const SCORE = 'score';
export const MINUTES = 'minutes';
export const SECONDS = 'seconds';
export const ROUNDS = 'rounds';
export const REPS = 'reps';
export const MODIFIED = 'modified';
export const HIGHLIGHT = 'highlight';

export const DATE = 'date';
export const REPMAX = 'repMax';
export const EXERCISE = 'exercise';

export const BARBELL = 'barbell';

export const DAILY = 'daily';
export const WEEKLY = 'weekly';
export const MONTHLY = 'monthly';

export const READABLE = 'readable';
export const EXCEL = 'excel';

export const PROGRAM = 'program';
export const BLOCKS = 'blocks';
export const WEEKS = 'weeks';
export const DAYS = 'days';
export const WORKOUTS = 'workouts';

export const LESSFIFTY = 'lessfifty';
export const FIFTY = 'fifty';
export const SIXTY = 'sixty';
export const SEVENTY = 'seventy';
export const EIGHTY = 'eighty';
export const NINETY = 'ninety';
export const NINETYFIVE = 'ninetyfive';
export const HUNDREDPLUS = 'hundredplus';

export const COACH= 'coach';
export const ASSISTANT_COACH = 'assistant_coach';
export const ATHLETE = 'athlete';
