import React, { Component } from 'react';
import { connect } from 'react-redux';
import LogView from '../log/LogView';
import { Draggable } from 'react-beautiful-dnd';
import { WorkoutContainer } from '../StyledComponents';
import { addProgramBuilderWorkout, deleteProgramBuilderWorkout } from '../../actions';
import { generateLayoutId } from './shared';
import { WORKOUTS } from '../../assets/literals';


class Workout extends Component {

  onCopy = () => {
    console.log('copying')
  }

  render() {
    const { editable, day, layout, workout, openScheduler, index, addProgramBuilderWorkout, deleteProgramBuilderWorkout } = this.props;
    console.log('WORKOIUT:', workout)
    return (
      <Draggable
          draggableId={workout.id}
          index={index}
          isDragDisabled={!editable}
        >
        {(provided, snapshot) => (
          <WorkoutContainer
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            >
            {workout.workout &&
              <LogView
                as='h6'
                workout={workout.workout}
                headerSize='h4'
                showName={true}
                showMenu
                scheduleOnly={!editable}
                templateId={workout.workout.id}
                onCopy={() => {
                  const id = generateLayoutId('workout');
                  addProgramBuilderWorkout(id, index+1, day.id, {...workout.workout, name : workout.workout.name + ' (Copy)', id})
                }}
                onDelete={() => deleteProgramBuilderWorkout(day.id, workout.id, layout)}
                onSchedule={() => openScheduler(WORKOUTS, workout.id, index)}
              />}
          </WorkoutContainer>
        )}
      </Draggable>
    )
  }
}

export default connect(null, { addProgramBuilderWorkout, deleteProgramBuilderWorkout })(Workout);
