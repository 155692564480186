import React, { Component } from 'react';
import DeleteModal from '../common/DeleteModal';
import { Dropdown } from 'semantic-ui-react';

class EditCopyDeleteMenu extends Component {
  state = {
    deleteModuleOpen : false
  }

  render() {
    const { showEdit, showCopy, showDelete, onEdit, onCopy, onDelete, deleteMessage,showSchedule, onSchedule } = this.props;
    return (
      <Dropdown
        style={{height : '20px', padding : '5px'}}
        closeOnChange
        icon='bars'>
        <Dropdown.Menu
          direction='left'
        >
          {showEdit && <Dropdown.Item
            icon='edit'
            text='Edit'
            onClick={onEdit}
          />}
          {showSchedule && <Dropdown.Item
            icon='calendar'
            text='Schedule'
            onClick={onSchedule}
          />}
          {showCopy && <Dropdown.Item
            icon='copy'
            text='Copy'
            onClick={onCopy}
          />}
          {showDelete &&
            <DeleteModal
              trigger={<Dropdown.Item icon='trash' text='Delete'/>}
              onDelete={onDelete}
              deleteMessage={deleteMessage}
            />}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

export default EditCopyDeleteMenu;
