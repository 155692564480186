import React, { Component } from 'react';
import { Button, Icon, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { deleteBuilderWeight, updateBuilderWeight } from '../../actions';
import { getExerciseOption } from '../../commonMethods';
import { getIntensity } from './shared_weight_functions';
import SetComplexContainer from './SetComplexContainer';
import Debug from '../../Debug';
import {SetRepInput} from './Weight';
import { HorizontalDiv, ButtonDiv, WeightContainer, StyledButton } from '../StyledComponents';

class WeightComplex extends Component {

    constructor(props) {
      super(props);
      this.state = {
        active: false,
        expanded: false
      }
    }

    componentDidMount(){
      Debug(this.props);
    }

    handleChange(id, value) {
      const { complexArr, weightIndex, updateBuilderWeight } = this.props;

      if (value.length>0) {
        if (id === 'sets' || id === 'reps')
          value = isNaN(value) ? 0 : parseInt(value);
        else if (id === 'weight') {
          let lastChar = value.slice(-1);
          if (lastChar === '.' || lastChar === '*' || lastChar ===',' || lastChar ==='#' || lastChar==='+')
            value= value.substring(0, value.length - 1) + '.';
          else
            value = isNaN(value) ? 0 : parseFloat(value);
        }
      }
        
      for (var i=0; i<complexArr.length; i++) {
           updateBuilderWeight(complexArr[i].exerciseIndex, weightIndex, id, value);

           //update setArr if sets or reps changes is being updated
           let defaultSetArr = [];
           if (id === 'sets') {
               defaultSetArr = this.props.getDefaultSetArr(value, complexArr[i].weightArr[weightIndex].reps);
               this.props.updateBuilderWeight(complexArr[i].exerciseIndex, weightIndex, 'setArr', defaultSetArr);
           }
           else if (id === 'reps') {
               defaultSetArr = this.props.getDefaultSetArr(complexArr[i].weightArr[weightIndex].sets, value);
               this.props.updateBuilderWeight(complexArr[i].exerciseIndex, weightIndex, 'setArr', defaultSetArr);
           }
      }
    }

    handleToggle(key, toggle) {
      const { complexArr, weightIndex, updateBuilderWeight } = this.props;
      for (var i=0; i<complexArr.length; i++) {
           updateBuilderWeight(complexArr[i].exerciseIndex, weightIndex, key, toggle)
      }
    }

    deleteBuilderComplexWeight() {
      const { complexArr, weightIndex, deleteBuilderWeight } = this.props;
      for (var i=0; i<complexArr.length; i++) {
            deleteBuilderWeight(complexArr[i].exerciseIndex, weightIndex);
      }
    }

    expand = () => {
      const {expanded} = this.state;
      let iconStr = 'caret left'
      if (expanded)
        iconStr='caret down';

        return (
         <Button
                  icon
                  value={expanded}
                  type='button'
                  size='massive'
                  style={{background: 'transparent',padding: 0, paddingLeft: '30px'}}
                  onClick={() => this.setState({expanded: !this.state.expanded})}
              >
                <Icon name={iconStr}
                  onClick={() => this.setState({expanded: !this.state.expanded})}
                  />
              </Button>
      )
    }

    renderSetContainer = () => {
        const {weightIndex, sets, setArr, complexArr} = this.props;
        if (this.state.expanded)
          return <SetComplexContainer
                      weightIndex={weightIndex}
                      sets={sets}
                      setArr={setArr}
                      complexArr={complexArr}/>
    }

    render() {

      const {athleteConfig, complexArr, weight,unit,sets,weightIndex} = this.props;
      const exerciseOption = getExerciseOption(complexArr[0].exercise);
      const { category } = exerciseOption;
      Debug(unit)
      let xButton = <Icon/>;
      if (weightIndex !== 0) {
       xButton = <ButtonDiv><StyledButton icon
                    style={{background:'transparent'}}
                    className='ui small button'
                    type='button'
                    variant='primary'
                    onClick={(e) => {this.deleteBuilderComplexWeight(weightIndex)}}
                    disabled={weightIndex !== 0 ? false : true}
                  >
                  <Icon name='trash'/>
                  </StyledButton></ButtonDiv>
      }
      return(
        <WeightContainer>
          <HorizontalDiv style={{justifyContent:"flex-end"}}>
            {getIntensity(category, weight, unit, complexArr[0].workingMax, athleteConfig.intensityThreshold, athleteConfig.defaultUnit)}
              {xButton}
          </HorizontalDiv>
            <HorizontalDiv style={{fontWeight: 'bold', color: 'grey'}}>
                <SetRepInput
                    placeholder='Sets'
                    value={sets}
                    min={0}
                    max={999}
                    onChange={(e, data) => this.handleChange('sets', data.value)}
                 /*   onKeyUp={() => {
                      if (!reps) {
                        if (sets.toString().length === 2)
                          this.repsInput.current.focus();
                        else if (sets.toString().length === 1)
                        setTimeout(() => {this.repsInput.current.focus()}, 500)
                      }
                    }}
                   */
                />
                &nbsp;@&nbsp;
                <SetRepInput
                        placeholder='Weight'
                        value={weight}
                        min={0}
                        max={9999}
                        onChange={(e, data) => this.handleChange('weight', data.value)}
                     /*   onKeyUp={() => {
                          if (!reps) {
                            if (sets.toString().length === 2)
                              this.repsInput.current.focus();
                            else if (sets.toString().length === 1)
                            setTimeout(() => {this.repsInput.current.focus()}, 500)
                          }
                        }}
                       */
                  />
                  <Dropdown
                        style={{marginLeft:'10px', marginRight:'10px', fontWeight: 'bold', fontSize : '18px'}}
                        options={[
                          { key: 'lbs', text: 'lbs', value: 'lbs' },
                          { key: 'kg', text: 'kg', value: 'kg' },
                          { key: '%', text: '%', value: '%' }
                        ]}
                        id='unit'
                        onChange={(e, data) => {this.handleChange('unit', data.value)}}
                        value={unit}
                    />
              {this.expand()}
           </HorizontalDiv>
           {this.renderSetContainer()}
        </WeightContainer>
      );
    }
 }

export default connect(null, { deleteBuilderWeight, updateBuilderWeight })(WeightComplex);
