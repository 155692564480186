import React, { PureComponent } from 'react';
import {
  ComposedChart, Bar, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Label
} from 'recharts';
import { Header } from 'semantic-ui-react';
import { getDateTickFormat, getLabel } from '../../commonMethods';

class VolumeLineChart extends PureComponent {

  render() {
    const { data, metric, unit, aggregate, isMobile } = this.props;
    return (
      <>
      <Header as='h3'>Volume & Intensity</Header>
      <ResponsiveContainer  width="99%" aspect={2}>
        <ComposedChart
          key={metric}
          data={data}
        >
          <Label value="Volume/Time" position="insideTop"/>
          <XAxis
            type={(data && data.length < 3) ? "category" : "number"} //need categroy when 1 due to recharts bug https://github.com/recharts/recharts/issues/2127
            domain={["dataMin - 172800", "dataMax + 172800"]}
            tickFormatter = {(unixTime) => {
                return getDateTickFormat(unixTime, aggregate);
            }}
            dataKey="date"
            tickCount={7}
          />
          <YAxis
            yAxisId="left"
            width={isMobile ? 0 : 60}
            label={{ value: getLabel(metric, unit), orientation: "left", angle: -90, position: 'insideLeft', textAnchor: 'middle' }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            width={isMobile ? 0 : 60}
            domain={[0.4, 1]}
            label={{ value: 'Intensity(%)', orientation: "right", angle: 90, position: 'insideRight', textAnchor: 'middle' }}
            tickFormatter = {(percent) => Math.round(percent*100)}
          />
          <Tooltip
            labelFormatter={(value) => {
              return getDateTickFormat(value, aggregate);
            }}
          />
          <Legend />
          <Bar yAxisId="left" dataKey={metric} fill="#2d7a9c" maxBarSize={50} />
          <Line yAxisId="right" dataKey="intensity" stroke="#e37e75" strokeWidth={isMobile ? 4 : 8} dot={false} />
          <Line yAxisId="right" dataKey="topIntensity" stroke="#e37e75b8" strokeWidth={isMobile ? 4 : 8} dot={false} />
        </ComposedChart>
      </ResponsiveContainer>
      </>
    );
  }
}

export default VolumeLineChart;
