import React, { Component } from 'react';
import Debug from '../../Debug';
import { connect } from 'react-redux';
import { Dropdown, Input, Modal, Button, Icon } from 'semantic-ui-react';
import { CategoryDropdown } from './CategoryDropdown';
import exerciseImplement from '../../assets/exercise_implement';
import { getImplementName } from '../../commonMethods';
import { createCustomExercise } from '../../actions';

const INITIAL_STATE = {
  open: false,
  text: '',
  category: '',
  category_level_2: '',
  implement: 'barbell'
}

class CreateExerciseModal extends Component {

  constructor(props) {
    super(props);
    Debug("Props:", props);
    this.state = {...INITIAL_STATE, open: props.open }
  }

  handleChange = (key, value) => {
    this.setState({[key] : value});
  }

  render() {
    const { open, text, category, category_level_2, implement} = this.state;

    return(
      <Modal
        closeIcon
        onClose={() => this.setState(INITIAL_STATE)}
        onOpen={() => this.setState({open: true})}
        open={open}
        trigger={this.props.trigger}
        size='mini'
      >
        <Modal.Header>Create New Exercise</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Input
                  fluid
                  value={text}
                  onChange={((e, data) => this.handleChange('text', data.value))}
                placeholder='Exercise Name' />
                <br/>
                <CategoryDropdown
                    onChange = {this.handleChange}
                    category={category}
                    category_level_2={category_level_2}
                />
                <br/>
                <Dropdown
                  fluid
                  button
                  className='icon'
                  floating
                  labeled
                  options={exerciseImplement}
                  text={getImplementName(implement)}
                  onChange={(e, data) => this.handleChange('implement', data.value)}
                />
                 <br/>

            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.setState(INITIAL_STATE)}>
            <Icon name='remove' /> Cancel
          </Button>
          <Button primary
            disabled={!text || !category || !category_level_2 || !implement }
            onClick={() => {
                Debug(this.state)
                const key = category + '_' + category_level_2 + '_' + implement + '_' + Math.round(Math.random()*1000000); //create unique custom key
                this.props.createCustomExercise(key, text, category, category_level_2, implement, this.props.exerciseIndex);
                if (this.props.onSave)
                  this.props.onSave(key);
                this.setState(INITIAL_STATE);
          }}>
            <Icon name='checkmark' /> Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(null, { createCustomExercise })(CreateExerciseModal);
