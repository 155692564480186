import update from 'immutability-helper';
import { RETRIEVE_WORKOUT_HISTORY, RETRIEVE_WORKOUT_HISTORY_SUCCESS, RETRIEVE_WORKOUT_HISTORY_FAIL,
          RETRIEVE_RECORDS_HISTORY, RETRIEVE_RECORDS_HISTORY_SUCCESS, RETRIEVE_RECORDS_HISTORY_FAIL,
          RETRIEVE_EXERCISE_HISTORY, RETRIEVE_EXERCISE_HISTORY_FAIL, RETRIEVE_EXERCISE_HISTORY_SUCCESS,
          RECORD_PR, RECORD_PR_SUCCESS, RECORD_PR_FAIL, DELETE_PR, DELETE_PR_SUCCESS, DELETE_PR_FAIL,
          UPDATE_PR_SUCCESS, UPDATE_PR_FAIL, RESET_CALENDAR, SET_ATHLETE_OR_TRACK, SET_HISTORY
        } from '../actions/types';
import Debug from '../Debug';

const INITIAL_STATE =  {
  workouts: [],
  records : [],
  exerciseWorkouts : [],
  loadedMonths : [],
  loading: false,
  didInvalidate: false,
  error: '',
  athlete : '',
  trackToggle : false
}

const HistoryLogReducer = (state = INITIAL_STATE, action) => {
    Debug(action);
  switch (action.type) {
    case RESET_CALENDAR:
      return {...INITIAL_STATE, athlete : state.athlete};
    case SET_ATHLETE_OR_TRACK :
      return {...INITIAL_STATE, trackToggle: action.payload.isTrack, athlete: action.payload.id}
    case SET_HISTORY :
      return {...state, [action.payload.key]: action.payload.value}
    case RETRIEVE_WORKOUT_HISTORY_SUCCESS:
      return update({...state,
                      workouts: [...state.workouts,...action.payload.workouts],
                      loading: false,
                      error: '',
                      didInvalidate: true
                      },
                      {  loadedMonths: {$push: [action.payload.month]} });
    case RETRIEVE_WORKOUT_HISTORY_FAIL:
      return { ...state, loading: false, didInvalidate: false, error: action.payload };
    case RETRIEVE_WORKOUT_HISTORY:
      return { ...state, loading: true, didInvalidate: false, error: '' };
      case RETRIEVE_EXERCISE_HISTORY_SUCCESS:
        return {...state,
                  exerciseWorkouts: action.payload,
                  loading: false,
                  error: ''
               }
      case RETRIEVE_EXERCISE_HISTORY_FAIL:
        return { ...state, loading: false, error: action.payload };
      case RETRIEVE_EXERCISE_HISTORY:
        return { ...state, loading: true, error: '' };
    case RECORD_PR:
      return { ...state, loading: true, error: '' }
    case RECORD_PR_SUCCESS:
      return { ...state, loading: false, error: '' }
    case RECORD_PR_FAIL:
      return { ...state, loading: false, error: action.payload }
    case DELETE_PR:
      return { ...state, loading: true, error: '' }
    case DELETE_PR_SUCCESS:
      return { ...state, loading: false, error: '' }
    case DELETE_PR_FAIL:
      return { ...state, loading: false, error: action.payload }
    case UPDATE_PR_SUCCESS:
      return { ...state, loading: false, error: '' }
    case UPDATE_PR_FAIL:
      return { ...state, loading: false, error: action.payload }
    case RETRIEVE_RECORDS_HISTORY_SUCCESS:
        return {...state,
                        records: action.payload,
                        loading: false,
                        error: ''
                };
      case RETRIEVE_RECORDS_HISTORY_FAIL:
        return { ...state, loading: false, error: action.payload };
      case RETRIEVE_RECORDS_HISTORY:
        return { ...state, loading: true, error: '' };
    default:
      return state;
  }
}

export default HistoryLogReducer;
