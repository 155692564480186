const REACT_APP_FIREBASE_KEY="AIzaSyDdkLDpbKtItGsAICy7oUKhM9O6QCGr_Zk"
const REACT_APP_FIREBASE_DOMAIN="app.traintensity.com"
const REACT_APP_FIREBASE_DATABASE="https://workout-tracker-407aa.firebaseio.com"
const REACT_APP_FIREBASE_PROJECT_ID="workout-tracker-407aa"
const REACT_APP_FIREBASE_STORAGE_BUCKET="workout-tracker-407aa.appspot.com"
const REACT_APP_FIREBASE_SENDER_ID="58500936378"
const REACT_APP_FIREBASE_APP_ID="1:58500936378:web:a7ecc19f518bfc12ddc883"
const REACT_APP_FIREBASE_MEASUREMENT_ID="G-9XH94TPHLB"

export const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_KEY,
  authDomain: REACT_APP_FIREBASE_DOMAIN,
  databaseURL: REACT_APP_FIREBASE_DATABASE,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID
};
