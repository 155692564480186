import React from 'react';
import { Icon, Statistic, Card } from 'semantic-ui-react';

/*
const Trend = ({positive, value}) =>
  <Header as='h3'>
    <Icon name='caret up' />
    5.23%
  </Header>
*/

export const StatCard = ({icon, value, label, caret, size }) => {
  return <Card style={{paddingTop:'1%', paddingBottom: '1%', margin: 0}}>
    <Statistic size={size || 'tiny'}>
      <div style={{ margin: 'auto', paddingBottom: '1%'}}>
        <Icon name={icon} size='large' color='grey' />
      </div>
      <Statistic.Value style={{color:'black'}}>
        {value}&nbsp;
        {caret !== undefined &&
          <Icon name={caret ===true ? 'caret up' : 'caret down'}
            color={caret === true ? 'green' : 'red'} />
        }
      </Statistic.Value>
      <Statistic.Label>{label}</Statistic.Label>
    </Statistic>
  </Card>
}
