import React from 'react';
import { Popup, Button, Icon } from 'semantic-ui-react';
import { ButtonDiv } from '../StyledComponents';

const SampleRepButton = ({icon, color}) => <Button
    style={{backgroundColor: color, margin: '2px'}}
    type='button'
    icon
    size='mini'
   >
   <Icon name={icon}
   />
 </Button>

export const SetToolTip = () => {
  return (
    <ButtonDiv><Popup basic
            content={<><SampleRepButton icon='check' color='lightgreen'/> Made rep <br/>
                      <SampleRepButton icon='x' color='lightcoral'/> Missed rep (standard) <br/>
                      <SampleRepButton icon='x' color='moccasin'/> Close miss (minor error) <br/>
                      <SampleRepButton icon='minus' color='lightgrey'/> Did not attempt
                    </>
                  }
            trigger={<Button icon
                      style={{background:'transparent', width: '30px'}}
                      className='ui small button'
                      type='button'
                      variant='primary'
                      onClick={(e) => {}}
                      >
                        <Icon name='info circle'/>
                      </Button>}
      />
     </ButtonDiv>
   )
 }
