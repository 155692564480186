import React, {Component} from 'react';
import { Header} from 'semantic-ui-react';
import Debug from '../../Debug';
import { getExerciseOption } from '../../commonMethods';
import { Paragraph } from '../StyledComponents';

class ExerciseSummary extends Component {

  static defaultProps = {

  }

  renderNotes = (notes) => {
    if (notes)
      return <><Paragraph>{notes}</Paragraph></>
    else
      return null;
  }

  render() {
    Debug(this.props);
    const {headerSize, exercise, weightArr, exerciseNotes, coachNotes, header, config} = this.props;
    Debug(config)
    return (
        <div style={{paddingBottom:'3%'}}>
          {header && <Header style={{marginBottom: '5px'}} as={headerSize || 'h3'} dividing>
            {getExerciseOption(exercise, config.customExercises).text}
          </Header>}
          {weightArr.map((weightObj, index) => {
            const { weight, sets, reps, unit, setArr } = weightObj;
            return <div key={'weight-' + index}>
            {weight > 0 ? <span>{sets} x {reps} @ {weight} {unit}<br/></span> : (sets>0 || reps>0) ? <span>{sets} x {reps}<br/></span> : <></>}
                {setArr && setArr.map((setObj, j) => {
                  const { pattern } = setObj;
                  let madeReps = pattern.match(/1/g);
                  madeReps = madeReps ? madeReps = madeReps.length : 0;
                  if (reps > madeReps)
                      return <div key={'set-' + j}><span style={{marginLeft: '3%', fontSize: '12px'}}> Set {j + 1} : {madeReps}/{reps} </span><br/></div>
                  else
                    return null;
              })}
            </div>
          })}
          {this.renderNotes(coachNotes)}
          {this.renderNotes(exerciseNotes)}
        </div>
    );
  }
}

export default ExerciseSummary;
