import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import AuthReducer from './AuthReducer';
import SignUpReducer from './SignUpReducer';
import ConfigReducer from './ConfigReducer';
import LogReducer from './LogReducer';
import HistoryLogReducer from './HistoryLogReducer';
import ProgramReducer from './ProgramReducer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: AuthReducer,
  signup: SignUpReducer,
  config: ConfigReducer,
  log: LogReducer,
  historyLog: HistoryLogReducer,
  program: ProgramReducer
});

export default createRootReducer;
