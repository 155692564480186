import React from 'react';
import { Card, Header } from 'semantic-ui-react';
import { getTypeLabel } from '../../commonMethods';
import AverageWeightChart from './AverageWeightChart';

export const AverageWeightChartGroup = ({categories, data, sport}) => {
  if (!data)
    return null;

  return (
      <Card.Group stackable itemsPerRow={3}>
        {categories.map((category,i) =>
          <Card style={{width: '30%', height: '250px'}} key={category + '-' + i}>
            <Header as='h4' style={{marginTop: '8px', color: 'darkslategrey'}}>
                {getTypeLabel(category, sport)}
            </Header>
            <div style={{width: '100%', height: '200px'}}>
              <AverageWeightChart
                data={data}
                category={category}
              />
            </div>
          </Card>
      )}
      </Card.Group>
    );
}
