import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { getIntensityColor } from '../../commonMethods';
import { COLOR_PRIMARY } from '../../assets/literals';

const data01 = [
  { name: 'Group A', value: 400 },
];

export default class PieSummary extends PureComponent {


  render() {
    const { intensity } = this.props;
    let data = [ { name: 'Intensity', value: intensity }, { name: '', value: 100-intensity}]
    const COLORS = [ getIntensityColor(intensity), 'lightgrey'];
    return (
        <PieChart width={100} height={100}>
          <Pie startAngle={-270} data={data01} dataKey="value" outerRadius={35} fill={COLOR_PRIMARY} />
          <Pie startAngle={-270} data={data} dataKey="value"  innerRadius={38} outerRadius={50}>
            {data.map((entry, index) => (
             <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
           ))}
          </Pie>
        </PieChart>
    );
  }
}
