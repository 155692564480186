import React, { Component } from 'react';
import { Modal, Header, Icon, Button } from 'semantic-ui-react';

class DeleteModal extends Component {
  state = {
    deleteModuleOpen : false
  }
    render() {
      const { trigger, deleteMessage, onDelete } = this.props;
      return (
        <Modal
          basic
          onClose={() => this.setState({deleteModuleOpen: false})}
          onOpen={() => this.setState({deleteModuleOpen: true})}
          open={this.state.deleteModuleOpen}
          size='small'
          trigger={trigger}
        >
          <Header icon>
            <Icon name='warning sign' />
            Delete Workout
          </Header>
          <Modal.Content>
            <p>
              {deleteMessage || 'Are you sure you want to delete?'}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color='grey' inverted onClick={() => this.setState({deleteModuleOpen: false})}>
               Cancel
            </Button>
            <Button color='red' inverted onClick={() => {
              this.setState({deleteModuleOpen: false});
              onDelete();
            }}>
               Delete
            </Button>
          </Modal.Actions>
        </Modal>
      )
    }
}

export default DeleteModal
