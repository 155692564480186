import React, { Component } from 'react';
import { Button, Select, Header, Form, Card, Icon, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
//import Debug from '../../Debug';
import { updateBuilderExercise, deleteBuilderExercise } from '../../actions';
import { StyledButton, StyledInput, HorizontalDiv, ButtonDiv } from '../StyledComponents';
import { WodModal } from './WodModal';
import { NONE, TIME, AMRAP, MAXREPS, DISTANCE, CUSTOM, WODTYPE, TITLE, DESCRIPTION, BENCHMARK, SCORE, NOTES, COACHNOTES, MINUTES, SECONDS, ROUNDS, REPS, MODIFIED, UNIT } from '../../assets/literals';
import benchmarkTypes from '../../assets/benchmark-types';

const ScoreInput = React.forwardRef(({ type, placeholder, id, value, min, max, onChange, onKeyUp}, ref) => {
  return <StyledInput
     type={type}
     min={min}
     max={max}
     placeholder={placeholder}
     value={value || ''}
     onChange={onChange}
     onKeyUp={onKeyUp}
  >
   <input style={{paddingLeft: 0, paddingRight: 0, border: '0', borderBottom: '1px solid lightgrey', textAlign: 'center', fontSize: '18px', fontWeight: 'bold'}} />
  </StyledInput>
});

class Conditioning extends Component {

  handleChange = (type, key, value) => {
    const { exerciseIndex, score, updateBuilderExercise } = this.props;

  //  if (value.length>0 && (type === TIME || type === AMRAP || type === MAXREPS))
    //      value = isNaN(value) ? 0 : parseInt(value);

    updateBuilderExercise(exerciseIndex, SCORE, {...score, [key] : value });
  }

  renderScoreInput = () => {
    const { score, wodType } = this.props;
    let inputComponent = null;
    switch (wodType) {
      case NONE:
        inputComponent = null;
        break;
      case TIME:
        let minutes, seconds;
        if (score) {
           minutes = score.minutes;
           seconds = score.seconds;
        }
        inputComponent = <HorizontalDiv style={{alignItems : 'baseline'}}>
                            <ScoreInput
                              type='tel'
                              placeholder='Minutes'
                              value={minutes}
                              min={0}
                              max={60}
                              onChange={(e, data) => this.handleChange(TIME, MINUTES, data.value)}
                              /> &nbsp;:&nbsp;
                              <ScoreInput
                                type='tel'
                                placeholder='Seconds'
                                value={seconds}
                                min={0}
                                max={60}
                                onChange={(e, data) => this.handleChange(TIME, SECONDS, data.value)}
                                />
                          </HorizontalDiv>

        break;
      case AMRAP:
        let rounds, reps;
        if (score) {
          rounds = score.rounds;
          reps = score.reps;
        }
        inputComponent = <HorizontalDiv style={{alignItems : 'baseline'}}>
                            <ScoreInput
                              type='tel'
                              placeholder='Rounds'
                              value={rounds}
                              min={0}
                              onChange={(e, data) => this.handleChange(AMRAP, ROUNDS, data.value)}
                              /> &nbsp;+&nbsp;
                              <ScoreInput
                                type='tel'
                                placeholder='Reps'
                                value={reps}
                                min={0}
                                onChange={(e, data) => this.handleChange(AMRAP, REPS, data.value)}
                                />
                          </HorizontalDiv>
        break;
        case MAXREPS:
          let maxReps;
          if (score) {
            maxReps = score.reps;
          }
          inputComponent = <HorizontalDiv style={{alignItems : 'baseline'}}>
                                <ScoreInput
                                  type='tel'
                                  placeholder='Reps'
                                  value={maxReps}
                                  min={0}
                                  onChange={(e, data) => this.handleChange(MAXREPS, REPS, data.value)}
                                  />
                            </HorizontalDiv>
          break;
          case DISTANCE:
            let distance, unit;
            if (score) {
              distance = score.distance;
              unit = score.unit;
            }
            inputComponent = <HorizontalDiv style={{alignItems : 'baseline'}}>
                                  <ScoreInput
                                    type='tel'
                                    placeholder='Distance'
                                    value={distance}
                                    min={0}
                                    onChange={(e, data) => this.handleChange(DISTANCE, DISTANCE, data.value)}
                                    />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Dropdown
                                          options={[
                                            { key: 'meters', text: 'meters', value: 'meters' },
                                            { key: 'km', text: 'km (kilometers)', value: 'km' },
                                            { key: 'ft', text: 'feet', value: 'ft' },
                                            { key: 'miles', text: 'miles', value: 'miles' }
                                          ]}
                                          placeholder='unit'
                                          id='unit'
                                          onChange={(e, data) => {this.handleChange(DISTANCE, UNIT, data.value)}}
                                          value={unit}
                                      />
                              </HorizontalDiv>
            break;
      case CUSTOM:
        let customScore;
        if (score)
          customScore = score.score;
        inputComponent = <ScoreInput
                          placeholder='Score'
                          value={customScore}
                          min={0}
                          onChange={(e, data) => this.handleChange(CUSTOM, SCORE, data.value)}
                          />
        break;
      default:
        inputComponent = null;
    }

    if (inputComponent) {
      return (
        <HorizontalDiv style={{justifyContent: 'start', alignItems: 'baseline'}}>
         <Header style={{color: this.props.themeColor}} as='h4'> Score:&nbsp; </Header>
         {inputComponent}
       </HorizontalDiv>
      )
    }
    else
      return <span></span>;
  }

  onWodSelect = (wod) => {
    const { type, title, description } = wod;
    const { exerciseIndex, updateBuilderExercise } = this.props;
    updateBuilderExercise(exerciseIndex, WODTYPE, type);
    updateBuilderExercise(exerciseIndex, TITLE, title);
    updateBuilderExercise(exerciseIndex, DESCRIPTION, description);
    updateBuilderExercise(exerciseIndex, BENCHMARK, true);
  }

  renderBenchmarkTag = (benchmark) => {
    let color = '#00b5ad'
    return <Header style={{    background: color,
              color: 'white',
              borderRadius: '100%',
              padding: '8px'
            }}
            as='h5'>{benchmark}
        </Header>
  }

  render() {
    const {wodType, title, description, modified, benchmark, notes, coachNotes, programming, updateBuilderExercise, deleteBuilderExercise, exerciseIndex } = this.props;
    const xButton = <ButtonDiv><StyledButton icon
                  style={{background:'transparent'}}
                  type='button'
                  variant='primary'
                  onClick={(e) => {deleteBuilderExercise(exerciseIndex)}}
                >
                <Icon name='x'/>
                </StyledButton></ButtonDiv>
    return (
      <Card style={{background: '#f6f6f6', fontSize: '16px', width:'99%'}}>
        <HorizontalDiv style={{alignItems : 'baseline'}}>
          <span/>
          <HorizontalDiv style={{alignItems: 'baseline'}}>
            {benchmark && this.renderBenchmarkTag(title)}
            {xButton}
          </HorizontalDiv>
        </HorizontalDiv>
          <HorizontalDiv>
            <Select fluid
              placeholder='Type (FOR TIME, AMRAP, EMOM)'
              disabled={benchmark}
              options={benchmarkTypes}
              value={wodType}
              onChange={(e, data) => {updateBuilderExercise(exerciseIndex, WODTYPE, data.value);
                                      updateBuilderExercise(exerciseIndex, SCORE, null);
                                      }}
            >
            </Select>
            <WodModal
              onWodSelect={this.onWodSelect}
            />
          </HorizontalDiv>
          <Form.TextArea
            maxLength='1000'
            rows='8'
            placeholder='Workout description...'
            disabled={benchmark && !modified}
            value={description}
            onChange={(e, data) => {updateBuilderExercise(exerciseIndex, DESCRIPTION, data.value)}}
           />
           {!programming && <HorizontalDiv style={{width: '100%', alignItems: 'baseline', paddingLeft: '3%', paddingRight : '3%'}}>
              {this.renderScoreInput()}
              <div style={{marginLeft: '3%'}}>
                {benchmark && <>
                  <Button size='tiny' type='button' basic circular toggle active={modified} onClick={(e, data) => {updateBuilderExercise(exerciseIndex, MODIFIED, !modified)}}>
                   Modified
                 </Button>
                  <br/><br/>
                </>}
             </div>
          </HorizontalDiv>}
          {(programming || coachNotes) && <Form.TextArea
            maxLength='500'
            placeholder="Coach's Notes..."
            readOnly={programming ? false : true}
            value={coachNotes}
            onChange={(e) => {updateBuilderExercise(exerciseIndex, COACHNOTES, e.target.value)}}
           />}
          {!programming && <Form.TextArea
            maxLength='500'
            placeholder='Notes...'
            value={notes}
            onChange={(e) => {updateBuilderExercise(exerciseIndex, NOTES, e.target.value)}}
           />}
      </Card>
    )
  }
}

export default connect(null, { updateBuilderExercise, deleteBuilderExercise, })(Conditioning);
