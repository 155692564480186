import React, { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { HorizontalDiv } from '../StyledComponents';
import { updateBuilderSetPattern } from '../../actions';

const RepButton = ({exerciseIndex, weightIndex, setIndex, set, repIndex, status, updateBuilderSetPattern}) => {
    let icon = 'minus', color = 'lightgrey';
    switch (status) {
      case '1': // MAKE
        icon = 'check'; color = 'lightgreen';
        break;
      case '0': //BAD MISS
        icon = 'x'; color = 'lightcoral';
        break;
      case '9': //CLOSE MISS
        icon = 'x'; color = 'moccasin';
        break;
      case '5': //N/A
        icon = 'minus'; color = 'lightgrey';
        break;
      default:
        icon = 'minus'; color = 'lightgrey';
    }
//    Debug(exerciseIndex, weightIndex, setIndex, set, repIndex, status);
  return (
    <Button
        style={{backgroundColor: color, margin: '2px'}}
        type='button'
        icon
        size='mini'
        onClick={() => updateBuilderSetPattern(exerciseIndex, weightIndex, setIndex, set, repIndex)}
       >
       <Icon name={icon}
         onClick={() => updateBuilderSetPattern(exerciseIndex, weightIndex, setIndex, set, repIndex)}
       />
     </Button>
  );
}

class Set extends Component {

  render() {
    const { exerciseIndex, weightIndex, setIndex, setPattern, updateBuilderSetPattern } = this.props;
    const repArr = setPattern.split('');
    if (repArr.length > 0) {
      return <><HorizontalDiv>
         {repArr.slice(0,10).map((element, index) => {
          return <RepButton key={index}
                            status={element}
                            exerciseIndex={exerciseIndex}
                            weightIndex={weightIndex}
                            setIndex={setIndex}
                            set={setPattern}
                            repIndex={index}
                            updateBuilderSetPattern={updateBuilderSetPattern}></RepButton>;
          })
        }
      </HorizontalDiv>
      </>
    }
    return <br/>;
  }
 };

 export default connect(null, { updateBuilderSetPattern })(Set);
