import { SIGNUP_USER, SIGNUP_USER_SUCCESS, SIGNUP_USER_FAIL, SIGNUP_USER_PASSWORD_NOT_MATCHED} from './types';
import { firebaseAuth, FirebaseUser } from '../firebase';

export const signupUser = (email, password, confirmPassword, firstname, lastname) => {
  return (dispatch) => {
    if (confirmPasswordMatch(dispatch, password, confirmPassword)) {
      dispatch({type: SIGNUP_USER});
      firebaseAuth.createUserWithEmailAndPassword(email, password)
          .then(function(data) {
             let userId = data.user.uid;
             let user = {
               firstname,
               lastname,
               email
             };
            let firebaseUser = new FirebaseUser(userId);
            const userData = firebaseUser.createUserinDB(user);
            signupUserSuccess(dispatch, userData);
          })
         .catch(function(error) {
           signupUserFail(dispatch, error.message);
           //TODO: adde errors - https://firebase.google.com/docs/reference/js/firebase.auth.Auth#createuserwithemailandpassword
          console.error("Error writing document: ", error);
        });
    }
  }
}

const confirmPasswordMatch = (dispatch, password, confirmPassword) => {
  if (password !== confirmPassword) {
    dispatch({
      type: SIGNUP_USER_PASSWORD_NOT_MATCHED,
      payload: 'Passwords do not match.'
    });
    return false;
  }
  return true;
}

const signupUserSuccess = (dispatch, user) => {
  dispatch({
    type: SIGNUP_USER_SUCCESS,
    payload: user
  })
}

const signupUserFail = (dispatch, error) => {
  dispatch({
    type: SIGNUP_USER_FAIL,
    payload: error
   })
}
