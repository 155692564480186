import { SIGNUP_USER, SIGNUP_USER_SUCCESS, SIGNUP_USER_FAIL, SIGNUP_USER_PASSWORD_NOT_MATCHED} from '../actions/types';
import Debug from '../Debug';

const INITIAL_STATE =  {
  user: null,
  loading: false,
  error: ''
}

const SignUpReducer = (state = INITIAL_STATE, action) => {
    Debug(action);
  switch (action.type) {
    case SIGNUP_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: '' };
    case SIGNUP_USER_FAIL:
      return { ...state, loading: false, error: action.payload };
    case SIGNUP_USER:
      return { ...state, loading: true, error: '' };
    case SIGNUP_USER_PASSWORD_NOT_MATCHED:
     return { ...state, loading: false, error: action.payload }
    default:
      return state;
  }
}

export default SignUpReducer;
