import React, { Component } from 'react';
import { Header, Button, Transition, List } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { addProgramBuilderWorkout } from '../../actions';
import { Droppable } from 'react-beautiful-dnd';
import Workout from './Workout';
import { DayContainer } from '../StyledComponents';
import { firebaseAuth } from '../../firebase';
import { PROGRAMMED } from '../../assets/literals';
import { generateLayoutId } from './shared';


class Day extends Component {

  render() {
    const { editable, week, day, allWorkouts, openScheduler, index, layout, onWorkoutSelected, addProgramBuilderWorkout } = this.props;
    const workouts = day.workouts.map(workout => allWorkouts[workout]);

    const sampleWorkout = {
          name : week.title + ' Day ' + (index+1),
          exercises : [],
          exerciseArr: [],
          notes: '',
          userId: firebaseAuth.currentUser.uid,
          coach : firebaseAuth.currentUser.uid,
          status : PROGRAMMED
    }

    return (
      <Droppable droppableId={day.id}>
        {(provided, snapshot) => (
          <DayContainer
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            <Header style={{padding: '8px'}} as='h5'> Day {index+1} </Header>
            <Transition.Group
              as={List}
              style={{padding : '0'}}
              duration={200}
              divided
              animation="slide down"
            >
            {workouts.map((workout, index) => {
              return <List.Item key={workout.id}> <Workout workout={workout} editable={editable} index={index} day={day} layout={layout} onWorkoutSelected={onWorkoutSelected} openScheduler={openScheduler}/> </List.Item>
            })}
            </Transition.Group>
            {editable && <Button fluid icon='add' onClick={() => {
              const id = generateLayoutId('workout');
              addProgramBuilderWorkout(id, workouts.length , day.id, {...sampleWorkout, id});
              setTimeout(() => { onWorkoutSelected(id) }, 100);
            }} />}
            {provided.placeholder}
          </DayContainer>
       )}
      </Droppable>
    )
  }
}

export default connect(null, { addProgramBuilderWorkout })(Day);
