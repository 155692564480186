import React from 'react';
import { Popup, Button, Icon } from 'semantic-ui-react';
import { ButtonDiv } from '../StyledComponents';

export const InfoTooltip = ({content}) => {
  return (
    <ButtonDiv><Popup basic
            content={content}
            trigger={<Button icon
                      style={{background:'transparent', width: '30px'}}
                      className='ui small button'
                      type='button'
                      variant='primary'
                      onClick={(e) => {}}
                      >
                        <Icon name='info circle'/>
                      </Button>}
      />
     </ButtonDiv>
   )
 }
