import React, { Component } from 'react';
import Calendar from 'react-calendar';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Container, Icon, Header, Radio } from 'semantic-ui-react';
import logo from '../../assets/logo_color_transparent.png';
import Debug from '../../Debug';
import HeaderMain from './HeaderMain';
import LogView from '../log/LogView';
import { Loading } from '../common/Loading'
import { LogModal } from '../log/LogModal';
import WorkoutCopier from '../log/WorkoutCopier';
import AthleteSelect from '../menus/AthleteSelect';
import { ExportModal } from '../common/ExportModal';
import { StyledContainer, HorizontalDiv } from '../StyledComponents';
import { retrieveWorkoutHistory, startWorkoutBuilder, selectWorkout, resetHistory, setHistory } from '../../actions';
import { PROGRAMMED, COMPLETE, INPROGRESS, COACH, COLOR_SECONDARY, COLOR_PRIMARY } from '../../assets/literals'
import { dateToMonthString, getTeamRole, hasPro } from '../../commonMethods';
import { firebaseAuth } from '../../firebase';
import './calendar.css';
import '../../assets/nav.css';

/*
JSON.parse('{"date":{"seconds":1607576400,"nanoseconds":0},"exerciseArr":[{"complexId":null,"exercise":"FrontSquat","exerciseNotes":"","weightArr":[{"reps":5,"setArr":[{"pattern":"11111"},{"pattern":"11111"},{"pattern":"11111"}],"sets":3,"unit":"kg","warmup":true,"weight":60},{"reps":5,"setArr":[{"pattern":"11111"}],"sets":1,"unit":"kg","warmup":true,"weight":80},{"reps":5,"setArr":[{"pattern":"11111"},{"pattern":"11111"},{"pattern":"11111"}],"sets":3,"unit":"kg","warmup":false,"weight":100}]},{"complexId":null,"exercise":"DeadHngCl","exerciseNotes":"","weightArr":[{"reps":5,"setArr":[{"pattern":"11111"},{"pattern":"11111"},{"pattern":"11111"}],"sets":3,"unit":"kg","warmup":false,"weight":50},{"reps":5,"setArr":[{"pattern":"11111"},{"pattern":"11111"},{"pattern":"11111"}],"sets":3,"unit":"kg","warmup":false,"weight":60}]},{"complexId":"li9v1d3","exercise":"SplitJk","exerciseIndex":2,"exerciseNotes":"","weightArr":[{"reps":3,"setArr":[{"pattern":"111"},{"pattern":"111"},{"pattern":"111"}],"sets":3,"unit":"kg","warmup":false,"weight":50},{"reps":3,"setArr":[{"pattern":"111"},{"pattern":"111"},{"pattern":"111"}],"sets":3,"unit":"kg","warmup":false,"weight":60}]},{"complexId":"li9v1d3","exercise":"PushJk","exerciseIndex":3,"exerciseNotes":"","weightArr":[{"reps":3,"setArr":[{"pattern":"111"},{"pattern":"111"},{"pattern":"111"}],"sets":3,"unit":"kg","warmup":false,"weight":50},{"reps":3,"setArr":[{"pattern":"111"},{"pattern":"111"},{"pattern":"111"}],"sets":3,"unit":"kg","warmup":false,"weight":60}]},{"complexId":null,"exercise":"SnPullPandaFromBlwKn","exerciseNotes":"","weightArr":[{"reps":5,"setArr":[{"pattern":"11111"},{"pattern":"11111"},{"pattern":"11111"}],"sets":3,"unit":"kg","warmup":false,"weight":90}]}],"notes":"","userId":"UWl7sQdZ37W4og314PdcZfefeh22","id":"rSZCGM2sZYJGKEoVsgf1"}'
)
*/
const INITIAL_STATE = {
  date: new Date(),
  open: false,
  daysWorkouts: []
}

class TrainingCalendar extends Component {

  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    let { athlete } = this.props.historyLog;
    const uid = athlete ? athlete : firebaseAuth.currentUser.uid
    this.loadInitialWorkouts(uid, false);
  }

  componentDidUpdate() {
    if (this.props.log.didInvalidate) {
        this.props.resetHistory();
        let { athlete } = this.props.historyLog;
        const uid = athlete ? athlete : firebaseAuth.currentUser.uid
        this.loadInitialWorkouts(uid, true);
        this.setState({ open : false });
     }
  }

  loadInitialWorkouts = (uid, forceLoad) => {
    const { workoutBuilder } = this.props.log;
    let date = (workoutBuilder && workoutBuilder.date) ? new Date(workoutBuilder.date) : this.state.date;
    let start = this.getStartOfMonth(date);
    let end = this.getEndOfMonth(date);
    let prevStart = new Date(start);
    prevStart.setDate(0);
    prevStart = this.getStartOfMonth(prevStart);
    let prevEnd = this.getEndOfMonth(prevStart);
    this.loadMonthWorkouts(start, end, uid, forceLoad);
    this.loadMonthWorkouts(prevStart, prevEnd, uid, forceLoad);
  }

  loadMonthWorkouts =(start, end, uid, forceLoad) => {
    console.log('loading months...', this.props)
    try {
      if (forceLoad || !this.props.historyLog.loadedMonths.includes(dateToMonthString(start)) || this.props.log.didInvalidate)
        this.props.retrieveWorkoutHistory(start, end, uid, this.props.historyLog.trackToggle);
    }
    catch(error) {
      console.log(error.message)
    }
 }

  getStartOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  getEndOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 1);
  }

  onChange = (date) => {
    Debug('changing' + date);
    this.setState({ date });
  }

  onClickDay = (date) => {
    Debug('click day');
    this.setState({open: true, daysWorkouts: this.getDaysWorkouts(date)})
  }

  onMonthChange = (date) => {
    console.log('month change..')
    const { athlete } = this.props.historyLog;
    const uid = athlete ? athlete : firebaseAuth.currentUser.uid
    //get first and last day of month and retrieve workouts
    let start = this.getStartOfMonth(date)
    let end = this.getEndOfMonth(date);
    this.setState({ date: start });
    this.loadMonthWorkouts(start, end, uid);
  }

  getDaysWorkouts = date => {
    const { workouts } = this.props.historyLog;
    let daysWorkouts = [];
    if (workouts.length>0) {
        workouts.forEach(workout => {
          if (this.datesAreOnSameDay(date, workout.date.toDate()))
            daysWorkouts.push(workout);
        });
      }

    return daysWorkouts;
  }

  datesAreOnSameDay = (first, second) => {
    return first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();
  }

  getStatusIcon = (status) => {
    let icon = 'circle';
    switch(status) {
        case INPROGRESS:
          icon = 'ellipsis horizontal'
          break;
        case PROGRAMMED:
          icon = 'calendar outline'
          break;
        default:
          icon = 'circle'
      }
      return icon;
  }


  getLog = (date) => {
    let daysWorkouts = this.getDaysWorkouts(date);
        if (daysWorkouts.length  > 0) {
          const icon = this.getStatusIcon(daysWorkouts[0].status || COMPLETE);

          return <Container style={{fontSize: '14px', overflow: 'auto', maxHeight: '300px'}}>
                    {this.props.config.isMobile ? <Icon name={icon} size='small' color={daysWorkouts[0].highlight ? 'yellow' : 'black'} /> : this.renderSummary(daysWorkouts, false, false, false, false)}
                  </Container>
        }
        return null;
  }

  renderSummary = (workouts, withLink, showName, showDate, showMenu) => {
    const { config, historyLog } = this.props;
    const { trackToggle } = historyLog;
    const { team } = config;
    let teamRole = getTeamRole(firebaseAuth.currentUser.uid, team?.members);

    if (workouts.length > 0) {
      return workouts.map((workout, index) => {
        if (withLink) {
          return <Link key={index} to={{pathname :'/', state : { fromCalendar : true  }}} className="nav_link" onClick={() => {this.props.selectWorkout(workout)}}>
                    {showDate && <Header style={{color: COLOR_SECONDARY}} as='h4'> {workout.date.toDate().toLocaleDateString()} </Header>}
                    <LogView
                      as='h6'
                      workout={workout}
                      headerSize='h4'
                      showName={showName}
                      showMenu={showMenu}
                      teamRole={teamRole}
                      showStartButton={workout.userId === firebaseAuth.currentUser.uid}
                      fromCalendar
                      athlete={historyLog.athlete}
                      trackMode={trackToggle}
                    />
                    {!trackToggle && showMenu && teamRole === COACH && <WorkoutCopier
                      workout={workout}
                      config={config}
                    />}
                    <br/>
                  </Link>
        }
        else {
          return <div key={index}>
                  {showDate && <Header> {workout.date.toDate().toLocaleDateString()} </Header>}
                  <LogView
                    as='h6'
                    workout={workout}
                    headerSize='h4'
                    showName={showName}
                    showMenu={showMenu}
                    teamRole={teamRole}
                    showStartButton={showMenu && workout.userId === firebaseAuth.currentUser.uid}
                    fromCalendar
                    athlete={historyLog.athlete}
                    trackMode={trackToggle}
                  />
                  {!trackToggle && showMenu && teamRole===COACH && <WorkoutCopier
                    workout={workout}
                    config={config}
                  />}
                  <br/><br/><br/>
                </div>
        }
      }

      )
    }
    else {
      return null;
    }
  }

  renderHistoryList = () => {
    const { date } = this.state;
    const { workouts } = this.props.historyLog;
    const result = workouts.filter(workout =>
      workout.date.toDate().getMonth() === date.getMonth()
    );

      if (result.length > 0) {
        return <div>
                    {this.renderSummary(result, true, true, true, false)}
                    <br/>
              </div>
      }
      return null;
  }

  onAthleteSelect = athlete => {
    this.loadInitialWorkouts(athlete, true);
  }

  render() {
      const { date, open } = this.state;
      const { config, historyLog, log, startWorkoutBuilder, resetHistory, setHistory} = this.props;
      const { trackToggle } = historyLog;
      const { user, subscriptions, team, isMobile, customExercises } = config;
      let teamRole = getTeamRole(firebaseAuth.currentUser.uid, team?.members);

      return (
        <>
        <HeaderMain activeItem='Calendar'/>
        <StyledContainer
          style={isMobile ? {marginBottom: '40px'} : {marginBottom: '40px', padding: '3%', paddingTop: 0}}
        >
          <Loading active={historyLog.loading}/>
          {team && team.members &&
            <><div style={{width: '100%', marginBottom: '5px', marginTop: '10px', marginRight: '25px'}}>
              <HorizontalDiv style={{ float: 'right',  fontWeight: 'bold'}}>
                <span style={{color: trackToggle ? 'lightslategrey' : 'black'}}>Athletes&nbsp;&nbsp;&nbsp;</span>
                <Radio slider
                  checked={trackToggle}
                  onChange={(e, data) => {
                    resetHistory();
                    setHistory('trackToggle', !trackToggle);
                  }}
                  />
                <span style={{color: trackToggle ? 'black' : 'lightslategrey'}}>&nbsp;&nbsp;&nbsp;Tracks</span>
              </HorizontalDiv></div>
              <AthleteSelect
                showTracks={trackToggle}
                onAthleteSelect={this.onAthleteSelect}
              /></>}
          {isMobile && <div style={{width:'100%',backgroundColor: COLOR_PRIMARY}}><img style={{width: '99%', maxWidth: '200px', padding:'3%'}} src={logo} alt='||--||' /></div>}
          <Calendar
              className='customcalendar'
              onChange={this.onChange}
              onClickDay={this.onClickDay}
              value={date}
              next2Label={null}
              prev2Label={null}
              minDetail='year'
              tileContent={({ date, view }) => view === 'month' ? this.getLog(date) : null}
              defaultActiveStartDate={log.workoutBuilder.date ? new Date(log.workoutBuilder.date) : new Date()}
              onActiveStartDateChange={({ activeStartDate, value, view }) => view === 'month' ? this.onMonthChange(activeStartDate) : null}
            />
          <LogModal
                  trackMode={trackToggle}
                  renderSummary={this.renderSummary}
                  date={date}
                  daysWorkouts={this.state.daysWorkouts}
                  open={open}
                  onClose={() => this.setState({open: false})}
                  startWorkoutBuilder={startWorkoutBuilder}
                  athlete={historyLog.athlete}
                  coach={firebaseAuth.currentUser.uid}
                  teamRole={teamRole}
          />
          <br/>
          {isMobile && <div style={{width: '100%', marginBottom: '50px'}}>
          <br/>
          {date && this.renderHistoryList()}
          <br/>
          </div>}
          <div style={{ width:'100%'}}>
            {config && !isMobile &&
            <ExportModal
                trigger={<Button icon
                           style={{background:'transparent', float :'right'}}
                           type='button'
                           size='large'
                           variant='primary'
                          >
                            Export Data &nbsp;
                            <Icon name='download'/>
                          </Button>}
               customExercises={customExercises}
               athlete={historyLog.athlete || firebaseAuth.currentUser.uid}
               hasPro={hasPro(subscriptions)}
            />
            }
          </div>
        </StyledContainer>
        </>
      );
    }
  }

  const mapStateToProps = state => {
    const {log, historyLog, config } = state
    return {
             log,
             historyLog,
             config
           }
  };

export default connect(mapStateToProps, {retrieveWorkoutHistory, startWorkoutBuilder, selectWorkout, setHistory, resetHistory})(TrainingCalendar);
