import React from 'react';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { StyledContainer, SizingContainer } from '../StyledComponents';
import logo from '../../assets/black_cover_logo.png'
import { InstallPWA } from './InstallPWA';
import firebase from "firebase/app";
import "firebase/auth";

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
//  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID
  ]
};

class SignIn extends React.Component {
  render() {
    return (
      <StyledContainer style={{color: 'darkgrey'}}>
        <SizingContainer style={{height: '100vh', marginTop: '10%'}}>
        <a href='https://traintensity.com'>
          <img style={{width: '99%', maxWidth: '400px'}} src={logo} alt='||--||' />
        </a>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
        <br/>
        <p> By clicking to sign up you are agreeing to our <a href='https://traintensity.com/privacy-policy/'> Privacy Policy </a> and <a href='https://traintensity.com/terms-of-use'> Terms of Use </a> </p>
        <br/>
        <InstallPWA/>
      </SizingContainer>
      </StyledContainer>
    );
  }
}

export default SignIn;
