import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Button} from 'semantic-ui-react';
import Debug from '../../Debug';
import Set from './Set';
import SetModal from './SetModal';
import { SetToolTip } from './SetToolTip';
import { HorizontalDiv, StyledContainer, ButtonDiv, StyledButton } from '../StyledComponents';
import { deleteBuilderSet, addBuilderSet, updateBuilderSet } from '../../actions';

class SetComplexContainer extends Component {

    renderComplexSetCloseButton = (complexArr, weightIndex, setIndex) => {
        return <ButtonDiv><StyledButton icon
                    style={{background:'transparent'}}
                    className='ui  button'
                    type='button'
                    variant='primary'
                    onClick={(e) => { complexArr.forEach(exerciseObj => {
                      this.props.deleteBuilderSet(exerciseObj.exerciseIndex, weightIndex,setIndex)})}}
                  >
                  <Icon name='trash'/>
                  </StyledButton></ButtonDiv>
    }

    createDefaultSetObj = (setArr) => {
      let reps = setArr[0].pattern.length;
      let pattern = '';
      for (var i=0; i< reps; i++) {
          pattern+='1'
      }
      return {pattern}
    }

    renderComplexSetAddButton = (complexArr, weightIndex) => {
      return <ButtonDiv><StyledButton
                  style={{background:'transparent'}}
                  icon
                  className='ui small button'
                  circular
                  type='button'
                  variant='primary'
                  onClick={(e) => {
                    complexArr.forEach(exerciseObj => {
                      const setObj = this.createDefaultSetObj(exerciseObj.weightArr[weightIndex].setArr);
                      this.props.addBuilderSet(exerciseObj.exerciseIndex, weightIndex, setObj)
                    });
                  }}
                >
                <Icon name='add'/>
                </StyledButton></ButtonDiv>
    }

  renderComplex = (weightIndex, sets, complexArr) => {
      let setA = complexArr[0].weightArr[weightIndex].setArr;
      if (setA && setA.length) {
      return setA.slice(0,20).map((set, setIndex) => {
        return <HorizontalDiv key={'set-' + setIndex}
                style={{borderBottom: 'inset', width:'100%', display: 'flex', justifyContent: 'space-between'}}
                >
                <HorizontalDiv>
                    <SetModal
                      trigger={<Button type='button' compact size='tiny' style={{width: '6em'}}>Set {setIndex+1}&nbsp;
                                  {set.rpe && " @ " + set.rpe}
                                </Button>}
                      complexArr={complexArr}
                      weightIndex={weightIndex}
                      setIndex={setIndex}
                      updateBuilderSet={this.props.updateBuilderSet}
                    />
                    <StyledContainer style={{alignItems: 'start'}}>
                    {complexArr.map((exercise, i) => {
                      return <HorizontalDiv
                                key={'set-' + setIndex + '-' + i}>
                                {i>0  ? <Icon color='grey' name='add'/> : <Icon/>}
                              <Set exerciseIndex={exercise.exerciseIndex} weightIndex={weightIndex} setIndex={setIndex} setPattern={exercise.weightArr[weightIndex].setArr[setIndex].pattern} />
                            </HorizontalDiv>
                            })
                           }
                   </StyledContainer>
              </HorizontalDiv>
           {setIndex === 0 ?  <SetToolTip/> : this.renderComplexSetCloseButton(complexArr, weightIndex, setIndex)}
         </HorizontalDiv>
      });
    }
      else
        return <br/>
  }

  render() {
    const { weightIndex,sets,complexArr } = this.props;
    Debug(this.props);
    return (
       <StyledContainer style={{alignItems: 'start'}}>
       {this.renderComplex(weightIndex, sets, complexArr)}
       {sets>0 && this.renderComplexSetAddButton(complexArr, weightIndex)}
      </StyledContainer>
    );
  }
}

export default connect(null, { addBuilderSet, deleteBuilderSet, updateBuilderSet })(SetComplexContainer);
