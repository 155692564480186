import React, { Component } from 'react';
import { Header} from 'semantic-ui-react';
import Debug from '../../Debug';
import { getBenchmarkType, getScore } from '../../commonMethods';
import { Paragraph } from '../StyledComponents';

class WodSummary extends Component {

  static defaultProps = {

  }

  renderNotes = (notes) => {
    if (notes)
      return <Paragraph>{notes}</Paragraph>
    else
      return null;
  }

  render() {
    Debug(this.props);
    const {headerSize, header, wodType, title, description, notes, score} = this.props;
    return (
        <div style={{paddingBottom:'3%'}}>
          {header && <Header style={{marginBottom: '5px'}} as={headerSize || 'h3'} dividing>
              {title}
          </Header>}
          {getBenchmarkType(wodType)}
          <Paragraph style={{marginBottom: '3px'}}>
              {description}
          </Paragraph>
          <Paragraph style={{fontWeight : 'bold'}}> {getScore(wodType, score)} </Paragraph>
          {this.renderNotes(notes)}
        </div>
    );
  }
}

export default WodSummary;
