import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import { withRouter } from "react-router-dom";

const MenuItem = withRouter((props) => (
  <Menu.Item name={props.name} active={props.active} onClick={() => { props.history.push(props.route)}}/>
));

class HeaderTab extends Component {

  render () {
    const { activeItem } = this.props
    return (

  <Menu
      style={{marginTop:'51px'}}
      pointing secondary>
      <MenuItem
        name='Home'
        route='track'
        active={activeItem === 'Track'}
      />
      <MenuItem
      name='Progress'
      route='progress'
      active={activeItem === 'Progress'}
      />
      <MenuItem
      name='Calendar'
      route='calendar'
      active={activeItem === 'Calendar'}
      />
      <MenuItem
      name='Programs'
      route='programs'
      active={activeItem === 'Programs'}
      />
      <MenuItem
      name='Exercises'
      route='exercises'
      active={activeItem === 'Exercises'}
      />
      <MenuItem
      name='Profile'
      route='profile'
      active={activeItem === 'Profile'}
      />
  </Menu>
    );
  }
}

export default HeaderTab;
