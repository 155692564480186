import { Placeholder, Dimmer, Loader } from 'semantic-ui-react'

export const Loading = ({ active} ) => (
    <Dimmer active={active} inverted inline='centered'>
      <Loader/>
    </Dimmer>
)

export const DummyParagraph =({text, height }) => {
      return (
       <div style={{height: height + "px", width: "100%"}}>
        <Placeholder fluid>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
        </Placeholder>
      </div>
      )
  }
