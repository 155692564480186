import React, { Component } from 'react';
import moment from 'moment';
import { Statistic, Header, Modal, Button, TransitionablePortal, Icon, Dropdown, Divider } from 'semantic-ui-react';
import { StatCard } from '../charts/StatCard';
import { HorizontalDiv, Paragraph } from '../StyledComponents';
import VerticalBarChart  from '../charts/VerticalBarChart';
import { UpgradeModal } from '../common/UpgradeModal';
import { retrieveWorkouts, hasPro, getChartData, decimalToPercent } from '../../commonMethods';
import categories from '../../assets/exercise_category_level_2';
 import { REPS, SETS, VOLUME } from '../../assets/literals';

const INITIAL_STATE = {
  open : false,
  totalPercentDiff: 0,
  totalPercentPositive: true,
  metric: REPS
}

const WeekStat = ({title, name, number, percent}) => {
  console.log('weekstat', name, number, percent);
  return (
    <div>
    {title && <Header as='h4'>{title}</Header>}
    <Statistic size='mini' horizontal label={name} value={Math.abs(number)}></Statistic>
    <br/>
    {percent &&
      <>
      {percent}%
        <Icon name={number>=0 ? 'caret up' : 'caret down'}
          color={number>=0 ? 'green' : 'red'} />
      </>
    }
    </div>
  )
}

class PerformanceReview extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  retrieveWeeklyData = () => {
    const { athlete, config, date } = this.props;
    const start = moment(date).startOf('isoweek').toDate();
    const end = moment(date).endOf('isoweek').toDate();
    const prevStart = moment(date).subtract(7, 'd').startOf('isoweek').toDate();
    const prevEnd = moment(date).subtract(7, 'd').endOf('isoweek').toDate();
    //console.log('Sttart:', start, end, prevStart, prevEnd);

    let workouts = [], prevWorkouts = [];
    this.setState({ loading: true, start, end, prevStart, prevEnd })
    retrieveWorkouts(start, end, athlete)
     .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
         workouts.push(doc.data());
        });
        }).catch((error) => {
        console.log("Error getting document:", error);
        alert("Error retrieving workouts");
        this.setState({loading: false});
        })
        .finally(() => {
          this.setState({workoutData : getChartData(workouts, athlete, config), loading: false});
      });

      retrieveWorkouts(prevStart, prevEnd, athlete)
       .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
           prevWorkouts.push(doc.data());
          });
          }).catch((error) => {
          console.log("Error getting document:", error);
          alert("Error retrieving workouts");
          this.setState({loading: false});
          })
          .finally(() => {
            this.setState({prevWorkoutData : getChartData(prevWorkouts, athlete, config), loading: false});
        });
  }

  componentDidMount() {
      this.retrieveWeeklyData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { date } = this.props;
    if (!prevProps || prevProps.date !== date)
      this.retrieveWeeklyData();
  }

  getPerformanceData = (current, prev, name) => {
    if (!current || !prev)
      return {};

    return {
      name: name || "N/A",
      currentvolume: current.volume,
      previousvolume: prev.volume,
      currentreps: current.reps,
      previousreps: prev.reps,
      currentsets: current.sets,
      previoussets: prev.sets,
      volumeDiff : current.volume - prev.volume,
      percentvolumeDiff : decimalToPercent(Math.abs(current.volume - prev.volume) / prev.volume),
      repsDiff : current.reps - prev.reps,
      percentrepsDiff : decimalToPercent(Math.abs(current.reps - prev.reps) / prev.reps),
      setsDiff : current.sets - prev.sets,
      percentsetsDiff : decimalToPercent(Math.abs(current.sets - prev.sets) / prev.sets),
      intensityDiff : current.intensity - prev.intensity,
    }
  }

  renderStats = (performanceData, defaultUnit, type, hideParagraph) => {
    const { metric } = this.state;
    const { currentvolume, currentreps, currentsets,
      volumeDiff, percentvolumeDiff, repsDiff, percentrepsDiff, setsDiff, percentsetsDiff,
        intensityDiff } = performanceData;
    const currentVol = metric === 'volume' ? currentvolume : (metric === 'sets' ? currentsets : currentreps  )
    const volDiff = metric === 'volume' ? volumeDiff : (metric === 'sets' ? setsDiff : repsDiff  );
    const percentVolDiff  = metric === 'volume' ? percentvolumeDiff : (metric === 'sets' ? percentsetsDiff : percentrepsDiff  );

    if (!isNaN(percentVolDiff)) {
      return <div key={type}>
      {type && <Header as='h4' style={{textAlign: 'center'}}> {type} </Header>}
      <HorizontalDiv style={{paddingLeft: '10%', paddingRight: '10%'}}>
        <StatCard
          icon='weight'
          value={Math.abs(percentVolDiff) + '%'}
          label={metric}
          caret={volDiff>=0}
          size='mini'
        />
        <StatCard
          icon='power cord'
          value={Math.abs(decimalToPercent(intensityDiff)) + '%'}
          label='Intensity'
          caret={intensityDiff>=0}
          size='mini'
        />
      </HorizontalDiv>
      <br/>
      <VerticalBarChart
        data = {[performanceData]}
        metric={metric}
      />
      <br/>
      {!hideParagraph &&
        <><Paragraph style={{color:'darkgrey'}}>
        Your total {type && type} volume was {currentVol} {metric === 'volume' ? defaultUnit : metric},
        which is {volDiff>=0 ? 'up' : 'down'} {percentVolDiff}%
        compared to the previous week.
        You performed {Math.abs(setsDiff)} {setsDiff>=0 ? 'more' : 'less'} sets and {Math.abs(repsDiff)} {repsDiff>=0 ? 'more' : 'less'} reps.
        Intensity was {intensityDiff>=0 ? 'up' : 'down'} {intensityDiff>=0.1 && 'significantly at'} {decimalToPercent(intensityDiff)}%.
      </Paragraph>
      <Divider/>
      </>}
    </div>
    }
    else
      return null;
  }

  render() {
    const { defaultOpen, config } = this.props;
    const { user, subscriptions } = config;
    const {defaultUnit } = user;
    const { open, workoutData, prevWorkoutData, start, end } = this.state;
    let currentTotalData = workoutData?.weeklyTotalData[0],
      prevTotalData = prevWorkoutData?.weeklyTotalData[0];
    console.log('currentdata', currentTotalData, prevTotalData)
    const showData = currentTotalData && currentTotalData?.volume !== 0 && prevTotalData && prevTotalData?.volume !== 0;
    console.log('curTol', showData, currentTotalData, prevTotalData)
    console.log("WORKK", this.getPerformanceData(currentTotalData, prevTotalData), workoutData, prevWorkoutData);
    return (
      <TransitionablePortal
        defaultOpen={defaultOpen}
        trigger={showData && <Button fluid basic onClick={() => this.setState({ open: true })}>Week In Review<Icon name='caret right'></Icon>
                  <br/><br/>
                  {this.renderStats(this.getPerformanceData(currentTotalData, prevTotalData, "Total"), defaultUnit, null, true)}
        </Button>}
        onMount={() => this.setState({ open: true })}
        onClose={() => this.setState({ open: false })}
        onOpen={() => this.setState({ open: true })}
        transition = {{
            animation: 'scale',
            duration: 800,
          }}
      >
      <Modal
        size='tiny'
        onClose={() => this.setState({ open: false })}
        onOpen={() => this.setState({ open: true })}
        open={open}
        closeIcon
      >
        <Modal.Header style={{textAlign: 'center'}}>Week in Review!</Modal.Header>
        <Modal.Content>
          <Modal.Description>
          <HorizontalDiv style={{fontSize: 'medium', fontWeight: 'bold', alignItems : 'baseline'}} >
          {start ? <p>{start && start.toLocaleDateString()} - {end && end.toLocaleDateString()}</p> : <p/>}
            <Dropdown  upward floating
                options={[
                      {
                        key: REPS,
                        text: 'Reps',
                        value: REPS
                      },
                      {
                        key: SETS,
                        text: 'Sets',
                        value: SETS
                      },
                      {
                        key: VOLUME,
                        text: 'Volume (' + defaultUnit + ')',
                        value: VOLUME
                      }
                    ]}
                  value={this.state.metric}
                  onChange={(e, data) => {
                    this.setState({ metric : data.value });
                  }
                }
              />
            </HorizontalDiv>
            <br/>
            {this.renderStats(this.getPerformanceData(currentTotalData, prevTotalData, "Total"), defaultUnit)}
            {showData && <Header as='h3' style={{textAlign : 'center'}}> Volume and Intensity by Category </Header>}
            {hasPro(subscriptions) ?
              <div>
                {categories.map(category => {
                  const { key, text } = category;
                  const currentData = workoutData?.weeklyTypeData?.[key]?.[0];
                  const prevData = prevWorkoutData?.weeklyTypeData?.[key]?.[0];
                  return this.renderStats(this.getPerformanceData(currentData, prevData, category), defaultUnit, text);
                  }
                )}
              </div>
            :
            <div style={{textAlign: 'center'}}>
              <Header style={{marginTop: '30px'}} as='h5'>Upgrade to unlock this feature!</Header>
              <UpgradeModal
                defaultOpen={false}
                onClose={() => this.setState({ open: false })}
              />
            </div>
          }
          {!showData && <Header as='h5' style={{textAlign: 'center'}}> No volume has been logged for this week.</Header>}
          </Modal.Description>
          <br/>
        </Modal.Content>
      </Modal>
      </TransitionablePortal>
    );
  }
}

export default PerformanceReview;
