import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Debug from '../../Debug';
import HeaderMain from './HeaderMain';
import logo from '../../assets/barbell_logo.png'
import classification from '../../assets/exercise-classification';
import { firebaseDb, firebaseAuth } from "../../firebase";
import firebase from "firebase";
import { saveWorkout } from '../../actions';

class Home extends Component {

  handleClick = async (e) => {
    e.preventDefault();
      const now = new Date();
      const start = new Date('2021-08-10');
//      const end = new Date('2020-12-31');
//    const data = await this.retrieveDocument(exerciseCategoryPath);
  //  Debug(data);
  //  this.collectionGroupQuery(start, end);
  //  this.getExerciseClassification();
    this.retrieveWorkouts(start, now);
  //    this.retrieveUsers();
    //  this.csvExport(start, now);
  }

  csvExport = (start, now) => {
  //  let csvContent = "data:text/csv;charset=utf-8,";

  }

  getExerciseClassification = () => {
      //const classificationFile = new File("../../../classification.txt");
      Debug(classification);
    //  classification.forEach(element => Debug(element))
  }

//TODO import this function from general firebase functions?
  async retrieveDocument(ref) {
    return await firebaseDb.doc(ref).get().then((doc) => {
        return doc.data();
      }).catch((error) => {
          Debug("Error getting document:", error);
        });
  }

    async collectionGroupQuery(start, end) {

      return await firebaseDb.collectionGroup('workouts')
          .where('date', '>=', start)
          .where('date', '<=', end)
        .get().then((querySnapshot) => {
          Debug(querySnapshot);
          querySnapshot.forEach(doc => {
            Debug(doc.ref)
            Debug(doc.id, doc.data());
            Debug(JSON.stringify(doc.data()))
          })
        }).catch((error) => {
            Debug("Error getting document:", error);
          });
      }

    async retrieveWorkouts(start, end) {
      Debug(firebaseAuth);
      return await firebaseDb.collection('workouts')
          .where('userId', '==', firebaseAuth.currentUser.uid)
//          .where('date', '>=', start)
  //        .where('date', '<=', end)
        .where('status', '==', 'complete')
        .get().then((querySnapshot) => {
          Debug(querySnapshot);
          querySnapshot.forEach(doc => {
            Debug(doc.ref)
            Debug(doc.id, doc.data());
            Debug(JSON.stringify(doc.data()));
          })
        }).catch((error) => {
            Debug("Error getting document:", error);
          });
    }

    async retrieveUsers() {
      console.log('retrieving...')
      return await firebaseDb.collection('users')
          .get().then((querySnapshot) => {
            //  const workouts = querySnapshot.docs.reduce((res, item) => ({...res, [item.id]: item.data()}), {});
            querySnapshot.forEach((user, index) => {
              console.log('b; ', user.id, user.data())
              if (user.data().stripeId)
                this.deleteStripeFields(user.id)
              else
                console.log('no stripe data', user.id)
            }
            );
          }).catch((error) => {
              Debug("Error getting document:", error);
              alert('An error has occured: ' + error.message)
            });
    }

  async deleteStripeFields(userId) {
    return await firebaseDb.collection('users').doc(userId).update({
        "stripeId" : firebase.firestore.FieldValue.delete(),
        "stripeLink" : firebase.firestore.FieldValue.delete(),
    }).then(() => {
      console.log('deleted...', userId)
    }).catch(error => {
      console.log('error...', error.message);
    });
  }

  render() {
    return(
        <div>
          <HeaderMain
            itemLeft={<img style={{maxWidth:'100px', maxHeight: '50px'}} src={logo} alt='logo'/>}
            text='Traintensity'
            itemRight={<Button style={{background: 'transparent'}}>New</Button>}
            activeItem='Home'
          />
          <Button onClick={this.handleClick}>
             Test Query
            </Button>
        </div>
    );
  }

}

const mapStateToProps = state => {
  Debug(state);
  return {
           log: state.log,
           config : state.config
         }
  };

export default connect(mapStateToProps, {saveWorkout})(Home);
