import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Header, Input, Button, Transition, List } from 'semantic-ui-react';
import EditCopyDeleteMenu from '../menus/EditCopyDeleteMenu';
import { StyledContainer, BlockContainer, HorizontalDiv } from '../StyledComponents';
import {  updateProgramBuilderLayout, addProgramBuilderWeek, addProgramBuilderDay, deleteProgramBuilderBlock } from '../../actions';
import Week from './Week';
import { BLOCKS } from '../../assets/literals';
import { generateLayoutId } from './shared';

class Block extends Component {
  render() {
    const { editable, block, index, layout, updateProgramBuilderLayout, addProgramBuilderWeek, addProgramBuilderDay, deleteProgramBuilderBlock, frequency, onWorkoutSelected, openScheduler } = this.props;
    const weeks = block.weeks.map(weekId => layout.weeks[weekId]);
    const allWorkouts = layout.workouts;
    const allDays= layout.days;
    const { id, title } = block;
    return (
          <StyledContainer>
            <HorizontalDiv style={{width: '100%', display: 'flex', justifyContent:'space-between'}}>
              <Button style={{backgroundColor: 'transparent'}} disabled />
              {editable ?
                <Input style={{fontSize : '24px', fontWeight : 'bold', padding : '1.5%'}} transparent value={title} onChange={(e, data) => {updateProgramBuilderLayout('blocks', id, 'title', data.value)}} />
                : <Header as='h3'>{title}</Header>
              }
              <EditCopyDeleteMenu
                  style={{height: '20px', padding: '10px'}}
                  showDelete={editable}
                  onDelete={() => deleteProgramBuilderBlock(id, layout)}
                  deleteMessage='Are you sure you want to delete this block? All weeks will be deleted.'
                  showSchedule
                  onSchedule={()=> openScheduler(BLOCKS, id, index)}
              />
            </HorizontalDiv>
            <BlockContainer>
              <Transition.Group
                as={List}
                duration={200}
                divided
                animation="slide down"
              >
                {weeks.map((week, index) => <List.Item key={week.id}><Week  blockId={id} editable={editable} index={index} layout={layout} week={week} allDays={allDays} allWorkouts={allWorkouts} onWorkoutSelected={onWorkoutSelected} openScheduler={openScheduler}/></List.Item>)}
              </Transition.Group>
            </BlockContainer>
            {editable && <Button
                fluid
                onClick={() => {
                  const currentNumWeeks = _.size(weeks);
                  const currentNumDays = _.size(allDays);
                  const weekId = generateLayoutId('week');
                  addProgramBuilderWeek(weekId, currentNumWeeks, id);
                  for (var j=0; j< frequency; j++) {
                    addProgramBuilderDay(generateLayoutId('day'), currentNumDays+j, weekId)
                  }
                }}
            >
              Add Week
            </Button>}
            <br/>
          </StyledContainer>
    )
  }
}

export default connect(null, { updateProgramBuilderLayout, addProgramBuilderWeek, addProgramBuilderDay, deleteProgramBuilderBlock })(Block);
