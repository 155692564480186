import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { getDateTickFormat, getCategoryL2Obj } from '../../commonMethods';
import { DAILY } from '../../assets/literals';

export default class AverageWeightChart extends PureComponent {

  render() {
    const {data, category} = this.props;
    const categoryObj = getCategoryL2Obj(category);
    console.log('c:', category, categoryObj)
    const { key } = categoryObj;

    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="date"
            tickFormatter = {(unixTime) => {
                return getDateTickFormat(unixTime, DAILY);
            }}
          />
          <YAxis
          domain={['dataMin-10', 'dataMax+10']} />
          <Tooltip
            labelFormatter={(value) => {
              return getDateTickFormat(value, null);
            }}
          />
          <Line connectNulls strokeWidth="3" type="monotone" dataKey={key + '-averageWeight'} stroke="#316B83"
          dot={false} />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
