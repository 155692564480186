import React from 'react'
import { Icon, Segment, TransitionablePortal } from 'semantic-ui-react';
import { Paragraph } from '../StyledComponents';

export const SuccessPortal = ({ text }) => {
  const [open, setOpen] = React.useState(true);

  return (
    <TransitionablePortal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
        <Segment  style={{ backgroundColor: 'black', textAlign: 'center', width: '100%', position: 'fixed', top: '45%', zIndex: 1000 }}
            inverted
            textAlign='center'
            padded='very'
        >
          <Icon inverted name='check circle outline' color='green' size='big'/>
          <Paragraph>{text}</Paragraph>
        </Segment>
    </TransitionablePortal>
  )
}
