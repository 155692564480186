import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deleteProgramBuilderWeek, addProgramBuilderDay, addProgramBuilderWeek, addProgramBuilderWorkout } from '../../actions';
import { WeekContainer, BlockTitle } from '../StyledComponents';
import Day from './Day';
import EditCopyDeleteMenu from '../menus/EditCopyDeleteMenu';
import { generateLayoutId } from './shared';
import { WEEKS } from '../../assets/literals';

class Week extends Component {

  constructor(props) {
     super(props)
     this.weekRef = React.createRef();
   }

   componentDidMount() {
     this.weekRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
   }

  copyWeek = () => {
    const { week, blockId, index, layout, addProgramBuilderWeek, addProgramBuilderDay, addProgramBuilderWorkout } = this.props;
    const { days, workouts } = layout;
    const newWeekId = generateLayoutId('week');
    addProgramBuilderWeek(newWeekId, (index+1), blockId);
    for (var i=0; i<week.days.length; i++) {
      const dayId = week.days[i];
      const newDayId = generateLayoutId('day');
      addProgramBuilderDay(newDayId, (Object.keys(days).length+i), newWeekId);
      const workoutIds = days[dayId].workouts;
      for (var j=0; j<workoutIds.length;j++){
        const newWorkoutId = generateLayoutId('workout');
        addProgramBuilderWorkout(newWorkoutId, Object.keys(workouts).length+(i)*(j+1), newDayId, {...workouts[workoutIds[j]].workout, id : newWorkoutId, name : workouts[workoutIds[j]].workout.name + ' (Copy)' });
      }
    }
  }

  render() {
    const { editable, week, blockId, index, layout, allDays, allWorkouts, onWorkoutSelected, deleteProgramBuilderWeek, openScheduler } = this.props
    const { id } = week;
    const days = week.days.map(dayId => allDays[dayId]);
    return (
              <WeekContainer ref={this.weekRef}>
                <BlockTitle> Week {index+1} </BlockTitle>
                {days.map((day, index) => <Day key={day.id} index={index} editable={editable} week={week} day={day} layout={layout} allWorkouts={allWorkouts} onWorkoutSelected={onWorkoutSelected} openScheduler={openScheduler}/>)}
                <EditCopyDeleteMenu
                  style={{height: '20px', padding: '10px'}}
                  showCopy={editable}
                  showDelete={editable}
                  onDelete={() => deleteProgramBuilderWeek(blockId, id, layout)}
                  onCopy={this.copyWeek}
                  deleteMessage='Are you sure you want to delete this week? All workouts will be deleted.'
                  showSchedule
                  onSchedule={()=>openScheduler(WEEKS, id, index)}
                />
              </WeekContainer>
    )
  }
}

export default connect(null, { deleteProgramBuilderWeek, addProgramBuilderDay, addProgramBuilderWeek, addProgramBuilderWorkout })(Week);
