import React, { useEffect, useState } from 'react';
import { firebaseAuth } from '../../firebase';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState([], () => {
			const user = firebaseAuth.currentUser;
			if (user)
				return user;
			else
				return null;
	});

	useEffect(() => {
		const unsubscribe = firebaseAuth.onAuthStateChanged(setCurrentUser);
		//		localStorage.setItem('userData', JSON.stringify(currentUser));
		return () => {
			unsubscribe();
		};
	}, []);

	return (
		<AuthContext.Provider
			value={{
				currentUser
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
