import React from 'react'
import { TransitionablePortal, Button, Container, Modal, Icon } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/black_cover_logo.png'
import { StyledContainer } from '../StyledComponents';

export const WelcomeModal = ({onExit}) => {
  const [open, setOpen] = React.useState(true)

  return (
    <TransitionablePortal
      defaultOpen={true}
      onMount={() => setOpen(true)}
      onClose={() => setOpen(false)}
      transition = {{
          animation: 'scale',
          duration: 800,
        }}
    >
    <Modal
      size='tiny'
      onClose={() => {onExit();
                      setOpen(false)}}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
    >
      <Modal.Header>Welcome!</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <StyledContainer>
            <img style={{width: '99%', maxWidth: '400px'}} src={logo} alt='||--||' />
            <Container style={{fontSize: '16px'}}>
              Before you begin we recommend heading over to your profile and filling out your working maxes.
              This will allow you to start accurately tracking volume and intensity!
            </Container>
          </StyledContainer>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <NavLink to='/profile'>
          <Button primary onClick={() => {
            onExit();
            setOpen(false)
          }}>
            Go to profile <Icon name='right chevron' />
          </Button>
        </NavLink>
      </Modal.Actions>
    </Modal>
    </TransitionablePortal>
  )
}
