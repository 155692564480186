import Debug from '../Debug';
import { RETRIEVE_USER_CONFIG, RETRIEVE_USER_CONFIG_SUCCESS, RETRIEVE_USER_CONFIG_FAIL,
          UPDATE_USER_CONFIG, UPDATE_USER_CONFIG_SUCCESS, UPDATE_USER_CONFIG_FAIL,
          UPDATE_CURRENT_RECORDS, UPDATE_CURRENT_RECORDS_SUCCESS, UPDATE_CURRENT_RECORDS_FAIL,
          RETRIEVE_CUSTOM_EXERCISES, RETRIEVE_CUSTOM_EXERCISES_SUCCESS, RETRIEVE_CUSTOM_EXERCISES_FAIL,
          CREATE_CUSTOM_EXERCISE, CREATE_CUSTOM_EXERCISE_SUCCESS, CREATE_CUSTOM_EXERCISE_FAIL,
          UPDATE_BUILDER_EXERCISE, UPDATE_CONFIG, CREATE_USER_CONFIG,
          RETRIEVE_TEAM, RETRIEVE_TEAM_FAIL, RETRIEVE_TEAM_SUCCESS,
          RETRIEVE_ATHLETE_CONFIG, RETRIEVE_ATHLETE_CONFIG_FAIL, RETRIEVE_ATHLETE_CONFIG_SUCCESS,
          RETRIEVE_ATHLETE_CUSTOM_EXERCISES, RETRIEVE_ATHLETE_CUSTOM_EXERCISES_FAIL, RETRIEVE_ATHLETE_CUSTOM_EXERCISES_SUCCESS,
          RETRIEVE_SUBSCRIPTIONS, RETRIEVE_SUBSCRIPTIONS_SUCCESS, RETRIEVE_SUBSCRIPTIONS_FAIL,
          UPDATE_TEAM_CONFIG, UPDATE_TEAM_CONFIG_SUCCESS, UPDATE_TEAM_CONFIG_FAIL
      } from './types';
import { firebaseAuth, firebaseDb, usersRef, teamsRef } from '../firebase';
import { brzyckiFormula, lbsToKg, kgToLbs, getWorkingExerciseIndex } from '../commonMethods';
import { KG, LBS } from '../assets/literals';

export const updateConfig = (key, value) => {
  return {
    type: UPDATE_CONFIG,
    payload: { key, value }
  }
}

export const retrieveUserConfig = () => {
  const uid = firebaseAuth.currentUser.uid;
  return (dispatch) => {
    dispatch({type: RETRIEVE_USER_CONFIG});
    firebaseDb.collection(usersRef).doc(uid)
        .onSnapshot((doc) => {
          Debug(doc.data());
          if (doc.data() && doc.data().team)
            dispatch(getTeamData(doc.data().team));
          dispatch({ type: RETRIEVE_USER_CONFIG_SUCCESS, payload: doc.data() });
        }, error => {
            Debug("Error getting document:", error);
            alert('An error has occured: ' + error.message)
            dispatch({ type: RETRIEVE_USER_CONFIG_FAIL, payload: error.message });
          });
  }
}

export const retrieveSubscriptions = () => {
  const uid = firebaseAuth.currentUser.uid;
  return (dispatch) => {
    dispatch({type: RETRIEVE_SUBSCRIPTIONS});
    firebaseDb.collection(usersRef).doc(uid).collection('subscriptions')
        .onSnapshot((querySnapshot) => {
          let subscriptions = [];
          querySnapshot.forEach((doc) => {
              const { role, status } = doc.data();
              if (status === 'active')
                subscriptions.push(role);
          });
          dispatch({ type: RETRIEVE_SUBSCRIPTIONS_SUCCESS, payload: subscriptions });
        }, error => {
            Debug("Error getting document:", error);
            alert('An error has occured: ' + error.message)
            dispatch({ type: RETRIEVE_SUBSCRIPTIONS_FAIL, payload: error.message });
          });
  }
}

export const retrieveAthleteConfig = (uid) => {
  return (dispatch) => {
    dispatch({type: RETRIEVE_ATHLETE_CONFIG});
    firebaseDb.collection(usersRef).doc(uid)
        .onSnapshot((doc) => {
          dispatch({ type: RETRIEVE_ATHLETE_CONFIG_SUCCESS, payload: { uid, data : doc.data() }});
        }, error => {
            alert('An error has occured: ' + error.message)
            Debug("Error getting document:", error);
            dispatch({ type: RETRIEVE_ATHLETE_CONFIG_FAIL, payload: error.message });
          });
  }
}

export const retrieveAthleteCustomExercises = (uid) => {
  return (dispatch) => {
    dispatch({type: RETRIEVE_ATHLETE_CUSTOM_EXERCISES});
    firebaseDb.collection(usersRef).doc(uid).collection('custom').doc('exercises')
        .onSnapshot((doc) => {
          dispatch({ type: RETRIEVE_ATHLETE_CUSTOM_EXERCISES_SUCCESS, payload: { uid, data : doc.data() }});
        }, error => {
          alert('An error has occured: ' + error.message);
            Debug("Error getting document:", error);
            dispatch({ type: RETRIEVE_ATHLETE_CUSTOM_EXERCISES_FAIL, payload: error.message });
          });
  }
}

export const getTeamData = (teamId) => {
    return (dispatch) => {
      dispatch({type: RETRIEVE_TEAM});
      firebaseDb.collection(teamsRef).doc(teamId)
        .onSnapshot((doc) => {
          let data = {...doc.data(), id: doc.id };
          dispatch({ type: RETRIEVE_TEAM_SUCCESS, payload: data });
          data.members.forEach((member) => {
            dispatch(retrieveAthleteConfig(member.uid));
            dispatch(retrieveAthleteCustomExercises(member.uid));
          })

        }, error => {
            alert('An unexepected error has occured: ' + error.message)
            Debug("Error getting document:", error);
            dispatch({ type: RETRIEVE_TEAM_FAIL, payload: error.message });
          });
    }
}

export const retrieveCustomExercises = () => {
  const uid = firebaseAuth.currentUser.uid;
  return (dispatch) => {
    dispatch({type: RETRIEVE_CUSTOM_EXERCISES});
    firebaseDb.collection(usersRef).doc(uid).collection('custom').doc('exercises')
        .onSnapshot((doc) => {
          Debug(doc.data())
          dispatch({ type: RETRIEVE_CUSTOM_EXERCISES_SUCCESS, payload: doc.data() });
        }, error => {
            alert('An unexepected error has occured: ' + error.message)
            Debug("Error getting document:", error);
            dispatch({ type: RETRIEVE_CUSTOM_EXERCISES_FAIL, payload: error.message });
          });
  }
}

const INITIAL_USER_CONFIG = {
  currentRecords: {},
  defaultUnit: 'kg',
  intensityThreshold : 60,
  workingMaxes : [{lift: "Snatch", weight: 0},
                    {lift: "Clean", weight: 0},
                    {lift: "SplitJk", weight: 0},
                    {lift: "CleanAndJerk", weight: 0},
                    {lift: "CleanPull", weight: 0},
                    {lift: "SnatchPull", weight: 0},
                    {lift: "FrontSquat", weight: 0},
                    {lift: "BackSquat", weight: 0},
                    {lift: "BenchPress", weight: 0},
                    {lift: "Deadlift", weight: 0},
                    {lift: "MilitaryPress", weight: 0},
                    {lift: "BentOverRow", weight: 0}]
}

export const createUserConfig = () => {
  const {uid, email, phoneNumber, displayName, photoURL } = firebaseAuth.currentUser;

  const config = INITIAL_USER_CONFIG;
  config.email = email;
  config.phoneNumber = phoneNumber;
  config.displayName = displayName;
  config.photoURL = photoURL;
  config.isOnboarded = false;

  return (dispatch) => {
    dispatch({type: CREATE_USER_CONFIG});
    firebaseDb.collection(usersRef).doc(uid)
    .set(config, {merge: true})
    .then(() => {
        firebaseDb.collection(usersRef).doc(uid)
            .collection("custom").doc("exercises").set({});
      });
    }
}

export const updateUserConfig = (key, value, userId) => {
  const uid = userId || firebaseAuth.currentUser.uid;
  return (dispatch) => {
    dispatch({type: UPDATE_USER_CONFIG});
    firebaseDb.collection(usersRef).doc(uid)
    .update({ [key]: value })
    .then(() => {
      dispatch({ type: UPDATE_USER_CONFIG_SUCCESS, payload: " " });
      setTimeout(function(){
        dispatch({ type: UPDATE_USER_CONFIG_SUCCESS, payload: "" })
        if (key === 'team' && value === "" && userId === firebaseAuth.currentUser.uid)
            window.location.reload(false);
      }, 1200);
    })
    .catch(error => {
      alert('An error has occured: ' + error.message)
      dispatch({ type: UPDATE_USER_CONFIG_FAIL, payload : error.message });
    })
    }
}

export const updateTeamConfig = (teamId, key, value) => {
  return (dispatch) => {
    dispatch({type: UPDATE_TEAM_CONFIG});
    firebaseDb.collection(teamsRef).doc(teamId)
    .update({ [key]: value })
    .then(() => {
      dispatch({ type: UPDATE_TEAM_CONFIG_SUCCESS, payload: " " });
      setTimeout(function(){
        dispatch({ type: UPDATE_TEAM_CONFIG_SUCCESS, payload: "" })
      }, 1200);
    })
    .catch(error => {
      alert('An error has occured: ' + error.message)
      dispatch({ type: UPDATE_TEAM_CONFIG_FAIL, payload : error.message });
    })
    }
}

export const updateCurrentRecords = (newRecords, userConfig) => {
  const { currentRecords, defaultUnit, workingMaxes } = userConfig;
  let updatedRecords = {...currentRecords};
  let newWorkingMaxes = null;

  newRecords.forEach((record, i ) => {
    let { date, exercise, repMax, weight, unit } = record;
    if (unit === '%')
      return;
    else if (defaultUnit !== unit) {
      if (defaultUnit === KG)
        weight = lbsToKg(weight);
      else if (defaultUnit === LBS)
        weight = kgToLbs(weight);

      unit = defaultUnit;
    }

    let rmKeys = [];
    if (repMax >= 1)
      rmKeys.push('oneRM');
    if (repMax >= 2)
      rmKeys.push('twoRM');
    if (repMax >= 3)
      rmKeys.push('threeRM');
    if (repMax >= 5)
      rmKeys.push('fiveRM');
    if (repMax >= 10)
      rmKeys.push('tenRM');

    let estRM = brzyckiFormula(weight, repMax);
    if (updatedRecords[exercise] && updatedRecords[exercise].estRM  && estRM < updatedRecords[exercise].estRM)
      estRM = updatedRecords[exercise].estRM;

    updatedRecords[exercise] = {...updatedRecords[exercise],
                                   estRM,
                                   unit
                                  }

    for (var j=0; j<rmKeys.length; j++) {
        let rmKey = rmKeys[j];
        if ((!updatedRecords[exercise][rmKey]) || (updatedRecords[exercise][rmKey] && updatedRecords[exercise][rmKey].weight < weight)) {
          updatedRecords[exercise] = {...updatedRecords[exercise],
            [rmKey] : {
              date,
              weight,
              unit
          }};
        }
    }
    console.log('updatedrecords:',updatedRecords);

    let workingExerciseIndex = getWorkingExerciseIndex(exercise, workingMaxes);
    if (repMax === 1 && workingExerciseIndex !== null && workingMaxes[workingExerciseIndex] && workingMaxes[workingExerciseIndex].weight < weight)
      newWorkingMaxes = updateWorkingMax(workingExerciseIndex, exercise, weight, newWorkingMaxes || workingMaxes);
    });

  return (dispatch) => {
    dispatch({type: UPDATE_CURRENT_RECORDS});
    firebaseDb.collection(usersRef).doc(firebaseAuth.currentUser.uid).update({
      currentRecords: updatedRecords
    })
    .then(() => {
        dispatch({ type: UPDATE_CURRENT_RECORDS_SUCCESS });
       Debug("Document successfully written!");
       if (newWorkingMaxes)
        dispatch(updateUserConfig('workingMaxes', newWorkingMaxes));
     }).catch((error) => {
       Debug("Error writing document: ", error);
       alert('An error has occured: ' + error.message);
       dispatch({ type: UPDATE_CURRENT_RECORDS_FAIL, payload: error.message });
     })
  }
}

export const updateWorkingMax = (workingExerciseIndex, lift, weight, workingMaxes) => {
  let newWorkingMaxes = [...workingMaxes];
   newWorkingMaxes[workingExerciseIndex] = { lift, weight };

   return newWorkingMaxes;
}

export const createCustomExercise = (key, text, category, category_level_2, implement, exerciseIndex) => {

    const value = {
      text,
      category,
      category_level_2,
      implement
    }

    return (dispatch) => {
      dispatch({type: CREATE_CUSTOM_EXERCISE});
      firebaseDb.collection(usersRef).doc(firebaseAuth.currentUser.uid).collection('custom').doc('exercises')
      .update({
        [key]: value
      })
      .then(() => {
          dispatch({ type: CREATE_CUSTOM_EXERCISE_SUCCESS });
          if (exerciseIndex) {
            dispatch({ type: UPDATE_BUILDER_EXERCISE, payload: {
              exerciseIndex,
              key : 'exercise',
              value : key
            }})
          }
          Debug("Document successfully written!");
       }).catch((error) => {
         Debug("Error writing document: ", error);
         alert('An error has occured: ' + error.message);
         dispatch({ type: CREATE_CUSTOM_EXERCISE_FAIL, payload: error.message });
       })
    }
}
