import React, { Component } from 'react';
import { List, Input, Header, Select } from 'semantic-ui-react';
import { HorizontalDiv } from '../StyledComponents';
import { ListItem } from '../common/ListItem';
import { getExerciseOption } from '../../commonMethods';


class DummyConfig extends Component {

  findRecordAndUpdate = (lift, weight) => {
    if (!weight || isNaN(weight))
      weight = 0

    let newWorkingMaxes = [...this.props.workingMaxes]
    newWorkingMaxes.forEach((obj, i) => {
        if (obj.lift === lift) {
          obj.weight = parseFloat(weight)
        }
    })
    return newWorkingMaxes;
  }

  render() {
    const {defaultUnit, intensityThreshold, workingMaxes, onChange} = this.props;
    return (
      <>
      <ListItem
        leftHandHeader="Default Weight Unit:"
        rightHand={<p style={{ marginRight: '80px'}}>{defaultUnit}</p>}
      />
      <ListItem
        leftHandHeader="Intensity Threshold:"
        rightHand={<Select style={{minWidth : '8em', border : 'none'}}
                      placeholder='Intensity Threshold' options={[
                        { key: '0', value: '0', text: '0%' },
                        { key: '40', value: '40', text: '40%' },
                        { key: '50', value: '50', text: '50%' },
                        { key: '60', value: '60', text: '60%' },
                        { key: '65', value: '65', text: '65%' },
                        { key: '70', value: '70', text: '70%' },
                        { key: '75', value: '75', text: '75%' }]
                      }
        value={intensityThreshold}
        onChange={(e, data) => onChange("intensityThreshold", data.value)}  />}
      />
      <Header> Working Maxes </Header>
      <List divided>
       {workingMaxes.map((record, i) => {
         return <ListItem
             key={"working-max-" + i}
             leftHandHeader={getExerciseOption(record.lift).text}
             rightHand={<HorizontalDiv>
                 <Input
                   type='tel'
                   value = {record.weight}
                   onChange={(e, data) => onChange('workingMaxes', this.findRecordAndUpdate(record.lift, data.value))}
                 >
                 </Input>
               </HorizontalDiv>}
           />
       })}
      </List>
      </>
    );
  }
}

export default DummyConfig;
