import Debug from '../Debug';
import { RETRIEVE_WORKOUTS, RETRIEVE_WORKOUTS_SUCCESS, RETRIEVE_WORKOUTS_FAIL, SELECT_WORKOUT,
        RETRIEVE_TRACK_WORKOUTS, RETRIEVE_TRACK_WORKOUTS_SUCCESS, RETRIEVE_TRACK_WORKOUTS_FAIL,
        CLEAR_SELECTED_WORKOUT, UPDATE_BUILDER_FIELD, UPDATE_BUILDER_EXERCISE, ADD_BUILDER_EXERCISE,
        ADD_BUILDER_WOD,
        DELETE_BUILDER_EXERCISE, ADD_BUILDER_WEIGHT, DELETE_BUILDER_WEIGHT, UPDATE_BUILDER_WEIGHT,
        UPDATE_BUILDER_SET, SAVE_WORKOUT, SAVE_WORKOUT_SUCCESS, SAVE_WORKOUT_FAIL, START_WORKOUT_BUILDER,
        COPY_TO_WORKOUT_BUILDER, DELETE_BUILDER_COMPLEX, UPDATE_COMPLEX_REPS,
        DELETE_WORKOUT, DELETE_WORKOUT_SUCCESS, DELETE_WORKOUT_FAIL, ADD_BUILDER_SET, DELETE_BUILDER_SET, RESET_CALENDAR,
        SWAP_EXERCISES
      } from './types';
import { COMPLETE, STRENGTH, KG, LBS} from '../assets/literals';
import { firebaseAuth, firebaseDb, workoutsRef, templatesRef } from '../firebase';
import { recordPR, updateCurrentRecords } from './index';
import { lbsToKg, kgToLbs, generatePR } from '../commonMethods';

const INITIAL_EXERCISE = {
              type : 'strength',
              exercise: '',
              weightArr: [{
                        weight: '',
                        unit: 'kg',
                        sets: '',
                        reps: '',
                        setArr: []
                      }],
             exerciseNotes: ''
};

const INITIAL_WOD = {
              type : 'conditioning',
              title: '',
              description: ''
};


export const retrieveWorkouts = (start, end, uid) => {
  return (dispatch) => {
    dispatch({type: RETRIEVE_WORKOUTS});
    firebaseDb.collection(workoutsRef)
        .where('userId', '==', uid || firebaseAuth.currentUser.uid)
        .where('date', '>=', start)
        .where('date', '<=', end)
        .get().then((querySnapshot) => {
          //  const workouts = querySnapshot.docs.reduce((res, item) => ({...res, [item.id]: item.data()}), {});
          const workouts = querySnapshot.docs.map(workout => {
            const data = workout.data();
            data.id = workout.id
            return data;
          });
          dispatch({ type: RETRIEVE_WORKOUTS_SUCCESS, payload: workouts });
        }).catch((error) => {
            Debug("Error getting document:", error);
            alert('An error has occured: ' + error.message)
            dispatch({ type: RETRIEVE_WORKOUTS_FAIL, payload: error.message });
          });
  }
}

export const retrieveTrackWorkouts = (start, end, track) => {
  return (dispatch) => {
    dispatch({type: RETRIEVE_TRACK_WORKOUTS});
    firebaseDb.collection(templatesRef)
        .where('track', '==', track)
        .where('date', '>=', start)
        .where('date', '<=', end)
        .get().then((querySnapshot) => {
          //  const workouts = querySnapshot.docs.reduce((res, item) => ({...res, [item.id]: item.data()}), {});
          const workouts = querySnapshot.docs.map(workout => {
            const data = workout.data();
            data.id = workout.id
            return data;
          });
          dispatch({ type: RETRIEVE_TRACK_WORKOUTS_SUCCESS, payload: workouts });
        }).catch((error) => {
            Debug("Error getting document:", error);
            alert('An error has occured: ' + error.message)
            dispatch({ type: RETRIEVE_TRACK_WORKOUTS_FAIL, payload: error.message });
          });
  }
}

export const selectWorkout = (workoutId) => {
  return  {
    type: SELECT_WORKOUT,
    payload: workoutId
  }
}

export const clearSelectedWorkout = () => {
  return  {
    type: CLEAR_SELECTED_WORKOUT
  }
}

export const startWorkoutBuilder = (date, name, uid, coach, status, trackMode) => {
  let emptyWorkout = {
    date,
    name,
    exercises : [],
    exerciseArr: [],
    notes: '',
    userId: trackMode ? "" : uid || firebaseAuth.currentUser.uid,
    coach,
    status
  }
  if (trackMode)
    emptyWorkout.track = uid;

  return  {
    type: START_WORKOUT_BUILDER,
    payload: emptyWorkout
  }
}

export const copyToWorkoutBuilder = (workout) => {

  return  {
    type: COPY_TO_WORKOUT_BUILDER,
    payload: {...workout, name: workout.name + ' (Copy)', date: new Date(Date.now()) }
  }
}

export const updateBuilderField = (key, value) => {
  return {
    type: UPDATE_BUILDER_FIELD,
    payload: {
      key,
      value
    }
  }
}

export const updateBuilderExercise = (exerciseIndex, key, value) => {
    return {
      type: UPDATE_BUILDER_EXERCISE,
      payload: {
          exerciseIndex,
          key,
          value
      }
    }
  }

  export const addBuilderExercise = (complexId = null, weightArr, unit ) => {
      if (weightArr === null) {
        weightArr = INITIAL_EXERCISE.weightArr;
        weightArr.forEach((obj, i) => {
        weightArr[i].unit = unit || 'kg';
     });
  }

      return {
        type: ADD_BUILDER_EXERCISE,
        payload: {...INITIAL_EXERCISE,
          complexId,
          weightArr
        }
      }
    }

    export const addCopiedExercise = (exercise) => {
      return {
        type: ADD_BUILDER_EXERCISE,
        payload: exercise
      }
    }

    export const swapExercises = (exercises, exerciseArr) => {
      return {
        type: SWAP_EXERCISES,
        payload: {
          exercises,
          exerciseArr
        }
      }
    }

    export const addBuilderWod = () => {
        return {
          type: ADD_BUILDER_WOD,
          payload: INITIAL_WOD
        }
      }

    export const deleteBuilderExercise = (index) => {
        return {
          type: DELETE_BUILDER_EXERCISE,
          payload: index
        }
      }

    export const  addBuilderWeight = (exerciseIndex, reps, unit) => {
        return {
          type: ADD_BUILDER_WEIGHT,
          payload: {
            exerciseIndex,
            reps,
            unit
          }
        }
      }

      export const  deleteBuilderWeight = (exerciseIndex, weightIndex) => {
          return {
            type: DELETE_BUILDER_WEIGHT,
            payload: {
              exerciseIndex,
              weightIndex
            }
          }
        }

        export const updateBuilderWeight = (exerciseIndex, weightIndex, key, value) => {
          return {
            type: UPDATE_BUILDER_WEIGHT,
            payload: {
              exerciseIndex,
              weightIndex,
              key,
              value
            }
          }
        }

        export const  addBuilderSet = (exerciseIndex, weightIndex, setObj) => {
            return {
              type: ADD_BUILDER_SET,
              payload: {
                exerciseIndex,
                weightIndex,
                setObj
              }
            }
          }

          export const  deleteBuilderSet = (exerciseIndex, weightIndex, setIndex) => {
              return {
                type: DELETE_BUILDER_SET,
                payload: {
                  exerciseIndex,
                  weightIndex,
                  setIndex
                }
              }
            }

        export const updateBuilderSetPattern = (exerciseIndex, weightIndex, setIndex, set, rep) => {
          Debug(setIndex, set , rep)
          let status = '';
          /*
            0 : make (check)
            1 : bad miss (red X)
            9 : close miss (yellow X)
            5 : No attempt (-)
          */

          //swap through
          switch (set.charAt(rep)) {
            case '1':
              status = '0';
              break;
            case '0':
              status = '9';
              break;
            case '9':
              status = '5';
              break;
            case '5':
              status = '1';
              break;
            default:
              status = '1';
          }
          //const repStatus = (parseInt(set.charAt(rep))+1)%4;
          const key = "pattern";
          let value = set.substring(0,rep) + status + set.substring(rep+1,set.length);
          Debug(status);
          Debug(value);
          return {
            type: UPDATE_BUILDER_SET,
            payload: {
              exerciseIndex,
              weightIndex,
              setIndex,
              key,
              value
            }
          }
        }

        export const updateBuilderSet  =  (exerciseIndex, weightIndex, setIndex, key, value) => {
          return {
            type: UPDATE_BUILDER_SET,
            payload: {
              exerciseIndex,
              weightIndex,
              setIndex,
              key,
              value
            }
          }
        }

        export const deleteBuilderComplex = (complexId) => {
          return {
            type: DELETE_BUILDER_COMPLEX,
            payload: complexId
          }
        }

        export const updateComplexReps = (reps) => {
          return {
            type: UPDATE_COMPLEX_REPS,
            payload: reps
          }

        }

        export const saveWorkout = (workout, continueWorkout, user, trackMode) => {
          const path = trackMode ? templatesRef : workoutsRef;

          if (!continueWorkout)
            workout.status = COMPLETE;
          return (dispatch) => {
            dispatch({type: SAVE_WORKOUT});

            workout.date = new Date(workout.date);
            var id = null;
            if (workout.id)
              id = workout.id;
            Debug(id);
            delete workout.id;
            Debug(id);
            Debug(workout);

            if (id) {
              firebaseDb.collection(path).doc(id).update(workout)
              .then((doc) => {
                if (continueWorkout) {
                    dispatch({ type: SAVE_WORKOUT_SUCCESS, payload : {
                      workout,
                      id,
                      success : "Workout Saved!"
                    }
                    });
                }
                if (!trackMode)
                  dispatch(saveWorkoutPRs(id, workout, user));

                setTimeout(function(){
                    dispatch({ type: SAVE_WORKOUT_SUCCESS, payload : {
                      workout,
                      id,
                      success : ""
                    } })
                  }, 1200);
                  dispatch({ type: RESET_CALENDAR});
              //    if (continueWorkout)
              //      dispatch({ type: SELECT_WORKOUT, payload : doc.data() });
                 Debug("Document successfully written by update!");
               }).catch((error) => {
                 Debug("Error writing document: ", error);
                 alert('An error has occured: ' + error.message)
                 dispatch({ type: SAVE_WORKOUT_FAIL, payload: error.message });
               })
            }
            else {
              firebaseDb.collection(path).add(workout)
              .then((doc) => {
                if (continueWorkout) {
                  dispatch({ type: SAVE_WORKOUT_SUCCESS, payload : {
                    workout,
                    id: doc.id,
                    success : "Workout Saved!"
                  }
                  });
                 }
                  if (!trackMode)
                    dispatch(saveWorkoutPRs(doc.id, workout, user));

                  setTimeout(function(){
                    dispatch({ type: SAVE_WORKOUT_SUCCESS, payload : {
                      workout,
                      id: doc.id,
                      success : ""
                    } })
                  }, 1200);

                  dispatch({ type: RESET_CALENDAR});
            //      if (continueWorkout)
            //        dispatch({ type: SELECT_WORKOUT, payload : doc.data() });
                 Debug("Document successfully written by add!");
               }).catch((error) => {
                 alert('An error has occured: ' + error.message)
                 Debug("Error writing document: ", error);
                 dispatch({ type: SAVE_WORKOUT_FAIL, payload: error.message });
               })
            }
          }
        }

        const removeLighterPRs = (newRecords) => {
          let indexesToRemove = []
          for (var i = newRecords.length - 1; i > 0; i--) {
            for (var j=i-1; j>=0; j--) {
              if (newRecords[i].exercise === newRecords[j].exercise && newRecords[i].repMax === newRecords[j].repMax) {
                  if (newRecords[i].weight > newRecords[j].weight)
                    indexesToRemove.push(j)
                  else indexesToRemove.push(i)
              }
            }
          }
          let finalRecords = [];
          newRecords.forEach((record, index) => {
            if (!indexesToRemove.includes(index))
                finalRecords.push(record);
          });

          return finalRecords;
        }

        const getNewPRs = (newRecords, date, exercise, weightArr, currentRecords, defaultUnit) => {
            Debug(exercise, weightArr, currentRecords);

              let tenRM, fiveRM, threeRM, twoRM, oneRM = null;
              if (currentRecords && exercise in currentRecords) {
                 tenRM = currentRecords[exercise].tenRM;
                 fiveRM = currentRecords[exercise].fiveRM;
                 threeRM = currentRecords[exercise].threeRM;
                 twoRM = currentRecords[exercise].twoRM;
                 oneRM = currentRecords[exercise].oneRM;
              }

              weightArr.forEach((obj, i) => {
                let { weight, unit, setArr } = obj;
                let reps = 0;
                for (var j=0; j<setArr.length; j++) {
                  let madeReps = setArr[j].pattern.match(/1/g);
                  madeReps = madeReps ? madeReps = madeReps.length : 0;
                  if (madeReps>reps)
                    reps = madeReps;
                }

                //convert weight to default unit
                if (unit !== defaultUnit) {
                  if (unit === '%')
                    return;
                  else if (defaultUnit === KG)
                    weight = lbsToKg(weight);
                  else if (defaultUnit === LBS)
                    weight = kgToLbs(weight)
                }
                Debug(weight);

                if (reps>0 && weight>0) {
                    if (reps>=10) {
                      if (tenRM) {
                        if (weight > tenRM.weight) {
                            Debug('10RM: ' + weight + ' ' + unit + ' for ' + reps);
                            newRecords.push(generatePR(date, exercise, 10, defaultUnit, weight) );
                        }
                      }
                      else {
                        Debug("No 10RM pr logged. " +  weight + ' ' + defaultUnit + ' for ' + reps)
                        newRecords.push(generatePR(date, exercise, 10, defaultUnit, weight) );
                      }
                    }
                    if (reps>=5) {
                      if (fiveRM) {
                        if (weight > fiveRM.weight) {
                            Debug('5RM: ' + weight + ' ' + defaultUnit + ' for ' + reps);
                            newRecords.push(generatePR(date, exercise, 5, defaultUnit, weight) );
                        }
                      }
                      else {
                        Debug("No 5RM PR logged. " +  weight + ' ' + defaultUnit + ' for ' + reps)
                        newRecords.push(generatePR(date, exercise, 5, defaultUnit, weight) );
                      }
                    }
                    if (reps>=3) {
                      if (threeRM) {
                        if (weight > threeRM.weight) {
                            Debug('3RM: ' + weight + ' ' + defaultUnit + ' for ' + reps);
                            newRecords.push(generatePR(date, exercise, 3, defaultUnit, weight) );
                        }
                      }
                      else {
                        Debug("No 3RM PR logged. " +  weight + ' ' + defaultUnit + ' for ' + reps)
                        newRecords.push(generatePR(date, exercise, 3, defaultUnit, weight) );
                      }
                    }
                    if (reps>=2) {
                      if (twoRM) {
                        if (weight > twoRM.weight) {
                            Debug('2RM: ' + weight + ' ' + defaultUnit + ' for ' + reps);
                            newRecords.push(generatePR(date, exercise, 2, defaultUnit, weight) );
                        }
                      }
                      else {
                        Debug("No 2RM PR logged. " +  weight + ' ' + defaultUnit + ' for ' + reps)
                        newRecords.push(generatePR(date, exercise, 2, defaultUnit, weight) );
                      }
                    }
                    if (reps>=1) {
                      if (oneRM) {
                        if (weight > oneRM.weight) {
                            Debug('1RM: ' + weight + ' ' + defaultUnit + ' for ' + reps);
                            newRecords.push(generatePR(date, exercise, 1, defaultUnit, weight) );
                        }
                      }
                      else {
                        Debug("No 1RM PR logged. " +  weight + ' ' + defaultUnit + ' for ' + reps)
                        newRecords.push(generatePR(date, exercise, 1, defaultUnit, weight) );
                      }
                    }
                }
              });

              return newRecords;
        }

        const saveWorkoutPRs = (workoutId, workout, user) => {
            const { status, date, exerciseArr} = workout;
            return (dispatch) => {
              if (status === COMPLETE) {
                //console.log('saving workout prs....');
                //Find PRs in the workout, create new PRs and update current records in user config
                let newRecords = [];
                if (user) {
                  exerciseArr.forEach((obj, i) => {
                    if (obj.type === STRENGTH || obj.type === undefined)
                    newRecords = getNewPRs(newRecords, date, obj.exercise, obj.weightArr, user.currentRecords, user.defaultUnit )
                  })
                  newRecords = removeLighterPRs(newRecords);

                  newRecords.forEach((record, i) => {
                    Debug(record);
                    const { exercise, repMax, unit, weight } = record;
                    dispatch(recordPR(exercise, repMax, date, weight, unit, null, workoutId));
                  })
                  if (newRecords.length > 0)
                    dispatch(updateCurrentRecords(newRecords, user));
                }
            }
          }
        }

        export const deleteWorkout = (id, isTemplate) => {
          const ref = isTemplate ? templatesRef : workoutsRef;
          Debug(id);
          return (dispatch) => {
            dispatch({type: DELETE_WORKOUT});
            firebaseDb.collection(ref).doc(id).delete()
            .then((doc) => {
                dispatch({ type: DELETE_WORKOUT_SUCCESS });
               Debug("Document successfully deleted!");
             }).catch((error) => {
               Debug("Error deleting document: ", error);
               alert('An error has occured: ' + error.message)
               dispatch({ type: DELETE_WORKOUT_FAIL, payload: error.message });
             })
         }
        }
