import React, { Component} from 'react';
import { TransitionablePortal, Header, Modal, Button, Statistic} from 'semantic-ui-react';
import CategoryPieChart from '../charts/CategoryPieChart';
import { StyledContainer, HorizontalDiv } from '../StyledComponents';
import { numberWithCommas, decimalToPercent, getCategoryColor} from '../../commonMethods';

const Stat = ({value, label}) => {
  return <Statistic size='mini'>
            <Statistic.Value style={{textTransform : 'none', color: 'black'}}>{value}</Statistic.Value>
            <Statistic.Label style={{fontSize : '10px'}}>{label}</Statistic.Label>
          </Statistic>
}

export const Pie = ({typeData, metric, width, height, showSideLabels, showBottomLabels, hideHeader }) => {
  console.log('pie', typeData)
  return (
    <div>
    {!hideHeader && <Header style={{textTransform: 'capitalize', margin: 0}} as='h4' textAlign='center'> {metric} </Header>}
    <CategoryPieChart
          data={Object.keys(typeData).map(k => {return {...typeData[k], key: k}})}
          metric={metric}
          showSideLabels={showSideLabels}
          showBottomLabels={showBottomLabels}
          width={width}
          height={height}
   />
   </div>
 )
}

const objToArray = data => {
  return Object.keys(data).map(k => {return {...data[k], key: k}});
}

class SessionSummary extends Component {
  state = {open : false};
  handleOpen = () => this.setState({open : true});
  handleClose = (onClose) => {
    this.setState({open : false});
    if (onClose)
      onClose();
  }


  render() {
    const { defaultOpen, onClose, data, header } = this.props;
    const { volume, intensity, sets, reps, unit, typeData } = data;

    return (
      <TransitionablePortal
        trigger={<Button style={{background: 'transparent'}} icon='pie graph' size='large' onClick={this.handleOpen} />}
        onClose={this.handleClose}
        defaultOpen={defaultOpen}
        onMount={this.handleOpen}
        transition = {{
            animation: 'scale',
            duration: 1000,
          }}
      >
      <Modal
        closeIcon
        open={this.state.open}
        onMount={this.handleOpen}
        onClose={() => this.handleClose(onClose)}
      >
        <Modal.Header style={{textAlign: 'center'}}>{header}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
          <StyledContainer>
            { volume > 0 ?
              <><Statistic.Group size='tiny'>
                <Stat
                  value={numberWithCommas(volume)}
                  label={'Volume (' + unit + ')'}
                />
                <Stat
                  value={decimalToPercent(intensity) + '%'}
                  label='Intensity'
                />
                </Statistic.Group>
                <Statistic.Group size='mini'>
                  <Stat
                    value={sets}
                    label='Sets'
                  />
                  <Stat
                    value={reps}
                    label='Reps'
                  />
                </Statistic.Group>
                <br/>
                <HorizontalDiv style={{alignItems: 'baseline'}}>
                  <Pie
                    typeData={typeData}
                    metric='volume'
                    width={175}
                    height={175}
                  />
                  <div style={{marginLeft: '20px'}}>
                  <Header as='h4'>Intensity </Header>
                  {objToArray(typeData).map((entry, index) => <HorizontalDiv key={`intensity-${index}`}>
                                                  <Header as='h5' style={{ margin: 0, textTransform : 'capitalize', color: getCategoryColor(entry.key)}}>{entry.key + ':'}&nbsp;</Header>
                                                  <Header as='h5' style={{ margin: 0, textTransform : 'capitalize', color: getCategoryColor(entry.key)}}>{decimalToPercent(entry.intensity) + '%'}</Header>
                                                </HorizontalDiv>)
                  }
                  </div>
                </HorizontalDiv>
                <HorizontalDiv>
                <Pie
                  typeData={typeData}
                  metric='sets'
                  width={140}
                  height={140}
                  showSideLabels
                />
                <Pie
                  typeData={typeData}
                  metric='reps'
                  width={140}
                  height={140}
                />
               </HorizontalDiv>
               </>
             :
              <><br/><Header as='h4'> No strength volume logged for this workout. </Header><br/><br/></>}
            </StyledContainer>
          </Modal.Description>
        </Modal.Content>
      </Modal>
      </TransitionablePortal>
    )
  }
}

export default SessionSummary;
