import React, { PureComponent } from 'react';
import { Header } from 'semantic-ui-react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { getCategoryColor, getDateTickFormat, getLabel } from '../../commonMethods';
import { DAILY, SQUAT, PULL, PRESS, SNATCH, CLEAN, JERK, PRESS_VERTICAL, ROW } from '../../assets/literals';
import stackedVolume from '../../assets/stackedVolume.png';

export default class StackedVolumeChart extends PureComponent {

  render() {
    const { data, metric, unit, isMobile } = this.props;
    const categories = [SQUAT, PULL, PRESS, CLEAN, JERK, SNATCH, PRESS_VERTICAL, ROW ];
    if (data.length < 3) {
     return (
         <>
         <Header as='h3'>Volume Allocation</Header>
         <div style={{paddingTop: '20%', width: '100%',height: isMobile ? '72%' : '100%', backgroundSize: 'contain', borderRadius: '5px', backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ),url(${stackedVolume})`}} >
          <Header style={{color: 'darkgrey'}} as='h2'>Not enough data! </Header>
         </div>
         </>)
    }
    return (
      <>
      <Header as='h3'>Volume Allocation</Header>
      <ResponsiveContainer width="100%" height="88%">
        <AreaChart
          key={metric}
          data={data}
        >
          {isMobile ? <></> : <CartesianGrid strokeDasharray="5 5" />}
          <XAxis
            dataKey="date"
            type={(data && data.length < 3) ? "category" : "number"} //need category when 1 due to recharts bug https://github.com/recharts/recharts/issues/2127
            domain={["dataMin", "dataMax"]}
            tickFormatter = {(unixTime) => {
                return getDateTickFormat(unixTime, DAILY);
            }}
          />
          <YAxis
            width={this.props.isMobile ? 0 : 60}
            label={{ value: getLabel(metric, unit), orientation: "left", angle: -90, position: 'insideLeft', textAnchor: 'middle' }}
          />
          <Tooltip
            labelFormatter={(value) => {
              return getDateTickFormat(value, DAILY);
            }}
          />
          <Legend
            formatter={(value, entry, index) => value.split('-')[0]}
          />
          {categories.map(category => {
            const color = getCategoryColor(category);
            return <Area key={category + '-' + metric}
                    type="monotone"
                    dataKey={category + '-' + metric}
                    stackId="1"
                    stroke={color}
                    fill={color}
                    fillOpacity={0.75}
                  />
          })}
        </AreaChart>
      </ResponsiveContainer>
      </>
    );
  }
}
