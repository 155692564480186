import React from 'react'
import { Modal } from 'semantic-ui-react'
import DailyLog from './DailyLog';

export const DailyLogModal = ({history, open, onClose, selectedWorkout, header, handleSubmit }) => {
  return (
    <Modal
      closeIcon
      onClose={onClose}
      open={open}
      size="small"
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
        <DailyLog
          key={selectedWorkout}
          handleDateChange={() => console.log('handle date change')}
          handleSubmit={handleSubmit}
          programming={true}
          hideDate
          ignoreIntensity
        />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}
