import React from 'react';
import { Card, Header } from 'semantic-ui-react';
import { getTypeLabel } from '../../commonMethods';
import {IntensityZoneBarChart} from './IntensityZoneBarChart';


export const ZoneChartGroup = ({zoneData, sport, isProgramming}) => {
  const keys = Object.keys(zoneData), values = Object.values(zoneData);
  if (!keys || keys.length === 0)
    return null;

  return (
      <Card.Group stackable itemsPerRow={3}>
        {values.map((data,i) =>
          <Card style={{width: '30%', height: '350px'}} key={keys[i]}>
            <Header style={{marginTop: '8px', color: 'darkslategrey'}}>
                {getTypeLabel(keys[i], sport)}
            </Header>
            <div style={{width: '100%', height: '300px'}}>
              <IntensityZoneBarChart
                data={data}
                isProgramming={isProgramming}
              />
            </div>
          </Card>
      )}
      </Card.Group>
    );
}
