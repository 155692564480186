import React from 'react';
import { TransitionablePortal, Button, Icon, Modal } from 'semantic-ui-react';
import RepMaxCalculator from './RepMaxCalculator';


export const CalculatorModal = () => {
        const [open, setOpen] = React.useState(false)

        return (
          <TransitionablePortal
            open={open}
            trigger={<Button compact style={{background: 'transparent'}} onClick={() => setOpen(true)}><Icon name='calculator' size='large' /></Button>}
            transition = {{
                animation: 'scale',
                duration: 400,
              }}
              closeOnDocumentClick={false}
          >
            {open ? <Modal
              size='tiny'
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              open={true}
              closeIcon
            >
              <Modal.Header>Rep Max and Percent Calculator
              </Modal.Header>
              <Modal.Content>
                  Enter a weight and rep scheme. The calculator will estimate your 1RM for rep schemes between 1 and 10.
                  <br/>
                  <br/>
                  <RepMaxCalculator />
              </Modal.Content>
            </Modal> : <></>}
          </TransitionablePortal>
        )
  }
