import React, { useState } from 'react';
import {  Transition, Form, Button, Header, Modal, TransitionablePortal } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { OptionalLog } from "./OptionalLog";
import { StyledContainer } from '../StyledComponents';
import { HIGHLIGHT } from '../../assets/literals';


export const FinishModal = ({trigger, validateForm, formRef, addExerciseRef, workoutBuilder, updateBuilderField, handleSubmit, disabled, themeColor}) => {
  const [open, setOpen] = useState(false)
  const [expand, setExpand] = useState(false)


  const renderHelper = (expand) => {
    const { highlight, notes } = workoutBuilder;
    if (expand) {
      return    <Transition transitionOnMount visible={true} animation='slide down' duration={500}>
                 <Modal.Description>
                  <StyledContainer>
                  <div style={{width : '100%', textAlign : 'right'}}>
                    <Button compact circular
                        basic={!highlight}
                        size='small'
                        color={highlight ? 'yellow' : 'grey'}
                        onClick={() => updateBuilderField(HIGHLIGHT, !highlight)}
                    >
                        Highlight
                    </Button>
                  </div>
                  <Header style={{color: themeColor}}>Notes: </Header>
                  <Form
                    style={{width: '100%'}}
                  >
                  <Form.TextArea
                    rows='5'
                    maxLength='1000'
                    placeholder='Workout notes...'
                    id='notes'
                    value={notes}
                    onChange={(e) => {updateBuilderField('notes', e.target.value)}}
                   />
                   <br/>
                   <OptionalLog
                    updateBuilderField={updateBuilderField}
                    workoutBuilder={workoutBuilder}
                    />
                    <br/>
                  <Button primary fluid
                    disabled={disabled}
                    onClick={() => handleSubmit(false)}>
                   Finish
                  </Button>
                  </Form>
                  </StyledContainer>
                </Modal.Description>
               </Transition>
    }
    else
    return (
        <Modal.Description>
          <Button secondary fluid
              onClick={() => {
                handleSubmit(true);
                setOpen(false);
                setExpand(false);
                addExerciseRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "center"
                });
              }}
              > Save and Continue Session
          </Button>
          <br/>
          <Button primary fluid onClick={() => setExpand(!expand)}> Finish Session </Button>
        </Modal.Description>
      );


  }

  return (
    <TransitionablePortal
      open={open}
      transition = {{
          animation: 'scale',
          duration: 500,
        }}
        closeOnDocumentClick={false}
        trigger={trigger}
        onOpen={() => {validateForm(workoutBuilder.date, formRef) && setOpen(true)}}
    >
    {open ?
    <Modal
      size='small'
      onClose={() => {setOpen(false); setExpand(false)}}
      closeIcon
      open={open}
    >
      <Modal.Header>Finished with your session?</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {renderHelper(expand)}
        </Modal.Description>
      </Modal.Content>
    </Modal>: <></>}
    </TransitionablePortal>
  )
}
