import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Modal, Button, Icon } from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import ExerciseModal from '../exercise/ExerciseModal';
import { HorizontalDiv, StyledContainer } from '../StyledComponents';
import { WeightAndUnitInput } from './WeightAndUnitInput';
import { recordPR, deletePR, updateCurrentRecords } from '../../actions';
import { generatePR } from '../../commonMethods';
import {DATE, EXERCISE, REPMAX, KG, LBS } from '../../assets/literals';

const INITIAL_STATE = {
  open: false
}

const DeletePRModal = ({onDelete}) => {
  const [open, setOpen] = React.useState(false)
  return (
      <Modal
        basic
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size='small'
        trigger={<Button negative> <Icon name='x' /> Delete </Button>}
      >
        <Modal.Content>
          <p>
          Are you sure you want to delete this record?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color='red' inverted onClick={() => setOpen(false)}> Cancel
          </Button>
          <Button color='red' inverted onClick={() => {
            setOpen(false);
            onDelete();
          }}>
            <Icon name='x' /> Delete
          </Button>
        </Modal.Actions>
    </Modal>
  )
}


class PRModal extends Component {

  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }


  handleChange = (index, key, value) => {
    this.props.updateRecord(key, value);
  }

  changeExercise = (exercise) => {
    this.props.updateRecord(EXERCISE, exercise);
  }

  isValidRecord = record => {
    const { date, repMax, weight, unit, exercise } = record;
    if (!exercise) {
      alert('Please select an exercise.');
      return false;
    }
    else if (!repMax) {
      alert('Please select a rep max.');
      return false;
    }
    else if (!weight) {
      alert('Please enter a weight.');
      return false;
    }
    else if (unit !== KG && unit !== LBS) {
      alert('Please select kg or lbs.');
      return false;
    }
    else if (!date) {
      alert('Please select a date.');
      return false;
    }
    else
      return true;
  }

  render() {
    const { open } = this.state;
    const { newRecord, updateRecord, deletePR } = this.props;
    const { date, repMax, weight, unit, exercise, id, workoutId } = newRecord;
    return (
      <Modal
        closeIcon
        size='tiny'
        onClose={() => this.setState(INITIAL_STATE)}
        onOpen={() => this.setState({open: true})}
        open={open}
        trigger={this.props.trigger}
      >
        <Modal.Header> {id ? "Update Record" : "Record a Personal Record!"}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                {!id && <><span>Records are automatically recorded on workout completion but you can also log manually here.</span><br/></>}
                <br/>
                <DatePicker
                  id='datepicker-pr'
                  selected={date}
                  onChange={date => {
                      updateRecord(DATE, new Date(date));
                  }}
                  withPortal
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="MMM dd, yyyy"
                  placeholderText="Date"
                  className='ui button fluid'
                />
                <br/>
              <StyledContainer>
                <HorizontalDiv style={{justifyContent : 'flex-start'}}>
                  <ExerciseModal
                    exercise={exercise}
                    exerciseIndex={null}
                    onExerciseSelect={this.changeExercise}
                  />
                   <Dropdown
                      inline
                      style={{alignSelf: 'flex-end', padding:'.78em', fontSize: '18px'}}
                      compact
                      placeholder='Rep Max'
                       options={
                       [
                         { key: 1, value: 1, text: '1RM' },
                         { key: 2, value: 2, text: '2RM' },
                         { key: 3, value: 3, text: '3RM' },
                         { key: 5, value: 5, text: '5RM' },
                         { key: 10, value: 10, text: '10RM' },
                       ]}
                       value={repMax}
                       onChange={(e, data) => { updateRecord(REPMAX, parseInt(data.value))}}
                    />
                 </HorizontalDiv>
                 <HorizontalDiv>
                 </HorizontalDiv>
                 <br/>
                 <WeightAndUnitInput
                  weight={weight}
                  unit={unit}
                  size="large"
                  handleChange={this.handleChange}
                 />
              </StyledContainer>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.setState({open: false})}>
            <Icon name='remove' /> Cancel
          </Button>
          {id && <DeletePRModal
            onDelete={()=> {
              deletePR(id);
              this.setState({open: false})
            }}
          />}
          <Button primary onClick={() => {
              if (this.isValidRecord(newRecord)) {
                this.props.recordPR(exercise, repMax, date, weight, unit, id, workoutId);
                this.props.updateCurrentRecords([generatePR(date, exercise, repMax, unit, weight)], this.props.userConfig);
                this.setState({open: false});
              }
            }
            }>
            <Icon name='checkmark' /> Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    userConfig: state.config.user
  }
}

export default connect(mapStateToProps, { recordPR, deletePR, updateCurrentRecords })(PRModal);
