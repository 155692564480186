import React, { Component } from 'react';
import { Responsive, Menu, Header, Segment} from 'semantic-ui-react';
import { HorizontalDiv } from '../StyledComponents';
import logo from '../../assets/barbell_logo.png'

export const Logo = () => {
  return <img src={logo} alt='||--||' style={{height: '50px' }} />
}

class HeaderGeneric extends Component {

  state = { activeItem: 'home' }

  render () {
    const { text, itemLeft, itemRight } = this.props
    return (
      <Segment basic clearing>
      <Responsive style={{paddingRight: 0, display: 'flex', justifyContent: 'space-between', height: '50px'}} as={Menu} fixed='top' >
            <HorizontalDiv style={{justifyContent: 'space-between', width: '100%' }}>
              {itemLeft}
              <Header style={{margin: 'auto'}} as='h3'>&nbsp;{text}</Header>
              {itemRight}
            </HorizontalDiv>
      </Responsive>
      </Segment>
    );
  }
}

export default HeaderGeneric;
