import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.css';
import App from './App';
import { AuthProvider } from "./components/navigation/Auth";
import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore';
import history from './history';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

//const stripePromise = loadStripe('pk_test_51IV7dhKbBtisReTOysi0QPcODwtadjJ8yGd1rhLKTDuAVCgbjnd3YRyKw70zwqPMjHkjG8BKXQEFuimYLBOZRWM200vaX24T01');
const stripePromise = loadStripe('pk_live_51IV7dhKbBtisReTORSChN1BTV5pzLFVnL3ki8x8bk9ZheH2aTHABxGfc3SscpZgj6Uq0QYlS7bRHkiJq6Vgxum8x00TSj5QEJv');
const isLocalHost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);



if (!isLocalHost) {
  Sentry.init({
    dsn: "https://8abb03d6639349a1a92647133a25ba16@o525080.ingest.sentry.io/5638570",
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

const store = configureStore();

const render = () => {
  ReactDOM.render(
      <Provider store={store}>
        <AuthProvider>
          <Elements stripe={stripePromise}>
            <App history={history} />
          </Elements>
        </AuthProvider>
      </Provider>,
  document.getElementById('root'))
}

render()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
