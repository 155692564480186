import React, { Component } from 'react';
import { connect } from 'react-redux';
import Debug from '../../Debug';
import PRModal from '../records/PRModal';
import { RecordList } from '../records/RecordList';
import { StyledContainer, SizingContainer } from '../StyledComponents';

class Records extends Component {

  render() {
    Debug(this.props)
    return (
      <>
        <StyledContainer>
          <SizingContainer>
            <PRModal
              trigger={<button style={{display: 'flex', color: 'cornflowerblue', width:'100%', fontSize: '16px', fontWeight: 'bold'}}
                          type="button">Record A PR</button>}
            />
            <RecordList
              records={this.props.records}
            />
          </SizingContainer>
        </StyledContainer>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
           records: state.historyLog.records
         }
};

export default connect(mapStateToProps)(Records);
