import React, { useState } from 'react';
import {  Header, Modal } from 'semantic-ui-react';
import { CheckoutForm } from './CheckoutForm';

export const CheckoutModal = ({ trigger, productId, id, name, price, stripeId}) => {
        const [open, setOpen] = useState(false);

        return (
          <Modal
            style={{textAlign: 'center'}}
            onClose={() => {
              setOpen(false);
            }}
            onOpen={() => setOpen(true)}
            open={open}
            closeIcon
            trigger={trigger}
          >
            <Modal.Header>Purchase {name}</Modal.Header>
            <Modal.Content>
                <Header as='h2'>{price}</Header>
                <CheckoutForm programId={id} productId={productId} stripeId={stripeId} />
            </Modal.Content>
          </Modal>
        )
  }
