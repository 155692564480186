import { Header } from 'semantic-ui-react';
import { kgToLbs, lbsToKg } from '../../commonMethods';
import { COLOR_SECONDARY } from '../../assets/literals';

export const getIntensity = (category, weight, unit, workingMax, intensityThreshold, defaultUnit) => {
  if (unit === '%')
   return null
  else if (unit !== defaultUnit) {
    if (defaultUnit === 'kg')
      weight = lbsToKg(weight);
    else if (defaultUnit === 'lbs')
      weight = kgToLbs(weight);
  }

  let intensityStr = null;
  let color = COLOR_SECONDARY;
  if (weight>0 && workingMax >0 && intensityThreshold >= 0 ) {
        const intensity =  Math.round((weight / workingMax)*100);

        if (intensity < intensityThreshold) {
            intensityStr = category === 'olympic' ? 'Technique' : 'Warmup'
            color = 'gold'
        }
        else
         intensityStr =  Math.round((weight / workingMax)*100) + '%'

  return  <Header style={{    background: color,
            color: 'white',
            borderRadius: '100%',
            padding: '8px'
          }}
          as='h5'>{intensityStr}
      </Header>
  }
  return null;
}
