import React, { Component } from 'react';
import { Responsive, Menu, Icon} from 'semantic-ui-react';
import { withRouter } from "react-router-dom";

const MenuItem = withRouter((props) => (
  <Menu.Item style={{color: props.active ? 'black' : 'darkgrey' , background:'white'}} name={props.name} active={false} onClick={() => { props.history.push(props.name)}}>
      <Icon name={props.icon} />
  </Menu.Item>
));

class FooterTab extends Component {

  state = { activeItem: 'home' }

  render () {
    const { activeItem } = this.props
    return (

  <Responsive as={Menu} icon='labeled' fluid secondary widths={5} fixed='bottom' maxWidth={Responsive.onlyTablet.maxWidth}>
      <MenuItem
      name='Profile'
      active={activeItem === 'Profile'}
      icon='user'
      />
      <MenuItem
      name='Calendar'
      active={activeItem === 'Calendar'}
      icon='calendar alternate'
      />
      <MenuItem
      name='Track'
      active={activeItem === 'Track'}
      icon='add'
      />
      <MenuItem
      name='Exercises'
      active={activeItem === 'Exercises'}
      icon='trophy'
      />
      <MenuItem
          name='Progress'
          active={activeItem === 'Progress'}
          icon='chart line'
          />
  </Responsive>
    );
  }
}

export default FooterTab;
