import React from 'react';
import { Table } from 'semantic-ui-react'

const formatDate = seconds => {
  var dateFormatted = new Date(0);
    dateFormatted.setUTCSeconds(seconds);
    dateFormatted = (dateFormatted.getMonth() + 1) + '/' + dateFormatted.getDate() + '/' + dateFormatted.getFullYear()

  return dateFormatted;
}

const TableRow = ({date, volume, intensity}) => {
  return (
  <Table.Row>
    <Table.Cell>
      {formatDate(date)}
    </Table.Cell>
    <Table.Cell>{volume}</Table.Cell>
    <Table.Cell>{Math.round(intensity * 100)}</Table.Cell>
  </Table.Row>
)}

export const StatTable = ({data, unit}) => (
  <Table unstackable celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Date</Table.HeaderCell>
        <Table.HeaderCell>Volume ({unit})</Table.HeaderCell>
        <Table.HeaderCell>Intensity (%)</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {data && data.map((obj, i) =>
          <TableRow
            key={'row-' + i}
            date={obj.date}
            volume={obj.volume}
            intensity={obj.intensity}
          />
      )}
    </Table.Body>
  </Table>
)
