import { firebaseDb } from './firebase';
import { usersRef } from './firestore-refs';
import Debug from '../Debug';

export class FirebaseUser {
  constructor(userId) {
    this.userId = userId;
    this.path = usersRef;
    this.db = firebaseDb;
  }

  createUserinDB(user) {
      this.db.collection(usersRef).doc(this.userId).set(user).then(() => {
       Debug("Document successfully written!");
     }).catch(function(error) {
      console.error("Error writing document: ", error);
      return error;
    });
  }

  set(key, value) {
    return new Promise((resolve, reject) => {
      this.db.doc(`${this.path}/${key}`)
          .set(value, error => error ? reject(error) : resolve());
      });
  }

}
