import React, { Component } from 'react';
import { Header, Card, List } from 'semantic-ui-react';
import { getBenchmarkType } from '../../commonMethods';


class WodListItem extends Component {
  render() {
    const { wod, onWodSelect, toggleModal } = this.props;
    const { type, title, description } = wod;

    return (
      <List.Item>
          <Card fluid
              onClick={() => {
                onWodSelect(wod);
                if (toggleModal)
                  toggleModal();
              }}
            >
              <Card.Content header={title} />
              <Card.Content description= {<div>
                <Header style={{marginBottom : 0}} as='h5'> {getBenchmarkType(type)} </Header>
                <p style={{whiteSpace : 'pre-line', color: 'black'}}>{description}</p>
              </div>} />
          </Card>
      </List.Item>
    )
  }
}

export const WodList = ({wods, onWodSelect, toggleModal}) => {
  return (
    <List style={{maxHeight: window.height, width : '100%'}}>
      {wods.map((wod) => {
        return <WodListItem
          key={wod.title}
          wod={wod}
          onWodSelect={onWodSelect}
          toggleModal={toggleModal}
        />
      })}
    </List>
  );
}
/*
export const WodList = ({toggleModal, onExerciseSelect, exerciseIndex, wods, config}) => {
  return (
    <FixedSizeList
      height={600}
      itemCount={wods.length}
      itemSize={150}
      itemData={{toggleModal, wods, exerciseIndex, onExerciseSelect, config}}
    >
      {WodListItem}
    </FixedSizeList>
  );
}
*/
