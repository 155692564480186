import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Icon,Button, Statistic } from 'semantic-ui-react';
import { SizingContainer, StyledContainer, HorizontalDiv } from '../StyledComponents';
import PRChart from '../charts/PRChart';
import ExerciseHistory from './ExerciseHistory';
import { RecordList } from '../records/RecordList';
import PRModal from '../records/PRModal';
//import { CurrentRecordsModal } from '../records/CurrentRecordsModal';
import HeaderGeneric from '../menus/HeaderGeneric';
import { Loading } from '../common/Loading';
import { retrieveRecordsHistory } from '../../actions';
import { getRecord, kgToLbs, lbsToKg } from '../../commonMethods';
import { KG } from '../../assets/literals';

class ExerciseReview extends Component {

  constructor(props) {
      super(props);
      let unit = KG
      if( props.userConfig && props.userConfig.defaultUnit)
        unit = props.userConfig.defaultUnit;
      this.state = {
        tab: 'personalRecords',
        newRecord :   {
                      date: new Date(Date.now()),
                      repMax: 1,
                      weight: '',
                      unit: unit,
                      exercise: props.exercise.key
                   }
     }
  }

  componentDidMount() {
    this.props.retrieveRecordsHistory(this.props.athlete, 100, this.props.exercise.key);
  }

  componentDidUpdate(prevProps) {
    console.log('updating...', prevProps.athlete, this.props.athlete)
    if (this.props.athlete !== prevProps.athlete)
      this.props.retrieveRecordsHistory(this.props.athlete, 100, this.props.exercise.key);
  }

  renderPersonalRecords = () => {
    const { exercise, historyLog, userConfig, customExercises } = this.props;
    const { records, loading } = historyLog;
    const convertedRecords = records.map(record => {
      if (record.unit === userConfig.defaultUnit)
        return record;
      else {
        if (userConfig.defaultUnit === KG)
          return {...record, weight : lbsToKg(record.weight), unit : userConfig.defaultUnit};
        else
          return {...record, weight : kgToLbs(record.weight), unit : userConfig.defaultUnit }
      }
    });

    let currentRecord = null, actualRecord = {}, estRM = '';
    if (userConfig && userConfig.currentRecords) {
      currentRecord = userConfig.currentRecords[exercise.key];
      if (currentRecord) {
        actualRecord = getRecord(currentRecord);
        estRM = currentRecord.estRM;
      }
    }


    return (
      <>
      <Loading active={loading} />
      <HorizontalDiv>
      {currentRecord &&
        <Statistic.Group size='tiny'>
            <Statistic>
            <Statistic.Value>
              <Icon style={{color:'gold'}} name='trophy' />{actualRecord.weight} {actualRecord.unit || userConfig.defaultUnit}
            </Statistic.Value>
            <Statistic.Label style={{color:'darkgrey'}}>1RM</Statistic.Label>
            </Statistic>
            <Statistic>
            <Statistic.Value>
              <Icon style={{color:'gold'}} name='trophy' />{estRM} {currentRecord.unit || userConfig.defaultUnit}
            </Statistic.Value>
            <Statistic.Label style={{color:'darkgrey'}}>Projected</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        }
      </HorizontalDiv>
      <br/><br/>
      <PRChart
        data={convertedRecords}
      />
      <br/>
      <div style={{width:'100%', textAlign: 'center'}}>
        <RecordList
          customExercises={customExercises}
          allRecords={records}
          onRecordSelect={() => {}}
        />
      </div>
      </>
    )
  }

  renderInfo = () => {
    return <Header as='h4'> Instructions and videos coming soon! </Header>
  }

  renderHistory = () => {
    return <ExerciseHistory
              exercise={this.props.exercise}
              athlete={this.props.athlete}
            />
  }

  renderHelper = () => {
    const {tab} = this.state;
    switch(tab) {
      case 'info':
        return this.renderInfo();
      case 'personalRecords':
        return this.renderPersonalRecords();
      case 'history':
        return this.renderHistory();
      default:
        return this.renderPersonalRecords();
    }
  }

  updateRecord = (key, value) => {
    this.setState({ newRecord : {...this.state.newRecord, [key] : value }});
  }

  render() {
    const { exercise, clearExercise } = this.props;
    return (
      <>
      <HeaderGeneric
          itemRight={<PRModal
                      newRecord={this.state.newRecord}
                      updateRecord={this.updateRecord}
                      trigger={<Button size='mini' style={{fontSize: '20px', background: 'transparent'}} icon='add' />}
                    />}
          itemLeft={ <Button style={{fontSize: '20px', background: 'transparent'}} icon="arrow left" onClick={() => clearExercise()} />}
        >
        </HeaderGeneric>
      <SizingContainer>
          <HorizontalDiv>
          </HorizontalDiv>
          <Button.Group fluid primary>
            <Button primary size='large' basic={this.state.tab !== 'info'} onClick={()=> this.setState({tab:'info'})}>Info</Button>
            <Button primary size='large' basic={this.state.tab !== 'personalRecords'} onClick={()=> this.setState({tab:'personalRecords'})}>Personal Records</Button>
            <Button primary size='large' basic={this.state.tab !== 'history'} onClick={()=> this.setState({tab:'history'})}>History</Button>
          </Button.Group>
          <br/><br/>
          <StyledContainer>
          <Header> {exercise.text}</Header>
          <StyledContainer style={{width: '100%'}}>
            {this.renderHelper()}
          </StyledContainer>
          </StyledContainer>
      </SizingContainer></>
    );
  }
}

const mapStateToProps = state => {
  return {
           historyLog: state.historyLog,
           config : state.config
         }
};

export default connect(mapStateToProps, {retrieveRecordsHistory})(ExerciseReview);
