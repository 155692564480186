import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';

class MultiSelect extends Component {

  handleChange = (e, { value }) => {
    const { selectedValues, handleValuesChange } = this.props;
    if (selectedValues.length > value.length) { // an item has been removed
        const difference = selectedValues.filter(
            x => !value.includes(x),
        );
        //console.log(difference); // this is the item
        let updatedValues = [...selectedValues]
        const index = updatedValues.indexOf(difference[0]);
        if (index > -1) {
          updatedValues.splice(index, 1);
        }
        handleValuesChange(updatedValues);
    }
    return handleValuesChange(value);
};

  render() {
      const { selectedValues, placeholder, options} = this.props;
      return <Dropdown placeholder={placeholder || ''} fluid multiple selection options={options} value={selectedValues}
                onChange={this.handleChange} />
  }
}

export default MultiSelect;
