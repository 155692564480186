import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { HorizontalDiv } from '../StyledComponents';
import exerciseCategories from '../../assets/exercise_category';
import exerciseCategoriesL2 from '../../assets/exercise_category_level_2';
import { getCategoryName, getCategoryL2Name } from '../../commonMethods';

export const CategoryDropdown = ({onChange, exerciseOptions, icon, category, category_level_2}) => {
  return (
    <HorizontalDiv style={{justifyContent:"flex-start", margin: '4px'}}>
      <Dropdown
        fluid
        button
        className='icon'
        floating
        labeled
        icon={icon}
        options={exerciseCategories}
        text={getCategoryName(category)}
        onChange={(e, data) => onChange('category', data.value, exerciseOptions )}
      />
      <Dropdown
        fluid
        button
        className='icon'
        floating
        labeled
        icon={icon}
        options={exerciseCategoriesL2}
        text={getCategoryL2Name(category_level_2)}
        onChange={(e, data) => onChange('category_level_2', data.value, exerciseOptions )}
      />
    </HorizontalDiv>
  )
}
