import React, {Component} from 'react';
import Debug from '../../Debug';
import { Header} from 'semantic-ui-react';
import { Paragraph } from '../StyledComponents';
import { getExerciseOption } from '../../commonMethods';

class ExerciseComplexSummary extends Component {

  static defaultProps = {

  }

  renderNotes = (notes) => {
    if (notes)
      return <><Paragraph>{notes}</Paragraph></>
    else
      return null;
  }

  //returns 2d array [weight][sets] with
  getMissedSets = (complexArr) => {
    let misses = [];
    for (var i=0; i<complexArr.length; i++) {
      const { weightArr } = complexArr[i];
      for (var j=0; j<weightArr.length; j++) {
        if (i === 0)
          misses.push([]);
        let missedSets = [];

        const { reps, setArr } = weightArr[j]
        for (var k=0; k<setArr.length; k++) {
          const { pattern } = setArr[k];
          let madeReps = pattern.match(/1/g);
          madeReps = madeReps ? madeReps = madeReps.length : 0;
            if (reps > madeReps)
              missedSets.push(k+1)
        }
        misses[j] = [...new Set( [...misses[j], ...missedSets])];
      }
    }
    return misses;
  }

  render() {
    Debug(this.props);
    const {complexArr, headerSize, config} = this.props;
    Debug(complexArr);
    const misses = this.getMissedSets(complexArr);
    return (

        <div style={{paddingBottom:'3%'}}>
          <Header style={{marginBottom : '5px'}} as={headerSize || 'h3'} dividing>
            {complexArr.map((exerciseObj, index) => {
              const { exercise, weightArr } = exerciseObj;
              let str = (index === 0) ? '' : '+ ';
              str += weightArr[0].reps + " " + getExerciseOption(exercise, config.customExercises).text + '\n';
              return <span key = {'complex-sum' + index}>{str}<br/></span>
            }
            )}
            </Header>
          {complexArr[0].weightArr.map((weightObj, index) => {
            const { weight, sets, setArr, unit } = weightObj;
            return <div key={'weight-sum' + index}> {(weight>0 ? <span>{sets} {sets>1 ? "sets" : "set"} @ {weight} {unit}<br/></span>
                :  sets>0 ? <span>{sets} {sets>1 ? "sets" : "set"}<br/></span> : <></>)}
                {misses[index] && misses[index].length > 0 && setArr && setArr.map((setObj, j) => {
                    if (misses[index].includes(j+1))
                      return <div key={'set-' + j}><span style={{marginLeft: '3%', fontSize: '12px'}}> Set {j + 1} x </span><br/></div>
                    else
                      return null;
                })}
                </div>
          })}
          {this.renderNotes(complexArr[0].coachNotes)}
          {this.renderNotes(complexArr[0].exerciseNotes)}
        </div>
    );
  }
}

export default ExerciseComplexSummary;
