import React, { Component } from 'react';
import { Dropdown, Modal, Button, Input } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import DatePicker from "react-datepicker";
import { StyledContainer, HorizontalDiv } from '../StyledComponents';
import { firebaseDb } from '../../firebase';
import { cloneDeep } from 'lodash';
import { PROGRAMMED, STRENGTH } from '../../assets/literals';
import { intensityResolution } from '../../commonMethods';


const INITIAL_STATE = {
  date: new Date(),
  name: '',
  athletes: [],
  loading : false,
  error: '',
  success : ''
}

class WorkoutCopier extends Component {

  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE, name : props.workout.name, date : props.workout.date.toDate()};
  }

  teamToOptions = (team) => {
    const { members } = team;
    let options = []
    for (var i=0; i< members.length; i++ ) {
      const { name, uid } = members[i];
      options.push({ key : uid, text : name, value : uid });
    }
    return options;
  }

  renderDatePicker = () => {
    return <StyledContainer>
          <HorizontalDiv
                  style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems:'baseline'}}
              >
              <DatePicker
                id='datepicker-start'
                selected={this.state.date}
                onChange={date => {
                    this.setState({date, loading: false, error: '', success : ''});
                }}
                value={this.state.date}
                placeholderText="Date"
                className='ui button basic bold customDatePicker'
                withPortal
                required={true}
              />
            </HorizontalDiv>
          </StyledContainer>
  }

  handleChange = (e, { value }) => {
    if (this.state.athletes.length > value.length) { // an item has been removed
        const difference = this.state.athletes.filter(
            x => !value.includes(x),
        );
        //console.log(difference); // this is the item
        let updatedAthletes = [...this.state.athletes]
        const index = updatedAthletes.indexOf(difference[0]);
        if (index > -1) {
          updatedAthletes.splice(index, 1);
        }
        return this.setState({ athletes : updatedAthletes, loading: false, error: '', success : '' });
    }
    return this.setState({ athletes: value, loading: false, error: '', success : '' });
};

generateWorkouts = (workouts) => {

    var batch = firebaseDb.batch();
    const ref = firebaseDb.collection("workouts");
    workouts.forEach(workout => {
      batch.set(ref.doc(), workout);
    });

    this.setState({ loading : true, success : '', error : ''});

    batch.commit().then(() => {
      this.setState({ loading : false, success : "Workout successfully copied!"})
    }).catch( error => {
      this.setState({ loading : false, error : error.message });
    })
}

getUpdatedExerciseArr = (exerciseArr, workingMaxes, customExercises) => {
  let updatedExerciseArr = cloneDeep(exerciseArr);
  for (var i=0; i<updatedExerciseArr.length; i++) {
    if (updatedExerciseArr[i].type === STRENGTH || updatedExerciseArr[i].type === undefined)
      updatedExerciseArr[i].workingMax = intensityResolution(updatedExerciseArr[i].exercise, workingMaxes, customExercises);
  }
  return updatedExerciseArr;
}

handleClick = () => {
  const { workout, config } = this.props;
  const { athleteConfigs, customExercises } = config;
  const { date, name, athletes} = this.state;
  let workouts = [];

  if (athletes.length > 0) {
      for (var i=0; i<athletes.length; i++) {
        let athleteConfig = athleteConfigs[athletes[i]];
        workouts.push({...workout, defaultUnit : athleteConfig.defaultUnit, date, name, userId : athletes[i], status : PROGRAMMED,
          exerciseArr : this.getUpdatedExerciseArr(workout.exerciseArr, athleteConfig.workingMaxes, customExercises)});
      }
      this.generateWorkouts(workouts)
  }
  else {
    this.setState({ error: 'No athletes selected.'})
  }
}

  render() {
    const { config } = this.props;
    const { team } = config;
    return (
      <Modal
        closeIcon
        size="small"
        trigger={<Button basic compact size='tiny' style={{float: 'right'}}>
            Copy to team
          </Button>}
      >
        <Modal.Header>Copy Workout</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {this.renderDatePicker()}
            <br/>
            <Input style={{width: '100%'}} placeholder='Workout Name' value={this.state.name} onChange={(e, data) => this.setState({ name : data.value, loading: false, error: '', success : '' })}></Input>
            <br/><br/>
            <Dropdown placeholder='Athletes' fluid multiple selection options={this.teamToOptions(team)} value={this.state.athletes}
            onChange={this.handleChange} />
          </Modal.Description>
        </Modal.Content>
        {this.state.success && <div style={{textAlign : 'center'}} className='ui positive message'> {this.state.success}</div>}
        {this.state.error && <div style={{textAlign : 'center'}} className='ui negative message'> {this.state.error}</div>}
        <Modal.Actions>
          <Button
            content="Copy Workout"
            loading={this.state.loading}
            disabled={this.state.loading || this.state.success.length>0}
            primary
            onClick={this.handleClick}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default WorkoutCopier;
