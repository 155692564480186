import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import SetModal from './SetModal';
import Set from './Set';
import { SetToolTip } from './SetToolTip';
import { HorizontalDiv, StyledContainer, ButtonDiv, StyledButton } from '../StyledComponents';
import { deleteBuilderSet, addBuilderSet, updateBuilderSet } from '../../actions';

class SetContainer extends Component {

  renderSetCloseButton = (exerciseIndex, weightIndex, setIndex) => {
      return <ButtonDiv><StyledButton icon
                  style={{background:'transparent'}}
                  className='ui small button'
                  type='button'
                  variant='primary'
                  onClick={(e) => {this.props.deleteBuilderSet(exerciseIndex, weightIndex,setIndex)}}
                >
                <Icon name='trash'/>
                </StyledButton></ButtonDiv>
  }

  createDefaultSetObj = (setArr) => {
    let reps = setArr[0].pattern.length;
    let pattern = '';
    for (var i=0; i< reps; i++) {
        pattern+='1'
    }
    return {pattern}
  }

  renderSetAddButton = (exerciseIndex, weightIndex, setArr) => {
    return <ButtonDiv><StyledButton
                style={{background:'transparent'}}
                icon
                className='ui button'
                type='button'
                variant='primary'
                onClick={(e) => {
                  const setObj = this.createDefaultSetObj(setArr);
                  this.props.addBuilderSet(exerciseIndex, weightIndex, setObj)
                }}
              >
              <Icon name='add'/>
              </StyledButton></ButtonDiv>
}

  renderSets = () => {
    const { exerciseIndex, weightIndex, setArr, updateBuilderSet } = this.props;

    if (setArr && setArr.length) {
        //only display up to 20 sets to avoid rendering issues
        return setArr.slice(0,20).map((set, index) => {
        return <HorizontalDiv
                  style={{borderBottom: 'inset', width:'100%', display: 'flex', justifyContent: 'flex-start', alignItems:'baseline', marginTop: '5px'}}
                  key={'set-' + index}>
                  <SetModal
                    trigger={<Button type='button' compact size='tiny' style={{width: '6em'}}>Set {index+1}&nbsp;
                                {set.rpe && " @ " + set.rpe}
                              </Button>}
                    exerciseIndex={exerciseIndex}
                    weightIndex={weightIndex}
                    setIndex={index}
                    updateBuilderSet={updateBuilderSet}
                  />
                  <Set exerciseIndex={exerciseIndex} weightIndex={weightIndex} setIndex={index} setPattern={set.pattern} />
                  <div style={{width:'100%'}}>
                  {index === 0 ? <SetToolTip/> : this.renderSetCloseButton(exerciseIndex, weightIndex, index)}</div>
               </HorizontalDiv>
        });
    }
    else
      return <br/>
  }


  render() {
    const { exerciseIndex, weightIndex , setArr} = this.props;
    return (
       <StyledContainer style={{alignItems: 'start'}}>
        {this.renderSets()}
        {setArr.length > 0 && this.renderSetAddButton(exerciseIndex, weightIndex, setArr)}
      </StyledContainer>
    );
  }
}

export default connect(null, { addBuilderSet, deleteBuilderSet, updateBuilderSet })(SetContainer);
