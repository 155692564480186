import React from 'react';
import { Input } from 'semantic-ui-react';

export const ExerciseSearch = ({exerciseOptions, onSearchChange}) => {

  return (
  <Input
          style={{margin:'4px', width:'100%'}}
          icon='search'
          placeholder='Search...'
          onChange={(e) =>{onSearchChange(null, null, exerciseOptions, e.target.value)}}/>
  );

}
