import React, { useState }from 'react';
import { Dropdown, Button, Icon, Modal, Input, Header, Divider, TransitionablePortal } from 'semantic-ui-react';
import { HorizontalDiv, StyledContainer } from '../StyledComponents';
import { getExerciseOption } from '../../commonMethods';
import { CONDITIONING, WORKINGMAX, WEIGHT, UNIT } from '../../assets/literals';

export const PercentFillModal = ({ defaultOpen, exercises, defaultUnit, customExercises, updateBuilderExercise, updateBuilderWeight }) => {
        const [open, setOpen] = useState(false)
        const [weightUnit, setWeightUnit] = useState(defaultUnit || 'kg');
        let renderedComplexes = [];
        return (
          <TransitionablePortal
            defaultOpen={defaultOpen}
            trigger={<Button compact style={{background: 'transparent'}} onClick={() => setOpen(true)}><Icon name='percent' size='large' /></Button>}
            onMount={() => setOpen(true)}
            onClose={() => setOpen(false)}
            transition = {{
                animation: 'scale',
                duration: 800,
              }}
          >
          <Modal
            size='tiny'
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            closeIcon
          >
            <Modal.Header>Percentage Work!</Modal.Header>
            <Modal.Content>
                {exercises.length > 0 ?
                <div>
                  Enter or update your working maxes to compute your percentage weights.
                  <br/><br/>
                  <HorizontalDiv style={{justifyContent: 'center', alignItems: 'baseline', float: 'right'}}>
                      <Header as='h4'> Unit:&nbsp;&nbsp; </Header>
                      <Dropdown
                          additionLabel='Unit'
                          size='big'
                          options={[
                            { key: 'lbs', text: 'lbs', value: 'lbs' },
                            { key: 'kg', text: 'kg', value: 'kg' }
                          ]}
                          id='unit'
                          onChange={(e, data) => {setWeightUnit(data.value)}}
                          value={weightUnit}
                      />
                  </HorizontalDiv>
                </div>
              :
              <StyledContainer><br/><Header as='h4'> No exercises added yet... </Header></StyledContainer>}
                <br/><br/>
                {exercises.map((exerciseObj, exerciseIndex) => {
                const { type, exercise, workingMax, weightArr, complexId } = exerciseObj;
                if (type === CONDITIONING)
                  return null;

                let complexArr = [];
                if (complexId) {
                    if (renderedComplexes.includes(complexId))
                        return null;

                    renderedComplexes.push(complexId);
                    complexArr = exercises.filter((obj, i) => {
                        if (obj.complexId === complexId) {
                          return obj;
                        }
                        return null;
                    }, []);
                  }
                return <div key={'percentexercise-' + exerciseIndex}>
                            <PercentExercise
                              weightUnit={weightUnit}
                              exercise={exercise}
                              workingMax={workingMax}
                              weightArr={weightArr}
                              complexId={complexId}
                              complexArr={complexArr}
                              customExercises={customExercises}
                              exerciseIndex={exerciseIndex}
                              updateBuilderExercise={updateBuilderExercise}
                              updateBuilderWeight={updateBuilderWeight}
                            /><br/></div>
                })}
            </Modal.Content>
          </Modal>
          </TransitionablePortal>
        )
  }

  const PercentExercise = ({ weightUnit, exercise, workingMax, weightArr, complexId, complexArr, customExercises, exerciseIndex, updateBuilderExercise, updateBuilderWeight}) => {

    let hasPercent = false;

    const getComplexName = (complexArr) => {
      let name = '';
      for (var i=0; i<complexArr.length; i++) {
        name += getExerciseOption(complexArr[i].exercise, customExercises).text;
        if (i < complexArr.length-1)
          name += ' + ';
      }
      return name;
    }

    const getWeight = (percent, workingMax) => {
      return Math.round(percent/100 * workingMax)
    }

    return (
          <><HorizontalDiv style={{alignItems : 'baseline'}}>
            <Header as='h3'>{complexId ? getComplexName(complexArr) : getExerciseOption(exercise, customExercises).text}</Header>
            <Input fluid style={{width: '100%', maxWidth: '80px'}}
                   type='tel'
                   value={workingMax}
                   onChange={(e,data) => {
                     const newWorkingMax = data.value;
                     if (complexId) {
                       complexArr.forEach(complex => {
                         const { exerciseIndex } = complex;
                         updateBuilderExercise(exerciseIndex, WORKINGMAX, newWorkingMax)
                       })
                     }
                     else
                       updateBuilderExercise(exerciseIndex, WORKINGMAX, newWorkingMax)
                   }}
                   placeholder='Max'
            />
          </HorizontalDiv>

          {weightArr && weightArr.map((weightObj, i) => {
            const {weight, unit} = weightObj;
            if (unit === '%') {
              hasPercent = true;
            return <StyledContainer key={'percentweight' + i}><HorizontalDiv style={{alignItems : 'baseline', width: '100%', maxWidth : '250px'}}>
                <Header> {weight} {unit}</Header>
                <Icon name='arrow right' size='large' />
                <Header>{getWeight(weight, workingMax)} {weightUnit} </Header>
              </HorizontalDiv>
            </StyledContainer>
            }
            else return null
          })}
          {hasPercent && <><Button style={{float : 'right'}}
              primary
              onClick={() => {
                if (complexId) {
                  complexArr.forEach(complex => {
                    const { weightArr, exerciseIndex } = complex;
                    weightArr.forEach((weightObj, weightIndex) => {
                      const { weight, unit } = weightObj;
                      if (unit === '%') {
                        updateBuilderWeight(exerciseIndex, weightIndex, WEIGHT, getWeight(weight, workingMax));
                        updateBuilderWeight(exerciseIndex, weightIndex, UNIT, weightUnit);
                      }
                    });
                  })
                }
                else {
                  weightArr.forEach((weightObj, weightIndex) => {
                    const { weight, unit } = weightObj;
                    if (unit === '%') {
                      updateBuilderWeight(exerciseIndex, weightIndex, WEIGHT, getWeight(weight, workingMax))
                      updateBuilderWeight(exerciseIndex, weightIndex, UNIT, weightUnit)
                    }
                  });
                }
              }
          }>
          Fill </Button>
          <br/><br/></>}
          <Divider/></>
    )
  }
