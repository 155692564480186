import update from 'immutability-helper';
import { RETRIEVE_WORKOUTS, RETRIEVE_WORKOUTS_SUCCESS, RETRIEVE_WORKOUTS_FAIL, SELECT_WORKOUT,
        RETRIEVE_TRACK_WORKOUTS, RETRIEVE_TRACK_WORKOUTS_SUCCESS, RETRIEVE_TRACK_WORKOUTS_FAIL,
        CLEAR_SELECTED_WORKOUT, UPDATE_BUILDER_FIELD, UPDATE_BUILDER_EXERCISE, ADD_BUILDER_EXERCISE,
        ADD_BUILDER_WOD,
        DELETE_BUILDER_EXERCISE, ADD_BUILDER_WEIGHT, DELETE_BUILDER_WEIGHT, UPDATE_BUILDER_WEIGHT,
        UPDATE_BUILDER_SET, SAVE_WORKOUT, SAVE_WORKOUT_SUCCESS, SAVE_WORKOUT_FAIL, START_WORKOUT_BUILDER,
        COPY_TO_WORKOUT_BUILDER, DELETE_BUILDER_COMPLEX,
        DELETE_WORKOUT, DELETE_WORKOUT_SUCCESS, DELETE_WORKOUT_FAIL, ADD_BUILDER_SET, DELETE_BUILDER_SET,
        RETRIEVE_WORKOUT_HISTORY, ADD_PROGRAM_BUILDER_WORKOUT, SWAP_EXERCISES
      } from '../actions/types';
import Debug from '../Debug';

const INITIAL_STATE =  {
  workouts: [],
  loading: false,
  error: '',
  success: '',
  didInvalidate: false,
  selectedWorkout: null,
  workoutBuilder: {}
}


const INITIAL_WEIGHT = {
      weight: '',
      unit: 'kg',
      sets: '',
      reps: '',
      setArr: [],
      warmup: false
  }

const LogReducer = (state = INITIAL_STATE, action) => {
    Debug(action);
  switch (action.type) {
    case RETRIEVE_WORKOUTS_SUCCESS:
      return { ...state, workouts : action.payload, loading: false, error: '', didInvalidate: false, selectedWorkout: null, workoutBuilder: {} };
    case RETRIEVE_WORKOUTS_FAIL:
      return { ...state, loading: false, error: action.payload, didInvalidate: false, selectedWorkout: null, workoutBuilder: {} };
    case RETRIEVE_WORKOUTS:
      return { ...state, loading: true, error: '', didInvalidate: false, selectedWorkout: null, workoutBuilder: {} };
    case RETRIEVE_TRACK_WORKOUTS_SUCCESS:
      return { ...state, trackWorkouts : action.payload, loading: false, error: '', didInvalidate: false };
    case RETRIEVE_TRACK_WORKOUTS_FAIL:
      return { ...state, loading: false, error: action.payload, didInvalidate: false };
    case RETRIEVE_TRACK_WORKOUTS:
      return { ...state, loading: true, error: '', didInvalidate: false };
      case RETRIEVE_WORKOUT_HISTORY:
      return {...state, didInvalidate : false }
    case SELECT_WORKOUT:
     return { ...state, selectedWorkout: action.payload.id, workoutBuilder: {...action.payload, date: action.payload.date ? action.payload.date.toDate() : null }}; //convert firestore date to JS date
     case CLEAR_SELECTED_WORKOUT:
      return { ...state, selectedWorkout: null, workoutBuilder: {} };
    case START_WORKOUT_BUILDER:
      return { ...state, selectedWorkout: 1, workoutBuilder: action.payload, error: '' }
    case COPY_TO_WORKOUT_BUILDER:
        return { ...state, workouts: [], selectedWorkout: 1, didInvalidate: false, workoutBuilder: action.payload, error : '' }
      case UPDATE_BUILDER_FIELD:
       return { ...state,
                workoutBuilder: {...state.workoutBuilder, [action.payload.key]: action.payload.value, error: '' }};
    case UPDATE_BUILDER_EXERCISE:
      if ((action.payload.key === 'exercise' || action.payload.key === 'title') && state.workoutBuilder.exercises && state.workoutBuilder.exercises.length === state.workoutBuilder.exerciseArr.length) {
           return update(state, {
               workoutBuilder : {
                 exercises : {[action.payload.exerciseIndex] : {$set: action.payload.value}},
                 exerciseArr :  {
                   [action.payload.exerciseIndex]:
                        {[action.payload.key] :{$set: action.payload.value}}
                }
             }
           });
      }
      else {
        return update(state, {
            workoutBuilder : {
              exerciseArr :  {
                [action.payload.exerciseIndex]:
                     {[action.payload.key] :{$set: action.payload.value}}
             }
          }
        });
      }
     case ADD_BUILDER_EXERCISE:
      return update(state, {
        workoutBuilder : {
          exercises : {$push: [action.payload.exercise]},
          exerciseArr : {
            $push: [action.payload]
          }
        }
      });
      case SWAP_EXERCISES:
       return { ...state, workoutBuilder: {...state.workoutBuilder, exercises: action.payload.exercises, exerciseArr: action.payload.exerciseArr}  }
      case ADD_BUILDER_WOD:
       return update(state, {
         workoutBuilder : {
           exercises : {$push: [""]},
           exerciseArr : {
             $push: [action.payload]
           }
         }
       });
      case DELETE_BUILDER_EXERCISE:
       return update(state, {
         workoutBuilder : {
           exercises : {$splice:[[action.payload, 1]]},
           exerciseArr : {
             $splice: [[action.payload, 1]]
           }
         }
       });
       case ADD_BUILDER_WEIGHT:
        return update(state, {
            workoutBuilder : {
              exerciseArr : {
                [action.payload.exerciseIndex]: {
                  weightArr:
                    {$push:[{...INITIAL_WEIGHT, reps : action.payload.reps || '', unit : action.payload.unit || 'kg'}]
                  }
                }
              }
            }
          });
        case DELETE_BUILDER_WEIGHT:
          return update(state, {
            workoutBuilder : {
              exerciseArr: {
                [action.payload.exerciseIndex]:
                    {weightArr : { $splice: [[action.payload.weightIndex, 1]]}}
              }
            }
          });
      case UPDATE_BUILDER_WEIGHT:
          return update(state, {
            workoutBuilder : {
              exerciseArr : {
                [action.payload.exerciseIndex]:
                      {weightArr : {
                          [action.payload.weightIndex]:
                              {[action.payload.key] :{$set: action.payload.value}}}
                      }
              }
            }
        });
        case ADD_BUILDER_SET:
          return update(state, {
            workoutBuilder : {
              exerciseArr : {
                [action.payload.exerciseIndex]:
                      {weightArr : {
                          [action.payload.weightIndex]: {
                            setArr : {$push:[action.payload.setObj]},
                            sets : {$apply: function(sets) {return sets + 1;}}
                          }
                        }
                      }
                    }
                  }
              });
         case DELETE_BUILDER_SET:
           return update(state, {
             workoutBuilder : {
               exerciseArr : {
                 [action.payload.exerciseIndex]:
                       {weightArr : {
                           [action.payload.weightIndex]: {
                             setArr : { $splice: [[action.payload.setIndex, 1]]},
                             sets : {$apply: function(sets) {return sets - 1;}}
                           }
                         }
                       }
                     }
                   }
               });
        case UPDATE_BUILDER_SET:
            return update(state, {
              workoutBuilder : {
                exerciseArr : {
                  [action.payload.exerciseIndex]:
                        {weightArr : {
                            [action.payload.weightIndex]: {
                              setArr : {
                                  [action.payload.setIndex]:
                                    {[action.payload.key] :{$set: action.payload.value}}
                              }
                            }
                          }
                        }
                      }
                  }
        });
        case DELETE_BUILDER_COMPLEX:
          let { exerciseArr } = state.workoutBuilder;
          //find size and index of complex to delete from exercises
          let complexCount = 0, index = null;
          for (var i=0; i <exerciseArr.length; i++) {
            if (exerciseArr[i].complexId === action.payload) {
              if (index === null)
                index = i;
              complexCount++
            }
          }
          Debug(index, complexCount)

          return update(state, {
            workoutBuilder : {
              exercises : {$splice:[[index, complexCount]]},
              exerciseArr : arr => arr.filter(obj => obj.complexId !== action.payload)
            }
        });
        case SAVE_WORKOUT:
          return { ...state, loading: true, error: '' };
       case SAVE_WORKOUT_SUCCESS:
          return { ...state,  loading: false, success : action.payload.success, error: '', workoutBuilder : { ...action.payload.workout, id: action.payload.id }};
      case SAVE_WORKOUT_FAIL:
          return { ...state, loading: false, error: action.payload, didInvalidate: false };
      case DELETE_WORKOUT:
          return { ...state, loading: true, error: '' };
      case DELETE_WORKOUT_SUCCESS:
        return { ...state,  loading: false, error: '', didInvalidate: true, selectedWorkout: null, workoutBuilder: {} };
      case DELETE_WORKOUT_FAIL:
        return { ...state, loading: false, error: action.payload, didInvalidate: false };
      case ADD_PROGRAM_BUILDER_WORKOUT:
        return { ...state,   workouts : [...state.workouts, action.payload.obj.workout]}; //convert firestore date to JS date
    default:
      return state;
  }
}

export default LogReducer;
