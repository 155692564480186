import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Button, List, Card } from 'semantic-ui-react';
import HeaderMain from './HeaderMain';
import Program from '../program/Program';
import { SuccessPortal } from '../common/SuccessPortal';
import { Loading } from '../common/Loading';
import { firebaseAuth } from "../../firebase";
import { startProgramBuilder, retrievePrograms, clearPrograms, selectProgram } from '../../actions';
import { priceToString } from '../../commonMethods';

class Programs extends Component {
  //TODO: Fix program purchasing flow.
  componentDidMount() {
    const { retrievePrograms, clearPrograms } = this.props;
      clearPrograms();
      retrievePrograms(true, false, null, false); //get public programs
      retrievePrograms(false, true, null, true) // get user created programs
  }

  componentDidUpdate(prevProps) {
    const { config, program, retrievePrograms } = this.props;
    const { userPrograms, loading, didInvalidate } = program;
    const { programs } = config.user;
    if ((programs && programs.length > 0 && userPrograms && userPrograms.length === 0 && loading === false) || didInvalidate === true)
      retrievePrograms(false, false, programs, true) // retrieve user purchased programs

  }

  handleClick = () => {
    const { config } = this.props;
    const { team } = config;
    this.props.startProgramBuilder(firebaseAuth.currentUser.uid, team.id);
  }

  renderProgramCard = (program, index, showPrice) => (
    <List.Item key={'program-' + index }style={{ margin: '1em', marginBottom : '2em'}}>
      <Card onClick={() => this.props.selectProgram(program)}>
          <Card.Content style={{ display: 'flex', justifyContent: 'center', paddingTop: '50px', height: '250px', backgroundImage : "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/assets/pexels-tima-miroshnichenko-resize.jpg')", backgroundSize : 'cover'}}>
            <Card.Header as='h2' style={{ color: 'ghostwhite'}}>{program.name}</Card.Header>
          </Card.Content>
          <Card.Content>
          <Card.Meta>
            <span>{program.teamName}</span> <span>{program.coachName}</span>
          </Card.Meta>
          {showPrice && <Header as='h3'> {priceToString(program.price)} </Header>}
          </Card.Content>
      </Card>
    </List.Item>
  )

  render() {
    const { program } = this.props;
    const { loading, programs, userPrograms, selectedProgram, success } = program;

    if (loading)
      return <Loading active={true} />

    return(
      <>
      <HeaderMain
        activeItem='Programs'
        />
        <br/>
      <div>
        {success && <SuccessPortal text={success}></SuccessPortal>}
          {selectedProgram ?
              <div><Program/></div>
              :
              <>
              <div>
                <Button primary size='big' onClick={() => this.handleClick()}> Create New Program </Button><br/><br/>
                <Header as='h2'>My Programs</Header>
                <List horizontal>
                  <span style={{marginLeft : '1em'}} />
                  {userPrograms && userPrograms.length > 0 ? userPrograms.map((program, index) => {
                    return (
                      this.renderProgramCard(program, index, false)
                    )
                  })
                  : <List.Item><p> You currently do not have any programs. </p></List.Item>
                  }
                  </List>
                </div>
                {false && <><Header as='h2'>Program Catalog</Header>
                <List horizontal>
                  <span style={{marginLeft : '1em'}} />
                  {programs && programs.map((program, index) => {
                    return (
                      this.renderProgramCard(program,index,true)
                    )
                  })
                }
                </List></>}
              </>
          }
        <br/><br/>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    config : state.config,
    program : state.program
  }
}

export default connect(mapStateToProps, { startProgramBuilder, retrievePrograms, selectProgram, clearPrograms} )(Programs);
