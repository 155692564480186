import React, { PureComponent } from 'react';
import { Icon } from 'semantic-ui-react';
import { PieChart, Pie,  Cell } from 'recharts';
import { HorizontalDiv } from '../StyledComponents';
import { getCategoryColor } from '../../commonMethods';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  if (percent > 0) {
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  }
  else
    return null
};

class CategoryPieChart extends PureComponent {

  render() {
    const { data, metric, showSideLabels, showBottomLabels, width, height  } = this.props;
    return (
        <><HorizontalDiv style={{width:'100%', height : '100%'}}>
        {showSideLabels && <div>{
          data.map((entry, index) => <div key={`label-${index}`}><Icon name='circle' style={{ color: getCategoryColor(entry.key)}}></Icon>
                                        <span style={{fontSize : '10px', textTransform : 'capitalize'}}>{data[index].key}</span></div>)
        }
        </div>
        }
                <PieChart width={width} height={height}>
                  <Pie
                    data={data}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    fill="#8884d8"
                    dataKey={metric}
                    isAnimationActive={false}
                  >
                    {
                      data.map((entry, index) => <Cell key={`cell-${index}`} fill={getCategoryColor(entry.key)} />)
                    }
                  </Pie>
                </PieChart>
          </HorizontalDiv>
          {showBottomLabels && <span>{
            data.map((entry, index) => <span key={`label-${index}`}><Icon name='circle' style={{ color: getCategoryColor(entry.key)}}></Icon>
                                          <span style={{fontSize : '10px', textTransform : 'capitalize'}}>{data[index].key}</span></span>)
          }
          </span>
        }</>
    );
  }
}

export default CategoryPieChart;
