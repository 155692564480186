import React, {Component} from 'react';
import { List, Transition, Card, Icon, Form } from 'semantic-ui-react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Debug from '../../Debug';
import { connect } from 'react-redux';
import { updateBuilderExercise, deleteBuilderExercise, addBuilderWeight } from '../../actions';
import Weight from '../weights/Weight';
import { StyledButton, HorizontalDiv, ButtonDiv } from '../StyledComponents';
import ExerciseModal from './ExerciseModal';
import { EmbedModal } from '../common/EmbedModal';
import { intensityResolution, getExerciseOption } from '../../commonMethods';

class Exercise extends Component {

  constructor() {
    super();
    this.exerciseRef = React.createRef();
  }

   componentDidMount() {
       if (this.props.scrollExerciseIntoView) {
         this.exerciseRef.current.scrollIntoView({
            behavior: "smooth",
            block: 'center',
            inline: 'center'
          });
      }
   }

  handleOnChange = (e, data) => {
     Debug(data.value);
  }

  onExerciseSelect = exercise => {
    const {updateBuilderExercise, exerciseIndex, athleteConfig, customExercises, ignoreIntensity} = this.props;
    updateBuilderExercise(exerciseIndex, 'exercise', exercise);
    if (!ignoreIntensity)
      updateBuilderExercise(exerciseIndex, 'workingMax', intensityResolution(exercise, athleteConfig.workingMaxes, customExercises) );
  }

  onDragEndWeight = result => {
    const { exerciseIndex, weightArr, updateBuilderExercise } = this.props;
    const {source, destination} = result;
    if (destination && source.index !== destination.index) {
      const from = source.index, to = destination.index;

      let newWeightArr = weightArr.map(a => ({...a}));
      if (to > from) {
        for (var i=from; i<to; i++) {
          [newWeightArr[i], newWeightArr[i+1]] = [newWeightArr[i+1], newWeightArr[i]];
        }
      }
      else if (from > to) {
        for (var j=from; j>to; j--) {
          [newWeightArr[j], newWeightArr[j-1]] = [newWeightArr[j-1], newWeightArr[j]];
        }
      }
      updateBuilderExercise(exerciseIndex, 'weightArr', newWeightArr);
    }
  }

  render() {
    const {exercise, exerciseIndex, workingMax, defaultUnit, weightArr, exerciseNotes, coachNotes, programming, athleteConfig, deleteBuilderExercise, updateBuilderExercise, addBuilderWeight } = this.props;
    const { videoId } = getExerciseOption(exercise);
    const xButton = <ButtonDiv><StyledButton icon
                  style={{background:'transparent'}}
                  type='button'
                  variant='primary'
                  onClick={(e) => {deleteBuilderExercise(exerciseIndex)}}
                >
                <Icon name='x'/>
                </StyledButton></ButtonDiv>

    return (
       <div ref={this.exerciseRef}>
        <Card style={{background: '#f6f6f6', fontSize: '16px', width:'99%', userSelect: 'none', WebkitUserSelect: 'none'}}>
          <HorizontalDiv
            style={{paddingLeft:'3%'}}>
            <HorizontalDiv>
                <ExerciseModal
                  exercise={exercise}
                  onExerciseSelect = {this.onExerciseSelect}
                  onExit={() => { if (!exercise)
                                    deleteBuilderExercise(exerciseIndex)}
                                }
                  exerciseIndex={exerciseIndex}
                  open={exercise ? false : true}
                />
                {videoId &&
                  <EmbedModal
                    videoId={videoId}
                  />
                }
              </HorizontalDiv>
              {xButton}
          </HorizontalDiv>
          <br/>
          {weightArr &&
          <DragDropContext onDragEnd={this.onDragEndWeight}>
            <Droppable droppableId={'e' + exerciseIndex}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
            >
            <Transition.Group
              as={List}
              duration={200}
              divided
              animation="slide down"
            >
          {weightArr.map((weightObj, index) =>
            <List.Item key={'weight-' + index} ><Draggable draggableId={'w' + index} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Weight key={'weight-' + exerciseIndex + '-' + index}
                            exerciseIndex={exerciseIndex}
                            exercise={exercise}
                            workingMax={workingMax}
                            defaultUnit={defaultUnit}
                            weightIndex={index}
                            weight={weightObj.weight}
                            unit={weightObj.unit}
                            sets={weightObj.sets}
                            setArr={weightObj.setArr}
                            reps={weightObj.reps}
                            athleteConfig={athleteConfig}
                    />
                  </div>
                )}
              </Draggable>
              </List.Item>
              )}
              </Transition.Group>
              {provided.placeholder}
              </div>
            )}
            </Droppable>
          </DragDropContext>
          }
          <br/>
          <StyledButton
              className='ui mini button'
              fluid
              type='button'
              onClick={(e) => {addBuilderWeight(exerciseIndex, null, athleteConfig.defaultUnit)}}
            > <Icon name='add' />&nbsp;Add
            </StyledButton>
            <br/>
            {(programming || coachNotes) &&  <Form.TextArea
              readOnly={programming ? false : true}
              maxLength='500'
              placeholder="Coach's Notes..."
              value={coachNotes}
              onChange={(e) => {updateBuilderExercise(exerciseIndex, 'coachNotes', e.target.value)}}
             />}
            {!programming && <Form.TextArea
              maxLength='500'
              rows='2'
              placeholder='Notes...'
              value={exerciseNotes}
              onChange={(e) => {updateBuilderExercise(exerciseIndex, 'exerciseNotes', e.target.value)}}
             />}
        </Card>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
           config: state.config
         }
}

export default connect(mapStateToProps, { updateBuilderExercise, deleteBuilderExercise, addBuilderWeight })(Exercise);
