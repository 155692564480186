import React, {Component} from 'react';
import { Header, Modal, Button } from 'semantic-ui-react';
import Debug from '../../Debug';

const INITIAL_STATE = {
  open: false,
  rpe: null,
  description: ''
}

const descriptions = {
  6 : "Moderate effort - could comfortably perform reps",
  6.5 : "3-4 more reps could be completed",
  7 : "3 more reps could be completed",
  7.5 : "2-3 more reps could be completed",
  8 : "2 more reps could be completed",
  8.5 : "1 possibly 2, more reps could be completed",
  9 : "1 more rep could be completed",
  9.5 : "No more reps could be completed but possibly slightly more weight",
  10 : "Max effort - No more reps or weight could be completed"
}


const RpeButton = ({rpe, description, handleChange}) => <Button onClick={() => handleChange(rpe, description)}>{rpe}</Button>;

class SetModal extends Component {
  constructor(props) {
    super(props);
    Debug("Props:", props);
    this.state = {...INITIAL_STATE, open: props.open}
  }

  handleChange = (rpe, description) => {
    this.setState({rpe, description});
  }

  render() {
    const { open, rpe, description } = this.state;
    const { trigger, weightIndex, setIndex } = this.props;
    return (
      <Modal
        size='small'
        closeIcon
        onClose={() => this.setState(INITIAL_STATE)}
        onOpen={() => this.setState({open: true})}
        open={open}
        trigger={trigger}
      >
      <Modal.Header>Record Rate of Perceived Exertion</Modal.Header>
      <Modal.Content >
        <Modal.Description>
          <Header>RPE {rpe}</Header>
          <Header as='h5'>&nbsp;{description}</Header>
            <Button.Group compact fluid size="tiny">
              <RpeButton rpe='6' description={descriptions[6]} handleChange={this.handleChange} />
              <RpeButton rpe='7' description={descriptions[7]} handleChange={this.handleChange} />
              <RpeButton rpe='7.5' description={descriptions[7.5]} handleChange={this.handleChange} />
              <RpeButton rpe='8' description={descriptions[8]} handleChange={this.handleChange}/>
              <RpeButton rpe='8.5' description={descriptions[8.5]} handleChange={this.handleChange}/>
              <RpeButton rpe='9' description={descriptions[9]} handleChange={this.handleChange}/>
              <RpeButton rpe='9.5' description={descriptions[9.5]} handleChange={this.handleChange}/>
              <RpeButton rpe='10' description={descriptions[10]} handleChange={this.handleChange}/>
            </Button.Group>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => {
            //if complex, change all exercise in complex
            if (this.props.complexArr) {
              this.props.complexArr.forEach(exerciseObj => {
                this.props.updateBuilderSet(exerciseObj.exerciseIndex, weightIndex, setIndex, 'rpe', null);
              })
            }
            else //if exercise
            this.props.updateBuilderSet(this.props.exerciseIndex, weightIndex, setIndex, 'rpe', null);

            this.setState({open : false})}
          }>
          Cancel
        </Button>
        <Button
          onClick={() => {
            Debug(this.props)
            //if complex, change all exercise in complex
            if (this.props.complexArr) {
              this.props.complexArr.forEach(exerciseObj => {
                Debug('herez')
                this.props.updateBuilderSet(exerciseObj.exerciseIndex, weightIndex, setIndex, 'rpe', this.state.rpe);
              })
            }
            else //if exercise
            this.props.updateBuilderSet(this.props.exerciseIndex, weightIndex, setIndex, 'rpe', this.state.rpe);

            this.setState({open : false})}
        }
        primary
        >Select
        </Button>
      </Modal.Actions>
    </Modal>
    );
  }
}

export default SetModal;
