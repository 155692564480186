import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getIntensityZoneLabel } from '../../commonMethods';
export const IntensityZoneBarChart = ({data, isProgramming}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        layout='vertical'
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis type="number" />
        <YAxis style={{color: 'red', fontSize: '16px', fontWeight: 'bold'}}
            dataKey="intensityZone"
            type="category"
            tickLine={false}
            interval={0}
            tickFormatter = {(zone) => {
                return getIntensityZoneLabel(zone);
            }}
         />
        <Tooltip
          labelFormatter={(zone) => {
            return getIntensityZoneLabel(zone);
          }}
        />
        <Legend
          formatter={(value, entry, index) => {
              if (value === 'madeReps')
                return isProgramming ? 'Reps' :'Reps Made';
              else if (value === 'missedReps')
                return isProgramming ? '' : 'Reps Missed'
              else
                return value;
          }}
        />
        <Bar dataKey="madeReps" stackId="a" fill="#ADC2A9" />
        {!isProgramming && <Bar dataKey="missedReps" stackId="a" fill="#DF2E2E" />}
      </BarChart>
      </ResponsiveContainer>
    );
}
