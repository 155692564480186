import React from 'react';
import { Header, List } from 'semantic-ui-react';
import { HorizontalDiv } from '../StyledComponents';

export const ListItem =  ({leftHandHeader, subHeader, rightHand}) =>   <List.Item>
    <div style={{height: '40px'}}>
        <HorizontalDiv style={{justifyContent: 'space-between', width: '100%', height:'100%'}}>
            {leftHandHeader &&
              <Header as='h5' style={{marginTop:'0'}} textAlign="left">{leftHandHeader}
              <Header.Subheader style={{color:'grey', fontWeight: 'normal'}}> {subHeader}</Header.Subheader>
            </Header>}
            {rightHand && <div>{rightHand}</div>}
        </HorizontalDiv>
      </div>
      </List.Item>
