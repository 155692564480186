import _ from 'lodash';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firebaseAuth, firebaseDb, programsRef } from '../firebase';
import Debug from '../Debug';
import { START_PROGRAM_BUILDER, CLEAR_SELECTED_PROGRAM, UPDATE_PROGRAM_BUILDER_FIELD, UPDATE_PROGRAM_BUILDER_LAYOUT, ADD_PROGRAM_BUILDER_LAYOUT, ADD_PROGRAM_BUILDER_WEEK,
         ADD_PROGRAM_BUILDER_DAY, DELETE_PROGRAM_BUILDER_LAYOUT, ADD_PROGRAM_BUILDER_WORKOUT, SAVE_PROGRAM, SAVE_PROGRAM_SUCCESS, SAVE_PROGRAM_FAIL, DELETE_PROGRAM,
         DELETE_PROGRAM_SUCCESS, DELETE_PROGRAM_FAIL, RETRIEVE_PROGRAMS, RETRIEVE_PROGRAMS_SUCCESS, RETRIEVE_PROGRAMS_FAIL, RETRIEVE_PROGRAM_LAYOUT, RETRIEVE_PROGRAM_LAYOUT_SUCCESS,
         RETRIEVE_PROGRAM_LAYOUT_FAIL, SELECT_PROGRAM, RETRIEVE_PROGRAM_HISTORY, RETRIEVE_PROGRAM_HISTORY_SUCCESS, CLEAR_PROGRAMS
      } from './types';
import { BLOCKS, WEEKS, DAYS, WORKOUTS } from '../assets/literals';

export const selectProgram = (programId) => {
    return  {
      type: SELECT_PROGRAM,
      payload: programId
    }
}

export const startProgramBuilder = (uid, team) => {

  const emptyLayout = {
    workouts : {},
    blocks : {},
    blockOrder : {},
    weeks : {},
    days : {},
    creator: uid || firebaseAuth.currentUser.uid,
  }

  const emptyProgram = {
    name: '',
    tags: [],
    description : '',
    frequency : 0,
    creator: uid || firebaseAuth.currentUser.uid,
    layout : emptyLayout,
    team : team || ''
  }
  return  {
    type: START_PROGRAM_BUILDER,
    payload: emptyProgram
  }
}

export const clearSelectedProgram = () => {
    return  {
      type: CLEAR_SELECTED_PROGRAM
    }
}

export const updateProgramBuilderField = (key, value) => {
  return {
    type: UPDATE_PROGRAM_BUILDER_FIELD,
    payload: {
      key,
      value
    }
  }
}

export const updateProgramBuilderLayout = (section, id, key, value) => {
  return {
    type: UPDATE_PROGRAM_BUILDER_LAYOUT,
    payload: {
      section,
      id,
      key,
      value
    }
  }
}

const getBlankLayoutObject = (section, id,  index, workout) => {
  switch (section) {
    case BLOCKS:
      return {
          id,
          title: 'Block ' + (index+1),
          weeks: []
      }
    case WEEKS:
      return {
          id,
          title: 'Week ' + (index+1),
          days: []
      }
    case DAYS:
      return {
          id,
          title: 'Day ' + (index+1),
          workouts: []
      }
    case WORKOUTS:
      return {
          id,
          content : 'Workout ' + (index+1),
          workout
      }
    default:
      return
  }
}

export const addProgramBuilderLayout = (section, id, index, workout) => {

  return {
    type: ADD_PROGRAM_BUILDER_LAYOUT,
    payload: {
      section,
      obj : getBlankLayoutObject(section, id, index, workout),
    }
  }
}

export const addProgramBuilderWeek = (id, index, blockId) => {

  return {
    type: ADD_PROGRAM_BUILDER_WEEK,
    payload: {
      obj : getBlankLayoutObject(WEEKS, id, index, null),
      blockId
    }
  }
}

export const addProgramBuilderDay = (id, index, weekId) => {
  return {
    type: ADD_PROGRAM_BUILDER_DAY,
    payload: {
      obj : getBlankLayoutObject(DAYS, id, index, null),
      weekId
    }
  }
}

export const addProgramBuilderWorkout = (id, index, dayId, workout) => {
  return {
    type: ADD_PROGRAM_BUILDER_WORKOUT,
    payload: {
      obj : getBlankLayoutObject(WORKOUTS, id, index, workout),
      dayId
    }
  }
}

export const deleteProgramBuilderBlock = (blockId, layout) => {
    return dispatch => {
      const weeks = layout.blocks[blockId].weeks;
      for (var i=0; i<weeks.length; i++) {
        dispatch(deleteProgramBuilderWeek(blockId, weeks[i], layout));
      }
      dispatch({
        type: DELETE_PROGRAM_BUILDER_LAYOUT,
        payload: {
            section: BLOCKS,
            id: blockId
          }
      });
    }
}

export const deleteProgramBuilderWeek = (blockId, weekId, layout) => {
  return dispatch => {
    //remove week from block
    const weeks = layout.blocks[blockId].weeks;
    const newWeeks = weeks.filter(week => week !== weekId);
    dispatch(updateProgramBuilderLayout(BLOCKS, blockId, WEEKS, newWeeks));

    const days = layout.weeks[weekId].days;
    for (var i=0; i<days.length; i++) {
      dispatch(deleteProgramBuilderDay(weekId, days[i], layout));
    }

    dispatch({
      type: DELETE_PROGRAM_BUILDER_LAYOUT,
      payload: {
        section : WEEKS,
        id: weekId
        }
      });

    }
  }

export const deleteProgramBuilderDay = (weekId, dayId, layout) => {
  return dispatch => {
      //remove day from week
      const days = layout.weeks[weekId].days;
      const newDays = days.filter(day => day !== dayId);
      dispatch(updateProgramBuilderLayout(WEEKS, weekId, DAYS, newDays));

      const workouts = layout.days[dayId].workouts;
      for (var i=0; i<workouts.length; i++) {
        dispatch(deleteProgramBuilderWorkout(dayId, workouts[i], layout));
      }
      dispatch({
        type: DELETE_PROGRAM_BUILDER_LAYOUT,
        payload: {
          section : DAYS,
          id: dayId
        }
      });
  }
}

export const deleteProgramBuilderWorkout = (dayId, workoutId, layout) => {
  return dispatch => {
  //remove day from week
  const workouts = layout.days[dayId].workouts;
  const newWorkouts = workouts.filter(workout => workout !== workoutId);
  dispatch(updateProgramBuilderLayout(DAYS, dayId, WORKOUTS, newWorkouts, 1));

  dispatch({
      type: DELETE_PROGRAM_BUILDER_LAYOUT,
      payload: {
        section : WORKOUTS,
        id: workoutId
      }
    })

  }
}

export const saveProgram = (programBuilder) => {
  const programBuilderCopy = _.cloneDeep(programBuilder); //deconstruct program metadata and layout
  const { layout, ...program }  = programBuilderCopy;
  console.log(layout, program);
  return (dispatch) => {
    dispatch({type: SAVE_PROGRAM});
    var id = null;
    if (program.id)
      id = program.id;
    delete program.id;


    if (id) {
      firebaseDb.collection(programsRef).doc(id).update(program)
      .then((doc) => {
         dispatch(saveLayout(id, program, layout))
         Debug("Document successfully written by update!");
       }).catch((error) => {
         console.log("Error writing document: ", error);
         alert('An error has occured: ' + error.message)
         dispatch({ type: SAVE_PROGRAM_FAIL, payload: error.message });
       })
    }
    else {
      firebaseDb.collection(programsRef).add(program)
      .then((doc) => {
         dispatch(saveLayout(doc.id, program, layout));
          Debug("Document successfully written by add!");
       }).catch((error) => {
         alert('An error has occured: ' + error.message)
         console.log("Error writing document: ", error);
         dispatch({ type: SAVE_PROGRAM_FAIL, payload: error.message });
       })
    }
  }
}

export const saveLayout = (id, program, layout) => {
  console.log('SAVE LAYOUT:', id , program, layout)

  return (dispatch) => {
      firebaseDb.collection(programsRef).doc(id).collection('details').doc('layout').set(layout, {merge: true})
      .then((doc) => {
        program = {...program, layout}; //reconstruct program metadata and layout
        dispatch({ type: SAVE_PROGRAM_SUCCESS, payload : {
          program,
          id,
          success : "Program Saved!"
        }
        });

      setTimeout(function(){
        dispatch({ type: SAVE_PROGRAM_SUCCESS, payload : {
          program,
          id,
          success : ""
        } })
      }, 1200);
         Debug("Document successfully written by update!");
       }).catch((error) => {
         console.log("Error writing document: ", error);
         alert('An error has occured: ' + error.message)
         dispatch({ type: SAVE_PROGRAM_FAIL, payload: error.message });
       });
    }
}

export const deleteProgram = (id) => {
  return (dispatch) => {
    dispatch({type: DELETE_PROGRAM});
    firebaseDb.collection(programsRef).doc(id).delete()
    .then((doc) => {
        dispatch({ type: DELETE_PROGRAM_SUCCESS });
       Debug("Document successfully deleted!");
     }).catch((error) => {
       console.log("Error deleting document: ", error);
       alert('An error has occured: ' + error.message)
       dispatch({ type: DELETE_PROGRAM_FAIL, payload: error.message });
     })
 }
}

export const clearPrograms = () => {
  return {
    type: CLEAR_PROGRAMS
  }
}

export const retrievePrograms = (getPublic, getUsers, programIds, isUserPrograms) => {
  return (dispatch) => {
    dispatch({type: RETRIEVE_PROGRAMS});
    let ref = firebaseDb.collection(programsRef);
    if (getPublic)
      ref = ref.where('public', '==', getPublic);
    if (getUsers)
      ref = ref.where('creator', '==', firebaseAuth.currentUser.uid);
    if (programIds && programIds.length>0)
      ref = ref.where(firebase.firestore.FieldPath.documentId(), 'in', programIds); //TODO: WHY IS THIS NOT WORKING
    ref.get().then((querySnapshot) => {
          //  const workouts = querySnapshot.docs.reduce((res, item) => ({...res, [item.id]: item.data()}), {});
          const programs = querySnapshot.docs.map(program => {
            const data = program.data();
            data.id = program.id
            return data;
          });
          dispatch({ type: RETRIEVE_PROGRAMS_SUCCESS, payload: {
            isUserPrograms,
            programs
          }});
        }).catch((error) => {
            Debug("Error getting document:", error);
            alert('An error has occured: ' + error.message)
            dispatch({ type: RETRIEVE_PROGRAMS_FAIL, payload: error.message });
          });
  }
}

export const retrieveProgramLayout = (programId, programMeta) => {
  return (dispatch) => {
    dispatch({type: RETRIEVE_PROGRAM_LAYOUT});
    firebaseDb.collection(programsRef).doc(programId).collection('details').doc('layout').get().then((doc) => {
          dispatch({ type: RETRIEVE_PROGRAM_LAYOUT_SUCCESS, payload: {
            id: programId,
            program : {...programMeta, layout : doc.data()}
          }
          })
        }).catch((error) => {
            Debug("Error getting document:", error);
            alert('An error has occured: ' + error.message)
            dispatch({ type: RETRIEVE_PROGRAM_LAYOUT_FAIL, payload: error.message });
          });
  }
}

export const retrieveProgramHistory = (programId) => {
  return (dispatch) => {
    dispatch({type: RETRIEVE_PROGRAM_HISTORY});
    firebaseDb.collection(programsRef).doc(programId).collection('history').orderBy("timestamp", "desc").limit(10).onSnapshot((snapshot) => {
          let programHistory = [];
          snapshot.forEach(doc => {
            programHistory.push(doc.data());
          })
          dispatch({ type: RETRIEVE_PROGRAM_HISTORY_SUCCESS, payload: programHistory})
        })
  }
}
