import React from 'react';
import _ from 'lodash';
import { Header, Button, Icon, Modal } from 'semantic-ui-react'
import { HorizontalDiv } from '../StyledComponents';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getExerciseOption } from '../../commonMethods';
import { CONDITIONING } from '../../assets/literals';


export const ExerciseSwapModal = ({exercises, exerciseArr, swapExercises, config}) => {
  const [open, setOpen] = React.useState(false)

  // a little function to help us with reordering the result
  const reorder = (exercises, exerciseArr, i, j) => {
      let newExercises = _.cloneDeep(exercises);
      let newExerciseArr = _.cloneDeep(exerciseArr);
      let newExercise = _.cloneDeep(exercises[i]);
      let newExerciseObj = _.cloneDeep(exerciseArr[i]);

      newExercises.splice(i, 1);
      newExercises.splice(j,0,newExercise);
      newExerciseArr.splice(i, 1);
      newExerciseArr.splice(j,0,newExerciseObj);

      return {
        exercises: newExercises,
        exerciseArr: newExerciseArr
      };
  }


  const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  padding: '20px',
  borderBottom: '2px solid lightgrey',
  // change background colour if dragging
  background: isDragging ? "#005792" : "white",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
});

const onDragEnd = (result) => {
  // dropped outside the list
  if (!result.destination) {
    return;
  }

  const data = reorder(
    exercises,
    exerciseArr,
    result.source.index,
    result.destination.index
  );

  swapExercises(data.exercises, data.exerciseArr);
}

  return (
    <Modal
      closeIcon
      size="tiny"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button icon style={{background : 'transparent'}}> <Icon name='ordered list' size='large' /></Button>}
    >
      <Modal.Header>Swap Order</Modal.Header>
      <br/>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {exerciseArr.map((item, index) => {
                const id = exercises[index] + '-' + index;
//                const id = item.type === CONDITIONING ? (item.title || item.description.substring(0, 10)) : item.exercise;
                return <Draggable key={id} draggableId={id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <HorizontalDiv>
                        <Header as='h4'>{(item.type === CONDITIONING ? item.title || "Conditioning/Accessory" : getExerciseOption(item.exercise, config.customExercises).text)}</Header>
                        <Icon name='bars' size='large' />
                      </HorizontalDiv>
                    </div>
                  )}
                </Draggable>
              }
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Modal>
  )
}
