import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import Debug from '../../Debug';
import { AuthContext } from "./Auth";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const {currentUser} = useContext(AuthContext);
  Debug(currentUser);
  if (currentUser !== null) {
    return (
      <Route
        {...rest}
        render={routeProps =>
          !!currentUser.uid ? (
            <RouteComponent {...routeProps} />
          ) : (
            <></> //return empty until uid is available
          )
        }
      />
    );
  }
  else {
    return <Redirect to={"/login"} />
  };
}

//TODO FIX LEAK ON SIGN IN

export default PrivateRoute
