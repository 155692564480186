import React, { Component} from 'react';
import { connect } from 'react-redux';
import { TransitionablePortal, Modal, Button } from 'semantic-ui-react';
import ExerciseListView from './ExerciseListView';
import { retrieveCustomExercises } from '../../actions';
import { getExerciseOption } from '../../commonMethods';

const INITIAL_STATE = {
  open: false
}

class ExerciseModal extends Component {

    constructor(props) {
      super(props);
      this.state = {...INITIAL_STATE, open : props.open};
    }

    componentDidMount() {
      if (!this.props.config.customExercises)
        this.props.retrieveCustomExercises();
    }

    toggleModal = () => {
      this.setState({open: !this.state.open})
    }


    render() {
        const {exercise, exerciseIndex, onExerciseSelect, onExit, config} = this.props;
        const {open} = this.state;

        let exerciseDisplayName = 'Select an Exercise';
        if (exercise) {
          if (config)
          exerciseDisplayName = getExerciseOption(exercise, config.customExercises).text
          else exerciseDisplayName = getExerciseOption(exercise).text
        }
        return (
          <TransitionablePortal
            trigger={<Button style={{display: 'flex', border: 'none', outline:'none', background: 'transparent', color: 'darkslategrey', textAlign: 'left', fontWeight: 'bold', fontSize:'18px'}}
                      type="button"
                      onClick={() => this.setState({open :true})}>{exerciseDisplayName}</Button>}
            open={open}
            transition = {{
                animation: 'scale',
                duration: 400,
              }}
              closeOnDocumentClick={false}
          >
            {open ? <Modal
              closeIcon
              open={true}
              onClose={() => {
                this.setState(INITIAL_STATE);
                 onExit()
              }}
            >
              <Modal.Header>Select an Exercise</Modal.Header>
              <Modal.Content>
                <Modal.Description>
                      <ExerciseListView
                          exercise={exercise}
                          config={config}
                          exerciseIndex={exerciseIndex}
                          onExerciseSelect={onExerciseSelect}
                          toggleModal={this.toggleModal}
                      />
                  </Modal.Description>
              </Modal.Content>
            </Modal> : <></>}
          </TransitionablePortal>
      )
    }
}

const mapStateToProps = state => {
  return {
           config: state.config
         }
}

export default connect(mapStateToProps, { retrieveCustomExercises })(ExerciseModal);
