import { RETRIEVE_USER_CONFIG, RETRIEVE_USER_CONFIG_SUCCESS, RETRIEVE_USER_CONFIG_FAIL,
          UPDATE_USER_CONFIG, UPDATE_USER_CONFIG_SUCCESS, UPDATE_USER_CONFIG_FAIL,
          UPDATE_CURRENT_RECORDS, UPDATE_CURRENT_RECORDS_SUCCESS, UPDATE_CURRENT_RECORDS_FAIL,
          RETRIEVE_CUSTOM_EXERCISES, RETRIEVE_CUSTOM_EXERCISES_SUCCESS, RETRIEVE_CUSTOM_EXERCISES_FAIL,
          UPDATE_CONFIG, CREATE_CUSTOM_EXERCISE, CREATE_CUSTOM_EXERCISE_FAIL, CREATE_CUSTOM_EXERCISE_SUCCESS,
          RETRIEVE_TEAM, RETRIEVE_TEAM_SUCCESS, RETRIEVE_TEAM_FAIL,
          RETRIEVE_ATHLETE_CONFIG, RETRIEVE_ATHLETE_CONFIG_FAIL, RETRIEVE_ATHLETE_CONFIG_SUCCESS,
          RETRIEVE_ATHLETE_CUSTOM_EXERCISES, RETRIEVE_ATHLETE_CUSTOM_EXERCISES_FAIL, RETRIEVE_ATHLETE_CUSTOM_EXERCISES_SUCCESS,
          RETRIEVE_SUBSCRIPTIONS, RETRIEVE_SUBSCRIPTIONS_SUCCESS, RETRIEVE_SUBSCRIPTIONS_FAIL,
          UPDATE_TEAM_CONFIG, UPDATE_TEAM_CONFIG_SUCCESS, UPDATE_TEAM_CONFIG_FAIL
} from '../actions/types';
import Debug from '../Debug';

const INITIAL_STATE =  {
  isMobile : false,
  user : {},
  team : {},
  athleteConfigs : {},
  athleteCustomExercises : {},
  customExercises : null,
  loading: false,
  didInvalidate : false,
  error: '',
  success: ''
}

const ConfigReducer = (state = INITIAL_STATE, action) => {
  Debug(action);
  switch (action.type) {
    case UPDATE_CONFIG:
      return { ...state, [action.payload.key] : action.payload.value};
    case RETRIEVE_USER_CONFIG:
      return { ...state, loading: true, error: '' };
    case RETRIEVE_USER_CONFIG_SUCCESS:
      return { ...state, user : action.payload, loading: false, didInvalidate : false, error: ''  };
    case RETRIEVE_USER_CONFIG_FAIL:
      return { ...state, loading: false, didInvalidate : false, error: action.payload };
    case RETRIEVE_ATHLETE_CONFIG:
      return { ...state, loading: true, error: '' };
    case RETRIEVE_ATHLETE_CONFIG_SUCCESS:
      return { ...state, athleteConfigs : {...state.athleteConfigs, [action.payload.uid] : action.payload.data }, loading: false, didInvalidate : false, error: ''  };
    case RETRIEVE_ATHLETE_CONFIG_FAIL:
      return { ...state, loading: false, didInvalidate : false, error: action.payload };
    case RETRIEVE_ATHLETE_CUSTOM_EXERCISES:
      return { ...state, loading: true, error: '' };
    case RETRIEVE_ATHLETE_CUSTOM_EXERCISES_SUCCESS:
      const customExercises = Object.assign({}, state.customExercises, action.payload.data);
      return { ...state, athleteCustomExercises : {...state.athleteCustomExercises, [action.payload.uid] : action.payload.data }, customExercises, loading: false, didInvalidate : false, error: ''  };
    case RETRIEVE_ATHLETE_CUSTOM_EXERCISES_FAIL:
      return { ...state, loading: false, didInvalidate : false, error: action.payload };
    case UPDATE_USER_CONFIG:
      return { ...state, loading: true, error: '', success: '' };
    case UPDATE_USER_CONFIG_SUCCESS:
      return { ...state, loading: false, didInvalidate : true, error: '', success: action.payload };
    case  UPDATE_USER_CONFIG_FAIL:
      return { ...state, loading: false, didInvalidate : false, error: action.payload, success: ''};
    case UPDATE_TEAM_CONFIG:
      return { ...state, loading: true, error: '', success: '' };
    case UPDATE_TEAM_CONFIG_SUCCESS:
      return { ...state, loading: false, error: '', success: action.payload };
    case  UPDATE_TEAM_CONFIG_FAIL:
      return { ...state, loading: false, error: action.payload, success: ''};
    case RETRIEVE_TEAM:
      return { ...state, loading: true, error: '' };
    case RETRIEVE_TEAM_SUCCESS:
      return { ...state, team : action.payload, loading: false, didInvalidate : false, error: ''  };
    case RETRIEVE_TEAM_FAIL:
      return { ...state, loading: false, didInvalidate : false, error: action.payload };
    case UPDATE_CURRENT_RECORDS:
      return { ...state, loading: true, error: ''}
    case UPDATE_CURRENT_RECORDS_SUCCESS:
      return { ...state, loading: false, didInvalidate : true, error: ''  };
    case UPDATE_CURRENT_RECORDS_FAIL:
      return { ...state, loading: false, didInvalidate : false, error: action.payload }
    case RETRIEVE_CUSTOM_EXERCISES:
      return { ...state, loading: true, didInvalidate : false, error: '' }
    case RETRIEVE_CUSTOM_EXERCISES_SUCCESS:
      return { ...state, loading: false, didInvalidate : false, customExercises: action.payload }
    case RETRIEVE_CUSTOM_EXERCISES_FAIL:
      return { ...state, loading: false, didInvalidate : false, error: action.payload }
    case RETRIEVE_SUBSCRIPTIONS:
      return { ...state, loading: true, didInvalidate : false, error: '' }
    case RETRIEVE_SUBSCRIPTIONS_SUCCESS:
      return { ...state, loading: false, didInvalidate : false, subscriptions: action.payload }
    case RETRIEVE_SUBSCRIPTIONS_FAIL:
      return { ...state, loading: false, didInvalidate : false, error: action.payload }
    case CREATE_CUSTOM_EXERCISE:
      return { ...state, loading: true, error: '', success: '' }
    case CREATE_CUSTOM_EXERCISE_SUCCESS:
      return { ...state, loading: false, didInvalidate : true, error: '', success: 'Exercise successfully created!' }
    case CREATE_CUSTOM_EXERCISE_FAIL:
      return { ...state, loading: false, didInvalidate : false, error: action.payload }
    default:
      return state;
    }
  }

export default ConfigReducer;
