import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Debug from '../../Debug';
import { signupUser } from '../../actions';
import { Loading } from '../common/Loading';
import { StyledContainerPadded } from "../StyledComponents";

//TODO Add Google sign in
// Add password validation and confirmation
//Add error handling


class SignUp extends Component {

  handleSignUp = (event) => {
      event.preventDefault();
      let { email, password, confirmPassword, firstname, lastname } = event.target.elements;
      email = email.value;
      password = password.value;
      confirmPassword = confirmPassword.value
      firstname = firstname.value;
      lastname = lastname.value;
      Debug(email, password, firstname,lastname);
      this.props.signupUser(email, password, confirmPassword, firstname, lastname);
  };

  renderError() {
      if (this.props.error) {
        return (
          <div>
            <p style={styles.errorTextStyle}>
              {this.props.error}
            </p>
          </div>
        );
      }
    }
  render() {
    return (
      <StyledContainerPadded>
        <h1>Sign up</h1>
        <br/>
        <Form onSubmit={this.handleSignUp}>
        <Form.Field>
          <label>First Name</label>
          <input name="firstname" placeholder="First Name" />
        </Form.Field>
        <Form.Field>
          <label>Last Name</label>
          <input name="lastname" placeholder="Last Name" />
        </Form.Field>
          <Form.Field>
            <label>Email</label>
            <input name="email" type="email" placeholder="Email" />
          </Form.Field>
          <Form.Field>
            <label>Password</label>
            <input name="password" type="password" placeholder="Password" />
          </Form.Field>
          <Form.Field>
            <label>Confirm Password</label>
            <input name="confirmPassword" type="password" placeholder="Confirm Password" />
          </Form.Field>
          {this.renderError()}
          <Loading active={this.props.loading} />
          <Form.Button type="submit">Sign up</Form.Button>
        </Form>
        <br/>
        <Link to='/login'>Have an account? Click here</Link>
      </StyledContainerPadded>
    );
  }

}

const styles = {
  errorTextStyle: {
    fontSize: 14,
    alignSelf:  'center',
    color: 'red'
  }
}

const mapStateToProps = ({ signup }) => {
  const { error, loading } = signup;
  return { error, loading }
}

export default withRouter(connect(mapStateToProps, {signupUser})(SignUp));
