import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import Debug from '../../Debug';
import HeaderTab from '../menus/HeaderTab';
import FooterTab from '../menus/FooterTab';
import { updateConfig } from '../../actions';

const HeaderMain = ({activeItem, itemLeft, text, itemRight, isMobile, updateConfig}) => {

  function handleWindowSizeChange() {
        Debug('window size change: ' + window.innerWidth);
          if ( window.innerWidth <= 768)
            updateConfig('isMobile', true);
          else if ( window.innerWidth >= 768)
            updateConfig('isMobile', false);
    }

  useEffect(() => {
          updateConfig('isMobile', window.innerWidth <= 768 ? true : false);
          window.addEventListener('resize', handleWindowSizeChange);
          return () => {
              window.removeEventListener('resize', handleWindowSizeChange);
          }
    }, []);

//<Button style={{background:'transparent'}} floated='right'><Header floated='right' as='h2' icon='bars'/></Button>
  return <>
    {isMobile ? <FooterTab activeItem={activeItem}  /> : <HeaderTab activeItem={activeItem}  />}
  </>

}

const mapStateToProps = state => {
  return {
    isMobile : state.config.isMobile
  }
}

export default connect(mapStateToProps, { updateConfig })(HeaderMain);
