import React, { useState, useEffect, useCallback }from 'react';
import moment from 'moment'
import { firebaseAuth, firebaseDb, workoutsRef, templatesRef, programsRef } from '../../firebase';
import { Transition, Segment, Button, Modal, Header, TransitionablePortal } from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import { HorizontalDiv, StyledContainer } from '../StyledComponents';
import MultiSelect from '../common/MultiSelect';
import { PROGRAM, BLOCKS, WEEKS, WORKOUTS } from '../../assets/literals';

export const SchedulerModal = ({ defaultOpen, openModal, onClose, btnText, isCoach, type, schedulingComponentId, schedulingIndex, frequency, programId, layout, athleteOptions, trackOptions }) => {
        const [startDate, setStartDate] = useState(null);
        const [error, setError] = useState('');
        const [success, setSuccess] = useState('');
        const [loading, setLoading] = useState(false);
        const [athletes, setAthletes] = useState([]);
        const [tracks, setTracks] = useState([]);
        const [forTrack, setForTrack] = useState(null);
        const [selectedDays, setSelectedDays] = useState([false,false,false,false,false,false,false]);
        const [validInput, setValidInput] = useState(false);
        const [isReviewing, setIsReviewing] = useState(false);
        const [workouts, setWorkouts] = useState([]);
        const [typeName, setTypeName] = useState('');
        const programHistoryRef = firebaseDb.collection(programsRef).doc(programId).collection('history');



        useEffect(() => {
          if (frequency === 7)
            setSelectedDays([true,true,true,true,true,true,true]);
          if (!isCoach)
            setAthletes([firebaseAuth.currentUser.uid]);
        }, []);

        const isCorrectFrequency = useCallback(() => {
          if (type === WORKOUTS)
              return true
          else
            return selectedDays.filter(x => x === true).length === frequency;
        }, [selectedDays, frequency, type]);

        useEffect(() => {
          if (isCorrectFrequency() && startDate && (!forTrack && athletes.length>0 || (forTrack && tracks.length>0)))
            setValidInput(true);
          else if (validInput === true)
            setValidInput(false);
        },[validInput, frequency, athletes, tracks, startDate, isCorrectFrequency]);

        const setInitialState = () => {
            setStartDate(null);
            setError('');
            setSuccess('');
            setLoading(false);
            setAthletes(isCoach ? [] : [firebaseAuth.currentUser.uid]);
            setTracks([]);
            setSelectedDays([false,false,false,false,false,false,false]);
            setWorkouts([]);
            setIsReviewing(false);
            setValidInput(false);
            setForTrack(null);
        }

        const onInputChange = () => {
          setWorkouts([]);
          setIsReviewing(false);
        }

        const toggleDay = (dayIndex) => {
          if (frequency !== 7) {
            const newSelectedDays = [...selectedDays];
            newSelectedDays[dayIndex] = !selectedDays[dayIndex];
            setSelectedDays(newSelectedDays);
          }
          onInputChange();
        }

        const handleAthleteChange = (athletes) => {
            setAthletes(athletes);
            onInputChange();
        };

        const handleTrackChange = (tracks) => {
            setTracks(tracks);
            onInputChange();
        };

        const scheduler = () => {
          setWorkouts([]);

          const { blockOrder, blocks } = layout;
          let scheduleStartdate = moment(startDate);
          switch (type) {
            case PROGRAM:
              for (var i=0; i<blockOrder.length; i++) {
                const block = blocks[blockOrder[i]];
                scheduleBlock(scheduleStartdate.clone(), blockOrder[i]);
                scheduleStartdate.add(block.weeks.length, 'weeks');
              }
              break;
            case BLOCKS:
              scheduleBlock(scheduleStartdate.clone(), schedulingComponentId);
              break;
            case WEEKS:
              scheduleWeek(scheduleStartdate.clone(), schedulingComponentId);
              break;
            case WORKOUTS:
             scheduleWorkout(scheduleStartdate.clone(), schedulingComponentId);
             break;
            default:
              setError('No type was selected.');
          }
        }

        const scheduleWorkout = (date, workoutId) => {
          console.log('workout scheduler: ', date.format(), workoutId);
          if (forTrack && tracks) {
            for (var i=0; i<tracks.length; i++) {
              let workout = {...layout.workouts[workoutId].workout, date : date.toDate(), track : tracks[i], userId : '', programId};
              workout.programWorkoutId = workout.id;
              delete workout.id;
              setWorkouts(workouts => [...workouts, workout]);
            }
          }
          else if (!forTrack && athletes) {
            for (i=0; i<athletes.length; i++) {
              let workout = {...layout.workouts[workoutId].workout, date : date.toDate(), userId : athletes[i], programId};
              workout.programWorkoutId = workout.id;
              delete workout.id;
              setWorkouts(workouts => [...workouts, workout]);
            }
          }
          console.log('1workout scheduler: ', workouts);
        }

        const commitSchedulerBatch = () => {
          let batch = firebaseDb.batch();
          let batchCopy = [];
          const ref = firebaseDb.collection(forTrack ? templatesRef : workoutsRef);
          setLoading(true);
          let programInstanceId = Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9);
          workouts.forEach(workout => {
            batch.set(ref.doc(), {...workout, programInstanceId});
            batchCopy.push({...workout, programInstanceId});
          });

          let name = type;
          name = name.charAt(0).toUpperCase() + name.slice(1);
          if (type !== PROGRAM)
            name = name.substring(0, name.length - 1);
          if (type === BLOCKS || type === WEEKS)
            name = name + ' ' + parseInt(schedulingIndex+1);
          setTypeName(name);

          let athleteObjects = [];
          athletes.forEach(athlete => athleteObjects.push(getAthlete(athlete)));

          const scheduleHistory = {
            timestamp : new Date(Date.now()),
            name,
            creator : firebaseAuth.currentUser.uid,
            creatorName: firebaseAuth.currentUser.displayName,
            type,
            programId,
            programInstanceId,
            athletes : athleteObjects,
            startDate
          }
          batch.set(programHistoryRef.doc(), scheduleHistory);
          batchCopy.push(scheduleHistory);

          //console.log('BATCH:', batchCopy, batch);

          batch.commit().then(() => {
            setLoading(false);
            setError('');
            setAthletes([]);
            setTracks([]);
            setIsReviewing(false);
            setSuccess("Workouts successfully scheduled! Head to the calendar to view or edit!");
          }).catch( error => {
            setLoading(false);
            setError('An error has occurred: ' + error.message);
          })

        }

        const scheduleDay = (start, dayIndex, day) => {
          console.log('dayStart:', start.format(), day)
          const { workouts } = layout.days[day];
          if (workouts) {
            for (var i=0; i<workouts.length; i++) {
                scheduleWorkout(start.clone(), workouts[i])
            }
          }
        }

        const getDayOfWeek = (currentDate) => {
            let currentWeekday = currentDate.isoWeekday();
            for (var i=0; i<7; i++) {
              let weekday = (currentWeekday + i)%7;
              if (weekday === 0 && selectedDays[6] === true)               //sunday special case due to mod
                return weekday
              else if (selectedDays[weekday-1] === true)
                return weekday;
            }
            return 1;
        }

        const scheduleWeek = (start, week) => {
          console.log('weekStart:', start.format(), week)
          const { days } = layout.weeks[week];
          if (days) {
            let currentDate = start.clone();
            for (var i=0; i<days.length; i++) {
                const weekday = getDayOfWeek(currentDate);
                if (currentDate.isoWeekday() <= weekday) {
                  currentDate.add(weekday - currentDate.isoWeekday(), 'days' );
                }
                else
                 currentDate.add(7 - (currentDate.isoWeekday() - weekday), 'days');

             scheduleDay(currentDate.clone(), i, days[i]);
             currentDate.add(1, 'days');
            }
          }
        }

        const scheduleBlock = (start, blockId) => {
          console.log('blockStart:', start.format());
          const block = layout.blocks[blockId];
          const { weeks } = block;
          if (weeks) {
            for (var i=0; i<weeks.length; i++) {
                scheduleWeek(start.clone(), weeks[i])
                start.add(1, 'weeks');
            }
          }
        }

        const getAthlete = (uid) => {
          for (var i=0; i<athleteOptions.length; i++) {
            if (athleteOptions[i].key === uid)
                return {id: athleteOptions[i].key, name: athleteOptions[i].text };
          }

        }

        const renderDatePicker = () => {
          return <StyledContainer>
                <HorizontalDiv
                        style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems:'baseline'}}
                    >
                    <DatePicker
                      id='datepicker-start'
                      selected={startDate}
                      onChange={date => {
                          setStartDate(date);
                          onInputChange();
                      }}
                      value={startDate}
                      placeholderText="Date"
                      className='ui button basic bold customDatePicker'
                      withPortal
                      required={true}
                    />
                  </HorizontalDiv>
                </StyledContainer>
        }

        return (
          <TransitionablePortal
            defaultOpen={defaultOpen}
            open={openModal}
            onClose={() => {
              setInitialState();
              onClose();
            }}
            transition = {{
                animation: 'scale',
                duration: 800,
              }}
          >
          <Modal
            size='tiny'
            open={openModal}
            onClose={() => {
              setInitialState();
              onClose();
            }}
            closeIcon
          >
            <Modal.Header>Program Scheduler</Modal.Header>
            {forTrack === null ?
              <div style={{margin: '10%'}}>
                <Header as='h4'> Would you like to program for individual athlete(s) or for tracks? </Header>
                <Button.Group fluid>
                  <Button onClick={() => setForTrack(false)}>Athlete</Button>
                  <Button.Or />
                  <Button onClick={() => setForTrack(true)}>Track</Button>
                </Button.Group>
                <br/><br/><br/><br/>
                <span style={{textAlign: 'center'}}>{type && type !== PROGRAM && <Header as='h5'> Note: You are scheduling just 1 {type.substring(0,type.length-1)} of the program! </Header>}</span>
              </div>
            :
            <Modal.Content style={{textAlign: 'center'}}>
            {type !== WORKOUTS &&
              <>
              {frequency !==7 && !isCorrectFrequency() && <p>Select {frequency} days to schedule the program on: </p>}
                <Button.Group widths={7}>
                  <Button color={selectedDays[0] ? 'blue' : 'grey'} onClick={() => toggleDay(0)}>Mon</Button>
                  <Button color={selectedDays[1] ? 'blue' : 'grey'} onClick={() => toggleDay(1)}>Tues</Button>
                  <Button color={selectedDays[2] ? 'blue' : 'grey'} onClick={() => toggleDay(2)}>Weds</Button>
                  <Button color={selectedDays[3] ? 'blue' : 'grey'} onClick={() => toggleDay(3)}>Thurs</Button>
                  <Button color={selectedDays[4] ? 'blue' : 'grey'} onClick={() => toggleDay(4)}>Fri</Button>
                  <Button color={selectedDays[5] ? 'blue' : 'grey'} onClick={() => toggleDay(5)}>Sat</Button>
                  <Button color={selectedDays[6] ? 'blue' : 'grey'} onClick={() => toggleDay(6)}>Sun</Button>
                </Button.Group>
                <br/>
              </>}
              <Header as='h4'> Start Date: </Header>
              {renderDatePicker()}
              <br/>
              {isCoach && <MultiSelect
                selectedValues={forTrack ? tracks : athletes}
                placeholder={forTrack ? 'Tracks' : 'Athletes'}
                options={forTrack ? trackOptions : athleteOptions}
                handleValuesChange={forTrack ? handleTrackChange : handleAthleteChange}
              />}
              <br/>
              <br/>
              {isReviewing ?
              <div>
               <Transition transitionOnMount visible={isReviewing} animation='fly down' duration={800}>
                 <Segment>
                    <Header as='h4'> Please review before scheduling! </Header>
                    {type && <p>Schedule Type:  <span style={{fontWeight: 'bold'}}>{(type && type === PROGRAM) ? type : typeName }</span></p>}
                    {isCoach &&
                      <>{forTrack ?
                        <p>Tracks: <span style={{fontWeight: 'bold'}}>{tracks.length}</span> </p>
                        :
                        <p>Athletes: <span style={{fontWeight: 'bold'}}>{athletes.length}</span> </p>}
                      </>}
                    {!forTrack && athletes.length > 0 && <p>Workouts: <span style={{fontWeight: 'bold'}}>{workouts.length/athletes.length}</span> </p>}
                    {forTrack && tracks.length > 0 && <p>Workouts: <span style={{fontWeight: 'bold'}}>{workouts.length/tracks.length}</span> </p>}
                    {workouts.length > 0 &&
                      <><p>Start: <span style={{fontWeight: 'bold'}}>{workouts[0].date.toLocaleDateString()}</span></p>
                        <p>End: <span style={{fontWeight: 'bold'}}>{workouts[workouts.length-1].date.toLocaleDateString()}</span></p>
                      </>
                    }
                  </Segment>
                </Transition>
                <br/>
                <Button fluid primary
                    loading={loading}
                    disabled={!validInput}
                    onClick={() => commitSchedulerBatch()}
                  >
                    Schedule
                </Button>
              </div>
            :
              <Button fluid primary basic
                  loading={loading}
                  disabled={!validInput}
                  onClick={() => {
                    setIsReviewing(true);
                    scheduler();
                  }}>
                  Review
              </Button>
            }
            {success && <div style={{textAlign : 'center'}} className='ui positive message'> {success}</div>}
            {error && <div style={{textAlign : 'center'}} className='ui negative message'> {error}</div>}
            {type && type !== PROGRAM && <Header as='h5'> Note: You are scheduling just 1 {type.substring(0,type.length-1)} of the program! </Header>}
            </Modal.Content>}
          </Modal>
          </TransitionablePortal>
        )
  }
