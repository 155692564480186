import React, { Component } from 'react';
import { Transition, Item, List, Input, Form, Button, Header } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import Debug from '../../Debug';
import Exercise from "../exercise/Exercise";
import {withRouter} from 'react-router';
import ExerciseComplex from "../exercise/ExerciseComplex";
import Conditioning from "../conditioning/Conditioning";
import SessionSummary from './SessionSummary';
import  HeaderGeneric from "../menus/HeaderGeneric";
import { ExerciseSwapModal } from "../exercise/ExerciseSwapModal";
import { StyledContainer, HorizontalDiv } from '../StyledComponents';
import { CalculatorModal } from '../common/CalculatorModal';
import { PercentFillModal } from '../common/PercentFillModal';
import { SuccessPortal } from '../common/SuccessPortal';
import { FinishModal } from './FinishModal';
import { clearSelectedWorkout, updateBuilderField, updateBuilderExercise, updateBuilderWeight, addBuilderExercise, addBuilderWod, saveWorkout, swapExercises } from '../../actions';
import { buildChartData, hasPercentWork, getAthleteName, intensityResolution } from '../../commonMethods';
import { STRENGTH, CONDITIONING, PROGRAMMED, INPROGRESS, COMPLETE, KG, LBS } from '../../assets/literals';
import './datepicker.css';


const themeColor = 'darkslategray';
/*
const ConfirmExit = ({handleExit}) => {

  const [open, setOpen] = React.useState(false)
    return (
      <div>
        <Button size='huge' style={{background: 'transparent', padding: '10px'}} icon="arrow left" onClick={() => setOpen(true)} />
        <Confirm
          content='Are you sure you want to exit? Any changes will not be saved.'
          open={open}
          onCancel={() =>setOpen(false)}
          onConfirm={() => {
            handleExit();
            setOpen(false);
          }}
        />
      </div>
    )
}
*/
const INITIAL_STATE = {
  renderedComplexes : [],
  showSummaryModal: false,
  summary : {},
  hasPercent : false
}

class DailyLog extends Component {

  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.state.hasPercent = hasPercentWork(props.workoutBuilder.exerciseArr);
    this.state.scrollExerciseIntoView = false;
    this.formRef = React.createRef();
    this.addExerciseRef = React.createRef();
  }

  componentDidMount() {
    const { programming, workoutBuilder, config, athlete, updateBuilderField, updateBuilderExercise } = this.props;
    const { athleteConfigs, customExercises } = config;
    const user = athlete ? athleteConfigs[athlete] : config.user;
    const { defaultUnit, exerciseArr } = workoutBuilder;

    if (!programming) {
      updateBuilderField('status', INPROGRESS);

      for (var i=0; i<exerciseArr.length; i++) {
        const { exercise, type, workingMax } = exerciseArr[i];
        if (!workingMax && type === STRENGTH) {
          updateBuilderExercise(i, 'workingMax', intensityResolution(exercise, user.workingMaxes, customExercises) );
        }

      }
    }

    this.setState({scrollExerciseIntoView : true});
    if (!defaultUnit) {
      if (user)
        updateBuilderField('defaultUnit', user.defaultUnit || KG);
      else
        updateBuilderField('defaultUnit', KG);
    }

    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
  };

  validateForm= (start, formRef) => {

    if (!start) {
      alert('Please enter the session start time');
      return false
    }

    const { workoutBuilder } = this.props;
    if (workoutBuilder) {
      //validate Exercises
      for (var i=0; i< workoutBuilder.exerciseArr.length; i++ ) {
        if (workoutBuilder.exerciseArr[i].type === CONDITIONING)
            Debug('TODO: validate conditioning');
        else if (!workoutBuilder.exerciseArr[i].exercise) {
          alert('An exercise was not selected. Please select a valid exercise.');
          return false;
        }
      }
    }

    if (formRef) {
      return formRef.current.reportValidity();
    }

    return true;
  }

  validateEnd = (start, end) => {
    if (!end) {
      alert('Please select an end time.');
      return false;
    }
    if (end < start) {
      alert('Please select an end time that is after the start time.');
      return false;
    }
    return true;
  }

  validateWeightUnits = exerciseArr => {
    for (var i=0; i<exerciseArr.length; i++) {
      const { weightArr } = exerciseArr[i];
      if (weightArr) {
        for (var j=0; j<weightArr.length; j++) {
          if (weightArr[j].unit !== LBS && weightArr[j].unit !== KG) {
            alert("Please use kg or lbs units to complete a workout.");
            return false;
          }
       }
     }
    }
    return true;
  }

  renderError = (error) => {
    alert(error);
  }

    handleSubmit = (continueWorkout) => {
      const { config, workoutBuilder, saveWorkout, athlete, handleSubmit, trackMode } = this.props;
      const { date, exerciseArr } = workoutBuilder;
      const { athleteConfigs, customExercises } = config;
      const user = athlete ? athleteConfigs[athlete] : config.user;

      if (handleSubmit) //Handle submit by parent
        handleSubmit(workoutBuilder);
      else {
        if (workoutBuilder.status === PROGRAMMED)
           saveWorkout(workoutBuilder, true, user, trackMode);
        else if (this.validateForm(date,this.formRef)) {
          Debug("submitting...", workoutBuilder);
            if (!continueWorkout) {
              if (!this.validateWeightUnits(exerciseArr))
                return;
              else {
                saveWorkout(workoutBuilder, continueWorkout, user, trackMode);
                if (workoutBuilder.status === COMPLETE)
                  this.setState({showSummaryModal: true, summary : buildChartData(workoutBuilder, user, customExercises)});
              }
          }
          else
             saveWorkout(workoutBuilder, continueWorkout, user, trackMode);
      }
    }
  }
/* DATEPICKER FOR START TIME
    renderDatePicker = () => {
      const { workoutBuilder, updateBuilderField } = this.props;
      return <StyledContainer>
            <Header style={{color: themeColor}}> {workoutBuilder.date.toLocaleDateString()} </Header>
            <HorizontalDiv
                    style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems:'baseline'}}
                >
                <Header as='h4'> Start:&nbsp;&nbsp; </Header>
                <DatePicker
                  id='datepicker-start'
                  selected={workoutBuilder.date}
                  onChange={date => {
                      updateBuilderField('date', date)
                      if (!workoutBuilder.end || workoutBuilder.end < date)
                        updateBuilderField('end', date)
                  }}
                  showTimeSelect
                  dateFormat=" h:mm aa"
                  placeholderText="Start"
                  className='ui button customDatePicker'
                  withPortal
                  required={true}
                />
              </HorizontalDiv>
            </StyledContainer>
    }
    */

    renderDatePicker = () => {
      const { workoutBuilder, updateBuilderField } = this.props;
      return <StyledContainer>
            <HorizontalDiv
                    style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems:'baseline'}}
                >
                <DatePicker
                  id='datepicker-start'
                  selected={workoutBuilder.date}
                  onChange={date => {
                      updateBuilderField('date', date)
                  }}
                  placeholderText="Date"
                  className='ui button circular basic bold customDatePicker'
                  withPortal
                  required={true}
                />
              </HorizontalDiv>
            </StyledContainer>
    }

    renderExercises = (user, customExercises) => {
      const { programming, workoutBuilder, ignoreIntensity } = this.props;
      var renderedComplexes = [];

      return <Transition.Group
                as={List}
                duration={200}
                divided
                animation="slide down"
         >
        {workoutBuilder.exerciseArr.map((exerciseObj, i) => {
        const { type, exercise, workingMax, exerciseNotes, coachNotes, weightArr, complexId } = exerciseObj;
        if (type === CONDITIONING) {
          const { wodType, title, description, modified, favorited, benchmark, score, notes } = exerciseObj
            return <List.Item key={'conditioning-' + i}>
                    <Conditioning
                      exerciseIndex={i}
                      wodType={wodType}
                      title={title}
                      description={description}
                      modified={modified}
                      favorited={favorited}
                      benchmark={benchmark}
                      score={score}
                      notes={notes}
                      coachNotes={coachNotes}
                      programming={programming}
                      themeColor={themeColor}
                   /></List.Item>
        }
        //if the exercise was part of a complex and already rendered, do not render
        if (renderedComplexes.includes(complexId)) {
            return null;
        }
        //if the element is the first of a complex, find all exercise in the complex and pass to ExerciseComplex Component
        else if (complexId) {
          const complexArr = workoutBuilder.exerciseArr.reduce((acc, obj, i) => {
              if (obj.complexId === complexId) {
                obj.exerciseIndex = i;
                acc.push(obj);
                return acc;
              }
              return acc;
          }, []);
          Debug(complexArr);
          renderedComplexes.push(complexId);
          return <List.Item key={'complex-' + complexId}>
          <ExerciseComplex
                complexArr={complexArr}
                athleteConfig={user}
                customExercises={customExercises}
                programming={programming}
                scrollExerciseIntoView={this.state.scrollExerciseIntoView}
                ignoreIntensity={ignoreIntensity}
            />
          </List.Item>
        }
        else
        return <List.Item key={'exercise-' + i}>
         <Exercise
            exerciseIndex={i}
            exercise={exercise}
            exerciseNotes={exerciseNotes}
            coachNotes={coachNotes}
            weightArr={weightArr}
            workingMax={workingMax}
            defaultUnit={workoutBuilder.defaultUnit}
            athleteConfig={user}
            customExercises={customExercises}
            programming={programming}
            scrollExerciseIntoView={this.state.scrollExerciseIntoView}
            ignoreIntensity={ignoreIntensity}
        /></List.Item>
      }
      )
    }</Transition.Group>
    }

    hiddenButton = <Button disabled style={{background: 'transparent'}}></Button>;

    render() {
      const { programming, workoutBuilder, loading, error, hideDate, clearSelectedWorkout, addBuilderExercise, swapExercises, addBuilderWod, updateBuilderField, updateBuilderExercise, updateBuilderWeight, config, athlete, trackMode } = this.props
      const { athleteConfigs, customExercises } = config;
      const user = (athlete && !trackMode) ? athleteConfigs[athlete] : config.user;
      console.log('user:', user, athlete);

        return (
          <Item>
            {error.length>0 && this.renderError(error)}
            {this.props.success && <SuccessPortal text={this.props.success}></SuccessPortal>}
            <HeaderGeneric
              itemRight={
                (programming ?
                  <Button
                      style={{background: 'transparent', color: '#0E7AFE', fontSize:'18px'}}
                      type='button'
                      onClick={() => this.handleSubmit(false)}
                  >
                    Done
                  </Button>
                  :
                  <FinishModal
                            trigger={<Button style={{background: 'transparent', color: '#0E7AFE', fontSize:'18px'}} type='button' disabled={loading}>Done </Button>}
                            validateForm={this.validateForm}
                            addExerciseRef={this.addExerciseRef}
                            formRef={this.formRef}
                            workoutBuilder={workoutBuilder}
                            updateBuilderField={updateBuilderField}
                            handleSubmit={this.handleSubmit}
                            disabled={this.props.loading}
                            themeColor={themeColor}
                           />)}
              itemLeft={ <Button size='huge' style={{background: 'transparent', padding: '10px'}} icon="arrow left"
                              onClick={(e) => {
                                              if (this.props.handleDateChange)
                                                this.props.handleDateChange(workoutBuilder.date);
                                              clearSelectedWorkout()
                                              if (this.props.fromCalendar)
                                                this.props.history.goBack();
                                              }}/>}
            />
            <br/>
            <Transition
              animation='pulse'
              duration={500}
              visible={true}
              transitionOnMount
            >
            <div>
            {athlete && config.team && config.team.members && programming &&
               <Header as='h4'>{getAthleteName(config.team.members, athlete)} </Header>}
            <HorizontalDiv>
              <Input fluid style={{width: '100%'}} placeholder='Workout Name' value={workoutBuilder.name} onChange={(e) => {updateBuilderField('name', e.target.value)}}>
                <input style={{borderLeft: 'none', borderRight: 'none', borderTop: 'none', padding: '8px', fontSize:'18px',fontWeight: 'bold', color:themeColor}} />
              </Input>
              <CalculatorModal />
              {!programming && <PercentFillModal
                    defaultOpen={this.state.hasPercent}
                    defaultUnit={user.defaultUnit}
                    exercises={workoutBuilder.exerciseArr}
                    customExercises={customExercises}
                    updateBuilderExercise={updateBuilderExercise}
                    updateBuilderWeight={updateBuilderWeight}
              />}
            </HorizontalDiv>
            <br/>
            <HorizontalDiv>
                {this.hiddenButton}
                {hideDate ? <span></span> : this.renderDatePicker()}
                {(workoutBuilder.exercises && workoutBuilder.exercises.length > 1)
                    ? <ExerciseSwapModal
                          exercises={workoutBuilder.exercises}
                          exerciseArr={workoutBuilder.exerciseArr}
                          swapExercises={swapExercises}
                          config={config}
                     />
                     : this.hiddenButton
                }
            </HorizontalDiv>
            <br/>
            <form
             className="ui form"
             ref={this.formRef}
             style={{width:'100%'}}
             onSubmit={this.handleSubmit}>
             {this.renderExercises(user, customExercises)}
              <br/>
              <div ref={this.addExerciseRef}>
                  <Button.Group fluid>
                        <Button icon
                            className='secondary ui button'
                            type='button'
                            onClick={(e) => addBuilderExercise(null, null, user.defaultUnit)}
                          >
                          Add New Exercise
                          </Button>
                          <Button.Or />
                          <Button
                              className='secondary ui button'
                              type='button'
                              variant='primary'
                              onClick={(e) => {
                                const complexId = Math.random().toString(36).substring(5);
                                addBuilderExercise(complexId, null, user.defaultUnit);
                              }}
                            >
                            Add New Complex
                            </Button>
                  </Button.Group>
                  <br/><br/>
                  <Button fluid
                      className='secondary ui button'
                      type='button'
                      onClick={(e) => addBuilderWod()}>
                      Add Conditioning/Accessory
                  </Button>
            </div>
            <br/>
            {workoutBuilder.conditioning &&
              <><Header style={{color: themeColor}}>Conditioning/Accessory: </Header>
              <Form.TextArea
                maxLength='5000'
                placeholder='Enter conditioning/accessory workout details here'
                id='conditioning'
                value={workoutBuilder.conditioning}
                onChange={(e) => {updateBuilderField('conditioning', e.target.value)}}
               /></>
             }
            {workoutBuilder.exerciseArr.length > 0 &&
              (programming ?
                <Button primary fluid type='button' onClick={() => this.handleSubmit(false)}>Done</Button>
                :
                    <FinishModal
                        trigger={<Button primary fluid type='button'>Done</Button>}
                        validateForm={this.validateForm}
                        addExerciseRef={this.addExerciseRef}
                        formRef={this.formRef}
                        workoutBuilder={workoutBuilder}
                        updateBuilderField={updateBuilderField}
                        handleSubmit={this.handleSubmit}
                        disabled={this.props.loading}
                        themeColor={themeColor}
                     />)
              }
              </form>
              {this.state.showSummaryModal && <SessionSummary
                data={this.state.summary}
                header="Session Summary"
                defaultOpen={true}
                onClose={() => this.props.handleDateChange(workoutBuilder.date)}
              />}
              </div>
              </Transition>
            </Item>
        );
      }
  }

  const mapStateToProps = state => {
    return {
             workoutBuilder: state.log.workoutBuilder,
             loading: state.log.loading,
             error: state.log.error,
             config: state.config,
             success: state.log.success
           }
          };

export default withRouter(connect(mapStateToProps, {clearSelectedWorkout, updateBuilderField, updateBuilderExercise, updateBuilderWeight, addBuilderExercise, addBuilderWod, saveWorkout, swapExercises })(DailyLog));
