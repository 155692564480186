import React, { Component } from 'react';
import { List, Header, Divider } from 'semantic-ui-react';
import PRModal from './PRModal';
import {  HorizontalDiv, TouchableButton } from '../StyledComponents';
import { getExerciseOption } from '../../commonMethods';

const touch = {
  opacity: 0.5
}

class RecordListItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      touched : false,
      record : {...props.record, date: props.record.date ? props.record.date.toDate() : props.record.date}
    }
  }

  toggleTouched = () => {
      this.setState( prevState => ({
        touched: !prevState.touched
      }));
  }


  handleMouseUp = () => {
    // Handle smooth animation when clicking without holding
    setTimeout( () => {
      this.setState({ touched: false });
    }, 150);
  }


  updateRecord = (key, value) => {
    this.setState({ record : {...this.state.record, [key] : value }});
  }

  render() {
    const { touched, record} = this.state;
    let { date, exercise, repMax, weight, unit } = record;
    const touchableStyle = touched ? touch : null;
    return (
      <PRModal
        trigger={<TouchableButton
                    style={touchableStyle}
                    onMouseDown={this.toggleTouched}
                    onMouseUp={this.toggleTouched}
                  >
                    <HorizontalDiv style={{height:'100%', width: '100%', paddingLeft: '5%'}}>
                      <Header as='h5' style={{marginTop:'0'}} textAlign="left">{getExerciseOption(exercise, this.props.customExercises).text + " " + ((repMax>1) ? repMax + " RM" : "")}
                          <Header.Subheader style={{marginTop:'10px'}}>{weight + " " + unit}</Header.Subheader>
                      </Header>
                      {date && <p>{date.toLocaleDateString()} </p>}
                    </HorizontalDiv>
                    <Divider/>
                   </TouchableButton>}
          newRecord={record}
          updateRecord={this.updateRecord}
       />
    )
  }
}


export const RecordList = ({allRecords, onRecordSelect, customExercises}) => {

  const [records, updateRecords] = React.useState(allRecords);
  React.useEffect(() => {
      updateRecords(allRecords);
  }, [allRecords]);

  if (records.length > 0) {
    return (<>
      <List>
        {records.map(record => {
          return <RecordListItem
           key={record.id}
            record={record}
            customExercises={customExercises}
          />
        })}
      </List>
      </>
    );
  }
  else {
    return <Header as='h5'> No personal records logged. </Header>
  }

}
