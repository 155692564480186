import React from 'react';
import { TransitionablePortal, Modal, Button, Icon} from 'semantic-ui-react';
import iosShareIcon from '../../assets/ios-share.png';
import useIosInstallPrompt from '../../shared/hooks/useIosInstallPrompt';
import useWebInstallPrompt from '../../shared/hooks/useWebInstallPrompt';

export const InstallPWA = () => {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();

  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }

  const renderInstagramNote = () => {
    const userAgent = window.navigator.userAgent.toLowerCase
    const isFromInstagram =  /instagram/.test( userAgent );
    if (!isFromInstagram)
      return <><span> Click <Icon name='ellipsis horizontal'></Icon> in the top right and select "Open In Safari"</span><br/></>
    else
    return null

  }

  return (
    <TransitionablePortal
          open={true}
          transition = {{
                    animation: 'scale',
                    duration: 400,
                  }}
                  closeOnDocumentClick={false}
      >
      <Modal style={{textAlign : 'center'}} open={true}>
          <Modal.Content>
            <Modal.Header className="text-center">
              <h3>Install Traintensity!</h3>
            </Modal.Header>
            <br/>
            {iosInstallPrompt && (
              <>
                <Modal.Description>
                  Note: You can only install from Safari browser on iOS!
                  <br/>
                  {renderInstagramNote()}
                  <br/>
                  In Safari, click
                  <img
                    src={iosShareIcon}
                    style={{ margin: 'auto 8px 8px' }}
                    className=""
                    alt="Add to homescreen"
                    width="20"
                  />
                  then &quot;Add to Home Screen&quot;
                </Modal.Description>
                <div className="d-flex justify-content-center">
                  <Button onClick={handleIOSInstallDeclined}>Close</Button>
                </div>
              </>
            )}
            {webInstallPrompt && (
              <div className="d-flex justify-content-around">
                <Button onClick={handleWebInstallDeclined}>Close</Button>
                <Button color="primary" onClick={handleWebInstallAccepted}>
                  Install
                </Button>
              </div>
            )}
          </Modal.Content>
      </Modal>
    </TransitionablePortal>
  );
};
