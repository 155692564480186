import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Select, Image, Segment, List, Header, Icon } from 'semantic-ui-react';
import Debug from '../../Debug';
import { retrieveUserConfig, updateUserConfig } from '../../actions';
import { StyledContainer, SizingContainer, HorizontalDiv } from '../StyledComponents';
import { NavLink } from 'react-router-dom';
import { UpgradeModal } from '../common/UpgradeModal';
import { CalculatorModal} from '../common/CalculatorModal';
import { ExportModal } from '../common/ExportModal';
import { FeedbackModal } from '../common/FeedbackModal';
import { SuccessPortal } from '../common/SuccessPortal';
import { ListItem } from '../common/ListItem';
import HeaderGeneric from '../menus/HeaderGeneric';
import HeaderMain from './HeaderMain';
import { firebaseAuth } from '../../firebase';
import { getExerciseOption, kgToLbs, lbsToKg, hasPro } from '../../commonMethods';

  const INITIAL_STATE = {
    workingMaxes : [{lift: "Snatch", weight: 0},
                      {lift: "Clean", weight: 0},
                      {lift: "SplitJk", weight: 0},
                      {lift: "CleanAndJerk", weight: 0},
                      {lift: "FrontSquat", weight: 0},
                      {lift: "BackSquat", weight: 0},
                      {lift: "BenchPress", weight: 0},
                      {lift: "Deadlift", weight: 0},
                      {lift: "MilitaryPress", weight: 0}
                    ]
  }

class Profile extends Component {

  constructor() {
    super();
    this.state = INITIAL_STATE
  }

  componentDidMount() {
      window.scrollTo(0, 0);
      this.props.retrieveUserConfig();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.config && nextProps.config.user && nextProps.config.user.workingMaxes) {
        return {workingMaxes : nextProps.config.user.workingMaxes}
    }
    else return INITIAL_STATE;
  }


  findRecordAndUpdate = (lift, weight) => {
    if (!weight || isNaN(weight))
      weight = 0

    let newWorkingMaxes = [...this.state.workingMaxes]
    newWorkingMaxes.forEach((obj, i) => {
        if (obj.lift === lift) {
          obj.weight = parseFloat(weight)
        }
    })
    Debug(newWorkingMaxes);
    return newWorkingMaxes;
  }

  convertWorkingMaxes = (unit) => {
    let newWorkingMaxes = [...this.state.workingMaxes];
    if (unit === 'kg') {
      newWorkingMaxes.forEach((obj, i) => {
            obj.weight = lbsToKg(obj.weight)
      })
    }
    else if (unit === 'lbs') {
      newWorkingMaxes.forEach((obj, i) => {
            obj.weight = kgToLbs(obj.weight);
      })
    }
    else return null;

    this.props.updateUserConfig('workingMaxes', newWorkingMaxes)
  }

  render() {
    const { config, updateUserConfig } = this.props
    const {user, subscriptions } = config;
    let  intensityThreshold, defaultUnit = null;
    if (user) {
      intensityThreshold = user.intensityThreshold;
      defaultUnit = user.defaultUnit
    }

    const profilePic = firebaseAuth.currentUser.photoURL;
    return (
      <>
        <HeaderMain activeItem='Profile'/>
        <StyledContainer>
          <SizingContainer>
          {config && config.success && <SuccessPortal text={config.success}></SuccessPortal>}
            <br/>
          <StyledContainer>
            <HeaderGeneric
              text="Profile" />
              <Image src={profilePic} alt='' size='tiny' circular />
              <Header style={{margin:'8px'}} as='h2'>{firebaseAuth.currentUser.displayName}</Header>
            </StyledContainer>
            <NavLink to='/team'><Button circular color='black' compact > {config.team && config.team.id ? `Team ${config.team.name}` : 'Join A Team'} </Button></NavLink>
            <Segment>
              <List divided>
                <ListItem
                  leftHandHeader="Email"
                  rightHand={<HorizontalDiv>
                      <p>{firebaseAuth.currentUser.email}</p>
                    </HorizontalDiv>}
                />
                <ListItem
                    leftHandHeader="Team"
                    rightHand={config.team  && config.team.name && <>{config.team.name}&nbsp;&nbsp;</>}
                />
                <ListItem
                  leftHandHeader="Default Weight Unit:"
                  rightHand={<Select style={{minWidth : '8em', border : 'none'}}
                                placeholder='Default Unit'
                                options={[
                                  { key: 'kg', value: 'kg', text: 'kg' },
                                  { key: 'lbs', value: 'lbs', text: 'lbs' }]}
                                value={defaultUnit}
                                onChange={(e, data) => {
                                    updateUserConfig('defaultUnit' , data.value);
                                    this.convertWorkingMaxes(data.value);
                                }}
                                />}
                />
                <ListItem
                  leftHandHeader="Intensity Threshold:"
                  subHeader='Weights below the threshold will be considered warmup sets'
                  rightHand={<Select style={{minWidth : '8em', border : 'none'}}
                                placeholder='Intensity Threshold' options={[
                                  { key: '0', value: '0', text: '0%' },
                                  { key: '40', value: '40', text: '40%' },
                                  { key: '50', value: '50', text: '50%' },
                                  { key: '60', value: '60', text: '60%' },
                                  { key: '65', value: '65', text: '65%' },
                                  { key: '70', value: '70', text: '70%' },
                                  { key: '75', value: '75', text: '75%' }]
                                }
                  value={intensityThreshold}
                  onChange={(e, data) => updateUserConfig('intensityThreshold' , data.value)}  />}
                />
              </List>
            </Segment>
            <>
              <Header> Working Maxes
                <Header.Subheader style={{textAlign: 'left'}}>
                  <HorizontalDiv>
                    <span>Enter your 1RM for the main lifts you will train.
                        These inputs will be used to autofill intensities.
                        </span>
                        <CalculatorModal/>
                    </HorizontalDiv>
                </Header.Subheader>
              </Header>
            <Segment>

              <List divided>
               { this.state.workingMaxes.map((record, i) => {
                 return <ListItem
                     key={"working-max-" + i}
                     leftHandHeader={getExerciseOption(record.lift).text}
                     rightHand={<HorizontalDiv>
                         <Input
                           type='tel'
                           value = {record.weight}
                           onChange={(e, data) => this.setState(this.findRecordAndUpdate(record.lift, data.value))}
                         >
                         </Input>
                       </HorizontalDiv>}
                   />
               })}
              </List>
              <Button primary fluid
                onClick={() => updateUserConfig('workingMaxes', this.state.workingMaxes)}>
                Save
              </Button>
            </Segment></>
            {config && <ExportModal
                trigger={<Button icon
                           style={{background:'transparent'}}
                           type='button'
                           size='large'
                           variant='primary'
                          >
                            Export Data &nbsp;
                            <Icon name='download'/>
                          </Button>}
               customExercises={config.customExercises}
               athlete={firebaseAuth.currentUser.uid}
               hasPro={hasPro(subscriptions)}
            />}
            <br/>
            <FeedbackModal
              trigger={<Button icon style={{background:'transparent'}}
               type='button'
               size='large'
               variant='primary'>Send Feedback &nbsp;
               <Icon name='send'/>
               </Button>}
            />
            <br/>
            {<UpgradeModal
                subscribed={hasPro(subscriptions)}
              />}
            <br/><br/>
            <Button onClick={() => firebaseAuth.signOut()}> Sign Out </Button>
            <br/><br/><p> Version 1.4.0</p>
            <p> <a href='https://traintensity.com/privacy-policy/'> Privacy Policy </a> and <a href='https://traintensity.com/terms-of-use'> Terms of Use </a> </p>
          </SizingContainer>
        </StyledContainer>
      </>
    );
  }

}

const mapStateToProps = (state) => {
  Debug(state)
  return {
    config: state.config
  }
}

export default connect(mapStateToProps, {retrieveUserConfig, updateUserConfig})(Profile);
