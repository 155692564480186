import update from 'immutability-helper';
import Debug from '../Debug';
import { START_PROGRAM_BUILDER, CLEAR_SELECTED_PROGRAM, UPDATE_PROGRAM_BUILDER_FIELD, UPDATE_PROGRAM_BUILDER_LAYOUT, ADD_PROGRAM_BUILDER_LAYOUT,
          ADD_PROGRAM_BUILDER_WEEK, ADD_PROGRAM_BUILDER_DAY, DELETE_PROGRAM_BUILDER_LAYOUT, ADD_PROGRAM_BUILDER_WORKOUT, SAVE_PROGRAM, SAVE_PROGRAM_SUCCESS,
          SAVE_PROGRAM_FAIL, DELETE_PROGRAM, DELETE_PROGRAM_SUCCESS, DELETE_PROGRAM_FAIL, RETRIEVE_PROGRAMS, RETRIEVE_PROGRAMS_FAIL, RETRIEVE_PROGRAMS_SUCCESS,
          SELECT_PROGRAM, RETRIEVE_PROGRAM_LAYOUT, RETRIEVE_PROGRAM_LAYOUT_SUCCESS, RETRIEVE_PROGRAM_LAYOUT_FAIL, RETRIEVE_PROGRAM_HISTORY, RETRIEVE_PROGRAM_HISTORY_SUCCESS,
          RETRIEVE_PROGRAM_HISTORY_FAIL, CLEAR_PROGRAMS
      } from '../actions/types';
import { BLOCKS } from '../assets/literals';

const INITIAL_STATE =  {
  error: '',
  success: '',
  loading : 'false',
  didInvalidate: false,
  selectedProgram: null,
  userPrograms : [],
  programs : [],
  programBuilder: {},
  programHistory : []
}

const ProgramReducer = (state = INITIAL_STATE, action) => {
    Debug(action)
    switch (action.type) {
      case START_PROGRAM_BUILDER:
        return { ...state, selectedProgram: 1, programBuilder: action.payload, error: '' }
      case CLEAR_SELECTED_PROGRAM:
       return { ...state, selectedProgram: null, programBuilder: {} };
      case SELECT_PROGRAM:
        return { ...state, selectedProgram: action.payload.id, programBuilder: {...action.payload }};
      case UPDATE_PROGRAM_BUILDER_FIELD:
        return { ...state,
                 programBuilder: {...state.programBuilder, [action.payload.key]: action.payload.value, error: '' }};
      case UPDATE_PROGRAM_BUILDER_LAYOUT:
        return update(state, {
            programBuilder : {
              layout: {
                [action.payload.section] : {
                  [action.payload.id] : {
                    [action.payload.key] : {$set: action.payload.value}
                }
              }
            }
          }
        });
        case ADD_PROGRAM_BUILDER_LAYOUT:
          return update(state, {
              programBuilder : {
                layout: {
                  [action.payload.section] : {
                    $merge: { [action.payload.obj.id] : action.payload.obj }
                  },
                  blockOrder : current => action.payload.section === BLOCKS ? [...current, action.payload.obj.id ] : current
                }
            }
          });
          case ADD_PROGRAM_BUILDER_WEEK:
            return update(state, {
                programBuilder : {
                  layout: {
                    weeks : {
                      $merge: { [action.payload.obj.id] : action.payload.obj }
                    },
                    blocks : {
                      [action.payload.blockId] : {
                        weeks : {$push: [action.payload.obj.id]}
                      }
                    }
                  }
              }
            });
            case ADD_PROGRAM_BUILDER_DAY:
              return update(state, {
                  programBuilder : {
                    layout: {
                      days : {
                        $merge: { [action.payload.obj.id] : action.payload.obj }
                      },
                      weeks : {
                        [action.payload.weekId] : {
                          days : {$push: [action.payload.obj.id]}
                        }
                      }
                    }
                }
              });
              case ADD_PROGRAM_BUILDER_WORKOUT:
                return update(state, {
                    programBuilder : {
                      layout: {
                        workouts : {
                          $merge: { [action.payload.obj.id] : action.payload.obj }
                        },
                        days : {
                          [action.payload.dayId] : {
                            workouts : {$push: [action.payload.obj.id]}
                          }
                        }
                      }
                  }
                });
              case DELETE_PROGRAM_BUILDER_LAYOUT:
                return update(state, {
                    programBuilder : {
                      layout: {
                        [action.payload.section] : {
                          $unset: [action.payload.id]
                      },
                      blockOrder : current => action.payload.section === BLOCKS ? current.filter(block => block !== action.payload.id) : current
                    }
                  }
                });
            case SAVE_PROGRAM:
                return { ...state, loading: true, error: '' };
            case SAVE_PROGRAM_SUCCESS:
                return { ...state,  loading: false, success : action.payload.success, error: '', programBuilder : { ...action.payload.program, id: action.payload.id }};
            case SAVE_PROGRAM_FAIL:
                return { ...state, loading: false, error: action.payload, didInvalidate: false };
            case DELETE_PROGRAM:
                return { ...state, loading: true, error: '' };
            case DELETE_PROGRAM_SUCCESS:
              return { ...state,  loading: false, error: '', didInvalidate: true, selectedProgram: null, programBuilder: {} };
            case DELETE_PROGRAM_FAIL:
              return { ...state, loading: false, error: action.payload, didInvalidate: false };
            case CLEAR_PROGRAMS:
              return { ...state, loading: false, error: '', didInvalidate: false, selectedProgram: null, programBuilder :{}, programs: [], userPrograms: []};
            case RETRIEVE_PROGRAMS:
              return { ...state, loading: true, error: '', didInvalidate: false, selectedProgram: null, programBuilder :{}};
            case RETRIEVE_PROGRAMS_SUCCESS:
              let key = 'programs';
              if (action.payload.isUserPrograms === true)
                key = 'userPrograms';
              return update(state, {
                      [key] : { $push: action.payload.programs },
                      loading : { $set: false },
                      error : { $set: '' }
                    }
              );
            case RETRIEVE_PROGRAMS_FAIL:
              return { ...state, loading: false, error: action.payload, didInvalidate: false, selectedProgram: null, programBuilder: {} };
            case RETRIEVE_PROGRAM_LAYOUT:
              return { ...state, loading: true, error: '', didInvalidate: false, selectedProgram: null, programBuilder :{}};
            case RETRIEVE_PROGRAM_LAYOUT_SUCCESS:
              return { ...state,  selectedProgram: action.payload.id, programBuilder : action.payload.program, loading: false, error: '', didInvalidate: false };
            case RETRIEVE_PROGRAM_LAYOUT_FAIL:
              return { ...state, loading: false, error: action.payload, didInvalidate: false, selectedProgram: null, programBuilder: {} };
            case RETRIEVE_PROGRAM_HISTORY:
              return { ...state, programHistory : []};
            case RETRIEVE_PROGRAM_HISTORY_SUCCESS:
              return { ...state,  programHistory : action.payload};
            case RETRIEVE_PROGRAM_HISTORY_FAIL:
              return { ...state, error: action.payload, programHistory : [] };
      default:
        return state;
  }
}

export default ProgramReducer;
