import React, { useState } from 'react';
import { Select, Button, Modal, Icon, Radio, Form } from 'semantic-ui-react';
import { UpgradeModal } from '../common/UpgradeModal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Debug from '../../Debug';
import { StyledContainer, HorizontalDiv } from '../StyledComponents';
import { firebaseDb } from '../../firebase';
import { STRENGTH, CONDITIONING, READABLE, EXCEL } from '../../assets/literals';
import { scoreToString, replaceNewLines, getExerciseOption, workoutToSummary } from '../../commonMethods';

export const ExportModal = ({ trigger, hasPro, athlete, customExercises }) => {
        const [open, setOpen] = useState(false);
        const [loading, setLoading] = useState(false);
        const [exportType, setExportType] = useState(READABLE);
        const [type, setType] = useState(STRENGTH)
        const [workouts, setWorkouts] = useState([]);
        const [start, setStart] = useState();
        const [end, setEnd] = useState();
        const [error, setError] = useState('');

        const exportData = (athlete) => {
          Debug('exporting...');
          setWorkouts([]);
          setLoading(true)
          if (!hasPro && numDaysBetween(start, new Date()) > 30)
            setError('You are only able to export up to 30 days back. Export over 30 days is available with Pro version!');
          else
            retrieveWorkoutsForExport(athlete, start, end);

          setLoading(false);
        }

        const numDaysBetween = function(d1, d2) {
          var diff = Math.abs(d1.getTime() - d2.getTime());
          return diff / (1000 * 60 * 60 * 24);
        };

        const handleExportClick = () => {
          let fileContent = '', fileType = 'txt';
          if (exportType === EXCEL){
              fileContent = toCSV();
              fileType = 'csv'
          }
          else {
            fileContent = toTXT()
          }
          //var encodedUri = encodeURI(csvContent);
          //window.open(encodedUri);
          var hiddenElement = document.createElement('a');
          hiddenElement.href = 'data:text/' + fileType + ';charset=utf-8,' + encodeURI(fileContent);
          hiddenElement.target = '_blank';
          hiddenElement.download = 'workout-export_' + start.toLocaleDateString() + '-' + end.toLocaleDateString() + '.' + fileType;
          hiddenElement.click();
        }

        const toTXT = () => {
          let txtContent = '';
          workouts.forEach(element => {
            txtContent += workoutToSummary(element, customExercises);
          });
          return txtContent;
        }

        const toCSV = () => {
          let csvContent = '';
          if (type === CONDITIONING) {
            csvContent = "Date, WorkoutName, Title, Description, Type, Score, Benchmark, Notes, WorkoutNotes\r\n";
            workouts.forEach(element => {
              csvContent += workoutToCSV(element, CONDITIONING);
            })
          }
          else {
            /*
            csvContent += "Date/Tm,Exercise,Weight,Set,Set,Set,Set,Set,Set,Set,Set,Set,Set\r\n";
            workouts.forEach(element => {
              csvContent += workoutToArtieCSV(element);
            });
            */
            csvContent = "Date, WorkoutName, Category, Subcategory, Exercise,ComplexId, Weight, Unit, Sets, Reps, WorkingMax, Notes, Set1,Set2,Set3,Set4,Set5,Set6,Set7,Set8,Set9,Set10, WorkoutNotes, Difficulty, Sleep, Fatigue, Bodyweight, Bodyweight Unit\r\n";
            workouts.forEach(element => {
              csvContent += workoutToCSV(element, STRENGTH);
            });
          }
          return csvContent;
        }
/*
        const patternToCode = (pattern) => {
          let code = pattern.match(/1/g);
          code = (code && code.length > 0) ? code.length : 0;
          let dot = false;
          for (var i=0; i<pattern.length; i++) {
            if (parseInt(pattern.charAt(i)) !== 1) {
              if (!dot) {
                code += '.';
                dot = true;
              }
              code+= pattern.charAt(i);
            }
          }
          return code;
        }
*/
/*
        const workoutToArtieCSV = (workout) => {
          const { date, exerciseArr } = workout;
          const dateString = new Date(date.seconds*1000);
          const dateTime = dateString.toLocaleDateString() + "." + dateString.toLocaleString('en-US', { hour: 'numeric', hour12: true }) + ',';
          let csvString = "";

          exerciseArr.forEach( (exerciseObj, exerciseIndex) => {
            csvString += dateTime;
              const { type, exercise, complexId, weightArr } = exerciseObj;
              if (type === CONDITIONING) {
                //csvString += 'conditioning...'
              }
              else {
              csvString += exercise + ","
                        + (complexId || "") + ",";
              const numWeights = weightArr.length;
              weightArr.forEach((weightObj, weightIndex) => {
                const {weight, setArr} = weightObj;
                csvString += weight + ','
         //       					+ unit + ','
          ///						+ sets + ','
          //						+ reps + ',';
                for (var i=0; i<10; i++) {
                  if (setArr[i])
                csvString += patternToCode(setArr[i].pattern)

                   csvString += ",";
                }
                if (weightIndex < numWeights-1)
                  csvString+="\r\n,,,";
              })
            }
            csvString += "\r\n";
          })
          //csvString += "\r\n";  //";";//"\n";
          return csvString;
        }

*/

        const workoutToCSV = (workout, workoutType) => {
          let csvString = "";
          const { date, name, exerciseArr, difficulty, sleep, fatigue, bodyweight, bodyweightUnit, defaultUnit } = workout;
          const workoutNotes = workout.notes;
          const dateString = new Date(date.seconds*1000);
        //  const dateTime = dateString.toLocaleDateString() + "." + dateString.toLocaleString('en-US', { hour: 'numeric', hour12: true }) + ',';
          const dateTime = dateString.toLocaleDateString();

          exerciseArr.forEach( (exerciseObj, exerciseIndex) => {
            const { type } = exerciseObj;
            if (workoutType === CONDITIONING) {
              if (type === CONDITIONING) {
              const {title, description, wodType, score, benchmark, notes } = exerciseObj;
              csvString += dateTime + ','
                        + name + ','
                        + title + ','
                        + replaceNewLines(description, '\\n ') + ','
                        + wodType + ','
                        + scoreToString(score, wodType) + ','
                        + benchmark +','
                        + replaceNewLines(notes, '\\n ') + ','
                        + replaceNewLines(workoutNotes, '\\n ');
                  csvString += "\r\n";
                }
            }
            else {
              if (type === STRENGTH || !type) {
              const { exercise, complexId, weightArr, workingMax, exerciseNotes } = exerciseObj;
              weightArr.forEach((weightObj, weightIndex) => {
                const { category, category_level_2, text } = getExerciseOption(exercise, customExercises);
                let exerciseString = dateTime + ',' + name + ',' + category + ',' + category_level_2 + ',' + (text || exercise) + "," + (complexId || "") + ",";
                const {weight, unit, sets, reps, setArr} = weightObj;
                exerciseString += weight + ','
                          + unit + ','
                      + sets + ','
                      + reps + ','
                      + workingMax + ','
                      + replaceNewLines(exerciseNotes, '\\n ') + ',';
                for (var i=0; i<10; i++) {
                  if (setArr[i])
                    exerciseString += setArr[i].pattern

                   exerciseString += ",";
                }
                exerciseString += workoutNotes + ',' + (difficulty || '') + ',' + (sleep || '') + ',' + (fatigue || '') + ',' + (bodyweight || '') + ',' + (bodyweightUnit || (bodyweight ? defaultUnit : ''));
                csvString += exerciseString + '\r\n';
              })
            }
          }
          })
          //csvString += "\r\n";  //";";//"\n";
          return csvString;
        }

        async function retrieveWorkoutsForExport(athlete, start, end) {
          return await firebaseDb.collection('workouts')
              .where('userId', '==', athlete)
              .where('date', '>=', start)
              .where('date', '<=', end)
            .get().then((querySnapshot) => {
              const workouts = [];
              querySnapshot.forEach(doc => {
                Debug(doc.ref)
                Debug(doc.id, doc.data());
                Debug(JSON.stringify(doc.data()))
                  workouts.push(doc.data());
              })
              if (workouts.length > 0)
                setWorkouts(workouts);
              else
                setError('No workouts retrieved for this time frame.');
            }).catch((error) => {
                Debug("Error getting document:", error);
                setError(error.message);
            });
        }

        const renderDatePicker = (selected, onChange, placeholder) => {
          return <>
              <DatePicker
                className='ui button large'
                dateFormat="MMM d, yyyy"
                selected={selected}
                onChange={date => {
                  setWorkouts([]);
                  setError('');
                  onChange(date)
                }}
                placeholderText={placeholder}
                showMonthDropdown
                showYearDropdown
              />
            </>
        }

        const onClose = () => {
          setOpen(false);
          setLoading(false);
          setWorkouts([]);
          setStart();
          setEnd();
          setError('');
        }

        return (
          <Modal
            size='mini'
            onClose={onClose}
            onOpen={() => setOpen(true)}
            open={open}
            closeIcon
            trigger={trigger}
          >
            <Modal.Header>Export Data</Modal.Header>
            <Modal.Content>
              <StyledContainer>
              <Form>
                <Form.Field>
                  What type of export would you like to receive?
                </Form.Field>
                  <Form.Field>
                    <Radio
                        label='Readable (.txt)'
                        name='radioGroup'
                        value={READABLE}
                        checked={exportType === READABLE}
                        onChange={(e, { value }) => setExportType(READABLE)}
                      />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label='Excel (.csv)'
                      name='radioGroup'
                      value={EXCEL}
                      checked={exportType === EXCEL}
                      onChange={(e, { value }) => setExportType(EXCEL)}
                    />
                  </Form.Field>
                </Form>
                <br/>
                {exportType === EXCEL && <Select placeholder='Select type'
                  options={[  { key: 'strength', value: 'strength', text: 'Strength' },
                            { key: 'conditioning', value: 'conditioning', text: 'Conditioning' }]}
                  value={type}
                  onChange={(e, data) => setType(data.value)}
                />}
                <br/>
                <HorizontalDiv>
                    {renderDatePicker(start, setStart, 'Start')}
                </HorizontalDiv>
                <br/>
                <HorizontalDiv>
                    {renderDatePicker(end, setEnd, 'End')}
                </HorizontalDiv>
                <br/>
                {workouts.length > 0 ?
                  <Icon size='big' color='green' name='check circle'/> :
                  <Button
                          primary
                          variant='primary'
                          loading={loading}
                          disabled={error || (!loading && workouts.length > 0)}
                          onClick={(e) => {exportData(athlete, workouts)}}
                >
                    Load
                </Button>}
                {error &&
                    <>
                      <p className='ui error message'>{error}</p>
                      <UpgradeModal/>
                    </>
                }
                </StyledContainer>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={onClose}>
                Cancel
              </Button>
              <Button
                content="Export"
                loading={loading}
                disabled={!loading && workouts.length === 0}
                primary
                onClick={handleExportClick}
              />
            </Modal.Actions>
          </Modal>
        )
  }
