import React from 'react';
import { Embed, Button, Icon, Modal } from 'semantic-ui-react';

export const EmbedModal = ({ videoId}) => {
        const [open, setOpen] = React.useState(false)

        return (
          <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            centered
            closeIcon
            trigger={<Button compact type='button' style={{background: 'transparent'}}><Icon name='youtube' size='large' /></Button>}
          >
            <Modal.Content>
                  <Embed
                    autoplay
                    id={videoId}
                    placeholder={'https://img.youtube.com/vi/' + videoId + '/sddefault.jpg'}
                    source='youtube'
                  />
            </Modal.Content>
          </Modal>
        )
  }
