import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
//import Debug from '../Debug';

import { firebaseConfig } from './config';


export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebase.auth();
export const firebaseDb = firebase.firestore();
export const firebaseAnalytics = firebase.analytics();

/*
const firestorePersistenceEnabler = () => {
  console.log('FirestorePersistenceEnabler', navigator.serviceWorker);

  if (navigator.serviceWorker === undefined) {
    return false;
  }
  return true;
}

if (firestorePersistenceEnabler()) {
  try {
  firebaseDb.enablePersistence({ synchronizeTabs: true })
    .catch(function(err) {
        console.log(err);
        if (err.code === 'failed-precondition') {
            // Multiple tabs open, persistence can only be enabled
            // in one tab at a a time.
            // ...
            Debug('Failed precondition');
        } else if (err.code === 'unimplemented') {
            // The current browser does not support all of the
            // features required to enable persistence
            // ...
            Debug('Current Browser does not support');
        }
    })
  }
  catch (error) {
    console.log(error);
  }
 }

 */
